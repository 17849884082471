import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { TabGroupConfig } from '@components/tab-group/route-tab-group.component';

@Component({
  selector: 'aux-timeline-group',
  templateUrl: './timeline-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineGroupComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Trial Timeline',
      route: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE,
    },
    {
      label: 'Payment Milestones',
      route: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.PAYMENT_MILESTONES,
    },
  ];
}
