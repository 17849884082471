import { GridApi } from '@ag-grid-community/core';

export function AgGridGetData<T>(gridApi: GridApi) {
  const values: T[] = [];

  gridApi.forEachNode(({ data }) => {
    values.push({
      ...data,
    });
  });

  return values;
}
