import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonToggleItem } from '@components/button-toggle-group/button-toggle-item.model';
import { Option } from '@components/components.type';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';
import { MessagesConstants } from '@constants/messages.constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkflowStep } from '@services/gql.service';
import { Maybe } from '@services/utils';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { BehaviorSubject } from 'rxjs';

export interface ProtocolForm {
  protocolVersion: string | null;
  patientGroup: string | null;
}

@UntilDestroy()
@Component({
  selector: 'aux-protocol-section',
  templateUrl: './protocol-section.component.html',
  styleUrls: ['./protocol-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolSectionComponent implements OnInit {
  @Input() versionOptions!: (Option & { effective_date?: Maybe<string> })[];

  @Input() patientOptions!: ButtonToggleItem[];

  @Input() template?: TemplateRef<unknown>;

  @Input() className = '';

  @Input() alwaysShowSection = false;

  @Input() isAmendmentDisabled = false;

  @Input() isVersionControlEditable = false;

  @Input() userHasUpdateProtocolEntryPermission = false;

  @Output() editProtocolVersion = new EventEmitter<EditableListDropdownItem>();

  @Output() deleteProtocolVersion = new EventEmitter<EditableListDropdownItem>();

  @Input() hideAmendment = false;

  @Output() valuesChange = new EventEmitter();

  @Output() formReady = new EventEmitter<FormGroup>();

  @Output() amendmentClick = new EventEmitter<boolean>();

  @Input() labelDirection: 'horizontal' | 'vertical' = 'horizontal';

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  patientTrackerLockedTooltip = computed(() =>
    this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : ''
  );

  form = this.fb.group<ProtocolForm>({
    protocolVersion: null,
    patientGroup: null,
  });

  constructor(
    private fb: FormBuilder,
    private workflowQuery: WorkflowQuery
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((formValues) => {
      this.valuesChange.emit(formValues);
    });

    this.formReady.emit(this.form);
  }

  onCreateAmendment = () => {
    this.amendmentClick.emit(true);
  };

  getOptionWithVisibility(option: ButtonToggleItem): ButtonToggleItem {
    return !option.show ? { ...option, show: new BehaviorSubject(true) } : option;
  }

  convertOptionToEditableState(): EditableListDropdownItem[] {
    return this.versionOptions.map((item, i, arr) => ({
      name: item.label,
      value: item.value,
      showLine: i === 1 && arr.length !== 1,
      isEditable: this.userHasUpdateProtocolEntryPermission,
      isDeletable: i !== 0 && this.userHasUpdateProtocolEntryPermission,
      bottomText: `Effective Date: ${item.effective_date || ''}`,
      disabled: this.isPatientsFinalized(),
      tooltip: this.patientTrackerLockedTooltip(),
    }));
  }
}
