import { Injectable } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import {
  TrialInsightsScreenFailKey,
  TrialInsightsTableModel,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsClinicalScreenFailTableService implements TrialInsightsTableModel {
  route = ROUTING_PATH.INVESTIGATOR.INDEX;
  subRoute = ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS;

  createTable = (
    selectedKey: TrialInsightsScreenFailKey = TrialInsightsScreenFailKey.NUMBER,
    rowData: TrialInsightsTableRowData[] = []
  ): TrialInsightsTableOptions => {
    return {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [
          { key: 'number', value: '# Screen Fails' },
          { key: 'percent', value: '% Screen Fails' },
        ],
      },
      header: {
        display: true,
        data: [
          { buttonKey: 'number', leftValue: 'Site', rightValue: 'Screen Fails' },
          { buttonKey: 'percent', leftValue: 'Site', rightValue: 'Screen Fails' },
        ],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'Investigator Transactions',
      },
    };
  };
}
