import { RouterModule } from '@angular/router';
import { Component } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-no-protocol-versions-link',
  template: `<div class="bg-[#FFF0B5] p-4">
    Please enter a
    <a class="aux-link inline-block" [routerLink]="[protocolEntryLink]">Protocol</a>
    before adding Site Costs
  </div>`,
  standalone: true,
  imports: [RouterModule],
})
export class NoProtocolVersionsLinkComponent {
  protocolEntryLink = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY}`;
}
