import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { TrialsService } from '@models/trials/trials.service';
import { TrialImplementationStatus } from '@services/gql.service';
import { Utils } from '@services/utils';

@UntilDestroy()
@Component({
  selector: 'aux-new-trial-modal',
  templateUrl: './new-trial-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTrialDialogComponent {
  fg = this.formBuilder.group({
    nct_id: '',
    onboarding_complete: false,
    auxilius_start_date: null,
  });

  customFg = this.formBuilder.group({
    sponsor_organization_name: '',
    name: '',
    short_name: '',
    therapy_area: '',
    auxilius_start_date: null,
    onboarding_complete: true,
    program: null,
    project: null,
    implementation_status: TrialImplementationStatus.IMPLEMENTATION_STATUS_IMPLEMENTATION,
  });

  blur$ = new Subject();

  cache: {
    [k: string]: { success: boolean; error?: string; data?: string } | undefined;
  } = {};

  sponsor$ = new BehaviorSubject<{ success: boolean; error?: string; data?: string } | undefined>(
    undefined
  );

  loading$ = new BehaviorSubject(false);

  implementationStatusOptions = Object.entries(Utils.TRIAL_IMPLEMENTATION_STATUS_OPTIONS)
    .map(([key, label]) => ({
      label,
      key,
    }))
    .slice(1);

  constructor(
    public ref: CustomOverlayRef<string>,
    private trialsService: TrialsService,
    private formBuilder: UntypedFormBuilder
  ) {}

  async onSubmit() {
    if (this.fg.valid) {
      const { nct_id, onboarding_complete, auxilius_start_date } = this.fg.value;

      const resp = await this.trialsService.add({
        nct_id,
        onboarding_complete,
        auxilius_start_date,
      });

      this.ref.close(resp);
    }
  }

  onInputBlur() {
    this.blur$.next(null);
  }

  async createCustomTrial() {
    if (this.customFg.valid && !this.loading$.getValue()) {
      this.loading$.next(true);
      const {
        sponsor_organization_name,
        name,
        short_name,
        therapy_area,
        auxilius_start_date,
        onboarding_complete,
        program,
        project,
        implementation_status,
      } = this.customFg.value;

      const resp = await this.trialsService.createCustomTrial({
        sponsor_organization_name,
        name,
        short_name,
        therapy_area,
        auxilius_start_date,
        onboarding_complete,
        program,
        project,
        implementation_status,
      });

      this.loading$.next(false);

      this.ref.close(resp);
    }
  }
}
