<aux-protocol-section
  *ngIf="(isProtocolVersionLoading$ | async) === false"
  className="mb-4"
  [versionOptions]="protocolVersionOptions"
  [patientOptions]="patientGroupOptions"
  [hideAmendment]="true"
  [alwaysShowSection]="true"
  [template]="exportButton"
  (formReady)="onProtocolFormReady($event)"
/>
<ng-template #exportButton>
  <div class="flex justify-between ml-4 mb-4">
    <div class="flex items-center space-x-4">
      <div
        *ngIf="
          (this.importSiteCostFF$ | async) === true &&
          (this.userHasUpdateSiteCostsPermission$ | async) === true
        "
      >
        <aux-button
          variant="secondary"
          label="Import Site Cost Data"
          icon="Upload"
          [disabled]="isPatientsFinalized()"
          [auxTooltip]="patientTrackerLockedTooltip()"
          [spinnerSize]="7"
          [onClick]="onImportSiteCostData.bind(this)"
        />
      </div>
      <div *ngVar="isBtnLoading('export') | async as isExportProcessing">
        <aux-export-excel-button
          [clickFN]="onExportPatientBudget.bind(this)"
          [loading]="isExportProcessing"
          [spinnerSize]="7"
          [disabled]="isExportProcessing || (loading$ | async)"
          pendoTag="patient-protocol-excel-export-button"
        />
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="!$any(loading$ | async)">
  <div class="mt-4">
    <aux-patient-budget-table
      [tableType]="
        protocolForm.controls.patientGroup.value === 'invoiceables'
          ? patientTableTypes.INVOICEABLES
          : patientTableTypes.VISITS_COSTS
      "
      [patientGroupId]="
        protocolForm.controls.patientGroup.value === 'invoiceables'
          ? ''
          : protocolForm.controls.patientGroup.value
      "
      (gridApiChanged)="handleGridApi($event)"
    />
  </div>
</div>
