import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';
import { ToggleComponent } from '@components/form-inputs/toggle/toggle.component';
import { EventType } from '@services/gql.service';
import { EventService } from '@services/event.service';
import { MainQuery } from '@shared/store/main/main.query';

import { BudgetCurrencyState } from './state/budget-currency.model';
import { BudgetCurrencyStore } from './state/budget-currency.store';
import { BudgetCurrencyType } from './budget-type';
import { ExchangeLinkComponent } from '@components/exchange-link/exchange-link.component';

@Component({
  selector: 'aux-toggle-budget-currency',
  template: `
    <div class="flex items-center">
      <div class="flex items-center space-x-2">
        <div>USD</div>
        <aux-toggle [disabled]="disabled" [value]="fc.value" (change)="onInputChange($event)" />
        <div>Vendor Currency</div>
      </div>
      <aux-exchange-link class="ml-4" />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ToggleBudgetCurrencyComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ToggleComponent, ExchangeLinkComponent],
})
export class ToggleBudgetCurrencyComponent extends ToggleComponent {
  constructor(
    private budgetCurrencyStore: BudgetCurrencyStore,
    private eventService: EventService,
    private mainQuery: MainQuery
  ) {
    super();

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.budgetCurrencyStore.update({ currency: BudgetCurrencyType.VENDOR });
      });
  }

  @Output() nameSelectedCurrency = new EventEmitter();

  @Input() onChangeEvent: (isSelected: boolean) => void = () => {};

  @Input() disabled = false;

  onInputChange(event: Event) {
    const valueToUpdateTo = {
      currency: event ? BudgetCurrencyType.VENDOR : BudgetCurrencyType.USD,
    } as BudgetCurrencyState;
    this.nameSelectedCurrency.emit(event ? BudgetCurrencyType.VENDOR : BudgetCurrencyType.USD);
    this.budgetCurrencyStore.update(valueToUpdateTo);
  }
}
