import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { AgSetColumnsVisible } from '@shared/utils';
import { BehaviorSubject } from 'rxjs';

interface CollapsibleHeaderParams extends IHeaderParams {
  collapsibleColumns: string[];
  collapsedByDefault: boolean;
  isColumnCollapsed$?: BehaviorSubject<boolean>;
}

@Component({
  template: `<div class="flex items-center space-x-2">
    <span
      class="text-base text-aux-gray-darkest"
      [ngClass]="{ 'cursor-pointer': isSortable }"
      (click)="onSortClick()"
      >{{ params.displayName }}</span
    >
    <button class="flex items-center no-underline aux-link" (click)="onChangeClick()">
      <span
        ref="eSortAsc"
        class="ag-sort-indicator-icon ag-sort-ascending-icon"
        *ngIf="params.column.isSortAscending()"
        ><span class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span
      ></span>

      <span
        ref="eSortDesc"
        class="ag-sort-indicator-icon ag-sort-descending-icon"
        *ngIf="params.column.isSortDescending()"
        ><span class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span
      ></span>

      <aux-icon
        class="text-aux-gray-darkest"
        [name]="(isExpanded$ | async) ? 'ChevronLeft' : 'ChevronRight'"
        [size]="20"
      />
    </button>
  </div> `,
  standalone: true,
  imports: [IconComponent, AsyncPipe, NgClass, NgIf],
})
export class AgCollapsibleHeaderComponent implements IHeaderAngularComp {
  params!: CollapsibleHeaderParams;

  isExpanded$ = new BehaviorSubject(true);

  isSortable = false;

  agInit(params: CollapsibleHeaderParams): void {
    this.params = params;

    this.isExpanded$.next(!this.params.collapsedByDefault);
    this.params.isColumnCollapsed$?.next(!this.isExpanded$.getValue());

    this.isSortable = !!params.column.getUserProvidedColDef()?.sortable;
  }

  refresh(): boolean {
    return false;
  }

  onSortClick() {
    if (!this.isSortable) return;

    const sort = this.params.column.getSort();
    const sortingAscending = sort === 'asc';
    const sortingDescending = sort === 'desc';
    const notSorting = !sortingAscending && !sortingDescending;

    if (notSorting) {
      this.params.setSort('asc');
      return;
    }

    if (sortingAscending) {
      this.params.setSort('desc');
      return;
    }

    if (sortingDescending) {
      this.params.setSort(null);
      return;
    }
  }

  updateVisibilityColumns() {
    if (!this.params.collapsibleColumns?.length) {
      console.warn('No collapsible columns has been found');
    }

    AgSetColumnsVisible({
      gridApi: this.params.api,
      keys: this.params.collapsibleColumns ?? [],
      visible: this.isExpanded$.getValue(),
    });

    this.params.api.sizeColumnsToFit();
  }

  onChangeClick() {
    this.isExpanded$.next(!this.isExpanded$.getValue());
    this.params.isColumnCollapsed$?.next(!this.isExpanded$.getValue());
    this.updateVisibilityColumns();
  }
}
