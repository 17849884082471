<ng-container *ngIf="(periodCloseComponent.loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="(periodCloseComponent.loading$ | async) === false" class="py-4 px-6">
  <div *ngIf="showAnalyticsSection$ | async">
    <div class="grid grid-cols-3 gap-7.5 mb-8">
      <div class="border rounded p-4 grid grid-cols-12 h-28">
        <div class="border-r flex flex-col justify-center col-span-6">
          <div class="font-bold leading-5">
            In-month <br />
            Overrides
          </div>
          <div class="text-xs">% of Total WP</div>
          <div class="text-xs">Overrides $</div>
        </div>
        <div
          class="pl-5 text-lg font-bold flex justify-center items-center col-span-6 tabular-nums"
        >
          <div>
            <div>12%</div>
            <div>$300,000</div>
          </div>
        </div>
      </div>
      <div class="border rounded p-4 grid grid-cols-12 h-28">
        <div class="border-r flex flex-col justify-center col-span-6">
          <div class="font-bold leading-5">
            Avg. Delta to <br />
            Vendor Fcts
          </div>
          <div class="text-xs">% of Total WP</div>
          <div class="text-xs">Average $</div>
        </div>
        <div
          class="pl-5 text-lg font-bold flex justify-center items-center col-span-6 tabular-nums"
        >
          <div>
            <div>24%</div>
            <div>$600,000</div>
          </div>
        </div>
      </div>

      <div class="border rounded p-4 grid grid-cols-12 h-28">
        <div class="border-r flex flex-col justify-center col-span-6">
          <div class="font-bold leading-5">
            Accrual vs. <br />
            Previous Qtr
          </div>
          <div class="text-xs">% Increase</div>
          <div class="text-xs">Increase $</div>
        </div>
        <div
          class="pl-5 text-lg font-bold flex justify-center items-center col-span-6 tabular-nums"
        >
          <div>
            <div>8%</div>
            <div>$82,000</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="gridOptions$ | async as go" style="width: 100%; overflow: auto">
    <div class="flex items-center justify-end gap-5 pb-4 pt-1 pr-1">
      <div class="flex items-center">
        <div class="text-sm mr-2">Vendor</div>
        <aux-vendor-dropdown
          [showAllOption]="true"
          [vendors]="$any(organizationQuery.allVendors$ | async)"
          [formControl]="selectedVendor"
          (onChange)="onVendorSelected($event)"
        />
      </div>

      <aux-export-excel-button
        [gridAPI]="gridAPI"
        [excelOptions]="excelOptions"
        [ignoreColsId]="['vendor_id']"
        [customColFilter]="customColFilter"
        [getDynamicExcelParamsCallback]="getDynamicExcelParams"
        pendoTag="reconciliation-excel-export-button"
      />
    </div>

    <ag-grid-angular
      domLayout="autoHeight"
      class="ag-theme-aux tabular-nums w-full"
      [gridOptions]="go"
      [rowData]="filteredGridData$ | async"
      (firstDataRendered)="onDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (columnResized)="autoSize()"
      (viewportChanged)="onWindowScroll()"
      (gridSizeChanged)="gridSizeChanged()"
    />
  </div>
</div>
