import { NgClass } from '@angular/common';
import { Component, EventEmitter, input, Output } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { OverlayService } from '@services/overlay.service';
import { ConfirmationActionModalComponent } from '@shared/components/confirmation-action-modal';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'aux-remove-site-budget',
  template: `<div
    class="flex items-center space-x-2 text-aux-error cursor-pointer"
    (click)="removeSiteBudget()"
    [ngClass]="{ 'opacity-50 !cursor-not-allowed': disabled() }"
    [auxTooltip]="disabled() ? 'Original Site Budget Version cannot be deleted.' : ''"
  >
    <aux-icon name="Trash" />
    <p class="text-sm">Delete Site Budget Amendment</p>
  </div>`,
  standalone: true,
  imports: [IconComponent, NgClass, TooltipDirective],
})
export class RemoveSiteBudgetComponent {
  @Output() removeSiteBudgetEvent = new EventEmitter<boolean>();

  disabled = input<boolean>();

  constructor(private overlayService: OverlayService) {}

  async removeSiteBudget() {
    if (this.disabled()) {
      return;
    }

    const modalEvent = this.overlayService.open<{ success: boolean }>({
      baseComponent: ConfirmationActionModalComponent,
      content:
        'This will permanently delete the selected site budget amendment under this Protocol Version. This action cannot be undone and will remove all associated data including all visit and invoiceable costs.',
      data: {
        header: 'Delete Site Budget Amendment?',
        keywordToExecuteAction: 'Delete Site Budget',
        okButtonText: 'Delete Site Budget',
        maxWidth: '530px',
      },
    }).afterClosed$;

    const resp = await firstValueFrom(modalEvent);

    this.removeSiteBudgetEvent.emit(!!resp.data?.success);
  }
}
