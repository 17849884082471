<div class="grid grid-cols-[1fr_1fr] gap-4 text-sm leading-7">
  <div class="col-span-2">
    <p class="font-bold">Site Name</p>
    <p>{{ site.name || zeroHyphen }}</p>
  </div>

  <div class="space-y-4 gap-2">
    <div>
      <p class="font-bold">Site #</p>
      <p>{{ site.site_no || zeroHyphen }}</p>
    </div>

    <div>
      <p class="font-bold">Managed by</p>
      <p>{{ site.investigator_name || zeroHyphen }}</p>
    </div>

    <div>
      <p class="font-bold">Site Activation Date</p>
      <p>{{ site.site_activation || zeroHyphen }}</p>
    </div>
  </div>

  <div class="space-y-4">
    <div>
      <p class="font-bold">Currency</p>
      <p>{{ site.currency || zeroHyphen }}</p>
    </div>

    <div>
      <p class="font-bold">Target Patients</p>
      <p>{{ site.target_patients || zeroHyphen }}</p>
    </div>

    <div>
      <p class="font-bold">Site Close Out Date</p>
      <p>{{ site.closeout_date || zeroHyphen }}</p>
    </div>
  </div>

  <div class="col-span-2">
    <p class="font-bold">Primary Investigator Name</p>
    <p>{{ site.primary_investigator_name || zeroHyphen }}</p>
  </div>

  <div class="flex flex-col space-y-1/2 col-span-2">
    <p class="font-bold">Address</p>
    @for (field of addressFields; track field) {
      @if (!!site[field]) {
        <div class="col-span-2">
          <p>{{ site[field] || zeroHyphen }}</p>
        </div>
      }
    }
  </div>
</div>
