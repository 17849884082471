import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { TabGroupConfig } from '@components/tab-group/route-tab-group.component';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { OrganizationService } from '@models/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventType, listUserNamesWithEmailQuery } from '@services/gql.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { ChangeOrderSharedService } from './tabs/change-order/state/change-order-shared.service';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';

@UntilDestroy()
@Component({
  selector: 'aux-budget-page',
  templateUrl: './budget-page.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetPageComponent {
  numberOfChangeOrdersInPendingApproval$ = this.mainQuery.select('numberOfCOsInPendingApproval');

  tabs: TabGroupConfig[] = [
    {
      label: 'Budget',
      route: ROUTING_PATH.BUDGET.INDEX,
    },
    {
      label: 'Budget Library',
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_budget_library),
      route: ROUTING_PATH.BUDGET.LIBRARY,
    },
    {
      label: 'Change Log',
      route: ROUTING_PATH.BUDGET.CHANGE_LOG,
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_changelog),
    },
    {
      label: 'Change Orders',
      route: ROUTING_PATH.BUDGET.CHANGE_ORDER,
      show: this.launchDarklyService.select$((flags) => flags.tab_change_order_review),
      badge: {
        amount$: this.numberOfChangeOrdersInPendingApproval$,
        tooltip: '# of Change Orders Pending Approval.',
      },
    },
    {
      label: 'Compare',
      route: ROUTING_PATH.BUDGET.COMPARE,
      show: this.launchDarklyService.select$((flags) => flags.tab_compare),
    },
  ];

  constructor(
    private launchDarklyService: LaunchDarklyService,
    public cdr: ChangeDetectorRef,
    public authQuery: AuthQuery,
    private organizationService: OrganizationService,
    private changeOrderSharedService: ChangeOrderSharedService,
    private mainQuery: MainQuery
  ) {
    this.mainQuery
      .selectProcessingEvent(EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED)
      .pipe(
        startWith(false),
        filter((isUploaded) => isUploaded),
        switchMap(() => this.organizationService.getListWithTotalBudgetAmount()),
        untilDestroyed(this)
      )
      .subscribe();

    this.mainQuery
      .select('userList')
      .pipe(untilDestroyed(this))
      .subscribe((_users) => {
        _users.forEach((user: listUserNamesWithEmailQuery) => {
          this.changeOrderSharedService.users.set(user.sub, user);
        });
      });
  }
}
