import { Component } from '@angular/core';
import { ButtonComponent } from '@components/button/button.component';
import { IconComponent } from '@components/icon/icon.component';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { MODAL_ANIMATIONS } from '@shared/animations/modal.animation';

@Component({
  templateUrl: './change-effective-date-modal.component.html',
  animations: MODAL_ANIMATIONS,
  standalone: true,
  imports: [IconComponent, ButtonComponent],
})
export class ChangeEffectiveDateModalComponent {
  header = 'Updating Site Budget Effective Date';

  constructor(public ref: CustomOverlayRef) {}

  closeModal() {
    this.ref.close({ success: false });
  }
}
