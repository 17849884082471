import { NgIf } from '@angular/common';
import { Component, OnInit, signal, ViewChild } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Option } from '@components/components.type';
import { FileManagerUploadedFilesComponent } from '@components/file-manager/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { IconComponent } from '@components/icon/icon.component';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { NgSelectModule } from '@ng-select/ng-select';
import { DocumentType, EntityType, EventType, GqlService } from '@services/gql.service';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import { SiteBudgetService } from '../services/site-budget.service';
import { firstValueFrom } from 'rxjs';
import { FormErrorDirective } from '@directives/form-error.directive';
import { Maybe } from '@services/utils';
import { isDuplicateError } from '@shared/utils';
import { MainQuery } from '@shared/store/main/main.query';
import { AuthQuery } from '@shared/store/auth/auth.query';

interface CreateAmendmentForm {
  protocolVersion: string | null;
  amendmentName: string | null;
  reason: string | null;
}

@Component({
  templateUrl: './site-budget-amendment-modal.component.html',
  styles: [
    `
      :host .site-budget-amendment-modal {
        .select {
          height: auto;
        }
      }
    `,
  ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    InputComponent,
    FileManagerComponent,
    IconComponent,
    FileManagerUploadedFilesComponent,
    NgIf,
    ButtonComponent,
    FormErrorDirective,
  ],
})
export class SiteBudgetAmendmentModalComponent implements OnInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  @ViewChild('siteBudgetNameInput') siteBudgetNameInput!: InputComponent;

  documentTypeOptions: Option[] = [];

  createAmendmentForm = this.fb.group<CreateAmendmentForm>({
    protocolVersion: this.ref.data?.protocolVersion || null,
    amendmentName: null,
    reason: null,
  });

  submitting = signal(false);

  constructor(
    public ref: CustomOverlayRef<
      unknown,
      { protocolVersionList: Option[]; protocolVersion: string; siteId: string }
    >,
    private fb: FormBuilder,
    private documentLibrary: DocumentLibraryService,
    private mainQuery: MainQuery,
    private siteBudgetService: SiteBudgetService,
    private authQuery: AuthQuery,
    private gqlService: GqlService
  ) {}

  ngOnInit(): void {
    this.documentTypeOptions = this.documentLibrary.getDocumentOptions();
  }

  async uploadFiles(siteBudgetId: string) {
    const { siteId } = this.ref.data || {};

    if (!this.fileManager || !siteId) return;

    const { trialKey } = this.mainQuery.getValue();

    await this.fileManager.fileService.uploadFiles(
      {
        documentType: DocumentType.DOCUMENT_SITE_AGREEMENTS,
        entity_type_id: EntityType.SITE_BUDGET_VERSION,
        site: siteId,
        entity_id: siteBudgetId,
      },
      true,
      true,
      `trials/${trialKey}/sites/${siteId}/${siteBudgetId}/contracts/`,
      true,
      true,
      false
    );

    const files = this.fileManager.fileQuery.getAll();
    const { reason } = this.createAmendmentForm.getRawValue();

    this.gqlService.processEvent$({
      type: EventType.SITE_CONTRACT_UPLOADED,
      entity_type: EntityType.DOCUMENT,
      entity_id: '',
      payload: JSON.stringify({
        site_id: siteId,
        uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
        file_names: files.map((file) => file.fileName).join(', '),
        site_budget_version_id: siteBudgetId,
        reason_for_amendment: reason,
      }),
    });
  }

  revalidateFields() {
    Object.values(this.createAmendmentForm.controls).forEach((control) => {
      control.markAsTouched();
      control.updateValueAndValidity();
    });
  }

  async createAmendment() {
    this.revalidateFields();

    if (this.createAmendmentForm.invalid) {
      return;
    }

    const { protocolVersion, amendmentName, reason } = this.createAmendmentForm.getRawValue();

    this.submitting.set(true);

    const { data: siteBudget, errors } = await firstValueFrom(
      this.siteBudgetService.createSiteBudgetVersion$({
        site_id: this.ref.data?.siteId || '',
        patient_protocol_version_id: <string>protocolVersion,
        name: <string>amendmentName,
        message: reason,
        files_uploaded:
          this.fileManager?.fileQuery
            .getAll()
            .map((x) => x.fileName)
            .join(', ') || '—',
      })
    );

    if (errors.length && errors.some((error) => isDuplicateError(error))) {
      this.siteBudgetNameInput.fc.setErrors({ duplicate_site_budget: true });
      this.submitting.set(false);
      return;
    }

    let reasonId: Maybe<string> = null;

    if (siteBudget) {
      await this.uploadFiles(siteBudget.id);

      if (reason) {
        const { data } = await firstValueFrom(
          this.siteBudgetService.createNote$(reason, siteBudget.id)
        );

        reasonId = data?.id;
      }
    }

    this.submitting.set(false);

    this.ref.close({
      siteBudget: {
        ...siteBudget,
        notes: reason ? [{ message: reason, id: reasonId }] : [],
        documents:
          this.fileManager?.fileQuery.getAll()?.map((file) => ({ ...file, name: file.fileName })) ||
          [],
      },
    });
  }
}
