<div *ngIf="isActivitiesTabVisible">
  <div class="hidden absolute sm:block top-0 right-0 pr-4 pt-4">
    <button
      id="closeButton"
      type="button"
      class="bg-white flex rounded-md text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      (click)="activitiesTabComponent.onCloseActivities()"
    >
      <span class="sr-only">Close</span>
      <aux-icon name="X" />
    </button>
  </div>

  <div class="text-2xl font-bold absolute pt-4 top-0">Manage Activities</div>
  <div class="flex items-center justify-between my-4 be-attributes-container">
    <div>
      <ng-select
        [(ngModel)]="activitiesTabComponent.selectedVendor"
        bindLabel="label"
        bindValue="id"
        [disabled]="true"
        [items]="activitiesTabComponent.vendors"
      />
    </div>
    <div>
      <aux-button
        variant="secondary"
        (clickEmit)="activitiesTabComponent.addNewRow()"
        icon="Plus"
        label="Add New Activity"
        classList="!border-none h-[35px]"
      />
    </div>
  </div>
</div>

<div *ngIf="!isActivitiesTabVisible">
  <div class="hidden absolute sm:block top-0 right-0 pr-4 pt-4">
    <button
      id="closeButton"
      type="button"
      class="bg-white flex rounded-md text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      (click)="attributesTabComponent.onClose()"
    >
      <span class="sr-only">Close</span>
      <aux-icon name="X" />
    </button>
  </div>

  <div class="text-2xl font-bold absolute pt-4 top-0">Edit Attributes</div>
  <div class="flex items-center justify-between be-attributes-container my-4">
    <div>
      <ng-select
        [(ngModel)]="attributesTabComponent.selectedVendor"
        bindLabel="label"
        bindValue="id"
        [disabled]="true"
        [items]="attributesTabComponent.vendors"
      />
    </div>
    <div>
      <aux-button
        variant="secondary"
        (clickEmit)="attributesTabComponent.addNewColumn()"
        icon="Plus"
        label="Add New Attribute"
        classList="!border-none h-[35px]"
        [disabled]="attributesTabComponent.addNewColumnDisabled"
      />
    </div>
  </div>
</div>

<div
  class="max-h-[532px] w-[1220px] h-[532px] mb-[16px] be-attributes-container relative overflow-y-auto border-b"
  style="box-shadow: inset 0px -5px 5px -5px rgba(102, 102, 102, 0.5)"
>
  <aux-activities-tab
    #activitiesTabComponent
    class="block"
    [ngClass]="{
      'invisible h-0 overflow-hidden': !isActivitiesTabVisible
    }"
    [gridData$]="gridData$"
    [data]="ref.data"
    [hasExternalChanges]="attributesTabComponent.isAttributesTabHasChanges()"
    (toggleTabs)="toggleToAttributesTab($event)"
    (save)="saveFromActivitiesTab($event)"
    (closeTab)="close()"
    [inAppDocumentState]="inAppDocumentState()"
  />
  <aux-attributes-tab
    #attributesTabComponent
    class="block"
    [ngClass]="{
      'invisible h-0 overflow-hidden': isActivitiesTabVisible
    }"
    [gridData$]="gridData$"
    [data]="ref.data"
    [categories]="categories"
    [hasExternalChanges]="activitiesTabComponent.isActivitiesTabHasChanges()"
    (toggleTabs)="toggleToActivitiesTab($event)"
    (save)="saveFromAttributesTab($event)"
    (closeTab)="close()"
    [inAppDocumentState]="inAppDocumentState()"
  />
  <div class="w-[1200px]">
    <ng-container *ngTemplateOutlet="InAppNotesAndDocuments"></ng-container>
  </div>
</div>
<div *ngIf="isActivitiesTabVisible" class="flex items-center justify-between">
  <div>
    <aux-button
      label="Cancel"
      variant="hyperlink"
      classList="no-underline text-aux-gray-darkest"
      (clickEmit)="activitiesTabComponent.onCloseActivities()"
    />
  </div>
  <div class="flex items-center justify-between">
    <div
      (click)="activitiesTabComponent.scrollToNotes()"
      [auxTooltip]="activitiesTabComponent.saveBtnTooltipAct()"
    >
      <aux-button
        class="mr-[16px]"
        label="Save & Exit"
        variant="secondary"
        [ngClass]="{
          'pointer-events-none': activitiesTabComponent.isSaveButtonDisabledAct() === true
        }"
        (clickEmit)="activitiesTabComponent.onSaveActivities()"
        [disabled]="activitiesTabComponent.isSaveButtonDisabledAct()"
        [loading]="loading"
      />
    </div>
    <aux-button
      label="Edit Attributes"
      variant="primary"
      (clickEmit)="activitiesTabComponent.goToAttributesTab()"
      [disabled]="activitiesTabComponent.editButtonDisabled | async"
      [auxTooltip]="
        (activitiesTabComponent.editButtonDisabled | async) === true
          ? activitiesTabComponent.editButtonTooltip
          : ''
      "
      [loading]="loading"
    />
  </div>
</div>

<div *ngIf="!isActivitiesTabVisible" class="flex items-center justify-between">
  <div>
    <aux-button
      label="Cancel"
      variant="hyperlink"
      classList="no-underline text-aux-gray-darkest"
      (clickEmit)="attributesTabComponent.onClose()"
    />
  </div>
  <div class="flex items-center justify-between">
    <aux-button
      class="mr-[16px]"
      label="Edit Activities"
      variant="secondary"
      [disabled]="attributesTabComponent.doesHeaderHasError()"
      [auxTooltip]="
        attributesTabComponent.doesHeaderHasError() ? attributesTabComponent.headerErrorTooltip : ''
      "
      (clickEmit)="attributesTabComponent.goToActivitiesTab()"
      [loading]="loading"
    />
    <div
      (click)="attributesTabComponent.scrollToNotes()"
      [auxTooltip]="attributesTabComponent.saveBtnTooltip()"
    >
      <aux-button
        label="Save & Exit"
        variant="primary"
        [ngClass]="{ 'pointer-events-none': attributesTabComponent.isSaveBtnDisabled() === true }"
        [disabled]="attributesTabComponent.isSaveBtnDisabled()"
        (clickEmit)="attributesTabComponent.onSave()"
        [loading]="loading"
      />
    </div>
  </div>
</div>

<ng-template #InAppNotesAndDocuments>
  <div class="grid grid-cols-3 w-[1200px] h-60">
    <div class="flex flex-col items-start justify-start space-y-3 col-span-2 mr-8">
      <div class="flex items-center justify-start space-x-1">
        <aux-icon [name]="'Message'" />
        <span class="font-bold">
          <span class="mr-1">Notes</span>
          <span class="text-aux-error">*</span>
        </span>
      </div>
      <aux-input
        id="notes"
        class="text-xs w-full pl-1"
        placeholder="Enter notes here..."
        validators="required"
        [inAppBudgetStyle]="true"
        [textArea]="true"
        [errMessage]="'Notes*'"
        [(ngModel)]="notes"
        (ngModelChange)="noteChange(notes)"
      />
    </div>
    <div class="flex flex-col space-y-3 w-full col-span-1 mb-4">
      <div class="flex flex-row justify-start items-center space-x-2">
        <span class="font-bold">Supporting Documents</span>
        <span class="italic"> (optional)</span>
      </div>
      <div class="w-full">
        <aux-file-manager
          #manager
          class="h-32"
          [fetchFilesOnInit]="false"
          [eager]="false"
          [pathFn]="getFilePath()"
          [metadataFn]="getMetadata()"
        />
        <div class="mt-4 max-h-[42px] overflow-y-auto">
          <aux-file-viewer
            [fileManager]="manager"
            [disableFirstFileMargin]="true"
            [onlyShowUploaded]="false"
            [inAppBudget]="true"
          />
        </div>
      </div>
    </div>
  </div>
</ng-template>
