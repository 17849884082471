import { Injectable } from '@angular/core';
import { first, switchMap, tap } from 'rxjs/operators';
import {
  CreateDriverBlendedSiteDistributionInput,
  DriverSiteGroup,
  GqlService,
  UpdateDriverBlendedSiteDistributionInput,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { MessagesConstants } from '@constants/messages.constants';
import { SiteCurveModel, SiteCurveStore } from './site-curve.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteCurveService {
  constructor(
    private siteCurveStore: SiteCurveStore,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.siteCurveStore.remove(() => true);
        return this.gqlService.listDriverSiteGroups$().pipe(
          tap(({ success, data, errors }) => {
            let flag = false;
            if (success && data && data?.length > 0) {
              this.siteCurveStore.set(
                <SiteCurveModel[]>data
                  .filter((y) => y.curve_type === 'NET')
                  .map((x) => {
                    if (!x.is_blended && !flag) {
                      flag = true;
                      return { ...x, showLine: true };
                    }
                    return { ...x, showLine: false };
                  })
              );
            } else if (!success) {
              this.overlayService.error(errors);
            }
          })
        );
      })
    );
  }

  async createBlendedSiteCurve(site: CreateDriverBlendedSiteDistributionInput) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.createDriverBlendedSiteDistribution$(site)
    );
    if (success && data) {
      await firstValueFrom(this.get().pipe(first()));
      this.overlayService.success(MessagesConstants.SITE_CURVES.SUCCESSFULLY_CREATED);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  async updateBlendedSiteCurve(site: UpdateDriverBlendedSiteDistributionInput) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updateDriverBlendedSiteDistribution$(site)
    );
    if (success && data) {
      await firstValueFrom(this.get().pipe(first()));
      this.overlayService.success(`${site.name} successfully updated!`);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  async removeSiteCurve(driverSiteGroup: DriverSiteGroup) {
    let successOverall = false;
    let errorsOverall: string | string[] | undefined = [];
    if (driverSiteGroup.is_blended && driverSiteGroup.site_group_id) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.removeDriverBlendedSiteDistribution$(driverSiteGroup.site_group_id)
      );
      successOverall = success;
      errorsOverall = errors;
    } else if (!driverSiteGroup.is_blended && driverSiteGroup.driver_setting_id) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.removeSiteGroupDriver$(driverSiteGroup.driver_setting_id)
      );
      successOverall = success;
      errorsOverall = errors;
    }
    if (successOverall) {
      await firstValueFrom(this.get().pipe(first()));
      this.overlayService.success(`${driverSiteGroup.name} successfully removed!`);
    } else {
      this.overlayService.error(errorsOverall);
    }

    return { successOverall, errorsOverall };
  }
}
