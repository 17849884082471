import { Component, ChangeDetectionStrategy, ViewChild, OnInit } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import {
  BudgetType,
  EntityType,
  EventType,
  GqlService,
  TemplateType,
  WorkflowStep,
} from '@services/gql.service';
import { UntypedFormControl } from '@angular/forms';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { formatDate } from '@angular/common';
import { map, take } from 'rxjs/operators';
import dayjs from 'dayjs';
import { Utils } from '@services/utils';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessagesConstants } from '@constants/messages.constants';

interface MonthOption {
  name: string;
  date: string;
  disabled?: boolean;
}

Utils.extendDayjs();

@UntilDestroy()
@Component({
  selector: 'aux-quarter-close-upload',
  template: `
    <div class="w-screen max-w-sm">
      <div class="text-lg font-bold mb-4">Upload Vendor Estimate</div>

      <div class="grid">
        <div class="flex justify-between flex-col">
          <div
            *ngIf="errorMessage"
            class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md"
          >
            {{ errorMessage }}
          </div>

          <div>
            <div
              class="aux-link cursor-pointer flex justify-center mb-4"
              (click)="downloadPatientTrackerTemplate()"
            >
              <span *ngIf="templateLoading$ | async" class="spinner w-6 h-6 mr-3"></span>
              <span *ngIf="(templateLoading$ | async) === false">
                <aux-icon name="Download" [size]="20" />
              </span>
              Download Vendor Estimate Template
            </div>
            <div class="mb-4">
              <div class="mb-2 text-xs">Month</div>
              <ng-container *ngVar="currentMonth$ | async as currentMonth">
                <ng-select
                  placeholder="Select"
                  id="months"
                  class="month-select"
                  [formControl]="selectedMonth"
                  [appendTo]="'body'"
                  [searchable]="true"
                  [clearable]="false"
                  [items]="availableMonths"
                  bindLabel="name"
                  bindValue="date"
                >
                  <ng-template let-item="item" ng-option-tmp>
                    <ng-container
                      *ngVar="currentMonth === item?.date && workflowLocked as isDisabled"
                    >
                      <div
                        *ngIf="isDisabled"
                        class="-ml-3 -mt-2 w-full h-full absolute l-0 t-0 cursor-not-allowed disabled-option"
                        (click)="onDisabledOptionClick($event)"
                        [auxTooltip]="MessagesConstants.VENDOR_ESTIMATES_LOCKED"
                      ></div>
                      <span
                        class="ng-option-label"
                        [class.disabled-option-label]="isDisabled"
                        [title]="item?.date"
                      >
                        {{ item?.name }}
                      </span>
                    </ng-container>
                  </ng-template>
                </ng-select>
              </ng-container>
            </div>
            <div class="mb-4">
              <div class="mb-2 text-xs">Vendors</div>
              <ng-select
                placeholder="Select"
                id="vendors"
                [formControl]="selectedVendor"
                [appendTo]="'body'"
                [searchable]="true"
                [clearable]="false"
              >
                <ng-option
                  *ngFor="let vendor of vendorsQuery.allVendors$ | async"
                  [value]="vendor.id"
                >
                  <span [title]="vendor.name">{{ vendor.name }}</span>
                </ng-option>
              </ng-select>
            </div>
            <aux-file-manager
              #manager
              class="h-48"
              [fetchFilesOnInit]="false"
              [pathFn]="pathFn"
              [eager]="false"
              [metadata]="metadata"
              [accept]="'.csv'"
              [showSuccessOnUpload]="true"
            />
          </div>

          <div class="mt-4 flex space-x-4">
            <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
              <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
              <span>Upload</span>
            </button>

            <button
              class="text-sm font-normal aux-link focus:outline-none"
              type="button"
              (click)="ref.close()"
            >
              Cancel
            </button>
          </div>
        </div>

        <div></div>
        <div class="max-h-60 overflow-auto mt-4">
          <aux-file-viewer
            [fileManager]="manager"
            [disableFirstFileMargin]="true"
            [onlyShowUploaded]="false"
          />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      ::ng-deep
        .month-select.ng-dropdown-panel
        .ng-dropdown-panel-items
        .ng-option.ng-option-marked:has(.disabled-option) {
        background-color: #fff;
      }

      .disabled-option-label {
        color: #ccc;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorEstimateUploadComponent implements OnInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  availableMonths = this.getAvailableMonths();

  isConfirmVendorExpensesLocked!: boolean;

  isReviewVendorEstimatesLocked!: boolean;

  selectedVendor = new UntypedFormControl('');

  selectedMonth = new UntypedFormControl('');

  templateLoading$ = new BehaviorSubject(false);

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  readonly MessagesConstants = MessagesConstants;

  currentMonth$ = this.mainQuery
    .select('currentOpenMonth')
    .pipe(map((m) => dayjs(m).format('MM-DD-YYYY')));

  constructor(
    public ref: CustomOverlayRef,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private budgetQuery: BudgetQuery,
    public vendorsQuery: OrganizationQuery,
    private gqlService: GqlService,
    private workflowQuery: WorkflowQuery
  ) {}

  get workflowLocked(): boolean {
    return this.isConfirmVendorExpensesLocked || this.isReviewVendorEstimatesLocked;
  }

  ngOnInit(): void {
    combineLatest([
      this.workflowQuery.getLockStatusByWorkflowStepType$(
        WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS
      ),
      this.workflowQuery.getLockStatusByWorkflowStepType$(
        WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_VENDOR_ESTIMATES
      ),
    ])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(
        ([isConfirmVendorExpensesLocked, isReviewVendorEstimatesLocked]: [boolean, boolean]) => {
          this.isConfirmVendorExpensesLocked = isConfirmVendorExpensesLocked;
          this.isReviewVendorEstimatesLocked = isReviewVendorEstimatesLocked;

          if (
            !isConfirmVendorExpensesLocked &&
            !isReviewVendorEstimatesLocked &&
            this.availableMonths.length === 1
          ) {
            this.selectedMonth.reset(this.availableMonths[0].date);
          }
        }
      );
  }

  pathFn: () => string = () => '';

  onDisabledOptionClick(event: MouseEvent): boolean {
    event.stopPropagation();
    return false;
  }

  getFilePath() {
    const vendorId = this.selectedVendor.value;
    // const date = formatDate(new Date(), 'MMM-YYYY', 'en-US');
    const { budget_info } = this.budgetQuery.getValue();
    const date = budget_info[0].current_month;
    if (!date) {
      this.errorMessage = 'No in month found!';
      return '';
    }
    const formatted_current_month = formatDate(date, 'MMMM-y', 'en-US');
    const trialId = this.mainQuery.getValue().trialKey;
    // vendor/<vendor uuid>/in-month/<MON-YYYY>/<file name goes here>
    return `trials/${trialId}/vendors/${vendorId}/vendor-estimate/${formatted_current_month}/`;
  }

  // Revisit after month reopen, as current month may no longer be enough information
  getAvailableMonths() {
    const { budget_info } = this.budgetQuery.getValue();
    let date = dayjs(budget_info[0].current_month);
    const months: MonthOption[] = [];
    const current_quarter = date.quarter();

    while (date.quarter() === current_quarter) {
      months.push({ name: date.format('MMMM YYYY'), date: date.format('MM-DD-YYYY') });
      date = date.add(1, 'months');
    }

    return months;
  }

  async downloadPatientTrackerTemplate() {
    if (!this.selectedMonth.value) {
      this.overlayService.error('Please select a month');
      return;
    }
    if (!this.selectedVendor.value) {
      this.overlayService.error('Please select a vendor');
      return;
    }
    if (!this.templateLoading$.getValue()) {
      this.templateLoading$.next(true);
      const month = dayjs(this.selectedMonth.value, 'MM-DD-YYYY');
      const { success, data } = await this.apiService.getTemplatePath(
        this.selectedVendor.value,
        TemplateType.VENDOR_ESTIMATE_TEMPLATE,
        JSON.stringify({ month: month.format('MMM-YYYY').toUpperCase() })
      );
      if (!(success && data)) {
        this.overlayService.error('There was a problem downloading the template');
      } else {
        await this.apiService.downloadFileFromPath(data.id);
      }
      this.templateLoading$.next(false);
    }
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      if (!this.selectedMonth.value) {
        this.overlayService.error('Please select a month');
        return;
      }

      if (!this.selectedVendor.value) {
        this.overlayService.error('Please select a vendor');
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath()}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.VENDOR_ESTIMATE_TEMPLATE_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.selectedVendor.value,
            bucket_key: `public/${key}`,
            payload: JSON.stringify({
              budget_type: BudgetType.BUDGET_VENDOR_ESTIMATE,
              period: this.selectedMonth.value,
            }),
          })
        );
        if (success) {
          this.overlayService.success();
        } else {
          this.apiService.removeFile(`${this.getFilePath()}${file.bucket_key}`);
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
