import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationModel } from '@models/organization/organization.store';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { BudgetType, Currency, PermissionType, WorkflowStep } from '@services/gql.service';
import {
  OrganizationDialogComponent,
  OrganizationDialogData,
} from './organization-dialog/organization-dialog.component';
import { MultipleOrganizationsDialogComponent } from './multiple-organizations-dialog/multiple-organizations-dialog';
import { AuthService } from '@shared/store/auth/auth.service';
import { combineLatest, firstValueFrom } from 'rxjs';
import { MessagesConstants } from '@constants/messages.constants';
import { ConfirmationActionModalComponent } from '@shared/components/confirmation-action-modal';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'aux-vendors',
  templateUrl: './vendors.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorsComponent implements OnInit {
  PermissionType = PermissionType;

  userHasVendorPermission = false;

  isChangeOrdersWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS
  );

  constructor(
    private organizationService: OrganizationService,
    private overlayService: OverlayService,
    public vendorsQuery: OrganizationQuery,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.setUserPermissions();

    this.organizationService
      .getListWithTotalBudgetAmount(BudgetType.BUDGET_PRIMARY)
      .pipe(untilDestroyed(this))
      .subscribe();

    combineLatest([this.route.queryParams, this.vendorsQuery.selectLoading()])
      .pipe(untilDestroyed(this))
      .subscribe(([params, loading]) => {
        if (loading) {
          return;
        }

        if (params['selectedVendor']) {
          const org = this.vendorsQuery.getEntity(params['selectedVendor']);
          if (org) {
            this.onEdit(org);
          }
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        }
      });
  }

  ngOnInit(): void {
    this.workflowService.getWorkflowList().pipe(untilDestroyed(this)).subscribe();
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_VENDORS],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasVendorPermission]) => {
        this.userHasVendorPermission = userHasVendorPermission;
      });
  }

  noPermissionsTooltipText(): string {
    return this.userHasVendorPermission ? '' : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  }

  btnTooltipText(isChangeOrdersWorkflowLocked: boolean): string {
    if (!this.userHasVendorPermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (isChangeOrdersWorkflowLocked) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  }

  currencyFormatter(amount: unknown, currency?: Currency) {
    if (amount !== undefined && amount && !Number.isNaN(amount)) {
      return Utils.currencyFormatter(amount, {}, currency);
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });

    return `${formatter.formatToParts(0).find((x) => x.type === 'currency')?.value}${
      Utils.zeroHyphen
    }`;
  }

  onEdit(org: OrganizationModel) {
    this.overlayService.open<unknown, OrganizationDialogData>({
      content: OrganizationDialogComponent,
      data: {
        id: org.id,
        fetchFilesOnInit: true,
        userHasPermission: this.userHasVendorPermission,
      },
    });
  }

  async removeVendor(vendor: OrganizationModel) {
    if (!vendor) return;

    const modalEvent = this.overlayService.open<{ success: boolean }>({
      baseComponent: ConfirmationActionModalComponent,
      content:
        'This will permanently delete the selected vendor. This action cannot be undone and will remove all associated data including budget and accruals.',
      data: {
        header: 'Delete Vendor?',
        keywordToExecuteAction: 'Delete Vendor',
        okButtonText: 'Delete Vendor',
      },
    }).afterClosed$;

    const resp = await firstValueFrom(modalEvent);

    if (resp?.data?.success) {
      await this.organizationService.remove(vendor);
    }
  }

  onNewVendor = () => {
    this.overlayService.open<unknown, OrganizationDialogData>({
      content: OrganizationDialogComponent,
      data: {
        fetchFilesOnInit: false,
        userHasPermission: true,
      },
    });
  };

  onAddMultipleVendors = () => {
    this.overlayService.open<unknown, null>({
      content: MultipleOrganizationsDialogComponent,
    });
  };
}
