<div class="flex justify-between items-center mb-8">
  <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">Account Profile</h1>
</div>

<div>
  <div class="mb-8">
    <aux-route-tab-group [tabs]="tabs" />
  </div>

  <router-outlet />
</div>
