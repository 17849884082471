import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@shared/store/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { AppInitService } from '@services/app-init.service';
import { EventService } from '@services/event.service';
import { CustomValidators } from '@components/form-inputs/custom-validators';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { Utils } from '@services/utils';
import { confirmSignIn, signIn, signInWithRedirect } from '@aws-amplify/auth';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { SignInOutput } from '@aws-amplify/auth/src/providers/cognito/types';

@Component({
  selector: 'aux-login',
  templateUrl: './login.component.html',
  styles: [
    `
      .custom-dropdown {
        @apply invisible;
      }

      ::ng-deep ng-dropdown-panel.custom-dropdown .ng-dropdown-panel-items {
        @apply border rounded-md;
      }
    `,
  ],
})
export class LoginComponent {
  forgotPasswordLink = `/${ROUTING_PATH.FORGOT_PASSWORD}`;

  public year = new Date().getFullYear();

  loading$ = new BehaviorSubject(false);

  showLoginForm = true;

  showSSOLoginButton = false;

  errorMessage = '';

  signInForm = this.fb.group({
    email: ['', [Validators.required, CustomValidators.emailValidator()]],
    password: ['', [Validators.required]],
  });

  newPasswordForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    password: [
      '',
      [
        Validators.required,
        CustomValidators.mustHaveLowercaseCharacter(),
        CustomValidators.mustHaveUppercaseCharacter(),
        CustomValidators.mustHaveNumber(),
        Validators.minLength(8),
      ],
    ],
  });

  user?: SignInOutput;

  showSSODropdown = true;

  options: string[] = [];

  selectedProvider: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    public appInitService: AppInitService,
    public eventService: EventService
  ) {
    localStorage.removeItem(LocalStorageKey.LAST_ACTIVE_DATE);
    const providers = appInitService.SAML_PROVIDERS;
    this.showSSOLoginButton = providers.length > 0;
    this.showSSODropdown = providers.length > 1;
    this.options = providers;
    const auxProvider = 'auxili.us';
    // If auxilius is in the provider list, change it to last option.
    if (providers.includes(auxProvider)) {
      const arr = providers.filter((x) => x !== auxProvider);
      arr.push(auxProvider);
      this.options = arr;
    }
    this.selectedProvider = localStorage.getItem('defaultProvider') || this.options[0];
  }

  async submitSignInForm() {
    if (this.signInForm.invalid || this.loading$.getValue()) {
      this.signInForm.markAllAsTouched();
      this.signInForm.updateValueAndValidity();
      return;
    }

    if (this.showSSOLoginButton && location.hostname !== 'localhost') {
      const userDomain = String(this.signInForm.value.email).split('@')[1]?.toLocaleLowerCase();
      if (userDomain && this.appInitService.SAML_PROVIDERS.includes(userDomain)) {
        this.errorMessage = 'You must log in with SSO.';
        return;
      }
    }
    this.loading$.next(true);
    this.errorMessage = '';

    const gotoHome = async () => {
      await this.authService.setUserAttributes();
      const lastURL = localStorage.getItem(LocalStorageKey.LOGIN_LAST_URL);
      await this.router.navigate([`${lastURL || '/'}`]);
    };
    try {
      const username = this.signInForm.value.email || '';
      const password = this.signInForm.value.password || '';

      const { isSignedIn, nextStep } = await signIn({ username, password });

      if (isSignedIn) {
        await gotoHome();
        return;
      }
      if (nextStep) {
        switch (nextStep.signInStep) {
          case 'CONFIRM_SIGN_UP':
          case 'RESET_PASSWORD': {
            this.authService.latestUserParams = {
              email: this.signInForm.value.email || '',
              password: this.signInForm.value.password || '',
            };
            this.router.navigate([`/${ROUTING_PATH.CONFIRMATION}`]);
            break;
          }
          default:
            break;
        }
      }
      this.showLoginForm = false;
    } catch (err: unknown) {
      if (Utils.isError(err)) {
        if (err.name === 'UserAlreadyAuthenticatedException') {
          await gotoHome();
          return;
        }
      }
      console.error(err);
      const e = err as { message?: string };
      this.errorMessage = e.message || '';
    }

    this.loading$.next(false);
  }

  async submitNewPasswordForm() {
    if (this.newPasswordForm.invalid || this.loading$.getValue()) {
      this.newPasswordForm.markAllAsTouched();
      this.newPasswordForm.updateValueAndValidity();
      return;
    }

    this.loading$.next(true);

    this.errorMessage = '';
    try {
      await confirmSignIn({
        options: {
          userAttributes: {
            given_name: this.newPasswordForm.value.firstName || '',
            family_name: this.newPasswordForm.value.lastName || '',
          },
        },
        challengeResponse: this.newPasswordForm.value.password || '',
      });

      await this.authService.setUserAttributes();
      localStorage.setItem(LocalStorageKey.SEND_PASSWORD_UPDATED_EMAIL, 'true');
      await this.router.navigateByUrl('/');
    } catch (e: unknown) {
      if ((e as { name: string })?.name === 'InvalidPasswordException') {
        this.errorMessage =
          'Your password must include minimum of 8 characters and contain an uppercase character, lowercase character and number';
        return;
      }
      console.error(e);
      if (Utils.isError(e)) {
        this.errorMessage = e.message;
      }
    }
    this.loading$.next(false);
  }

  onSSOClick() {
    this.onSSODropdown(this.selectedProvider);
  }

  async onSSODropdown(provider: string) {
    this.errorMessage = '';
    localStorage.setItem('defaultProvider', provider);
    this.loading$.next(true);
    this.selectedProvider = provider;
    try {
      await signInWithRedirect({
        provider: {
          custom: provider,
        },
      });
      localStorage.setItem(LocalStorageKey.LOGIN_SSO_SUBMIT, 'true');
    } catch (e: unknown) {
      if ((e as Error)?.name === 'UserAlreadyAuthenticatedException') {
        await this.router.navigateByUrl('/');
      }
      console.error(e);
      return;
    }
    await this.authService.setUserAttributes();
    await this.router.navigateByUrl('/');
    this.loading$.next(false);
  }
}
