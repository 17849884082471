import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgSelectModule } from '@ng-select/ng-select';
import { FirstNameShowComponent } from '@components/first-name-show/first-name-show.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { WorkflowModel } from '@shared/store/workflow/workflow.store';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { listUsersQuery, PermissionType, WorkflowStep } from '@services/gql.service';
import { Utils } from '@services/utils';
import { AuthService } from '@shared/store/auth/auth.service';
import { Workflow } from '@shared/store/workflow/workflow.store';

@UntilDestroy()
@Component({
  selector: 'aux-assign-month-close',
  templateUrl: './assign-month-close.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSelectModule,
    ReactiveFormsModule,
    FirstNameShowComponent,
    NgIf,
    DirectivesModule,
    NgForOf,
    AsyncPipe,
  ],
})
export class AssignMonthCloseComponent implements OnInit {
  readonly zeroHyphen = Utils.zeroHyphen;

  isAdmin = false;

  workflow!: Workflow | null;

  workflowSubscription!: Subscription;

  user = '';

  usersList$ = new BehaviorSubject<listUsersQuery[]>([]);

  assignToFormControl = new FormControl<string | null>(null);

  externalUserWithPermissionsList$ = this.usersList$.pipe(
    map((listUsers) =>
      listUsers.filter(
        (user) =>
          !user.email.includes('@auxili.us') &&
          user.permissions.some(
            (permission) =>
              permission.permission_type === PermissionType.PERMISSION_APPROVE_IN_MONTH &&
              permission.permission_roles === 'E'
          )
      )
    )
  );

  userHasChecklistAdminPermission = false;

  constructor(
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_ADMIN],
      })
      .pipe(untilDestroyed(this))
      .subscribe((userHasChecklistAdminPermission: boolean) => {
        this.userHasChecklistAdminPermission = userHasChecklistAdminPermission;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnInit(): void {
    this.workflowQuery
      .getWorkflowByStepType$(WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CLOSE_MONTH)
      .pipe(untilDestroyed(this))
      .subscribe((workflow) => {
        this.workflow = workflow;
        this.initializeWorkflow();
        this.changeDetectorRef.detectChanges();
      });
  }

  async changeAssign() {
    const selectedUser = this.usersList$
      .getValue()
      .find((user) => user.sub === this.assignToFormControl.value);
    const currentUserID = this.authQuery.getValue().sub;
    const updateLockedBy = this.usersList$.getValue().find((user) => user.sub === currentUserID);
    const currenDate = dayjs(new Date()).format('YYYY-MM-DD');
    await this.workflowService.updateWorkflowLockStatus(
      false,
      {
        ...this.workflow,
        updated_by: currentUserID ? currentUserID : this.workflow?.updated_by,
        updatedAuthorFullName: updateLockedBy?.given_name + ' ' + updateLockedBy?.family_name,
        properties: {
          ...this.workflow?.properties,
          assigned_to: selectedUser?.sub ? selectedUser?.sub : '',
          assigned_by: this.workflow?.updated_by,
          assigned_date: currenDate,
        },
      } as WorkflowModel,
      this.isAdmin,
      true
    );
  }

  private initializeWorkflow(): void {
    this.isAdmin = this.authQuery.isAuxAdmin();
    this.user = '';
    if (this.workflowSubscription) {
      this.workflowSubscription.unsubscribe();
    }
    this.workflowSubscription = this.workflowService.listUsers$
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        this.usersList$.next([...users]);

        const selectedUser = users.find(
          (user) =>
            !user.email.includes('@auxili.us') &&
            user?.sub === this.workflow?.properties?.assigned_to &&
            user.permissions.some(
              (permission) =>
                permission.permission_type === PermissionType.PERMISSION_APPROVE_IN_MONTH &&
                permission.permission_roles === 'E'
            )
        );

        if (selectedUser) {
          this.user = `${selectedUser?.given_name} ${selectedUser?.family_name}`;
        }

        this.assignToFormControl.setValue(selectedUser ? selectedUser.sub : null);
        this.changeDetectorRef.markForCheck();
      });
  }
}
