import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { ROUTING_PATH } from '@shared/constants/routingPath';
import { PermissionType } from '@services/gql.service';
import { ButtonComponent } from '@components/button/button.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

import { InvoiceBannerButtonDirective } from './invoice-detail-banner-button.directive';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-status-banner-delete-button',
  template: `
    <aux-button
      [auxTooltip]="tooltip()"
      [disabled]="disabled()"
      [onClick]="onDelete"
      icon="Trash"
      variant="negative"
      classList="h-9"
      label="Delete"
      [iconSize]="16"
    />
  `,
  imports: [ButtonComponent, TooltipDirective],
  hostDirectives: [
    {
      directive: InvoiceBannerButtonDirective,
      inputs: ['invoice'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailDeleteBtnComponent {
  ctx = inject(InvoiceBannerButtonDirective);
  router = inject(Router);

  hasDeletePermission = this.ctx.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_DELETE_INVOICE],
  });

  disabled = computed(() => {
    return !this.hasDeletePermission() || this.ctx.buttonDisabled() || this.ctx.statusCheck();
  });

  tooltip = computed(() => {
    if (!this.hasDeletePermission()) {
      return this.ctx.dontHavePermission;
    }

    if (this.ctx.buttonDisabled()) {
      return this.ctx.invoiceLockTooltip();
    }

    if (this.ctx.statusCheck()) {
      return 'Invoice in closed month cannot be deleted.';
    }

    return '';
  });

  onDelete = async () => {
    const { overlayService, invoiceQuery, invoiceService, userTaskService } = this.ctx;
    const invoice = this.ctx.invoice();
    const resp = overlayService.openConfirmDialog({
      header: 'Remove Invoice?',
      message: `Are you sure you want to remove Invoice ${invoice.invoice_no}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.result) {
      const id = invoiceQuery.getEntity(invoice.id);
      if (!id) {
        return;
      }

      const { success } = await invoiceService.remove(id, event.data?.textarea);

      if (success) {
        await userTaskService.triggerUserTaskList$();
        await this.router.navigateByUrl(`/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}`);
      }
    }
  };
}
