<div class="font-inter w-[500px]">
  <div class="mb-2 text-2xl font-bold">Delete row</div>
  <div class="mb-4 text-lg">
    Are you sure you want to delete <span class="font-bold">this row</span>? If you delete, it will
    be permanently lost.
  </div>

  @if (!ref.data?.createMode) {
    <aux-investigator-transaction-supporting-section
      #supportingSection
      [noteFormControl]="noteFormControl"
    />
  }

  <div class="border-b h-1 border-aux-gray-dark mt-4"></div>

  <div class="flex justify-end mt-4 space-x-4">
    <aux-button variant="secondary" label="Cancel" (click)="ref.close()" />
    <aux-button
      variant="negative"
      label="Delete"
      [spinnerSize]="6"
      [disabled]="ref.data?.createMode ? false : noteFormControl.invalid"
      [onClick]="deleteTransaction"
    />
  </div>
</div>
