import { Route, Routes } from '@angular/router';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { SettingsComponent } from './settings.component';
import { UsersComponent } from './users/users.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { ExchangeRatesComponent } from './exchange-rates/exchange-rates.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ExpenseDefaultsComponent } from './expense-defaults/expense-defaults.component';

export const SETTINGS_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.SETTINGS.EXCHANGE_RATES,
    component: ExchangeRatesComponent,
    data: {
      feature: FeatureFlag.SETTINGS_EXCHANGE_RATES,
    },
  },
  {
    path: ROUTING_PATH.SETTINGS.INTEGRATIONS,
    component: IntegrationsComponent,
    data: {
      feature: FeatureFlag.SETTINGS_INTEGRATIONS,
    },
  },
  {
    path: ROUTING_PATH.SETTINGS.EXPENSE_DEFAULTS,
    component: ExpenseDefaultsComponent,
    data: {
      feature: FeatureFlag.SETTING_EXPENSE_DEFAULTS,
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ROUTING_PATH.SETTINGS.USER_PERMISSIONS,
    component: UserPermissionsComponent,
    data: {
      feature: FeatureFlag.USER_PERMISSIONS,
    },
    canDeactivate: [CanDeactivateGuard],
  },
];

export const buildSettingsRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    { path: ROUTING_PATH.SETTINGS.USERS, component: UsersComponent },
    ...buildRoutesByFeatureFlags(featureFlags, SETTINGS_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.SETTINGS.INDEX,
    component: SettingsComponent,
    canDeactivate: [CanDeactivateGuard],
    children: [...enabledRoutes, { path: '**', redirectTo: ROUTING_PATH.SETTINGS.USERS }],
  };
};
