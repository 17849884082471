<div class="mb-4 w-[1200px]">
  <ag-grid-angular
    class="ag-theme-aux tabular-nums be-attributes-table h-[300px]"
    [rowData]="filteredRowData"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [getRowId]="getRowId"
    (cellValueChanged)="onCellChanged($event)"
  />
</div>
