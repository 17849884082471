import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserTasksQuery } from '@models/user-tasks';
import { NO_TASKS_MESSAGE } from '@pages/portfolio-dashboard/portfolio-tasks';

@Component({
  selector: 'aux-trial-tasks',
  templateUrl: './trial-tasks.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialTasksComponent {
  isOpen = false;

  noMessage = NO_TASKS_MESSAGE.NO_TASKS;

  trialTasks$ = this.userTasksQuery.getCurrentTrialTasks();

  constructor(private userTasksQuery: UserTasksQuery) {}

  toggleOpenList = (value: boolean) => {
    this.isOpen = value;
  };
}
