import { ChangeDetectionStrategy, Component, inject, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SitesService } from '@models/sites/sites.service';
import { BehaviorSubject } from 'rxjs';
import {
  RouteTabGroupComponent,
  TabGroupConfig,
} from '@components/tab-group/route-tab-group.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { EventType, PermissionType, WorkflowStep } from '@services/gql.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { AuthService } from '@shared/store/auth/auth.service';
import { EventQuery } from '@models/event/event.query';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'aux-patient-drivers',
  templateUrl: './patient-drivers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WorkflowPanelComponent, RouteTabGroupComponent, RouterOutlet],
})
export class PatientDriversComponent {
  sitesService = inject(SitesService);
  workflowQuery = inject(WorkflowQuery);
  launchDarklyService = inject(LaunchDarklyService);
  authService = inject(AuthService);
  eventQuery = inject(EventQuery);

  tabs: TabGroupConfig[] = [
    {
      label: 'Patient Curves',
      route: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES,
    },
    {
      label: 'Patient Groups',
      show: this.launchDarklyService.select$((flags) => flags.tab_patient_groups),
      route: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.GROUPS,
    },
  ];

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES;

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;

  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  rightSideContainer = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  userHasLockPatientSiteCurvePermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_SITE_CURVES],
  });

  constructor() {
    this.sitesService.get().pipe(takeUntilDestroyed()).subscribe();
  }
}
