<div
  class="p-4 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all font-inter max-w-[550px]"
  @enterLeave
>
  <div class="flex justify-between">
    <div class="text-2xl font-bold mb-6 mr-8 text-aux-black flex items-center space-x-2">
      <aux-icon name="AlertTriangle" class="text-aux-red-dark" [size]="26" />
      <span>
        {{ header }}
      </span>
    </div>

    <button
      id="closeButton"
      type="button"
      class="bg-white flex rounded-md text-gray-400 hover:text-gray-700 focus:outline-none"
      (click)="closeModal()"
    >
      <span class="sr-only">Close</span>
      <aux-icon name="X" />
    </button>
  </div>

  <p class="mb-6">
    Modifying the effective date of the site budget might influence the linked budget version tied
    to a transaction, potentially impacting costs for any completed transactions. By selecting
    "Apply New Effective Date", you acknowledge and agree to this update.
  </p>

  <div class="flex justify-between">
    <aux-button label="Cancel" variant="secondary" (click)="ref.close({ success: false })" />
    <aux-button
      label="Apply New Effective Date"
      variant="success"
      (click)="ref.close({ success: true })"
    />
  </div>
</div>
