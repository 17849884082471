import { Maybe, Utils } from '@services/utils';

export function userFormatter({
  sub,
  userMap,
  adminUser,
}: {
  sub: Maybe<string>;
  adminUser: boolean;
  userMap: Map<
    string,
    {
      email: string;
      given_name: string;
      family_name: string;
    }
  >;
}) {
  const user = userMap.get(sub || '');
  if (user) {
    const isUserAuxAdmin = user.email.includes('@auxili.us');
    if (adminUser || !isUserAuxAdmin) {
      return `${user.given_name} ${user.family_name}`;
    }
    return 'Auxilius Expert';
  }
  return Utils.zeroHyphen;
}
