import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import {
  ContractedInvestigatorAmountValues,
  InvestigatorForecastForm,
  InvestigatorForecastValues,
  PatientVisitsValues,
  SiteContractAveragesValues,
  Source,
  SourceType,
} from './investigator-forecast.types';
import { Utils } from '@services/utils';
import {
  AVERAGES_SOURCE_INITIAL_VALUES,
  CONTRACTED_SOURCE_INITIAL_VALUE,
  INITIAL_FORM_VALUES,
  PATIENT_VISITS_SOURCE_INITIAL_VALUE,
} from './investigator-forecast.const';
import { filter, skip, switchMap, tap, map } from 'rxjs/operators';
import {
  InvestigatorAveragesService,
  InvestigatorForecastLsService,
  InvestigatorForecastService,
} from './services';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { flatMap, isEqual } from 'lodash-es';
import { InvestigatorForecastSource } from '@services/gql.service';
import { InvestigatorForecastUpdateModalComponent } from './investigator-forecast-update-modal/investigator-forecast-update-modal.component';
import dayjs from 'dayjs';
import { InvestigatorPatientVisits } from './services/investigator-patient-visits.service';

@UntilDestroy()
@Component({
  selector: 'aux-investigator-forecast',
  templateUrl: './investigator-forecast.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastComponent implements OnInit {
  private editableControlNames = [
    ['averages', 'contractedSites'],
    ['averages', 'totalForecastedPatients'],
    ['averages', 'averagePatientCost'],
    ['averages', 'invoiceables'],
    ['averages', 'otherInvoiceablesPerc'],
    ['averages', 'overheadInvoiceablesPerc'],
    ['patientVisits', 'invoiceablesPerc'],
    ['patientVisits', 'invoiceablesDistribution'],
    ['patientVisits', 'totalDiscontinuedPerc'],
    ['patientVisits', 'remainingPatientsToDiscontinuePerc'],
  ];

  editMode$ = new BehaviorSubject(false);

  vendorListLoading$ = new BehaviorSubject(true);

  contractedSourceUpdatedDate: string | null = null;

  patientVisitsSourceUpdatedDate: string | null = null;

  defaultSource = SourceType.Contracted;

  triggerFetchActualDataAfterSubmit$ = new BehaviorSubject(false);

  sources: Source[] = [
    {
      label: 'Contracted Investigator Amount',
      value: SourceType.Contracted,
    },
    {
      label: 'Site Contract Averages',
      value: SourceType.Averages,
    },
    {
      label: 'Actual Patient Visits',
      value: SourceType.PatientVisits,
      icon: 'AuxLogo',
    },
  ];

  bannerValues$ = new BehaviorSubject<{ source: string; amount: number }>({
    source: '',
    amount: 0,
  });

  vendorList: { id: string; name: string }[] = [];

  private mapSaveSiteContractBySource = new Map<SourceType, () => Promise<void>>([
    [SourceType.Averages, () => this.updateAverages()],
    [SourceType.Contracted, () => this.updateSiteContract()],
    [SourceType.PatientVisits, () => this.updatePatientVisits()],
  ]);

  initialFormValues?: InvestigatorForecastValues;

  form: FormGroup<InvestigatorForecastForm> = new FormGroup({
    vendorId: new FormControl(''),
    source: new FormControl<SourceType | null>(null),
    averages: this.fb.nonNullable.group<SiteContractAveragesValues>(AVERAGES_SOURCE_INITIAL_VALUES),
    contracted: this.fb.nonNullable.group<ContractedInvestigatorAmountValues>(
      CONTRACTED_SOURCE_INITIAL_VALUE
    ),
    patientVisits: this.fb.nonNullable.group<PatientVisitsValues>(
      PATIENT_VISITS_SOURCE_INITIAL_VALUE
    ),
  });

  submitButtonDisabled$ = new BehaviorSubject(false);

  constructor(
    private fb: FormBuilder,
    private investigatorForecastService: InvestigatorForecastService,
    private investigatorForecastLsService: InvestigatorForecastLsService,
    private investigatorAveragesService: InvestigatorAveragesService,
    private investigatorPatientVisitsService: InvestigatorPatientVisits,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchVendorList();
    this.updateSaveSettingAvailabilityState();

    const vendorControl = this.form.get('vendorId');
    const sourceControl = this.form.get('source');

    if (!vendorControl || !sourceControl) {
      return;
    }

    this.setControlStateByCondition(sourceControl, !!vendorControl?.value);

    combineLatest([vendorControl.valueChanges, this.triggerFetchActualDataAfterSubmit$])
      .pipe(
        untilDestroyed(this),
        tap(([vendorId]) => {
          if (sourceControl && vendorId) {
            this.setControlStateByCondition(sourceControl, true);
          }
        }),
        filter(([vendorId]) => !!vendorId),
        switchMap(() => {
          // Angular form cant update disabled fields
          this.setEnableStateForEditableControls(true);
          this.investigatorForecastService.isLoadingSettings$.next(true);

          return this.investigatorForecastService.getInvestigatorForecastContractedAmounts(
            vendorControl.value || ''
          );
        }),
        tap((contractedValues) => {
          this.contractedSourceUpdatedDate = contractedValues.sourceLastUpdate;

          this.form.get('contracted')?.setValue(contractedValues.values);
        }),
        switchMap((contractedValues) => {
          return this.investigatorForecastService.getInvestigatorForecastPatientVisitsAmounts(
            vendorControl.value || '',
            contractedValues.values.spendToDateAmount
          );
        }),
        tap((patientVisits) => {
          this.patientVisitsSourceUpdatedDate = patientVisits.sourceLastUpdate;

          this.form.get('patientVisits')?.setValue(patientVisits.values);
        }),
        switchMap(() => {
          const vendorId = vendorControl.value;

          return this.investigatorForecastService.getInvestigatorForecastAveragesAmounts(
            vendorId as string,
            this.form.get('contracted')?.getRawValue()
          );
        })
      )
      .subscribe((averagesValues) => {
        this.form.get('averages')?.setValue(averagesValues.values);

        this.investigatorForecastService.isLoadingSettings$.next(false);

        this.assignBottomValuesToSourceOptions();

        if (this.vendorList.length) {
          const source = this.getLastSavedSource(
            this.contractedSourceUpdatedDate,
            averagesValues.sourceLastUpdate,
            this.patientVisitsSourceUpdatedDate
          );

          this.defaultSource = source || SourceType.Contracted;
          sourceControl?.setValue(this.defaultSource);
          this.updateBanner(source);
        }

        // rollback enable state after updating form values
        this.setEnableStateForEditableControls(this.editMode$.getValue());

        this.initialFormValues = this.form.getRawValue();
      });

    this.editMode$.pipe(untilDestroyed(this)).subscribe((editMode) => {
      this.investigatorAveragesService.editMode$.next(editMode);
      this.investigatorPatientVisitsService.editMode$.next(editMode);
      this.setEnableStateForEditableControls(editMode);
    });

    this.mainQuery
      .select('trialKey')
      .pipe(untilDestroyed(this), skip(1)) // skip first call on initial fetching data
      .subscribe(() => {
        this.updateBanner(null);
        this.initialFormValues = undefined;
        this.resetPageState();
      });
  }

  toggleEditMode = (mode: boolean): void => {
    this.editMode$.next(mode);
  };

  editModeEnable = () => () => {
    this.toggleEditMode(true);
  };

  cancelEdit = () => {
    this.resetPageState();
  };

  private setEnableStateForEditableControls(condition: boolean) {
    this.editableControlNames.forEach((controlName) => {
      const control = this.form.get(controlName);

      if (control) {
        this.setControlStateByCondition(control, condition);
      }
    });
  }

  private updateBanner(savedSource: SourceType | null) {
    if (!savedSource) {
      this.bannerValues$.next({
        source: '',
        amount: 0,
      });

      return;
    }

    const mapAmount = new Map<SourceType, number>([
      [SourceType.Contracted, this.sources[0].forecastTotal || 0],
      [SourceType.Averages, this.sources[1].forecastTotal || 0],
      [SourceType.PatientVisits, this.sources[2].forecastTotal || 0],
    ]);

    this.bannerValues$.next({
      source: this.getConfirmInvestigatorForecastTitle(savedSource),
      amount: mapAmount.get(savedSource) || 0,
    });
  }

  private resetPageState() {
    this.form.setValue(
      {
        ...INITIAL_FORM_VALUES,
        ...this.initialFormValues,
      },
      { emitEvent: false }
    );

    this.assignBottomValuesToSourceOptions();
    this.editMode$.next(false);
  }

  private updateSaveSettingAvailabilityState() {
    combineLatest([this.form.valueChanges, this.form.statusChanges])
      .pipe(
        untilDestroyed(this),
        map(([{ source }]) => {
          if (source !== SourceType.Averages) {
            this.submitButtonDisabled$.next(false);
            return;
          }

          this.submitButtonDisabled$.next(this.form.invalid);
        })
      )
      .subscribe();
  }

  private fetchVendorList() {
    this.investigatorForecastService
      .getVendorListWithInvestigatorCosts()
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.vendorListLoading$.next(true);
        })
      )
      .subscribe((list) => {
        this.vendorList = list.sort(({ name: label1 }, { name: label2 }) =>
          Utils.alphaNumSort(label1, label2)
        );

        const vendorIdFromStorage = this.investigatorForecastLsService.getLastSavedVendorByTrial(
          this.mainQuery.getValue().trialKey
        );

        if (vendorIdFromStorage) {
          this.form.get('vendorId')?.setValue(vendorIdFromStorage);
        } else if (this.vendorList.length) {
          this.form.get('vendorId')?.setValue(this.vendorList[0].id);
        }

        this.vendorListLoading$.next(false);
      });
  }

  getLastSavedSource(
    contractedDate: string | null,
    averagesDate: string | null,
    patientVisitsDate: string | null
  ): SourceType {
    const savedDateList = [contractedDate, averagesDate, patientVisitsDate].filter(
      Boolean
    ) as string[];

    if (!savedDateList.length) {
      return SourceType.Contracted;
    }

    const latestDate = savedDateList.reduce((prevDate, currDate) => {
      const prevDayjs = dayjs(prevDate);
      const currDayjs = dayjs(currDate);
      return prevDayjs.isAfter(currDayjs) ? prevDate : currDate;
    });

    if (latestDate === contractedDate) {
      return SourceType.Contracted;
    }

    return latestDate === averagesDate ? SourceType.Averages : SourceType.PatientVisits;
  }

  private setControlStateByCondition(control: AbstractControl, condition: boolean) {
    if (condition) {
      control.enable({ onlySelf: true, emitEvent: false });
    } else {
      control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  private assignBottomValuesToSourceOptions() {
    this.sources = this.sources.map((source) => {
      const values = this.form.getRawValue()[source.value] || {};

      return {
        ...source,
        forecastTotal: values.totalInvestigatorCosts || 0,
        spendToDate: values.spendToDateAmount || 0,
        remaining: values.remainingAmount || 0,
        avgPatientCost: values.avgPatientCost || 0,
      };
    });

    this.cdr.markForCheck();
  }

  saveSiteContractSetting = async () => {
    const responseModel = await this.showConfirmModal();

    if (!responseModel?.data?.result || !this.form.value.source) {
      return;
    }

    const saveMethod = this.mapSaveSiteContractBySource.get(this.form.value.source);

    if (saveMethod) {
      await saveMethod();
      const { vendorId } = this.form.value;
      if (!vendorId) {
        return;
      }

      this.investigatorForecastLsService.saveSettings(this.mainQuery.getValue().trialKey, vendorId);

      this.triggerFetchActualDataAfterSubmit$.next(true);
      this.editMode$.next(false);
    }
  };

  private async updatePatientVisits() {
    const formValues = this.form.getRawValue();

    if (!formValues.vendorId) {
      return;
    }

    const responses = await this.investigatorForecastService.savePatientVisits(formValues);

    const errors = flatMap(responses, (obj) => obj.errors);

    if (errors.length) {
      this.overlayService.error(errors);
      return;
    }

    this.overlayService.success('Investigator Forecast successfully updated!');
  }

  private async updateSiteContract() {
    const { vendorId, contracted } = this.form.getRawValue();
    if (!vendorId) {
      return;
    }

    const { errors } = await firstValueFrom(
      this.investigatorForecastService.updateInvestigatorForecast$(vendorId, {
        source: InvestigatorForecastSource.CONTRACTED_AMOUNT,
        patient_visits_amount: contracted?.totalVisitCosts || 0,
        other_amount: contracted?.otherInvoiceables || 0,
        overhead_amount: contracted?.overheadInvoiceables || 0,
      })
    );
    if (errors.length) {
      this.overlayService.error(errors);
      return;
    }

    this.overlayService.success('Investigator Forecast successfully updated!');
  }

  private getConfirmInvestigatorForecastTitle(source: SourceType) {
    const mapTitle = new Map<SourceType, string>([
      [SourceType.Contracted, this.sources[0].label],
      [SourceType.Averages, this.sources[1].label],
      [SourceType.PatientVisits, this.sources[2].label],
    ]);

    return mapTitle.get(source) || '';
  }

  private showConfirmModal() {
    const { source } = this.form.value;
    if (!source) {
      return;
    }

    return firstValueFrom(
      this.overlayService.open<{ result: boolean }>({
        content: InvestigatorForecastUpdateModalComponent,
        closeButton: false,
        data: {
          source: this.getConfirmInvestigatorForecastTitle(source),
        },
      }).afterClosed$
    );
  }

  private async updateAverages() {
    const { vendorId } = this.form.value;

    if (vendorId) {
      const responses = await this.investigatorForecastService.saveSiteContractAverages(
        this.form.getRawValue()
      );

      const errors = flatMap(responses, (obj) => obj.errors);

      if (errors.length) {
        this.overlayService.error(errors);
        return;
      }

      this.overlayService.success('Investigator Forecast successfully updated!');
    }
  }

  canDeactivate = async (): Promise<boolean> => {
    const { averages, patientVisits, vendorId } = this.form.getRawValue();

    if (!this.initialFormValues || !vendorId) {
      return true;
    }

    const hasChanges = !isEqual(
      {
        averages: this.initialFormValues?.averages,
        patientVisits: this.initialFormValues?.patientVisits,
      },
      {
        averages,
        patientVisits,
      }
    );

    if (hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  };
}
