<div
  class="text-base flex -mb-px overflow-x-auto overflow-y-hidden space-x-8 border-b border-aux-gray-dark"
>
  <button
    *ngFor="let option of options; index as index"
    type="button"
    class="text-sm font-medium whitespace-nowrap focus:outline-none flex items-center px-1 py-[8px] border-b-2"
    [ngClass]="{
      '!text-aux-blue-light-200 border-aux-blue-light-200 hover:text-aux-blue-light-200 hover:border-aux-blue-light-200':
        value === option.value,
      'border-transparent text-aux-gray-dark-100 hover:text-gray-700 hover:border-gray-300':
        value !== option.value
    }"
    (click)="selectButton(option.value)"
  >
    {{ option.label }}
  </button>
</div>
