<ng-container *ngIf="(periodCloseComponent.loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="(periodCloseComponent.loading$ | async) === false" class="py-4 px-6">
  <div class="pb-4 flex items-center justify-between">
    <div class="flex items-center">
      <div class="flex text-[17.5px] font-bold">
        <aux-icon name="FolderDollar" class="mr-[25px]" />
        {{ monthTitle$() | async }} - Journal Entries
      </div>

      <span
        *ngIf="isHowItWorksVisible$ | async"
        class="mx-[25px] text-sm text-aux-blue underline cursor-pointer"
        (click)="openHowItWorksModal()"
      >
        How it works
      </span>
      <aux-checkbox
        class="text-sm mr-4"
        id="hide-rows-with-no-debit-or-credit"
        [checked]="!!(hideRowsCheckBox | async)"
        (customChange)="hideRowsWithNoDebitOrCredit($event)"
      >
        Hide Rows with No Debit/Credit
      </aux-checkbox>
    </div>

    <div class="flex items-center">
      <div class="flex flex-row items-center">
        <span class="mr-2">Select Month</span>
        <ng-select
          class="w-64"
          [multiple]="false"
          [clearable]="false"
          [formControl]="selectedMonthControl"
        >
          <ng-option *ngFor="let month of months" [value]="month.iso">
            {{ month.label }}
          </ng-option>
        </ng-select>
      </div>

      <button
        type="button"
        class="btn--secondary pl-3 ml-1"
        (click)="openManageBalanceSheetAccountsModal()"
      >
        <aux-icon name="Settings" />
        <span class="ml-2">Manage Accounts</span>
      </button>
      <aux-export-excel-button
        className="ml-1"
        [clickFN]="onExport.bind(this)"
        pendoTag="journal-entries-excel-export-button"
      />
    </div>
  </div>

  <ng-container *ngIf="loadingGridData">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <div
    [ngClass]="{
      'invisible h-0 overflow-hidden': loadingGridData
    }"
  >
    <ag-grid-angular
      *ngIf="!loadingGridColumns"
      domLayout="autoHeight"
      class="ag-theme-aux tabular-nums w-full"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="gridData$ | async"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="autosize()"
      (viewportChanged)="onWindowScroll()"
    />
  </div>
</div>
