import { Component, signal, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { InvestigatorTransactionSupportingSectionComponent } from '../investigator-transaction-supporting-section/investigator-transaction-supporting-section.component';

@Component({
  templateUrl: './investigator-transactions-save-modal.component.html',
  standalone: true,
  imports: [InvestigatorTransactionSupportingSectionComponent],
})
export class InvestigatorTransactionsSaveModalComponent {
  @ViewChild('supportingSection')
  supportingSection?: InvestigatorTransactionSupportingSectionComponent;

  noteFormControl = new FormControl('', [Validators.required]);

  uploadingFiles = signal(false);

  constructor(public ref: CustomOverlayRef) {}

  async save() {
    if (this.noteFormControl.invalid) {
      return;
    }

    this.uploadingFiles.set(true);

    const { note, documents } = (await this.supportingSection?.saveSupportingSection()) || {};

    this.uploadingFiles.set(false);

    this.ref.close({
      note,
      documents,
    });
  }
}
