import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BeInlineCategoryDropdownOption } from '../be-inline-category-dropdown.model';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { IconComponent } from '@components/icon/icon.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivitySubType, ActivityType } from '@services/gql.service';

@Component({
  standalone: true,
  selector: 'aux-be-inline-category-option',
  templateUrl: 'be-inline-category-option.component.html',
  styleUrls: ['be-inline-category-option.component.scss'],
  imports: [NgClass, NgStyle, TooltipDirective, NgIf, IconComponent, ReactiveFormsModule],
})
export class BeInlineCategoryOptionComponent {
  @Input() option: BeInlineCategoryDropdownOption | undefined;

  @Input() categories: BeInlineCategoryDropdownOption[] = [];

  @Output() selectOptionEmit = new EventEmitter();

  @Output() addCategoryEmit = new EventEmitter<string>();

  @Output() editCategoryEmit = new EventEmitter<string>();

  @ViewChild('categoryInput') categoryInput!: ElementRef;

  categoryNameControl = new FormControl<string>('');

  isAddMode = false;

  isEditMode = false;

  tooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
    },
  ];

  onDropdownSelected(): void {
    if (this.option?.disabled) {
      return;
    }

    this.selectOptionEmit.emit();
  }

  onAddMode(): void {
    this.isAddMode = true;
    setTimeout(() => {
      this.categoryInput?.nativeElement.focus();
    });
  }

  onEditMode(): void {
    this.isEditMode = true;
    this.categoryNameControl.setValue(this.option?.name || '');
    setTimeout(() => {
      this.categoryInput?.nativeElement.focus();
    });
  }

  offUpdateMode(): void {
    this.isAddMode = false;
    this.isEditMode = false;
    this.categoryNameControl.setValue('');
  }

  isEditCategoryBtnVisible(): boolean {
    return this.option ? this.option.indent !== 1 && this.isUpdateCategoryBtnVisible() : false;
  }

  isAddCategoryBtnVisible(): boolean {
    return this.option ? this.option.indent < 6 && this.isUpdateCategoryBtnVisible() : false;
  }

  private isUpdateCategoryBtnVisible(): boolean {
    return (
      !this.isAddMode &&
      !this.isEditMode &&
      this.option?.type !== ActivityType.ACTIVITY_DISCOUNT &&
      this.option?.type !== ActivityType.ACTIVITY_INVESTIGATOR &&
      this.option?.type !== ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS &&
      this.option?.type !== ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES &&
      this.option?.type !== ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES
    );
  }

  updateCategoryList(): void {
    if (this.isUpdateCategoryDisabled()) {
      return;
    }

    if (this.isEditMode && this.categoryNameControl.value === this.option?.name) {
      this.isEditMode = false;
      return;
    }

    if (this.isAddMode) {
      this.isAddMode = false;
      this.addCategoryEmit.emit(this.categoryNameControl.value || '');
    } else {
      this.isEditMode = false;
      this.editCategoryEmit.emit(this.categoryNameControl.value || '');
      if (this.option) {
        this.option.name = this.categoryNameControl.value || '';
      }
    }

    this.categoryNameControl.setValue('');
  }

  isUpdateCategoryDisabled(): boolean {
    return (
      !this.categoryNameControl.value ||
      !this.categoryNameControl.value?.trim() ||
      this.isCategoryNameNotUnique()
    );
  }

  updateCategoryBtnTooltip(): string {
    if (!this.categoryNameControl.value || !this.categoryNameControl.value?.trim()) {
      return `Enter unique category name to ${this.isAddMode ? 'create' : 'rename'}.`;
    }

    if (this.isCategoryNameNotUnique()) {
      return 'Category name must be unique within parent.';
    }

    return '';
  }

  private isCategoryNameNotUnique(): boolean {
    return !!this.categories.find(
      (category) =>
        category.type === this.option?.type &&
        category.path === (this.isAddMode ? this.option?.fullPath : this.option?.path) &&
        (this.isAddMode
          ? category.name.toLowerCase() === this.categoryNameControl.value?.toLowerCase()
          : category.name === this.categoryNameControl.value && category.id !== this.option.id)
    );
  }
}
