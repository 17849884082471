<div class="text-lg font-bold mb-4">Upload Site Cost Data</div>

<div class="grid">
  <div class="flex justify-between flex-col">
    <div *ngIf="errorMessage" class="mb-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage }}
    </div>

    <div>
      <div class="mb-4">
        <div class="mb-2 text-xs">
          Protocol Version
          <span class="text-aux-error font-bold">*</span>
        </div>
        <ng-select
          placeholder="Select"
          id="protocols"
          [formControl]="selectedProtocol"
          [appendTo]="'body'"
          [searchable]="true"
          [clearable]="false"
        >
          <ng-option *ngFor="let protocol of this.protocolVersionOptions" [value]="protocol.value">
            <span [title]="protocol.label">{{ protocol.label }}</span>
          </ng-option>
        </ng-select>
      </div>
      <div class="mb-4">
        <div class="mb-2 text-xs">
          Patient Group <span class="text-aux-error font-bold">*</span>
        </div>
        <ng-select
          placeholder="Select"
          id="patientGroups"
          [formControl]="selectedPatientGroup"
          [appendTo]="'body'"
          [searchable]="true"
          [clearable]="false"
        >
          <ng-option
            *ngFor="let patientGroup of this.patientGroupOptions"
            [value]="patientGroup.value"
          >
            <span [title]="patientGroup.label">{{ patientGroup.label }}</span>
          </ng-option>
        </ng-select>
      </div>

      <div
        class="aux-link cursor-pointer flex justify-center mb-4"
        (click)="downloadPatientBudgetTemplate()"
      >
        Download the template
      </div>

      <aux-file-manager
        #manager
        class="h-48"
        [fetchFilesOnInit]="false"
        [pathFn]="pathFn"
        [eager]="false"
        [metadata]="metadata"
        [showSuccessOnUpload]="true"
      />
    </div>

    <div class="mt-4 flex space-x-4">
      <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
        <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
        <span>Upload</span>
      </button>

      <button
        class="text-sm font-normal aux-link focus:outline-none"
        type="button"
        (click)="ref.close()"
      >
        Cancel
      </button>
    </div>
  </div>

  <div></div>

  <div class="max-h-60 overflow-auto mt-4">
    <aux-file-viewer
      [fileManager]="manager"
      [disableFirstFileMargin]="true"
      [onlyShowUploaded]="false"
    />
  </div>
</div>
