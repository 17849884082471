import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { FormControlConstants } from '@constants/form-control.constants';
import { OrganizationQuery } from '@models/organization/organization.query';
import { SitesService } from '@models/sites/sites.service';
import { SiteModel } from '@models/sites/sites.store';
import { Country, Currency } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { Maybe, Utils } from '@services/utils';
import { CustomValidators } from '@components/form-inputs/custom-validators';
import { SitesQuery } from '@models/sites/sites.query';
import dayjs from 'dayjs';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';

interface SiteForm {
  name: Maybe<string>;
  site_no: Maybe<string>;
  target_patients: Maybe<number>;
  country: Maybe<string>;
  zip: Maybe<string>;
  city: Maybe<string>;
  state: Maybe<string>;
  address_line_1: Maybe<string>;
  address_line_2: Maybe<string>;
  address_line_3: Maybe<string>;
  given_name: Maybe<string>;
  family_name: Maybe<string>;
  managed_by_id: Maybe<string>;
  currency: Maybe<string>;
  closeout_date: Maybe<string>;
  site_activation: Maybe<string>;
  investigator_id: Maybe<string>;
  site_id: string | null;
}

@Component({
  selector: 'aux-site-information-edit-view',
  templateUrl: './site-information-edit-view.component.html',
})
export class SiteInformationEditViewComponent implements OnInit {
  @Input({ required: true }) site!: SiteModel & {
    investigator_name?: string;
  };

  @Input({ required: true }) editMode$!: BehaviorSubject<boolean>;

  readonly countryOptions = Utils.getCountriesForSelectOptions();

  readonly currencyOptions = Utils.CURRENCY_OPTIONS;

  vendorOptions$ = this.vendorQuery
    .selectAll()
    .pipe(map((vendors) => vendors.map((vendor) => ({ label: vendor.name, value: vendor.id }))));

  siteForm = this.fb.group<SiteForm>({
    name: null,
    site_no: null,
    target_patients: null,
    country: null,
    zip: null,
    city: null,
    state: null,
    address_line_1: null,
    address_line_2: null,
    address_line_3: null,
    given_name: null,
    family_name: null,
    managed_by_id: null,
    currency: null,
    closeout_date: null,
    site_activation: null,
    investigator_id: null,
    site_id: null,
  });

  constructor(
    private fb: FormBuilder,
    private vendorQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private sitesService: SitesService,
    private sitesQuery: SitesQuery
  ) {}

  get siteNoValidators(): ValidatorFn[] {
    const siteNumbersToForbid = this.sitesQuery
      .getAll()
      .map((site) => site.site_no)
      .filter((site) => site !== this.site.site_no);

    return [
      Validators.required,
      CustomValidators.valueNotInList(siteNumbersToForbid, 'duplicatedSite'),
    ];
  }

  ngOnInit(): void {
    this.setInitialValues();
  }

  async updateSite() {
    this.siteForm.markAllAsTouched();

    if (this.siteForm.invalid) {
      return;
    }

    const {
      name,
      site_no,
      closeout_date,
      site_activation,
      investigator_id,
      site_id,
      family_name,
      given_name,
      city,
      state,
      zip,
      country,
      managed_by_id,
      target_patients,
      currency,
      address_line_1,
      address_line_2,
      address_line_3,
    } = this.siteForm.getRawValue();

    if (closeout_date && site_activation && dayjs(closeout_date).isBefore(dayjs(site_activation))) {
      this.overlayService.error(FormControlConstants.VALIDATION_MESSAGE.SITE_CLOSEOUT);
      return;
    }

    const investigatorPromise = investigator_id
      ? firstValueFrom(
          this.sitesService.updateContact(investigator_id, family_name || '', given_name || '')
        )
      : firstValueFrom(
          this.sitesService.createSiteContact({
            entity_id: site_id as string,
            given_name: given_name || '',
            family_name: family_name || '',
          })
        );

    const { data, errors } = await investigatorPromise;

    if (errors.length) {
      this.overlayService.error(errors);
      return;
    }

    const siteUpdateResponse = await this.sitesService.update(
      site_id as string,
      {
        id: site_id as string,
        name: name || '',
        site_no: site_no || '',
        city: city || '',
        state,
        zip,
        country: country as Country,
        managed_by_id,
        target_patients,
        site_activation,
        closeout_date,
        currency: currency as Currency,
        address_line_1,
        address_line_2,
        address_line_3,
      },
      {
        id: data!.id,
        family_name: family_name || '',
        given_name: given_name || '',
      },
      false,
      this.site.managed_by_id || undefined
    );

    if (siteUpdateResponse.success) {
      this.editMode$.next(false);
      this.overlayService.success(`${name} successfully updated!`);
    }
  }

  cancelChanges() {
    this.editMode$.next(false);
  }

  private setInitialValues() {
    this.siteForm.patchValue({
      site_id: this.site.id,
      investigator_id: this.site.investigator?.id || null,
      name: this.site.name || null,
      site_no: this.site.site_no || null,
      target_patients: this.site.target_patients || null,
      country: this.site.country || null,
      zip: this.site.zip || null,
      city: this.site.city || null,
      state: this.site.state || null,
      address_line_1: this.site.address_line_1 || null,
      address_line_2: this.site.address_line_2 || null,
      address_line_3: this.site.address_line_3 || null,
      given_name: this.site.investigator?.given_name || null,
      family_name: this.site.investigator?.family_name || null,
      managed_by_id: this.site.managed_by_id || null,
      currency: this.site.currency || null,
      closeout_date: this.site.closeout_date || null,
      site_activation: this.site.site_activation || null,
    });
  }
}
