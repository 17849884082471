import { Injectable } from '@angular/core';
import {
  CreateDriverBlendedPatientDistributionInput,
  createDriverBlendedPatientDistributionMutation,
  GqlService,
  UpdateDriverBlendedPatientDistributionInput,
  updateDriverBlendedPatientDistributionMutation,
} from '@services/gql.service';
import { first, switchMap, take, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { MessagesConstants } from '@constants/messages.constants';

import { PatientCurveModel, PatientCurveStore } from './patient-curve.store';
import { firstValueFrom } from 'rxjs';
import { PatientCurveQuery } from './patient-curve.query';

@Injectable({ providedIn: 'root' })
export class PatientCurveService {
  constructor(
    private patientCurveStore: PatientCurveStore,
    private patientCurveQuery: PatientCurveQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientCurveStore.remove(() => true);
        return this.gqlService.listDriverPatientGroups$().pipe(
          tap(({ success, data, errors }) => {
            let flag = false;
            if (success && data && data?.length > 0) {
              this.patientCurveStore.set(
                data.map((x) => {
                  if (!x.is_blended && !flag) {
                    flag = true;
                    return { ...x, showLine: true };
                  }
                  return { ...x, showLine: false };
                }) as PatientCurveModel[]
              );
            } else if (!success) {
              this.overlayService.error(errors);
            }
          })
        );
      })
    );
  }

  async add(
    patient: CreateDriverBlendedPatientDistributionInput
  ): Promise<GraphqlResponse<createDriverBlendedPatientDistributionMutation>> {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.createDriverBlendedPatientDistribution$({
        name: patient.name,
        patient_group_ids: patient.patient_group_ids,
      })
    );
    if (success) {
      await firstValueFrom(this.get().pipe(first()));
      this.overlayService.success(MessagesConstants.PATIENT_CURVES.SUCCESSFULLY_CREATED);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  async update(
    patient: UpdateDriverBlendedPatientDistributionInput
  ): Promise<GraphqlResponse<updateDriverBlendedPatientDistributionMutation>> {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updateDriverBlendedPatientDistribution$({
        name: patient.name,
        blended_group_id: patient.blended_group_id,
        patient_group_ids: patient.patient_group_ids,
      })
    );
    if (success) {
      this.overlayService.success(`${patient.name} successfully updated!`);
      firstValueFrom(this.get().pipe(take(1)));
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  async remove(patient: PatientCurveModel) {
    let successOverall = false;
    let errorsOverall: string | string[] | undefined = [];
    if (patient.is_blended && patient.patient_group_id) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.removeDriverBlendedPatientDistribution$(patient.patient_group_id)
      );
      successOverall = success;
      errorsOverall = errors;
    } else if (!patient.is_blended) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.removePatientGroupDriver$(patient.driver_setting_id)
      );
      successOverall = success;
      errorsOverall = errors;
    }
    if (successOverall) {
      this.overlayService.success(`${patient.name} successfully removed!`);
      await firstValueFrom(this.get().pipe(take(1)));
    } else {
      this.overlayService.error(errorsOverall);
    }

    return { successOverall, errorsOverall };
  }
}
