<div *ngVar="{ editMode: editMode$ | async, site: (site$ | async) } as obj">
  <div class="flex justify-between">
    <div *ngIf="obj.site">
      <aux-site-information-view *ngIf="!obj.editMode" [site]="obj.site" />
      <aux-site-information-edit-view
        *ngIf="obj.editMode"
        [site]="obj.site"
        [editMode$]="editMode$"
      />
    </div>

    <aux-button
      label="Edit"
      variant="secondary"
      *ngIf="!obj.editMode"
      [disabled]="!siteWorkflow.hasUpdateSitePermission() || siteWorkflow.isPatientsFinalized()"
      [auxTooltip]="siteWorkflow.actionButtonsTooltip()"
      label="Edit"
      icon="Pencil"
      (click)="editMode()"
    />
  </div>
</div>
