import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { FormControl, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Option } from '@components/components.type';
import {
  EntityType,
  GqlService,
  PatientProtocolVersion,
  EventType,
  DocumentType,
} from '@services/gql.service';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';

import { MainQuery } from '@shared/store/main/main.query';
import { AuthQuery } from '@shared/store/auth/auth.query';

type ProtocolModalView = 'create' | 'edit' | 'amendment';

@Component({
  template: `<div class="font-inter w-[325px]">
    <div class="text-lg font-bold mb-4">{{ ref.data?.name }}</div>
    <div *ngVar="ref.data?.modalView === 'amendment' as isAmendmentView" class="flex flex-col">
      <div>
        <aux-input
          #protocolVersion
          label="Protocol Name"
          validators="required"
          [formControl]="protocolVersionFc"
          [placeholder]="isAmendmentView ? 'Amendment Name/Version' : 'Protocol Version Name'"
          [showRequiredAsterisk]="true"
          n
        />
      </div>

      <div *ngIf="ref.data?.protocolVersionOptions && isAmendmentView" class="mt-4">
        <div class="mb-1 text-xs" [ngClass]="{ 'text-aux-gray-dark': protocolVersionFc.invalid }">
          Copy From (optional)
        </div>
        <ng-select
          placeholder="Select version"
          bindLabel="label"
          bindValue="value"
          [clearable]="true"
          [searchable]="false"
          [formControl]="copyFromVersionFc"
          [appendTo]="'body'"
          [items]="ref.data!.protocolVersionOptions"
        />
      </div>

      <div class="mt-4">
        <aux-input
          label="Protocol Version Effective Date"
          [formControl]="effectiveDateFc"
          placeholder="YYYY-MM-DD"
          [type]="'date'"
        />
      </div>

      <aux-file-manager
        #fileManager
        class="h-28 bg-aux-gray-light mt-4"
        [fetchFilesOnInit]="ref.data?.modalView === 'edit'"
        [pathFn]="pathFn"
        [eager]="false"
        [metadata]="metadata"
        [showSuccessOnUpload]="true"
      >
        <div class="flex items-center justify-center text-aux-gray-dark-100">
          <aux-icon name="Upload" [size]="42" />
          <div class="ml-3">
            <p class="text-lg">
              Drag & Drop Documents Here or <span class="aux-link">Browse</span>
            </p>
          </div>
        </div>
      </aux-file-manager>
      <aux-file-manager-uploaded-files *ngIf="fileManager" [fileManager]="fileManager" />

      <div class="w-full border-t border-aux-gray-dark mt-4"></div>

      <div *ngVar="ref.data?.modalView === 'edit' as isEditView" class="mt-3 flex justify-between">
        <button type="button" (click)="ref.close()">Cancel</button>
        <aux-button
          [label]="isEditView ? 'Save' : 'Create'"
          [classList]="'text-sm'"
          [loading]="submitting$ | async"
          [disabled]="(submitting$ | async) || protocolVersionFc.invalid"
          [onClick]="isEditView ? onEdit : onSave"
        />
      </div>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolVersionModalComponent implements OnInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata = {
    documentType: DocumentType.DOCUMENT_TRIAL_PROTOCOL,
    entity_type_id: EntityType.TRIAL_PROTOCOL,
  };

  submitting$ = new BehaviorSubject(false);

  protocolVersionFc = new FormControl('', [Validators.required]);

  effectiveDateFc = new FormControl('');

  copyFromVersionFc = new FormControl(null);

  pathFn = () => {
    const trialId = this.mainQuery.getValue().trialKey;

    const versionId = this.ref.data?.protocolVersion?.id;

    return versionId ? `trials/${trialId}/document-library/${versionId}` : '';
  };

  @ViewChild('protocolVersion') protocolVersionInput!: InputComponent;

  constructor(
    public ref: CustomOverlayRef<
      unknown,
      {
        modalView: ProtocolModalView;
        name: string;
        protocolVersionOptions: Option[];
        protocolVersion: Pick<PatientProtocolVersion, 'name' | 'id' | 'effective_date'>;
      }
    >,
    private gqlService: GqlService,
    private patientProtocolService: PatientProtocolService,
    private mainQuery: MainQuery,
    private authQuery: AuthQuery
  ) {}

  ngOnInit(): void {
    this.setCopyFromFieldDefaultState();

    const isEditView = this.ref.data?.modalView === 'edit';

    if (isEditView) {
      this.protocolVersionFc.setValue(this.ref.data?.protocolVersion?.name || null);
      this.effectiveDateFc.setValue(this.ref.data?.protocolVersion?.effective_date || null);
    }

    this.protocolVersionFc.valueChanges.subscribe(() => {
      if (!this.protocolVersionFc.invalid) {
        this.copyFromVersionFc.enable();
      } else {
        this.setCopyFromFieldDefaultState();
      }
    });
  }

  onEdit = async () => {
    if (this.protocolVersionFc.value && this.ref.data) {
      const success = await this.patientProtocolService.updatePatientProtocolVersion({
        id: this.ref.data.protocolVersion.id,
        name: this.protocolVersionFc.value,
        effective_date: this.effectiveDateFc.value,
      });

      await firstValueFrom(
        this.gqlService.processEvent$({
          type: EventType.UPDATE_INVESTIGATOR_SCHEDULES,
          entity_type: EntityType.SITE,
          entity_id: '',
        })
      );

      if (!success) {
        this.setDuplicateError();

        return;
      } else {
        this.uploadDocuments(this.ref.data.protocolVersion.id);
      }

      this.ref.close({
        success,
        updatedVersionName: this.protocolVersionFc.value,
        effective_date: this.effectiveDateFc.value,
      });
    }
  };

  onSave = async (): Promise<void> => {
    if (this.protocolVersionFc.value) {
      const isAmendment = this.ref.data?.modalView === 'amendment';

      const copyFromParams =
        isAmendment && this.copyFromVersionFc.value
          ? { copy_from_id: this.copyFromVersionFc.value }
          : {};

      const { data, errors } = await this.patientProtocolService.createPatientProtocolVersion({
        ...copyFromParams,
        effective_date: this.effectiveDateFc.value || null,
        name: this.protocolVersionFc.value,
      });

      if (errors.length) {
        this.setDuplicateError();

        return;
      } else {
        this.uploadDocuments(data?.id);
      }

      this.ref.close({
        version: { label: data?.name, value: data?.id, effective_date: data?.effective_date },
      });
    }
  };

  private async uploadDocuments(versionId?: string) {
    if (!this.fileManager || !versionId) return;

    const files = this.fileManager.fileQuery.getAll().filter(({ uploaded }) => !uploaded);

    if (!files.length) return;

    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.TRIAL_PROTOCOL_UPLOADED,
        entity_type: EntityType.DOCUMENT,
        entity_id: '',
        payload: JSON.stringify({
          uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
          file_names: files.map((f) => f.fileName).join(', '),
        }),
      })
    );

    if (this.ref.data?.modalView !== 'edit') {
      this.updateFileStoreBeforeUpload(files, versionId);
    }

    await this.fileManager.fileService.uploadFiles(
      {
        documentType: DocumentType.DOCUMENT_TRIAL_PROTOCOL,
        entity_type_id: EntityType.TRIAL_PROTOCOL,
      },
      true,
      true,
      undefined,
      true,
      true,
      false
    );
  }

  private updateFileStoreBeforeUpload(files: File[], versionId: string) {
    const trialId = this.mainQuery.getValue().trialKey;

    files.forEach((file) => {
      this.fileManager?.fileStore.update(file.id, {
        ...file,
        bucket_key: `trials/${trialId}/document-library/${versionId}/${new Date().toISOString()}_${
          file.fileName
        }`,
      });
    });
  }

  private setDuplicateError() {
    this.protocolVersionFc.markAsTouched();
    this.protocolVersionInput.fc.setErrors({ duplicate_version: true });
  }

  private setCopyFromFieldDefaultState(): void {
    this.copyFromVersionFc.disable();
    this.copyFromVersionFc.reset();
  }
}
