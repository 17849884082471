import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { AccountSettingsComponent } from './tabs/account-settings/account-settings.component';
import { AccountNotificationsComponent } from './tabs/account-notifications/account-notifications.component';
import { AccountComponent } from './account.component';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';

export const ACCOUNT_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.ACCOUNT.SETTINGS,
    component: AccountSettingsComponent,
    data: {
      feature: FeatureFlag.ACCOUNT_SETTINGS,
    },
  },
];

export const buildAccountRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    {
      path: ROUTING_PATH.ACCOUNT.NOTIFICATIONS,
      component: AccountNotificationsComponent,
      canDeactivate: [CanDeactivateGuard],
    },
    ...buildRoutesByFeatureFlags(featureFlags, ACCOUNT_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.ACCOUNT.INDEX,
    component: AccountComponent,
    children: [...enabledRoutes, { path: '**', redirectTo: ROUTING_PATH.ACCOUNT.NOTIFICATIONS }],
  };
};
