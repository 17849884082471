<div
  class="flex items-center justify-between h-full pl-[10px] pr-[4px]"
  (click)="onDropdown()"
  [ngClass]="{ 'cursor-pointer': !disabled }"
  [auxTooltip]="disabled ? msg : ''"
  [auxTooltipPositions]="topLevelTooltipPositions"
>
  <div class="truncate" *ngIf="!selectedCategory">Select and Manage Categories</div>
  <div
    class="truncate"
    *ngIf="selectedCategory"
    #cat
    [auxTooltip]="cat.offsetWidth < cat.scrollWidth && !disabled ? selectedCategory : ''"
    [auxTooltipPositions]="categoryTooltipPositions"
  >
    {{ selectedCategory }}
  </div>
  <aux-icon class="flex-shrink-0" name="CaretDownFilled" [size]="16" />
</div>
<div #trigger="cdkOverlayOrigin" cdkOverlayOrigin></div>

<ng-template
  cdkConnectedOverlay
  class="border-solid border-aux-gray-dark-100"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'random-class'"
  (detach)="dropdownOpened = false"
  (backdropClick)="dropdownOpened = false"
>
  <div
    class="py-3 bg-white rounded-b-[4px] px-[8px] ml-[-1px] border"
    style="box-shadow: 0 5px 3px 0 #696f8e59"
    [ngStyle]="{
      width: trigger.elementRef.nativeElement.clientWidth + 1.5 + 'px'
    }"
  >
    <div class="h-[32px] w-full">
      <aux-input
        [(ngModel)]="filterText"
        (ngModelChange)="onFilter()"
        icon="Search"
        iconPosition="left"
        inputClassName="text-aux-gray-dark-100 focus-within:text-aux-gray-dark-100 rounded-[4px] placeholder:text-aux-gray-dark-100 !placeholder:not-italic"
        iconClassName="text-aux-gray-dark-100 focus-within:text-aux-gray-dark-100"
        placeholder="Search"
      />
    </div>
    <div class="h-[200px] mt-[10px] overflow-auto snap-y w-full">
      <aux-be-inline-category-option
        *ngFor="let option of filteredCategories; trackBy: trackByFn"
        [option]="option"
        [categories]="categories"
        (selectOptionEmit)="onDropdownSelected(option)"
        (addCategoryEmit)="onDropdownAdd($event, option)"
        (editCategoryEmit)="onDropdownEdit($event, option)"
      />
    </div>
  </div>
</ng-template>
