import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { AgIconLinkCellComponentParams } from '@shared/components/ag-icon-link-cell/ag-icon-link-cell.model';

@Component({
  templateUrl: 'ag-icon-link-cell.component.html',
  styleUrls: ['ag-icon-link-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TooltipDirective, IconComponent],
})
export class AgIconLinkCellComponent implements ICellRendererAngularComp {
  params!: AgIconLinkCellComponentParams;

  value!: string;

  cellClass!: string;

  agInit(params: AgIconLinkCellComponentParams): void {
    this.params = params;
    this.value = this.params.valueFormatted || this.params.value;
  }

  refresh(): boolean {
    return false;
  }
}
