import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { formatDate, NgClass, NgIf, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { IconComponent } from '@components/icon/icon.component';
import { AssignMonthCloseComponent } from '../../../assign-month-close/assign-month-close.component';
import { MessagesConstants } from '@constants/messages.constants';

export const QuarterCloseBannerHeight = 58;

@Component({
  selector: 'aux-quarter-close-banner',
  templateUrl: './quarter-close-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    TooltipDirective,
    NgIf,
    NgClass,
    NgStyle,
    IconComponent,
    AssignMonthCloseComponent,
  ],
})
export class QuarterCloseBannerComponent {
  @Input() disabled = false;

  @Input() hasClosePermission = false;

  @Input() currentMonth = '';

  @Output() closeMonth = new EventEmitter();

  readonly routerLink = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`;

  bannerHeight = QuarterCloseBannerHeight;

  get closeMonthText(): string {
    return this.currentMonth ? `Close ${formatDate(this.currentMonth, 'MMMM YYYY', 'en-US')}` : '';
  }

  get closeMonthBtnText(): string {
    return this.currentMonth ? `Close ${formatDate(this.currentMonth, 'MMMM', 'en-US')}` : '';
  }

  get closeMonthTooltip(): string {
    if (this.disabled) {
      return 'You can’t close the month until you review and complete the checklist.';
    }

    if (!this.hasClosePermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return '';
  }

  onCloseMonth(): void {
    this.closeMonth.emit();
  }
}
