import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { UntypedFormControl } from '@angular/forms';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { EntityType, EventType, GqlService, TemplateType } from '@services/gql.service';
import { PatientGroupsQuery } from '../patient-groups/state/patient-groups.query';

@Component({
  selector: 'aux-patient-driver-upload',
  template: `
    <div class="text-lg font-bold mb-4">Upload Patient Driver</div>

    <div class="grid grid-cols-2 gap-5">
      <div class="flex justify-between flex-col">
        <div class="">
          <div class="mb-2 text-xs">Patient Group</div>
          <ng-select
            placeholder="Select"
            id="sites"
            [formControl]="selectedPatientGroup"
            [appendTo]="'body'"
            [searchable]="true"
            [clearable]="false"
          >
            <ng-option value="" />
            <ng-option [value]="pt.id" *ngFor="let pt of patientGroupsQuery.selectAll() | async">
              {{ pt.name }}
            </ng-option>
          </ng-select>
        </div>

        <div>
          <div
            *ngIf="errorMessage"
            class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md"
          >
            {{ errorMessage }}
          </div>

          <div class="mt-16 flex space-x-4">
            <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
              <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
              <span>Upload</span>
            </button>

            <button
              class="text-sm font-normal aux-link focus:outline-none"
              (click)="ref.close()"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div>
        <div
          class="aux-link cursor-pointer flex justify-center mb-4"
          (click)="downloadSiteDriverTemplate()"
        >
          Download the template
        </div>

        <aux-file-manager
          class="h-48"
          #manager
          [fetchFilesOnInit]="false"
          [pathFn]="pathFn"
          [eager]="false"
          [metadata]="metadata"
          [showSuccessOnUpload]="true"
        />
      </div>
      <div></div>
      <div class="max-h-60 overflow-auto mt-4">
        <aux-file-viewer
          [fileManager]="manager"
          [disableFirstFileMargin]="true"
          [onlyShowUploaded]="false"
        />
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDriverUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  selectedPatientGroup = new UntypedFormControl('');

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  constructor(
    public ref: CustomOverlayRef<unknown, unknown>,
    private apiService: ApiService,
    private overlayService: OverlayService,
    public patientGroupsQuery: PatientGroupsQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {}

  pathFn: () => string = () => '';

  getFilePath(id: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/sites/${id}/patient-driver/`;
  }

  async downloadSiteDriverTemplate() {
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.PATIENT_DRIVER_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath(this.selectedPatientGroup.value)}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.PATIENT_DRIVER_TEMPLATE_UPLOADED,
            entity_type: EntityType.DRIVER,
            entity_id: this.selectedPatientGroup.value,
            bucket_key: `public/${key}`,
          })
        );
        if (success) {
          this.overlayService.success();
        } else {
          this.apiService.removeFile(this.getFilePath(file.bucket_key));
          this.overlayService.error(errors);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
