<!-- Workflow panel -->
<aux-workflow-panel
  *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
  className="mb-7"
  [workflowName]="workflowName"
  [processing]="(iCloseMonthsProcessing$ | async)!"
  [hasUserPermissions]="userHasLockForecastMethodologyPermission"
/>

<div
  *ngIf="(categoryQuery.selectLoading() | async) === true"
  class="border-8 h-32 m-auto mt-40 spinner w-32"
></div>

<ng-template #vendorDropdown>
  <div class="flex items-center">
    <div class="text-sm mr-2">Vendor</div>
    <aux-vendor-dropdown
      [vendors]="$any(organizationQuery.allVendors$ | async)"
      [formControl]="selectedVendor"
      (onChange)="onOrganizationSelected($event)"
    />
  </div>
</ng-template>

<ng-container *ngIf="(categoryQuery.selectLoading() | async) === false">
  <ng-container *ngIf="{ manual: isSelectedVendorHasManualForecast$ | async } as obj">
    <!-- Manual forecast container -->
    <ng-container *ngIf="obj.manual === true">
      <ng-container [ngTemplateOutlet]="vendorDropdown" />
      <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">
        <aux-icon name="FileDescription" [size]="70" />
        <div class="text-xl font-bold">Selected vendor has a manual forecast</div>
      </div>
    </ng-container>

    <!-- Non-manual container -->
    <ng-container *ngIf="obj.manual === false">
      <aux-forecast-analytics-section *ngIf="showAnalyticsSection$ | async" />

      <ng-container *ngIf="(categoryQuery.selectLoading() | async) === false">
        <!-- Driver links -->
        <div *ngIf="(successForecast$ | async) === false" id="notSuccessForecastMessage">
          <div class="flex items-start bg-aux-warn p-3 mt-8">
            <strong class="mr-1">Drivers Not Set:</strong> Drivers have not been added.
            <a
              *ngIf="isPatientDriversLinkVisible"
              class="font-bold text-aux-blue-light-200 ml-1"
              [routerLink]="[patientDriversLink]"
              >Add patient driver now.</a
            >

            <a
              *ngIf="isSiteDriversLinkVisible"
              class="font-bold text-aux-blue-light-200 ml-3"
              [routerLink]="[siteDriversLink]"
              >Add site driver now.</a
            >
          </div>
        </div>

        <div class="flex items-center mt-8 mb-8 text-xs justify-between">
          <div class="space-x-6 flex items-center">
            <ng-container [ngTemplateOutlet]="vendorDropdown" />
            <aux-checkbox
              id="hide-activities-with-no-remaining-cost"
              [checked]="(categoryStore.hideActivitiesWithNoRemainingCost$ | async)!"
              (customChange)="onHideActivitiesWithNoRemainingCost($event)"
            >
              Hide Activities with <br />
              No Remaining Costs
            </aux-checkbox>
            <aux-checkbox
              id="show-only-unforecasted-activities"
              [checked]="(categoryStore.hideForecastedActivities$ | async)!"
              (customChange)="onShowOnlyUnforecasted($event)"
            >
              Show Only <br />
              Unforecasted Activities
            </aux-checkbox>
          </div>

          <div class="space-x-2 flex items-center">
            <aux-button
              variant="secondary"
              classList="h-[40px]"
              icon="Eye"
              label="Forecast"
              (click)="navigateToBudgetForecast()"
            />
            <aux-export-excel-button
              className="ml-1"
              [clickFN]="onDownloadForecastSettings.bind(this)"
              [spinnerSize]="7"
              pendoTag="download-forecast-settings-excel-export-button"
            />
          </div>
        </div>

        <!-- Forecast table -->
        <aux-forecast-table-grid
          [isForecastFinalized]="(isForecastFinalized$ | async) || false"
          [isClosedMonthsProcessing]="(iCloseMonthsProcessing$ | async) || false"
          [isPatientDriverAvailable]="isPatientDriverAvailable || false"
          [isPatientDriversLinkVisible]="isPatientDriversLinkVisible || false"
          [isSiteDriverAvailable]="isSiteDriverAvailable || false"
          [isSiteDriversLinkVisible]="isSiteDriversLinkVisible || false"
          [selectedVendor]="selectedVendor"
          [successForecast]="(successForecast$ | async) || false"
          [changeDiscountTotalAmount]="changeDiscountTotalAmount"
          [changeDiscountTotalPercent]="changeDiscountTotalPercent"
          [saveCheck]="(saveCheck$ | async) || false"
          (emitSaveCheck)="onSaveCheck($event)"
          (emitIsPatientDriversLinkVisible)="onIsPatientDriversLinkVisible($event)"
          (emitIsSiteDriversLinkVisible)="onIsSiteDriversLinkVisible($event)"
          (emitHideDiscounts)="onHideDiscounts($event)"
          (emitSuccessForecast)="onSuccessForecast($event)"
          (emitChangeDiscountTotalAmount)="onChangeDiscountTotalAmount($event)"
          (emitChangeDiscountTotalPercent)="onChangeDiscountTotalPercent($event)"
          (emitChangeDiscountTotalAmountEnable)="onChangeDiscountTotalAmountEnable($event)"
          (emitChangeDiscountTotalPercentEnable)="onChangeDiscountTotalPercentEnable($event)"
        />

        <!-- Discount section -->
        <ng-container *ngIf="(categoryQuery.selectTopCategories$ | async)?.length">
          <ng-container *ngIf="(hideDiscounts$ | async) === false">
            <div
              class="font-medium rounded-md h-16 gap-5 grid grid-cols-12 mb-1 py-1 px-8 text-xs items-center z-10 bg-aux-gray-light border"
              [ngClass]="{ 'mb-20': (saveCheck$ | async) || false }"
            >
              <div class="col-span-2 text-base font-bold">
                <div class="flex items-center font-semibold text-xs h-full justify-between">
                  Services Discount
                </div>
              </div>
              <div class="col-span-3 text-sm justify-items-end">
                <div
                  *ngIf="
                    discountType$.getValue() === 'DISCOUNT_PERCENTAGE' ||
                    discountType$.getValue() === 'DISCOUNT_TOTAL'
                  "
                  class="flex text-xs items-center"
                >
                  <span class="w-24 mr-2">Percentage:</span>
                  <span>{{ changeDiscountTotalPercent.value.toFixed(2) }}%</span>
                </div>
                <div
                  *ngIf="
                    discountType$.getValue() === 'DISCOUNT_TOTAL' &&
                    changeDiscountTotalAmount.value !== 0 &&
                    changeDiscountTotalAmount.value !== null
                  "
                  class="flex text-xs items-center"
                >
                  <span class="w-24 mr-2">Total:</span>
                  <span>{{ changeDiscountTotalAmount.value | money: orgCurrency }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <!-- No results container -->
        <div
          *ngIf="
            !(topLevelActivities$ | async)?.length &&
            !(categoryQuery.selectTopCategories$ | async)?.length
          "
          class="mt-5"
        >
          <ng-container
            *ngIf="
              (topLevelActivities$ | async)?.length ||
                (categoryQuery.topCategories$ | async)?.length;
              else notFound
            "
          >
            No Results found with your selection. Please try
            <span class="aux-link cursor-pointer" (click)="clearFilters()">
              clearing the filters
            </span>
            .
          </ng-container>
          <ng-template #notFound> No budget data found for this vendor </ng-template>
        </div>
      </ng-container>

      <!-- Save changes -->
      <aux-save-changes
        *ngIf="(saveCheck$ | async) || false"
        [onSaveChanges]="forecastTableGridComponent.saveChanges"
        [showDiscardChangesBtn]="true"
        (cancel)="forecastTableGridComponent.onDiscardChanges()"
      />
    </ng-container>
  </ng-container>
</ng-container>
