@if (currentWorkflow()) {
  <div
    class="border border-aux-gray-dark flex items-center justify-between p-2.5 pl-4 pr-4"
    [ngClass]="{
      'bg-aux-gray-light': !isLocked(),
      'bg-aux-blue-dark': isLocked()
    }"
  >
    <div class="flex items-center">
      @if (isLocked()) {
        <aux-icon name="Lock" class="text-white" />
      } @else {
        <aux-icon name="LockOpen" />
      }

      <div class="ml-3">
        @if (isWorkflowNameVisible()) {
          <p
            class="text-aux-black font-bold"
            [ngClass]="{
              'text-aux-black': !isLocked(),
              'text-white': isLocked()
            }"
          >
            {{ workflowTitle() }}
          </p>
        }
        <p
          class="text-sm"
          [ngClass]="{
            'text-aux-gray-darkest': !isLocked(),
            'text-white': isLocked()
          }"
        >
          {{ getTitle() }}
        </p>
      </div>
    </div>

    <aux-button
      [disabled]="!hasUserPermissions() || !lockAvailable()"
      [auxTooltip]="getBtnTooltipText()"
      [label]="getBtnLabel()"
      [icon]="getBtnIcon()"
      [variant]="getBtnVariant()"
      (click)="onToggleLockWorkflow()"
      [attr.data-pendo-id]="getBtnDataPendoId()"
    />
  </div>
}
