<div
  class="p-4 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all font-inter"
  [style]="{ maxWidth: ref.data?.maxWidth ?? '530px' }"
  @enterLeave
>
  <div class="flex justify-between">
    <div
      *ngIf="ref.data?.header as header"
      class="text-2xl font-bold mb-6 mr-8 text-aux-black flex items-center space-x-2"
    >
      <aux-icon name="AlertTriangle" class="text-aux-red-dark" [size]="26" />
      <span>
        {{ header }}
      </span>
    </div>
    <div *ngIf="!ref.data?.header"></div>

    <button
      id="closeButton"
      type="button"
      class="bg-white flex rounded-md text-gray-400 hover:text-gray-700 focus:outline-none"
      (click)="closeModal()"
    >
      <span class="sr-only">Close</span>
      <aux-icon name="X" />
    </button>
  </div>

  <ng-container [ngSwitch]="contentType">
    <ng-container *ngSwitchCase="'string'">
      <div [innerHTML]="ref.content"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="$any(ref.content)" />
    </ng-container>
  </ng-container>

  <p class="mt-6 italic font-medium">
    To prevent accidental changes, please type “{{ ref.data?.keywordToExecuteAction }}” in the field
    below to confirm.
  </p>

  <aux-input
    [placeholder]="ref.data?.keywordToExecuteAction ?? ''"
    class="text-xs my-4"
    validators="required"
    [formControl]="confirmationFc"
  />

  <div class="flex justify-between">
    <aux-button label="Cancel" variant="secondary" (click)="ref.close({ success: false })" />
    <aux-button
      [label]="okButtonLabel"
      [variant]="submitButtonText"
      [disabled]="confirmationFc.invalid"
      (click)="ref.close({ success: true })"
    />
  </div>
</div>
