import { NgClass, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ButtonItemOption {
  label: string;
  value: string;
}

@Component({
  selector: 'aux-button-list',
  templateUrl: './button-list.component.html',
  standalone: true,
  imports: [NgFor, NgClass],
})
export class ButtonListComponent {
  @Input({ required: true }) options!: ButtonItemOption[];

  @Input() beforeSelect?: (
    nextValue: string | null,
    prevValue: string | null
  ) => boolean | Promise<boolean>;

  @Output() selectedItem = new EventEmitter<string>();

  @Input() value: string | null = '';

  async selectButton(value: string) {
    const prevValue = this.value;

    if (this.beforeSelect && !(await this.beforeSelect(value, prevValue))) {
      return;
    }

    this.value = value;
    this.selectedItem.emit(value);
  }
}
