import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntypedFormControl } from '@angular/forms';
import { OrganizationService } from '@models/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, firstValueFrom, ReplaySubject } from 'rxjs';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ExcelExportParams,
  GetQuickFilterTextParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
  ProcessCellForExportParams,
  RowNode,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { OverlayService } from '@services/overlay.service';
import { Router } from '@angular/router';
import {
  BudgetType,
  BudgetVersion,
  ChangeOrder,
  ChangeOrderStatus,
  Currency,
  EntityType,
  GqlService,
  listBudgetVersionExpensesQuery,
  Organization,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { map } from 'rxjs/operators';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';

import { AuthService } from '@shared/store/auth/auth.service';
import { ChangeOrderActionsComponent } from './change-order-actions.component';
import { ChangeOrderStatusComponent } from './change-order-status.component';
import { ChangeOrderQuery } from './state/change-order.query';
import { ChangeOrderService } from './state/change-order.service';
import { ChangeOrderUploadComponent } from './change-order-upload/change-order-upload.component';
import { ChangeOrderSharedService } from './state/change-order-shared.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { StickyElementService } from '@services/sticky-element.service';
import { batchPromises, AuxExcelStyleKeys, AuxExcelStyles, GetExcelStyle } from '@shared/utils';
import { cellSize } from '../budget-enhanced/column-defs';
import { VariationStatusComponent } from '../../../design-system/tables';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { TableConstants } from '@constants/table.constants';
import dayjs from 'dayjs';
import { BudgetCurrencyType } from '../budget-enhanced/budget-type';
import { Chart } from 'chart.js';
import { ChartDataset } from 'chart.js/dist/types';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';

interface ChangeOrderGridData {
  co_id: string;
  co_number: string;
  vendor_name: string;
  vendor_id: string;
  total_cost_impact: number;
  status: string;
  approved_date: string;
  approved_by: string;
  date_created: string;
  created_by: string;
  budget_total: unknown;
  vendor_currency: Currency;
}

interface BvExpenseData extends Partial<BudgetVersion> {
  expense_data: listBudgetVersionExpensesQuery[] | null;
  budget_short_type: 'CO' | 'BASE' | 'LRE' | undefined;
}

@UntilDestroy()
@Component({
  selector: 'aux-change-order',
  templateUrl: './change-order.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      ::ng-deep .change-order-table .ag-header-group-cell {
        background: var(--aux-gray-light);
        border: 0.5px solid var(--aux-gray-dark);
        border-bottom: 0;
      }
      ::ng-deep .change-order-table .open {
        background: var(--aux-gray-dark);
        color: var(--aux-black);
      }
      ::ng-deep .change-order-table .ag-header-row,
      ::ng-deep .change-order-table .ag-header-cell {
        font-size: 1rem;
        overflow: unset;
      }
      ::ng-deep .change-order-table .ag-header-cell-text,
      ::ng-deep .change-order-table .ag-row {
        font-size: 1rem;
        color: var(--aux-black);
      }
      ::ng-deep .change-order-table .ag-row {
        background: white !important;
      }
      ::ng-deep .change-order-table .ag-header-cell {
        text-align: center;
      }
      ::ng-deep .change-order-table .ag-header-cell-label {
        justify-content: center;
      }
      ::ng-deep .change-order-table .ag-header-cell-text {
        white-space: normal !important;
      }
      ::ng-deep .change-order-table .text-elypsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      ::ng-deep .change-order-table .net_accruals_header {
        padding: 0;
      }
      ::ng-deep .change-order-table .ag-cell.grid-cell {
        margin: 0 !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
      ::ng-deep .change-order-table .status-cell {
        display: flex;
        align-items: center;
      }
      ::ng-deep .change-order-table .action-cell {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderComponent implements OnInit, OnDestroy {
  selectedVendor = new UntypedFormControl('');

  myChart: Chart | null = null;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  coChartLoading$ = new BehaviorSubject(false);

  userHasDownloadChangeOrderPermission$ = new BehaviorSubject(false);

  showCOChart$ = new BehaviorSubject<boolean>(false);

  selectedBudgetCurrencyType$ = new BehaviorSubject<BudgetCurrencyType>(BudgetCurrencyType.VENDOR);

  overlayNoRowsTemplate = TableConstants.NO_ROWS_CO_MESSAGE;

  isAllCosSameCurrency = false;

  allOrgCurrency: Currency = Currency.USD;

  isChangeOrdersWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS
  );

  getCellClassForNumbers =
    () =>
    (params: CellClassParams): string[] => {
      const classes: string[] = [];
      if (
        params?.node?.data?.vendor_currency &&
        this.selectedBudgetCurrencyType$.getValue() === BudgetCurrencyType.VENDOR
      ) {
        classes.push(`budgetCost${params?.node?.data?.vendor_currency}`);
      } else {
        classes.push(`budgetCostUSD`);
      }

      classes.push(TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT);

      return classes;
    };

  excelOptions = {
    sheetName: 'Change order',
    fileName: 'auxilius-change-order.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_name':
        case 'co_number':
          return 280;
        default:
          return 150;
      }
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      if (
        params.column.getColId() === 'approved_date' ||
        params.column.getColId() === 'date_created'
      ) {
        return dayjs(params.value).format('YYYY-MM-DD');
      } else if (
        params.column.getColId() === 'approved_by' ||
        params.column.getColId() === 'created_by'
      ) {
        return this.changeOrderSharedService.userFormatter(params.value);
      } else if (params.column.getColId() === 'status') {
        switch (params.value as ChangeOrderStatus) {
          case ChangeOrderStatus.STATUS_APPROVED:
            return 'Approved';
          case ChangeOrderStatus.STATUS_DECLINED:
            return 'Declined';
          case ChangeOrderStatus.STATUS_PENDING_APPROVAL:
            return 'Pending Approval';
          case ChangeOrderStatus.STATUS_PENDING_REVIEW:
            return 'Pending Review';
          default:
            return Utils.zeroHyphen;
        }
      }
      return params.value;
    },
  } as ExcelExportParams;

  gridOptions$ = new BehaviorSubject({
    defaultColDef: {
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 80,
      cellRenderer: AgCellWrapperComponent,
    },
    suppressCellFocus: true,
    excelStyles: [
      ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
      ...AuxExcelStyles,
      {
        ...GetExcelStyle(AuxExcelStyleKeys.FIRST_ROW),
        id: 'trial_name',
        borders: {
          borderBottom: {
            color: 'black',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
    ],
    columnDefs: [
      {
        headerName: 'co_id',
        field: 'co_id',
        hide: true,
      },
      {
        headerName: 'vendor_id',
        field: 'vendor_id',
        hide: true,
        filter: true,
      },
      {
        headerName: '',
        field: 'actions',
        cellRenderer: ChangeOrderActionsComponent,
        cellRendererParams: {
          isAdmin: this.authQuery.isAuxAdmin(),
          downloadPermission$: this.userHasDownloadChangeOrderPermission$,
          isChangeOrdersWorkflowLocked: this.isChangeOrdersWorkflowLocked,
          downloadClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.downloadChangeOrder(rowNode);
          },
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.removeChangeOrder(rowNode);
          },
        },
        cellClass: ['grid-cell', 'action-cell'],
        maxWidth: 90,
        minWidth: 80,
        editable: false,
      },
      {
        headerName: 'Change Order #',
        field: 'co_number',
        type: 'string',
        tooltipField: 'co_number',
        resizable: true,
        onCellClicked: (event) => this.goToCODetail(event),
        cellClass: [
          'aux-link',
          'cursor-pointer',
          'ag-cell-align-left',
          'text-format',
          'cell-left',
          'text-elypsis',
        ],
      },
      {
        headerName: 'Vendor',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        resizable: true,
        cellClass: ['grid-cell', 'ag-cell-align-left', 'text-elypsis'],
      },
      {
        headerName: 'Total',
        field: 'total_cost_impact',
        width: 150,
        minWidth: 150,
        suppressSizeToFit: true,
        resizable: true,
        valueFormatter: Utils.agChangeOrderCurrencyFormatter(
          this.selectedBudgetCurrencyType$.getValue()
        ),
        cellClass: this.getCellClassForNumbers(),
      },
      {
        headerName: 'Variance',
        field: `budget_total.variance`,
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        suppressSizeToFit: true,
        resizable: true,
        cellRenderer: VariationStatusComponent,
        valueFormatter: Utils.agChangeOrderCurrencyFormatter(
          this.selectedBudgetCurrencyType$.getValue()
        ),
        cellClass: this.getCellClassForNumbers(),
      },
      {
        headerName: 'Status',
        field: 'status',
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        resizable: true,
        cellRenderer: ChangeOrderStatusComponent,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          switch (params.value as ChangeOrderStatus) {
            case ChangeOrderStatus.STATUS_APPROVED:
              return 'Approved';
            case ChangeOrderStatus.STATUS_DECLINED:
              return 'Declined';
            case ChangeOrderStatus.STATUS_PENDING_APPROVAL:
              return 'Pending Approval';
            case ChangeOrderStatus.STATUS_PENDING_REVIEW:
              return 'Pending Review';
            default:
              return Utils.zeroHyphen;
          }
        },
        cellClass: ['grid-cell', 'status-cell'],
      },
      {
        headerName: 'Date Approved',
        field: 'approved_date',
        type: 'date',
        minWidth: 135,
        width: 135,
        resizable: true,
        cellClass: ['grid-cell', 'ag-cell-align-left'],
        valueFormatter: Utils.agDateFormatter,
        getQuickFilterText: Utils.agDateFormatter,
      },
      {
        headerName: 'Approved By',
        field: 'approved_by',
        resizable: true,
        tooltipValueGetter: (params: ITooltipParams) =>
          this.changeOrderSharedService.userFormatter(params.value),
        cellClass: ['grid-cell', 'ag-cell-align-left', 'text-elypsis'],
        valueFormatter: (val: ValueFormatterParams) =>
          this.changeOrderSharedService.userFormatter(val.value),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          this.changeOrderSharedService.userFormatter(params.value),
      },
      {
        headerName: 'Date Created',
        field: 'date_created',
        type: 'date',
        minWidth: 135,
        width: 135,
        resizable: true,
        valueFormatter: Utils.agDateFormatter,
        getQuickFilterText: Utils.agDateFormatter,
        sort: 'desc',
        cellClass: ['grid-cell', 'ag-cell-align-left'],
      },
      {
        headerName: 'Created By',
        field: 'created_by',
        resizable: true,
        tooltipValueGetter: (params: ITooltipParams) =>
          this.changeOrderSharedService.userFormatter(params.value),
        cellClass: ['grid-cell', 'ag-cell-align-left', 'text-elypsis'],
        valueFormatter: (val: ValueFormatterParams) =>
          this.changeOrderSharedService.userFormatter(val.value),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          this.changeOrderSharedService.userFormatter(params.value),
      },
    ],
    columnTypes: Utils.columnTypes,
  } as GridOptions);

  totalCosts: { total: number; variance: number } = { total: 0, variance: 0 };

  loading$ = combineLatest([
    this.changeOrderQuery.selectLoading(),
    this.organizationQuery.selectLoading(),
  ]).pipe(map((arr) => arr.some((x) => x)));

  gridData$ = combineLatest([
    this.changeOrderQuery.selectAll(),
    this.organizationQuery.selectAll({ asObject: true }),
  ]).pipe(
    map(([changeOrders, organizations]) => {
      this.organizations = organizations as { [id: string]: Partial<Organization> };
      this.changeOrders = changeOrders as Partial<ChangeOrder>[];
      if (this.showCOChart$.getValue()) {
        this.drawChart(
          organizations as { [id: string]: Partial<Organization> },
          changeOrders as Partial<ChangeOrder>[]
        );
      }
      if (changeOrders.length > 0 && organizations) {
        const firstCurrency = organizations[changeOrders[0].organization_id]?.currency;
        this.isAllCosSameCurrency = changeOrders.every((co) => {
          const comparedCurrency = organizations[co.organization_id]?.currency;
          return firstCurrency && comparedCurrency && comparedCurrency === firstCurrency;
        });
        if (this.isAllCosSameCurrency) {
          this.allOrgCurrency = firstCurrency || Currency.USD;
        }
      }
      this.totalCosts.total = 0;
      this.totalCosts.variance = 0;

      return changeOrders.map((changeOrder) => {
        const budget_version = this.organizationQuery.getBudgetVersion(
          changeOrder.organization_id,
          BudgetType.BUDGET_CHANGE_ORDER,
          changeOrder.id,
          EntityType.CHANGE_ORDER
        );
        const total_cost_impact = budget_version?.total_budget_amount;
        const variance = changeOrder.budget_total?.variance;
        this.totalCosts.total += total_cost_impact || 0;
        this.totalCosts.variance += variance || 0;
        return {
          co_id: changeOrder.id,
          co_number: changeOrder.change_order_no,
          vendor_name: organizations[changeOrder.organization_id]?.name,
          vendor_id: changeOrder.organization_id,
          total_cost_impact: total_cost_impact,
          status: changeOrder.change_order_status,
          approved_date: changeOrder.approvals ? changeOrder.approvals[0]?.approval_time : null,
          approved_by: changeOrder.approvals ? changeOrder.approvals[0]?.aux_user_id : null,
          date_created: changeOrder.create_date,
          created_by: changeOrder.created_by,
          budget_total: changeOrder.budget_total,
          vendor_currency: changeOrder.organization_id
            ? organizations[changeOrder.organization_id]?.currency
            : null,
        } as ChangeOrderGridData;
      });
    })
  );

  nameFilterValue = '';

  changeOrders: Partial<ChangeOrder>[] = [];

  organizations: { [id: string]: Partial<Organization> } = {};

  constructor(
    public organizationQuery: OrganizationQuery,
    public changeOrderQuery: ChangeOrderQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private router: Router,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private changeOrderService: ChangeOrderService,
    private changeOrderSharedService: ChangeOrderSharedService,
    private stickyElementService: StickyElementService,
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService
  ) {
    this.changeOrderService.get().pipe(untilDestroyed(this)).subscribe();
    this.organizationService.getListWithTotalBudgetAmount().pipe(untilDestroyed(this)).subscribe();

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_TEMPLATE_UPLOADS],
      })
      .pipe(untilDestroyed(this))
      .subscribe((permissionForDownload) => {
        this.userHasDownloadChangeOrderPermission$.next(permissionForDownload);
        this.gridAPI?.refreshCells();
      });

    this.organizationQuery.allVendors$.pipe(untilDestroyed(this)).subscribe(() => {
      this.selectedVendor.setValue('');
      this.organizationStore.setActive(null);
    });
    combineLatest([
      this.organizationQuery.selectActiveId(),
      this.launchDarklyService.select$((flags) => flags.section_change_order_chart),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([, showChart]) => {
        const chartLoading = this.coChartLoading$.getValue();
        this.showCOChart$.next(showChart);
        if (
          showChart &&
          !chartLoading &&
          (this.changeOrders.length || Object.keys(this.organizations).length)
        ) {
          this.drawChart(
            this.organizations as { [id: string]: Partial<Organization> },
            this.changeOrders as Partial<ChangeOrder>[]
          );
        }
      });

    this.selectedBudgetCurrencyType$.pipe(untilDestroyed(this)).subscribe();
  }

  ngOnInit(): void {
    this.workflowService.getWorkflowList().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  onOrganizationSelected(orgId: string) {
    this.organizationStore.setActive(orgId);
    const filterInstance = this.gridAPI.getFilterInstance('vendor_id');
    if (filterInstance) {
      filterInstance.setModel(orgId ? { values: [orgId] } : null);
    }
    this.gridAPI.onFilterChanged();
    if (this.gridAPI.getDisplayedRowCount() === 0) {
      this.gridAPI.showNoRowsOverlay();
    } else {
      this.gridAPI.hideOverlay();
    }
  }

  async openChangerOrderUpload() {
    const resp = this.overlayService.open({
      content: ChangeOrderUploadComponent,
      closeButton: false,
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data) {
      if (this.organizationQuery.getActive()?.id === this.selectedVendor.value) {
        this.onOrganizationSelected(this.selectedVendor.value);
      }
    }
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const trial = this.mainQuery.getSelectedTrial();
    const columnKeys =
      this.gridAPI
        .getColumnDefs()
        ?.filter((colDef) => {
          const asColDef = colDef as ColDef;
          return (
            asColDef.colId !== 'co_id' &&
            asColDef.colId !== 'vendor_id' &&
            asColDef.colId !== 'actions'
          );
        })
        .reduce<string[]>((accum, cell: ColDef | ColGroupDef) => {
          const asColDef = cell as ColDef;
          accum.push(asColDef.colId || '');
          return accum;
        }, []) || [];
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');
    const fileName = trial?.short_name
      ? `${trial?.short_name}_change-orders_${dateStr}.xlsx`
      : `_Total Budget_${dateStr}.xlsx`;
    const appendContent: ExcelExportParams['appendContent'] = this.isAllCosSameCurrency
      ? [
          {
            cells: [
              {
                data: { value: `Total`, type: 'String' },
                mergeAcross: 1,
                styleId: 'total_row_header',
              },
              {
                data: { value: `${this.totalCosts.total}`, type: 'Number' },
                styleId: `total_row_${this.allOrgCurrency}`,
              },
              {
                data: { value: `${this.totalCosts.variance}`, type: 'Number' },
                styleId: `total_row_${this.allOrgCurrency}`,
              },
              {
                data: { value: ``, type: 'Number' },
                mergeAcross: 4,
                styleId: `total_row`,
              },
            ],
          },
        ]
      : [{ cells: [] }];

    return {
      ...this.excelOptions,
      fileName: fileName,
      columnKeys: columnKeys,
      appendContent: appendContent,
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial?.name}`, type: 'String' },
              mergeAcross: columnKeys.length - 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
    } as ExcelExportParams;
  };

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridAPI$.next(api);
    if (this.selectedVendor.value) {
      this.onOrganizationSelected(this.selectedVendor.value);
    }
  }

  onFirstDataRendered() {
    this.gridAPI.sizeColumnsToFit();
  }

  async downloadChangeOrder(rowNode: RowNode) {
    const { co_id } = rowNode.data;

    const { success, data, errors } = await this.changeOrderQuery.downloadCO(co_id);

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
  }

  goToCODetail(event: CellClickedEvent) {
    const id = event.data?.co_id;
    if (id) {
      this.router.navigateByUrl(
        `${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}/${id}`
      );
    }
  }

  async removeChangeOrder(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }
    const co = this.changeOrderQuery.getEntity(rowNode.data.co_id);

    if (!co) {
      return;
    }

    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Change Order',
      message: `Are you sure you want to remove Change Order ${rowNode.data.co_number}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.result) {
      await this.changeOrderService.remove(co, event.data.textarea);
    }
  }

  async getData(budgetVersion: Partial<BvExpenseData>) {
    const { data } = await firstValueFrom(
      this.gqlService.listBudgetVersionExpenses$(budgetVersion.budget_version_id || '')
    );
    return {
      ...budgetVersion,
      expense_data: data,
      budget_version_id: budgetVersion.budget_version_id || '',
      budget_short_type: budgetVersion.budget_short_type,
    };
  }

  getLabels(data: { period: string | null }[][], qString = '') {
    const labelSet = new Set<string>();
    for (const d of data) {
      for (const expense of d) {
        if (expense.period) {
          let label = this.transformPeriodStr(expense.period) || '';
          if (qString?.split('-')?.[1] === expense.period) {
            label = qString.split('-')[1] || '';
          }
          if (label) {
            labelSet.add(label);
          }
        }
      }
    }
    const labelArr = Array.from(labelSet) as string[];
    // Sort by quarters
    return labelArr.sort((a, b) => {
      const x = a === qString.split('-')[1] ? this.transformPeriodStr(qString) : a;
      const y = b === qString.split('-')[1] ? this.transformPeriodStr(qString) : b;

      const year1 = parseInt(x.split(' ')?.[1], 10) || -1;
      const year2 = parseInt(y.split(' ')?.[1], 10) || -1;
      if (year1 !== year2) {
        return year1 > year2 ? 1 : -1;
      }
      return x > y ? 1 : -1;
    });
  }

  transformPeriodStr(period: string) {
    if (period) {
      const split = period.split('-');
      if (split.length > 1) {
        return `${split[1]} ${split[0]}`;
      }
      if (split.length === 1) {
        return `${split[0]}`;
      }
    }
    return '';
  }

  mergeExpenseData(expenseData: listBudgetVersionExpensesQuery[][], sortedLabels: string[]) {
    // The labels _must_ stay in order
    const ed: number[] = new Array(sortedLabels.length).fill(0);
    for (const singleBudgetData of expenseData) {
      for (const expD of singleBudgetData) {
        const label = this.transformPeriodStr(expD.period || '');
        const labelIdx = sortedLabels.indexOf(label);
        if (label && labelIdx >= 0 && expD.amount) {
          ed[labelIdx] += expD.amount;
        }
      }
    }
    return ed;
  }

  runningSum(nums: number[]) {
    let total = 0;
    // eslint-disable-next-line no-return-assign
    return nums.map((num) => (total += num));
  }

  transformData(noVendorSelected: boolean, data: BvExpenseData[], sortedLabels: string[]) {
    const lre_color = '#094673';
    const co_color = '#bacad0';
    const baseline_color = '#236262';
    const lineTension = 0.5;

    const lres = data.filter((bv) => bv.budget_short_type === 'LRE');
    const baselines = data.filter((bv) => bv.budget_short_type === 'BASE');
    const changeorders = data.filter((bv) => bv.budget_short_type === 'CO');

    const datasets: ChartDataset<'line'>[] = [];

    if (lres.length > 0) {
      const lre_expense_data = lres
        .filter(
          (lre) =>
            lre.expense_data &&
            lre.expense_data.filter((ed) => ed.amount !== null && ed.amount !== undefined)
        )
        .map((lre) => lre.expense_data);
      const x = this.mergeExpenseData(
        lre_expense_data as listBudgetVersionExpensesQuery[][],
        sortedLabels
      );
      const lre_data = this.runningSum(x);
      datasets.push({
        label: noVendorSelected ? 'Current (LRE)' : `Current (LRE) Budget (${lres[0].budget_name})`,
        data: lre_data,
        pointBackgroundColor: lre_color,
        pointHoverBorderColor: lre_color,
        pointBorderColor: lre_color,
        pointHoverBackgroundColor: lre_color,
        segment: {
          backgroundColor: lre_color,
          borderColor: lre_color,
        },
        tension: lineTension,
        cubicInterpolationMode: 'monotone',
      });
    }

    if (changeorders.length > 0 && !noVendorSelected) {
      for (const co of changeorders) {
        const x = co.expense_data?.map((ed) => ed.amount || 0) || [];
        datasets.push({
          label: co.budget_name || '',
          data: this.runningSum(x),
          pointBackgroundColor: co_color,
          pointHoverBorderColor: co_color,
          pointBorderColor: co_color,
          pointHoverBackgroundColor: co_color,
          segment: {
            backgroundColor: co_color,
            borderColor: co_color,
          },
          cubicInterpolationMode: 'monotone',
          tension: lineTension,
        });
      }
    }

    if (baselines.length > 0) {
      const baseline_expense_data = baselines
        .filter(
          (baseln) =>
            baseln.expense_data &&
            baseln.expense_data.filter((ed) => ed.amount !== null && ed.amount !== undefined)
        )
        .map((baseln) => baseln.expense_data);
      const x = this.mergeExpenseData(
        baseline_expense_data as listBudgetVersionExpensesQuery[][],
        sortedLabels
      );
      const baseline_totals = this.runningSum(x);
      datasets.push({
        label: 'Baseline',
        data: baseline_totals,
        pointBackgroundColor: baseline_color,
        pointHoverBorderColor: baseline_color,
        pointBorderColor: baseline_color,
        pointHoverBackgroundColor: baseline_color,
        segment: {
          backgroundColor: baseline_color,
          borderColor: baseline_color,
        },
        cubicInterpolationMode: 'monotone',
        tension: lineTension,
      });
    }

    return datasets;
  }

  quarterDateCompare(x: string, y: string) {
    const xQuarter = x.split('-Q');
    const yQuarter = y.split('-Q');

    if (Number(xQuarter[0]) > Number(yQuarter[0])) {
      return false;
    }
    if (Number(xQuarter[0]) === Number(yQuarter[0])) {
      return Number(xQuarter[1]) <= Number(yQuarter[1]);
    }
    return true;
  }

  async drawChart(
    organizations: { [id: string]: Partial<Organization> },
    changeOrders: Partial<ChangeOrder>[]
  ) {
    const loading = this.coChartLoading$.getValue();
    if (!loading) {
      this.coChartLoading$.next(true);
      const selectedVendorId = this.selectedVendor.value;
      const noVendorSelected = !selectedVendorId;

      const budgetVersionsToQuery: { [bv_id: string]: BvExpenseData } = {};
      const lreBudgetVersions: { [bv_id: string]: BudgetVersion } = {};
      const baselineBudgetVersions: { [bv_id: string]: BudgetVersion } = {};
      const changeOrderBudgetVersions: { [bv_id: string]: BudgetVersion } = {};
      for (const orgId of Object.keys(organizations)) {
        if (noVendorSelected || selectedVendorId === orgId) {
          const org = organizations[orgId];

          for (const bv of org.current_budget_versions || []) {
            if (
              Object.keys(budgetVersionsToQuery).indexOf(bv.budget_version_id) === -1 &&
              bv.budget_type === BudgetType.BUDGET_PRIMARY
            ) {
              // Budget Primary (A.K.A. "LRE") Should always "Win"
              budgetVersionsToQuery[bv.budget_version_id] = {
                ...bv,
                budget_short_type: 'LRE',
                expense_data: null,
              };
              lreBudgetVersions[bv.budget_version_id] = bv;
            }
          }

          if (
            org.baseline_budget_version &&
            Object.keys(budgetVersionsToQuery).indexOf(
              org.baseline_budget_version.budget_version_id
            ) === -1
          ) {
            baselineBudgetVersions[org.baseline_budget_version.budget_version_id] =
              org.baseline_budget_version;
            budgetVersionsToQuery[org.baseline_budget_version.budget_version_id] = {
              ...org.baseline_budget_version,
              budget_short_type: 'BASE',
              expense_data: null,
            };
          }
        }
      }

      // ONLY Loop through the COs after all Baseline and LRE budgets have been added
      for (const co of changeOrders) {
        const newBvId = co.merged_budget_version?.budget_version_id;
        if (
          co.merged_budget_version &&
          newBvId &&
          Object.keys(budgetVersionsToQuery).indexOf(newBvId) === -1 &&
          (noVendorSelected || co.organization?.id === selectedVendorId)
        ) {
          budgetVersionsToQuery[newBvId] = {
            ...co.merged_budget_version,
            budget_short_type: 'CO',
            expense_data: null,
          };
          changeOrderBudgetVersions[newBvId] = co.merged_budget_version;
        }
      }

      const promises: Promise<BvExpenseData | null>[] = [];
      for (const bv_id of Object.keys(budgetVersionsToQuery)) {
        if (budgetVersionsToQuery[bv_id].budget_version_id) {
          promises.push(this.getData(budgetVersionsToQuery[bv_id]));
        }
      }

      const unfiltered = await batchPromises(promises, (p) => p);
      const start_date = this.mainQuery.getAuxiliusStartDate();
      const QYFChart = `${new Date(start_date || '').getFullYear()}-Q${
        Math.floor(new Date(start_date || '').getMonth() / 3) + 1
      }`;
      const QYFChartFilter = `${
        Math.floor(new Date(start_date || '').getMonth() / 3) === 0
          ? Number(new Date(start_date || '').getFullYear()) - 1
          : Number(new Date(start_date || '').getFullYear())
      }-Q${
        Math.floor(new Date(start_date || '').getMonth() / 3) === 0
          ? 4
          : Math.floor(new Date(start_date || '').getMonth() / 3)
      }`;

      const dataFiltered = unfiltered.filter(
        (itm) => !(itm instanceof Error) && itm?.expense_data && itm?.expense_data?.length > 0
      ) as BvExpenseData[];

      const data = dataFiltered.map((x) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const filteredExpense = x.expense_data?.filter((x) =>
          this.quarterDateCompare(QYFChartFilter, x.period || '')
        );

        const expenseData = filteredExpense?.filter((y) => {
          return this.quarterDateCompare(QYFChart, y?.period || '');
        });
        return { ...x, expense_data: expenseData };
      }) as BvExpenseData[];

      const labels = this.getLabels(
        data.map((d) => d?.expense_data) as { period: string | null }[][],
        QYFChartFilter
      );

      const datasets = this.transformData(noVendorSelected, data, labels);

      const ctx = document.getElementById('changeOrderLineChart');
      this.coChartLoading$.next(false);
      if (ctx) {
        if (this.myChart) {
          this.myChart.destroy();
        }
        this.myChart = new Chart(ctx as HTMLCanvasElement, {
          type: 'line',
          data: {
            labels,
            datasets,
          },
          options: {
            plugins: {
              legend: {
                display: true,
                labels: {
                  color: '#212332',
                  usePointStyle: true,
                  padding: 10,
                },
                position: 'bottom',
              },
              tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label(tooltipItem) {
                    return `${tooltipItem.dataset.label} ${Utils.currencyFormatter(
                      tooltipItem.raw,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}`;
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  autoSkip: false,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  callback: (value) => {
                    if (typeof value === 'number') {
                      return Utils.currencyFormatter(value, { notation: 'compact' });
                    }
                    return value;
                  },
                },
                position: 'right',
              },
            },
          },
        });
      }
    }
  }
}
