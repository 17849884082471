import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { Utils } from '@services/utils';
import { BehaviorSubject } from 'rxjs';
import {
  ColGroupDef,
  Column,
  GridApi,
  GridOptions,
  ColDef,
  GridReadyEvent,
  RowClassParams,
} from '@ag-grid-community/core';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';
import { SitesQuery } from '@models/sites/sites.query';
import { groupBy, merge } from 'lodash-es';
import { Currency } from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { UntypedFormBuilder } from '@angular/forms';
import { SitesStore } from '@models/sites/sites.store';
import { FormValuesQuery } from '@models/form-values/form-values.query';
import { TableService } from '@services/table.service';
import { TableConstants } from '@constants/table.constants';
import {
  AMOUNT_PATTERN,
  PATIENT_PROTOCOL_PATIENT_VISIT,
  PatientBudgetTableDataService,
  PatientProtocolComparator,
  CURRENCY_PATTERN,
} from './patient-budget-table-data.service';
import { COST_COLUMN_PROPS, getVisitInformationColumn } from './patient-budget-cols.const';
import { StickyElementService } from '@services/sticky-element.service';
import { AuxExcelStyleKeys, AuxExcelStyles, AgSetColumnsVisible } from '@shared/utils';
import { AgSiteCostComponent } from './ag-site-cost-column';

export enum PatientTableType {
  VISITS_COSTS = 'visits_costs',
  INVOICEABLES = 'invoiceables',
}

@Component({
  selector: 'aux-patient-budget-table',
  templateUrl: './patient-budget-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class PatientBudgetTableComponent implements OnInit, OnChanges, OnDestroy {
  readonly ignoreColsForTotal = [
    'target_date_days_out',
    'target_tolerance_days_out',
    'patient_protocol_name',
    'patient_protocol_id',
  ];

  @Input() tableType!: PatientTableType;

  @Input() patientGroupId!: string;

  @Output() gridApiChanged = new EventEmitter<GridApi>();

  selectedCurrencies$ = new BehaviorSubject(
    this.formValuesQuery.getValuesByFormName('patientBudget').selectedCurrencies
  );

  gridAPI!: GridApi;

  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    suppressMenuHide: true,
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', color: 'var(--text-aux-black)' };
      }
      return {};
    },
  } as GridOptions);

  gridData$ = new BehaviorSubject<Record<string, number>[]>([]);

  patientBudgetForm = this.formBuilder.group({
    site_ids: null,
  });

  siteOptions$ = this.patientTableDataService.siteOptions$;

  constructor(
    private patientProtocolQuery: PatientProtocolQuery,
    private paymentSchedulesQuery: PaymentSchedulesQuery,
    private formBuilder: UntypedFormBuilder,
    private formValuesQuery: FormValuesQuery,
    public sitesQuery: SitesQuery,
    private sitesStore: SitesStore,
    private patientTableDataService: PatientBudgetTableDataService,
    private stickyElementService: StickyElementService
  ) {
    this.patientBudgetForm.setValue(
      {
        site_ids: this.getSelectedSiteIds(),
      },
      {
        emitEvent: false,
      }
    );
  }

  ngOnInit(): void {
    this.initTable();

    this.patientBudgetForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
      this.formValuesQuery.updateValues({ site_ids: values.site_ids }, 'patientBudget');
      this.getSelectedSites(values.site_ids);
    });
  }

  ngOnDestroy() {
    this.stickyElementService.reset();
  }

  ngOnChanges() {
    this.initTable();
  }

  initTable() {
    const mapGridValues = new Map<PatientTableType, PatientProtocolComparator>([
      [PatientTableType.VISITS_COSTS, this.visitsPatientComparator],
      [PatientTableType.INVOICEABLES, this.otherCostsPatientComparator],
    ]);

    const comparator = mapGridValues.get(this.tableType) as PatientProtocolComparator;

    const data = this.patientTableDataService.getCostsGridData(comparator, this.patientGroupId);

    const allColumns = [
      ...getVisitInformationColumn(),
      ...this.getCostsCols(this.patientBudgetForm.value.site_ids),
    ];

    this.gridData$.next(data);
    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
      columnDefs: allColumns,
      excelStyles: [...AuxExcelStyles],
    });
  }

  otherCostsPatientComparator: PatientProtocolComparator = (patientType) => {
    return patientType.patient_protocol_type !== PATIENT_PROTOCOL_PATIENT_VISIT;
  };

  visitsPatientComparator: PatientProtocolComparator = (patientType) => {
    return !this.otherCostsPatientComparator(patientType);
  };

  getCostsCols(selectedSites?: string[]) {
    const groupedPaymentSchedules = groupBy(
      this.paymentSchedulesQuery.getAll(),
      'patient_protocol_id'
    );
    const siteColumns: (ColDef | ColGroupDef)[] = [];
    const siteSet = new Set<string>();
    this.patientProtocolQuery.getAll().forEach((patientProtocol) => {
      let data = {};
      const groupedPaymentSchedule = groupedPaymentSchedules[patientProtocol.id];
      if (groupedPaymentSchedule) {
        groupedPaymentSchedule.forEach((paymentSchedule) => {
          if (!siteSet.has(paymentSchedule.site_id)) {
            const site = this.sitesQuery.getEntity(paymentSchedule.site_id);
            if (site) {
              siteSet.add(paymentSchedule.site_id);
              siteColumns.push({
                headerName: `${site.site_no} - ${site.name}`,
                headerClass: 'ag-header-align-center justify-center',
                tooltipField: paymentSchedule.site_id,
                headerGroupComponent: AgSiteCostComponent,
                headerGroupComponentParams: {
                  siteName: `${site.site_no} - ${site.name}`,
                  budgetVersionName: `Site Budget Version: ${paymentSchedule.site_budget_version_name || Utils.zeroHyphen}`,
                },
                children:
                  paymentSchedule.sps_contract_expense_currency !==
                  paymentSchedule.sps_expense_currency
                    ? [
                        {
                          ...COST_COLUMN_PROPS,
                          headerName: `${paymentSchedule.sps_expense_currency}`,
                          field: `${paymentSchedule.site_id}${AMOUNT_PATTERN.PRIMARY}`,
                          valueFormatter: (params) =>
                            Utils.agCurrencyFormatter(
                              params,
                              paymentSchedule.sps_expense_currency as Currency
                            ),
                          cellRenderer: AgCellWrapperComponent,
                          cellRendererParams: {
                            customLocator: `${site.site_no}${AMOUNT_PATTERN.PRIMARY}`,
                          },
                          cellClass: [
                            AuxExcelStyleKeys.CELL_RIGHT,
                            TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
                          ],
                        },
                        {
                          ...COST_COLUMN_PROPS,
                          headerName: `${paymentSchedule.sps_contract_expense_currency}`,
                          field: `${paymentSchedule.site_id}${AMOUNT_PATTERN.CONTRACT}`,
                          valueFormatter: (params) =>
                            Utils.agCurrencyFormatter(
                              params,
                              paymentSchedule.sps_contract_expense_currency as Currency
                            ),
                          cellRenderer: AgCellWrapperComponent,
                          cellRendererParams: {
                            customLocator: `${site.site_no}${AMOUNT_PATTERN.CONTRACT}`,
                          },
                          cellClass: [
                            AuxExcelStyleKeys.CELL_RIGHT,
                            TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
                          ],
                        },
                      ]
                    : [
                        {
                          ...COST_COLUMN_PROPS,
                          headerName: `${paymentSchedule.sps_contract_expense_currency}`,
                          field: `${paymentSchedule.site_id}`,
                          valueFormatter: (params) =>
                            Utils.agCurrencyFormatter(
                              params,
                              paymentSchedule.sps_contract_expense_currency as Currency
                            ),
                          cellRenderer: AgCellWrapperComponent,
                          cellRendererParams: {
                            customLocator: `${site.site_no}${AMOUNT_PATTERN.CONTRACT}`,
                          },
                          cellClass: [
                            AuxExcelStyleKeys.CELL_RIGHT,
                            TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
                          ],
                        },
                      ],
              } as ColDef | ColGroupDef);
            }
          }
          data = {
            ...data,
            [paymentSchedule.site_id]: paymentSchedule.amount,
          };
        });
      }
    });

    if (selectedSites) {
      selectedSites.forEach((site) => {
        const selectedSite = this.sitesQuery.getEntity(site);
        if (selectedSite && !siteSet.has(site)) {
          siteSet.add(site);
          siteColumns.push({
            headerName: `${selectedSite.site_no} - ${selectedSite.name}`,
            headerClass: 'ag-header-align-center',
            tooltipField: selectedSite.id,
            headerTooltip: `${selectedSite.site_no} - ${selectedSite.name}`,
            children:
              (selectedSite.currency as Currency) !== Currency.USD
                ? [
                    {
                      ...COST_COLUMN_PROPS,
                      headerName: Currency.USD,
                      field: `${selectedSite.id}${AMOUNT_PATTERN.PRIMARY}`,
                      valueFormatter: (params) => Utils.agCurrencyFormatter(params, Currency.USD),
                      cellRenderer: AgCellWrapperComponent,
                      cellRendererParams: {
                        customLocator: `${selectedSite.site_no}${AMOUNT_PATTERN.PRIMARY}`,
                      },
                      cellClass: [AuxExcelStyleKeys.CELL_RIGHT],
                    },
                    {
                      ...COST_COLUMN_PROPS,
                      headerName: selectedSite.currency as Currency,
                      field: `${selectedSite.id}${AMOUNT_PATTERN.CONTRACT}`,
                      valueFormatter: (params) =>
                        Utils.agCurrencyFormatter(params, selectedSite.currency as Currency),
                      cellRenderer: AgCellWrapperComponent,
                      cellRendererParams: {
                        customLocator: `${selectedSite.site_no}${AMOUNT_PATTERN.CONTRACT}`,
                      },
                      cellClass: [AuxExcelStyleKeys.CELL_RIGHT],
                    },
                  ]
                : [
                    {
                      ...COST_COLUMN_PROPS,
                      headerName: selectedSite.currency as Currency,
                      field: `${selectedSite.id}`,
                      valueFormatter: (params) =>
                        Utils.agCurrencyFormatter(params, selectedSite.currency as Currency),
                      cellRenderer: AgCellWrapperComponent,
                      cellRendererParams: {
                        customLocator: `${selectedSite.site_no}${AMOUNT_PATTERN.CONTRACT}`,
                      },
                      cellClass: [AuxExcelStyleKeys.CELL_RIGHT],
                    },
                  ],
          } as ColDef | ColGroupDef);
        }
      });
    }
    const sortedSiteColumns: (ColDef | ColGroupDef)[] = [];
    siteColumns.sort((a: ColDef, b: ColDef) =>
      Utils.alphaNumSort(a.headerName as string, b.headerName as string)
    );
    siteColumns.forEach((column) => {
      sortedSiteColumns.push(column, TableConstants.SPACER_COLUMN);
    });

    return sortedSiteColumns;
  }

  getSelectedSites(site_ids: string[]) {
    this.sitesStore.setActive(site_ids);
    const columns = this.gridAPI.getColumns() || [];

    const all_site_ids = this.sitesQuery.getAll().map((site) => site.id);

    if (site_ids.length === 0) {
      const allSitesColumns = columns.filter(
        (column) => this.allCheck(all_site_ids, column) && this.canColumnBeVisible(column)
      );
      AgSetColumnsVisible({
        gridApi: this.gridAPI,
        keys: allSitesColumns,
        visible: true,
      });
    } else {
      all_site_ids.forEach((id) => {
        if (site_ids.includes(id)) {
          const selectedColumns = columns.filter(
            (column) => this.check(id, column) && this.canColumnBeVisible(column)
          );
          AgSetColumnsVisible({
            gridApi: this.gridAPI,
            keys: selectedColumns,
            visible: true,
          });
        } else {
          const unselectedColumns = columns.filter((column) => this.check(id, column));
          AgSetColumnsVisible({
            gridApi: this.gridAPI,
            keys: unselectedColumns,
            visible: false,
          });
        }
      });
    }
    this.sizeColumnsToFit();
  }

  private getSelectedSiteIds() {
    const cachedValues = this.formValuesQuery.getValuesByFormName('patientBudget').site_ids;

    return cachedValues.length ? cachedValues : this.sitesQuery.getActive().map((site) => site.id);
  }

  onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
    this.gridAPI = api;
    this.gridApiChanged.emit(this.gridAPI);
    this.gridAPI.resetRowHeights();

    const selectedSites = this.getSelectedSiteIds();

    this.selectedCurrencies$
      .pipe(untilDestroyed(this))
      .subscribe(({ isPrimaryCurrency, isContractCurrency }) => {
        const columns = this.gridAPI.getColumns() || [];

        const primaryColIds =
          selectedSites.length === 0
            ? columns.filter((x) => x.getColId().includes(AMOUNT_PATTERN.PRIMARY))
            : columns.filter(
                (x) =>
                  x.getColId().includes(AMOUNT_PATTERN.PRIMARY) && this.allCheck(selectedSites, x)
              );
        const contractColIds =
          selectedSites.length === 0
            ? columns.filter((x) => x.getColId().includes(AMOUNT_PATTERN.CONTRACT))
            : columns.filter(
                (x) =>
                  x.getColId().includes(AMOUNT_PATTERN.CONTRACT) && this.allCheck(selectedSites, x)
              );

        AgSetColumnsVisible({
          gridApi: this.gridAPI,
          keys: primaryColIds,
          visible: isPrimaryCurrency,
        });
        AgSetColumnsVisible({
          gridApi: this.gridAPI,
          keys: contractColIds,
          visible: isContractCurrency,
        });

        this.sizeColumnsToFit();

        this.formValuesQuery.updateValues(
          { selectedCurrencies: { isPrimaryCurrency, isContractCurrency } },
          'patientBudget'
        );
      });
  }

  check = (id: string, column: Column) => {
    return column.getColId().includes(id);
  };

  allCheck = (ids: string[], column: Column) => {
    const filteredIds = ids.filter((id) => this.check(id, column));
    return filteredIds.length !== 0;
  };

  canColumnBeVisible(column: Column) {
    return (
      (this.selectedCurrencies$.getValue().isPrimaryCurrency &&
        column.getColId().includes(AMOUNT_PATTERN.PRIMARY) &&
        !column.getColId().includes(AMOUNT_PATTERN.CONTRACT)) ||
      (this.selectedCurrencies$.getValue().isContractCurrency &&
        column.getColId().includes(AMOUNT_PATTERN.CONTRACT))
    );
  }

  onDataRendered() {
    this.gridAPI?.setGridOption('pinnedBottomRowData', [
      merge(
        {
          patient_protocol_name: 'Total',
        },
        TableService.generateTotalRow(this.gridData$.getValue(), this.ignoreColsForTotal),
        ...this.getCurrenciesForTotalRow()
      ),
    ]);

    this.onWindowScroll();
  }

  private getCurrenciesForTotalRow() {
    return this.gridAPI
      .getAllDisplayedColumns()
      ?.filter((col) =>
        [AMOUNT_PATTERN.CONTRACT, AMOUNT_PATTERN.PRIMARY].some((key) =>
          col.getColId().endsWith(key)
        )
      )
      .map((col) => {
        const colId = col.getColId();

        const isAmountContractCell = colId.endsWith(AMOUNT_PATTERN.CONTRACT);

        const amountKey = isAmountContractCell ? AMOUNT_PATTERN.CONTRACT : AMOUNT_PATTERN.PRIMARY;

        const currencyKey = isAmountContractCell
          ? CURRENCY_PATTERN.CONTRACT
          : CURRENCY_PATTERN.PRIMARY;

        const siteId = colId.replace(amountKey, '');

        return { [`${siteId}${currencyKey}`]: col.getColDef().headerName };
      });
  }

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }
}
