import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { IHeaderGroupParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

type SiteCostComponentParams = {
  siteName: string;
  budgetVersionName: string;
} & IHeaderGroupParams;

@Component({
  template: `<ng-template #tooltip>
      <p>{{ params.siteName }}</p>
      <p>{{ params.budgetVersionName }}</p>
    </ng-template>

    <div auxTooltip [template]="tooltip" class="ag-header-group-cell-label ag-sticky-label !grid">
      <span class="ag-header-group-text">{{ params.displayName }}</span>
    </div>`,
  standalone: true,
  imports: [TooltipDirective],
})
export class AgSiteCostComponent implements IHeaderGroupAngularComp {
  params!: SiteCostComponentParams;

  agInit(params: SiteCostComponentParams): void {
    this.params = params;
  }
}
