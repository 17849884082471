import { ChangeDetectionStrategy, Component, HostListener, OnDestroy } from '@angular/core';
import {
  EntityType,
  EventType,
  GqlService,
  listOrganizationNamesQuery,
  listSitesQuery,
  listUserNamesWithEmailQuery,
  User,
  UserAuditLog,
  UserAuditLogAction,
  UserAuditLogCategory,
  DocumentType,
} from '@services/gql.service';
import {
  CellClickedEvent,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ITooltipParams,
  ProcessCellForExportParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { BehaviorSubject, combineLatest, firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Utils } from '@services/utils';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { EventService } from '@services/event.service';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationModel, OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuditHistoryCategoryComponent } from './state/actions/audit-history-category.component';
import { MainStore } from '@shared/store/main/main.store';
import { MainQuery } from '@shared/store/main/main.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { StickyElementService } from '@services/sticky-element.service';
import { QuarterCloseAdjustmentsService } from '../closing-page/tabs/quarter-close-adjustments/quarter-close-adjustments.service';
import dayjs from 'dayjs';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { InvestigatorTransactionsService } from '../investigator/investigator-transactions/investigator-transactions.service';
import { SitesService } from '@models/sites/sites.service';
import { SiteOption } from '@models/site-option.model';
import {
  AuxExcelStyleKeys,
  GetExcelStyle,
  mapSiteToSiteOption,
  ServerSideColumnFilterType,
  ServerSideFilterInfo,
  ServerSideSortOrder,
} from '@shared/utils';
import { DatasourceService } from '@shared/services/datasource.service';
import {
  AuditActionStatuses,
  AuditBudgetStatuses,
  AuditCategoryStatuses,
} from './audit-history.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isArray } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'aux-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['audit-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditHistoryComponent implements OnDestroy {
  readonly datasource = this.datasourceService.userAuditLogDatasource;

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});
  private mappedFilterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  sortModel$ = new BehaviorSubject<Array<ServerSideSortOrder<UserAuditLog>>>([]);

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  optionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -10,
      offsetY: 8,
    },
  ];

  users$ = new BehaviorSubject<listUserNamesWithEmailQuery[]>([]);

  vendors$ = new BehaviorSubject<OrganizationModel[]>([]);

  vendorOptions$ = this.vendors$.pipe(
    map((vendors) => {
      return vendors.length > 1 ? [{ id: '', name: 'All' }, ...vendors] : vendors;
    })
  );

  category$ = new BehaviorSubject<{ id: UserAuditLogCategory; name: string }[]>([]);

  actions$ = new BehaviorSubject<{ id: UserAuditLogAction; name: string }[]>([]);

  gridAPI?: GridApi;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  userExpertIds: string[] = [];

  vendorNames = new Map<string, string>();

  siteNames = new Map<string, { site_name: string; site_number: string }>();

  siteOptions$ = new BehaviorSubject<SiteOption[]>([]);

  processorMessage = 'This process can take up to a minute. You can navigate away from this page.';

  mapAuditCategoryStatus = new Map<UserAuditLogCategory, string>(AuditCategoryStatuses);

  mapActionStatus = new Map<UserAuditLogAction, string>(AuditActionStatuses);

  gridOptions: GridOptions = {
    defaultColDef: {
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 60,
      headerClass: ['bg-aux-dark-light', 'justify-center'],
      cellClass: 'grid-cell',
    },
    rowHeight: 50,
    groupDefaultExpanded: -1,
    overlayNoRowsTemplate:
      '<div class="text-xl">No results found, please adjust your filters</div>',
    columnDefs: [
      {
        headerName: 'Date',
        field: 'date',
        colId: 'date',
        filter: 'agDateColumnFilter',
        minWidth: 120,
        maxWidth: 120,
        valueFormatter: (x) => Utils.dateFormatter(x.value),
        sortable: false,
        filterParams: {
          readOnly: true,
          comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
            if (cellValue < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellValue > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value) {
            this.gridAPI?.hideOverlay();
            return '<div class="h-3 m-auto spinner w-3"></div>';
          }
          return params.valueFormatted;
        },
      },
      {
        headerName: 'Time',
        field: 'create_date',
        colId: 'create_date',
        sortable: false,
        maxWidth: 110,
        valueFormatter: (x) => `${Utils.timeFormatter(x.value)}`,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value) {
            return null;
          }
          return params.valueFormatted;
        },
      },
      {
        headerName: 'Category',
        sortable: false,
        field: 'category',
        colId: 'category',
        cellClass: 'grid-cell justify-center ',
        maxWidth: 170,
        minWidth: 170,
        cellRenderer: AuditHistoryCategoryComponent,
      },
      {
        headerName: 'Action',
        field: 'action_text',
        colId: 'action_text',
        minWidth: 300,
        tooltipField: 'action_text',
        cellClass: 'grid-cell underline aux-link cursor-pointer',
        cellStyle: {
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
          overflow: 'hidden',
          padding: 0,
          display: 'block',
        },
        onCellClicked: (event: CellClickedEvent) => this.goToDetail(event),
      },
      {
        field: 'action',
        colId: 'action',
        hide: true,
      },
      {
        headerName: 'Vendor',
        field: 'vendor_id',
        colId: 'vendor_id',
        valueFormatter: (x) => this.vendorNames.get(x.value) || Utils.zeroHyphen,
        minWidth: 110,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.data?.id) {
            return null;
          }
          return params.valueFormatted;
        },
      },
      {
        headerName: 'Site',
        field: 'site_id',
        colId: 'site_id',
        valueFormatter: (x) => {
          return this.siteNames.get(x.value)?.site_name || Utils.zeroHyphen;
        },
        minWidth: 120,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.data?.id) {
            return null;
          }
          return params.valueFormatted;
        },
        tooltipValueGetter: (params: ITooltipParams) => {
          return this.siteNames.get(params.value)?.site_name;
        },
      },
      {
        headerName: 'User',
        field: 'created_by',
        colId: 'created_by',
        valueFormatter: (x: ValueFormatterParams) => {
          return this.userFormatter(x.value);
        },
        minWidth: 110,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value) {
            return null;
          }
          return params.valueFormatted;
        },
      },
    ],
  };

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Audit History',
    fileName: 'auxilius-audit-history.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'distribution_month':
          return 225;
        default:
          return 225;
      }
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      if (params.column.getColId() === 'id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }
      const colId = params.column.getColId();
      const mapColFormatterById = new Map<string, () => string>([
        // datastream download data and then set to excel grid
        // so date field is missing and we need to take from another source
        ['date', () => Utils.dateFormatter(params?.node?.data?.create_date)],
        ['create_date', () => Utils.timeFormatter(params.value)],
        ['category', () => this.mapAuditCategoryStatus.get(params.value) || ''],
        ['action', () => this.mapActionStatus.get(params.value) || ''],
        ['vendor_id', () => this.vendorNames.get(params.value) || ''],
        ['created_by', () => this.userFormatter(params.value) || ''],
        ['site_id', () => this.siteNames.get(params.value)?.site_name || ''],
      ]);

      const getFormatterCol = mapColFormatterById.get(colId);

      return getFormatterCol ? getFormatterCol() : params.value;
    },
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'text-aux-error',
        font: { color: '#D73C37' },
      },
      {
        id: 'text-aux-green',
        font: { color: '#437F7F' },
      },
      {
        id: 'cellPercent',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
    ],
  } as ExcelExportParams;

  exportGridOptions: GridOptions = {
    ...this.gridOptions,
    excelStyles: [
      { ...GetExcelStyle(AuxExcelStyleKeys.HEADER) },
      { ...GetExcelStyle(AuxExcelStyleKeys.TEXT_AUX_ERROR) },
      { ...GetExcelStyle(AuxExcelStyleKeys.TEXT_AUX_GREEN) },
      { ...GetExcelStyle(AuxExcelStyleKeys.CELL_PERCENT) },
      { ...GetExcelStyle(AuxExcelStyleKeys.FIRST_ROW) },
    ],
    onGridReady: (event: GridReadyEvent) => {
      this.exportGridApi = event.api;
    },
  };

  exportGridApi!: GridApi;

  serverSideFilters: ServerSideFilterInfo<UserAuditLog>[] = [
    {
      column: 'action_text',
      type: ServerSideColumnFilterType.Contains,
      inputPropertyName: 'actionSearch',
      transformFunction: (v: unknown) => (typeof v === 'string' ? v.trim() : v),
    },
    {
      column: 'action',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'selectedAction',
    },
    {
      column: 'category',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'selectedCategory',
    },
    {
      column: 'created_by',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'selectedUser',
    },
    {
      column: 'create_date',
      type: ServerSideColumnFilterType.IsGreaterThanOrEqualTo,
      inputPropertyName: 'fromDate',
    },
    {
      column: 'create_date',
      type: ServerSideColumnFilterType.IsLessThanOrEqualTo,
      inputPropertyName: 'toDate',
    },
    {
      column: 'vendor_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'selectedVendor',
    },
    {
      column: 'site_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'selectedSite',
    },
  ];

  gridFiltersFormGroup: FormGroup<{ [key: string]: FormControl<string | string[] | null> }> =
    this.formBuilder.group(
      this.serverSideFilters.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.inputPropertyName]: [],
        }),
        {}
      )
    );

  loading$ = new BehaviorSubject(true);

  loadedOnce$ = new BehaviorSubject(false);

  _searchSubject: Subject<string> = new Subject();

  constructor(
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private authQuery: AuthQuery,
    private eventService: EventService,
    private gqlService: GqlService,
    private vendorsService: OrganizationService,
    private router: Router,
    private mainStore: MainStore,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService,
    private investigatorTransactionsService: InvestigatorTransactionsService,
    private stickyElementService: StickyElementService,
    private sitesService: SitesService,
    private investigatorTransactionService: InvestigatorTransactionsService,
    private datasourceService: DatasourceService,
    private formBuilder: FormBuilder
  ) {
    // if the selectedUser's sub is 'Auxilius' then changed that with the whole admin ids.
    this.filterValues$.pipe(takeUntilDestroyed()).subscribe((values) => {
      let selectedUser = values.selectedUser;

      if (!this.authQuery.isAuxAdmin()) {
        if (isArray(values.selectedUser)) {
          selectedUser = values.selectedUser.reduce((acc, curr) => {
            if (curr === 'Auxilius') {
              return [...acc, ...this.userExpertIds];
            }
            return [...acc, curr];
          }, []);
        }
      }

      this.mappedFilterValues$.next({
        ...values,
        selectedUser,
      });
    });
    this.mainStore.update({ fullPage: true });
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.loading$.next(true);
          return combineLatest([
            this.mainQuery.select('userList'),
            this.vendorsService.listOrganizationNames(),
            this.vendorsService.get(),
            this.sitesService.get(),
          ]);
        }),
        untilDestroyed(this)
      )
      .subscribe(([_users, _vendors, , siteList]) => {
        this.resetFilter();
        const listAction: { id: UserAuditLogAction; name: string }[] = [];
        const listCategory: { id: UserAuditLogCategory; name: string }[] = [];
        this.mapActionStatus.forEach((value, i) => {
          listAction.push({ id: i, name: value });
        });
        this.mapAuditCategoryStatus.forEach((value, i) => {
          listCategory.push({ id: i, name: value });
        });
        _vendors?.data?.forEach((organization: listOrganizationNamesQuery) => {
          this.vendorNames.set(organization.id, organization.name);
        });
        this.siteOptions$.next(
          (siteList.data || [])
            .sort(({ site_no }, { site_no: site_no2 }) =>
              Utils.localeAlphaNumSort(site_no, site_no2)
            )
            .map((site: listSitesQuery) =>
              mapSiteToSiteOption(
                site,
                this.investigatorTransactionService.getterForPrincipalInvestigatorName(site.id)
              )
            )
        );
        siteList?.data?.forEach((site: listSitesQuery) => {
          this.siteNames.set(site.id, { site_name: site.name, site_number: site.site_no });
        });

        this.actions$.next(listAction.sort((x, y) => Utils.alphaNumSort(x.name, y.name)));
        this.category$.next(listCategory.sort((x, y) => Utils.alphaNumSort(x.name, y.name)));
        this.organizationStore.setActive(null);
        this.userExpertIds = [];
        _users?.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length > 1) {
          this.organizationStore.setActive(null);
        }
        const sortVendors = vendors?.sort((x, y) =>
          Utils.alphaNumSort(x.name as string, y.name as string)
        );
        this.vendors$.next(sortVendors || []);

        if (_users) {
          let isExternal = false;
          const userData = _users.filter((x) => {
            if (this.userFormatter(x.sub) === 'Auxilius Expert') {
              isExternal = true;
              this.userExpertIds.push(x.sub);
            }
            return this.userFormatter(x.sub) !== 'Auxilius Expert';
          });
          const sortUsers = userData.sort((x, y) =>
            Utils.alphaNumSort(
              `${x.given_name} ${x.family_name}`,
              `${y.given_name} ${y.family_name}`
            )
          );
          if (isExternal) {
            sortUsers.unshift({
              __typename: 'User',
              sub: 'Auxilius',
              given_name: 'Auxilius',
              family_name: 'Expert',
              email: '',
            });
          }

          this.users$.next(sortUsers as listUserNamesWithEmailQuery[]);
        }

        this.loadedOnce$.next(true);
        this.loading$.next(false);
      });
    this._searchSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.gridFiltersFormGroup.get('actionSearch')?.setValue(value);
    });

    this.eventService
      .select$(EventType.REFRESH_USER_AUDIT_LOG)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.datasource.forceRefresh();
        this.loading$.next(false);
      });
  }

  ngOnDestroy() {
    this.resetFilter();
    this.stickyElementService.reset();
    this.mainStore.update({ fullPage: false });
  }

  async triggerFetchAuditHistory() {
    this.loading$.next(true);

    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.USER_AUDIT_LOG,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getValue().trialKey,
        payload: JSON.stringify({}),
      })
    ).finally(() => {
      setTimeout(() => {
        // if we are still showing the loading spinner after 5 minutes (300000 milliseconds),
        // set loading to false. If the user audit log process fails for whatever reason,
        // we don't want this to spin forever
        if (this.loading$.getValue()) {
          this.loading$.next(false);
        }
      }, 300000);
    });
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.datasource.initialize({
      untilDestroyedPipeOperator: untilDestroyed(this),
      filters: this.serverSideFilters,
      filterValues$: this.mappedFilterValues$,
      sortModel$: this.sortModel$,
      parseFunction: (items) => {
        return items?.map((x) => {
          return {
            ...x,
            details: (x.details_json ? JSON.parse(x.details_json) : undefined) || {},
            date: Utils.dateParse(x.create_date),
          };
        });
      },
    });
  }

  goToDetail(item: CellClickedEvent) {
    const mapBudgetStatus = new Map<UserAuditLogCategory, string>(AuditBudgetStatuses);
    const id = item.data?.id;
    if (id) {
      if (UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_UPDATED === item.data.action) {
        this.quarterCloseAdjustmentsService.updateFormControlValues(
          this.formatDateFromActionText(item.data.action_text),
          item.data.vendor_id
        );
        this.router.navigateByUrl(
          `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`
        );
        return;
      } else if (
        [
          UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_AMENDMENT_INFO_UPDATED,
          UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_AMENDMENT_CREATED,
          UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_CREATED,
          UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_UPDATED,
          UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_INFO_UPDATED,
        ].includes(item.data.action)
      ) {
        const detailsObject = JSON.parse(item.data.details_json);
        this.router.navigateByUrl(
          `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}/${item.data.site_id}/${ROUTING_PATH.INVESTIGATOR.SITES.SITE_BUDGET}?patient_protocol_version_id=${detailsObject.patient_protocol_version_id}&site_budget_version_id=${item.data.entity_id}`
        );
        return;
      } else if (
        [
          UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_COSTS_UPDATED,
          UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_AMENDMENT_COSTS_UPDATED,
        ].includes(item.data.action)
      ) {
        const detailsObject = JSON.parse(item.data.details_json);
        this.router.navigateByUrl(
          `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}/${item.data.site_id}/${ROUTING_PATH.INVESTIGATOR.SITES.SITE_BUDGET}?patient_protocol_version_id=${detailsObject.patient_protocol_version_id}&site_budget_version_id=${detailsObject.site_budget_version_id}&patient_group_id=${detailsObject.patient_group_id}`
        );
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_AMENDMENT_DELETED === item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}/${item.data.site_id}/${ROUTING_PATH.INVESTIGATOR.SITES.SITE_BUDGET}`
        );
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_SCHEDULED_SITE_INVOICEABLE_GENERATED ===
        item.data.action
      ) {
        this.investigatorTransactionsService.addInMonthAdjustmentsFilters(
          this.formatDateFromActionText(item.data.action_text, 1),
          dayjs(this.formatDateFromActionText(item.data.action_text, 1))
            .endOf('month')
            .format('YYYY-MM-DD'),
          item.data.vendor_id
        );
        this.router.navigateByUrl(
          `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS}`
        );
        return;
      } else if (
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_BUDGET === item.data.category ||
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_FORECAST === item.data.category ||
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE === item.data.category
      ) {
        this.organizationStore.setActive(item.data.vendor_id);
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_QUARTER_CLOSED ===
          item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSED === item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_LOCKED === item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_UNLOCKED === item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`
        );
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_LOCKED === item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_UNLOCKED === item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`
        );
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_LOCKED === item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_UNLOCKED === item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`
        );
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_DISCOUNTS_LOCKED === item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_DISCOUNTS_UNLOCKED === item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`
        );
        return;
      } else if (
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_EXPENSE_SOURCE === item.data.category
      ) {
        this.quarterCloseAdjustmentsService.updateFormControlValues(
          `${item.data.details?.period?.substring(0, 7)}-03`,
          item.data.vendor_id
        );
        this.router.navigateByUrl(mapBudgetStatus.get(item.data.category) as string);
        return;
      } else if (
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_HISTORICAL_ADJUSTMENT === item.data.category ||
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE === item.data.category
      ) {
        this.quarterCloseAdjustmentsService.updateFormControlValues(
          this.formatDateFromActionText(item.data.action_text),
          item.data.vendor_id
        );
        this.router.navigateByUrl(mapBudgetStatus.get(item.data.category) as string);
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_LOCKED ===
          item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_UNLOCKED ===
          item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`
        );
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_UPLOADED === item.data.action
      ) {
        const dateFrom = dayjs(item.data.date);
        const dateTo = dateFrom.add(1, 'day');

        this.router.navigateByUrl(
          `/${ROUTING_PATH.DOCUMENTS}?documentType=${DocumentType.DOCUMENT_SITE_BUDGET}&dateFrom=${dateFrom.format('YYYY-MM-DD')}&dateTo=${dateTo.format('YYYY-MM-DD')}`
        );
        return;
      }
      this.router.navigateByUrl(mapBudgetStatus.get(item.data.category) as string);
    }
  }

  resetFilter() {
    this.gridFiltersFormGroup.reset();
  }

  getDynamicExcelParams = async (): Promise<ExcelExportParams> => {
    if (!this.gridAPI) {
      return {};
    }

    const data = await this.datasource.downloadAll();

    this.exportGridApi.setGridOption('rowData', data.items);

    const name = this.mainQuery.getSelectedTrial()?.short_name;
    return {
      ...this.excelOptions,
      columnKeys: [
        'date',
        'create_date',
        'category',
        'action_text',
        'vendor_id',
        'site_id',
        'created_by',
      ],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${name}`, type: 'String' },
              mergeAcross: 6,
              styleId: 'first_row',
            },
          ],
        },
      ],
    } as ExcelExportParams;
  };

  // updateSearch(target: EventTarget | null): void {
  //   const searchText = target as HTMLInputElement;
  updateSearch(target: EventTarget | null) {
    const searchTextValue = target as HTMLInputElement;
    if (searchTextValue.value.length > 2 || searchTextValue.value.length === 0) {
      // todo(upgrade) find out why we are looking at this key value should be string
      this._searchSubject.next(searchTextValue.value);
    }
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }

  // Action text is like "Manual Override Modified - February 2021" so this method transforms the date into 2021-02-03
  formatDateFromActionText(input: string, dayOfMonth = 3): string {
    const regex = /(\w+)\s+(\d{4})/;
    const match = input.match(regex);

    if (match) {
      const monthName = match[1];
      const year = match[2];

      // Using the 3rd of the month since that's the day that period-close.component.ts uses in parseBudgetHeaderDate
      const dateString = `${monthName} ${dayOfMonth}, ${year}`;
      const date = dayjs(dateString, 'MMMM D, YYYY');
      return date.format('YYYY-MM-DD');
    }

    return '';
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  onMultiselectChange(controlValue: string | OrganizationModel, controlName: string): void {
    if (controlValue === '' || (typeof controlValue !== 'string' && controlValue.id === '')) {
      this.gridFiltersFormGroup.get(controlName)?.patchValue('');
    }
  }
}
