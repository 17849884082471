import { AgPromise, Events, _ } from '@ag-grid-community/core';
/** @param V type of value in the Set Filter */
export class ClientSideValuesExtractor {
    constructor(rowModel, filterParams, createKey, caseFormat, columnModel, valueService, treeDataOrGrouping, treeData, getDataPath, groupAllowUnbalanced, addManagedListener) {
        this.rowModel = rowModel;
        this.filterParams = filterParams;
        this.createKey = createKey;
        this.caseFormat = caseFormat;
        this.columnModel = columnModel;
        this.valueService = valueService;
        this.treeDataOrGrouping = treeDataOrGrouping;
        this.treeData = treeData;
        this.getDataPath = getDataPath;
        this.groupAllowUnbalanced = groupAllowUnbalanced;
        this.addManagedListener = addManagedListener;
    }
    extractUniqueValuesAsync(predicate, existingValues) {
        return new AgPromise(resolve => {
            if (this.rowModel.isRowDataLoaded()) {
                resolve(this.extractUniqueValues(predicate, existingValues));
            }
            else {
                const destroyFunc = this.addManagedListener(Events.EVENT_ROW_COUNT_READY, () => {
                    destroyFunc === null || destroyFunc === void 0 ? void 0 : destroyFunc();
                    resolve(this.extractUniqueValues(predicate, existingValues));
                });
            }
        });
    }
    extractUniqueValues(predicate, existingValues) {
        const values = new Map();
        const existingFormattedKeys = this.extractExistingFormattedKeys(existingValues);
        const formattedKeys = new Set();
        const treeData = this.treeData && !!this.getDataPath;
        const groupedCols = this.columnModel.getRowGroupColumns();
        const addValue = (unformattedKey, value) => {
            const formattedKey = this.caseFormat(unformattedKey);
            if (!formattedKeys.has(formattedKey)) {
                formattedKeys.add(formattedKey);
                let keyToAdd = unformattedKey;
                let valueToAdd = _.makeNull(value);
                // when case insensitive, we pick the first value to use. if this is later filtered out,
                // we still want to use the original value and not one with a different case
                const existingUnformattedKey = existingFormattedKeys === null || existingFormattedKeys === void 0 ? void 0 : existingFormattedKeys.get(formattedKey);
                if (existingUnformattedKey != null) {
                    keyToAdd = existingUnformattedKey;
                    valueToAdd = existingValues.get(existingUnformattedKey);
                }
                values.set(keyToAdd, valueToAdd);
            }
        };
        this.rowModel.forEachLeafNode(node => {
            // only pull values from rows that have data. this means we skip filler group nodes.
            if (!node.data || !predicate(node)) {
                return;
            }
            if (this.treeDataOrGrouping) {
                this.addValueForTreeDataOrGrouping(node, treeData, groupedCols, addValue);
                return;
            }
            let value = this.getValue(node);
            if (this.filterParams.convertValuesToStrings) {
                // for backwards compatibility - keeping separate as it will eventually be removed
                this.addValueForConvertValuesToString(node, value, addValue);
                return;
            }
            if (value != null && Array.isArray(value)) {
                value.forEach(x => {
                    addValue(this.createKey(x, node), x);
                });
                if (value.length === 0) {
                    addValue(null, null);
                }
            }
            else {
                addValue(this.createKey(value, node), value);
            }
        });
        return values;
    }
    addValueForConvertValuesToString(node, value, addValue) {
        const key = this.createKey(value, node);
        if (key != null && Array.isArray(key)) {
            key.forEach(part => {
                const processedPart = _.toStringOrNull(_.makeNull(part));
                addValue(processedPart, processedPart);
            });
            if (key.length === 0) {
                addValue(null, null);
            }
        }
        else {
            addValue(key, key);
        }
    }
    addValueForTreeDataOrGrouping(node, treeData, groupedCols, addValue) {
        var _a;
        let dataPath;
        if (treeData) {
            if ((_a = node.childrenAfterGroup) === null || _a === void 0 ? void 0 : _a.length) {
                return;
            }
            dataPath = this.getDataPath(node.data);
        }
        else {
            dataPath = groupedCols.map(groupCol => this.valueService.getKeyForNode(groupCol, node));
            dataPath.push(this.getValue(node));
        }
        if (dataPath) {
            dataPath = dataPath.map(treeKey => _.toStringOrNull(_.makeNull(treeKey)));
        }
        if (!treeData && this.groupAllowUnbalanced && (dataPath === null || dataPath === void 0 ? void 0 : dataPath.some(treeKey => treeKey == null))) {
            dataPath = dataPath.filter(treeKey => treeKey != null);
        }
        addValue(this.createKey(dataPath), dataPath);
    }
    getValue(node) {
        return this.filterParams.getValue(node);
    }
    extractExistingFormattedKeys(existingValues) {
        if (!existingValues) {
            return null;
        }
        const existingFormattedKeys = new Map();
        existingValues.forEach((_value, key) => {
            existingFormattedKeys.set(this.caseFormat(key), key);
        });
        return existingFormattedKeys;
    }
}
