import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { EntityType, EventType, GqlService, TemplateType } from '@services/gql.service';
import { MessagesConstants } from '@constants/messages.constants';

@Component({
  selector: 'aux-import-patient-data-modal',
  templateUrl: 'import-patient-data-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportPatientDataModalComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  onSuccess: FunctionStringCallback = () => {};

  constructor(
    public ref: CustomOverlayRef<unknown, { onSuccess: VoidFunction }>,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {
    if (this.ref.data) {
      this.onSuccess = this.ref.data.onSuccess;
    }
  }

  pathFn: () => string = () => '';

  getFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/patient-tracker/`;
  }

  async downloadPatientTrackerTemplate() {
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.SITE_PATIENT_TRACKER_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error(MessagesConstants.FILE.PROBLEM_DOWNLOADING_TEMPLATE);
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = MessagesConstants.FILE.NEED_UPLOAD_FILE;
        return;
      }

      if (files.length > 1) {
        this.errorMessage = MessagesConstants.FILE.MAX_ONE_FILE;
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath()}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors, data } = await firstValueFrom(
          this.gqlService.validateEventFile$({
            type: EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED,
            entity_type: EntityType.TRIAL,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${key}`,
          })
        );
        if (success && data?.id) {
          this.overlayService.success('Template validation started.');
          this.onSuccess(data.id);
        } else {
          await this.apiService.removeFile(key);
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
