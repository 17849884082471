import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { IconComponent } from '@components/icon/icon.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { File } from '@components/file-manager/state/file.model';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { Utils } from '@services/utils';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MainQuery } from '@shared/store/main/main.query';
import { DocumentType, GqlService, ProcessType } from '@services/gql.service';
import dayjs from 'dayjs';
import { EdcConvertedFilesComponent } from './edc-converted-files/edc-converted-files.component';
import { OverlayService } from '@services/overlay.service';
import { v4 as uuid } from 'uuid';

@UntilDestroy()
@Component({
  selector: 'aux-new-edc-converter',
  templateUrl: './new-edc-converter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ComponentsModule,
    IconComponent,
    NgForOf,
    NgIf,
    NgSelectModule,
    AsyncPipe,
    EdcConvertedFilesComponent,
  ],
})
export class NewEdcConverterComponent implements AfterViewInit {
  @ViewChild(FileManagerComponent) fileManager?: FileManagerComponent;

  @ViewChild(EdcConvertedFilesComponent) edcConvertedFiles?: EdcConvertedFilesComponent;

  readonly extensions = [...Utils.EXCEL_FILE_EXTENSIONS, 'text/csv'];

  readonly accept = this.extensions.join(',');

  uploadedFiles: File[] = [];

  activeTrial = this.mainQuery.getSelectedTrial()?.name;

  currentOpenMonth = '';

  loading = false;

  constructor(
    public authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.initCurrentOpenMonth();
  }

  ngAfterViewInit(): void {
    this.fileManager?.fileQuery.selectAll().subscribe((files) => {
      this.uploadedFiles = files;
    });
  }

  getBucketKey = () => {
    return `document-storage/upload/${DocumentType.DOCUMENT_EDC_DATA}`;
  };

  clearAll(): void {
    this.fileManager?.fileStore.remove(() => true);
    this.fileManager?.resetUploadInput();
  }

  async convert(): Promise<void> {
    this.loading = true;
    const entityId = uuid();
    this.updateBucketKey(entityId);
    const s3Uploaded = await this.fileManager?.fileService.uploadFiles();

    if (s3Uploaded) {
      const sourceDocuments = this.uploadedFiles.map((file) => ({
        bucket_key: `public/${file.bucket_key}`,
      }));

      this.gqlService
        .createStoredDocument$({
          trial_id: this.mainQuery.getValue().trialKey,
          process_type: ProcessType.PROCESS_TRANSFORM_ATHENA,
          source_documents: JSON.stringify(sourceDocuments),
          document_type_id: DocumentType.DOCUMENT_EDC_DATA,
          entity_id: entityId,
        })
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          if (result.success && result.data) {
            this.overlayService.success(`Conversion in progress!`);
            this.edcConvertedFiles?.refreshGridData();
            this.clearAll();
            this.loading = false;
            this.changeDetectorRef.detectChanges();
          } else {
            this.overlayService.error(result.errors, undefined, true);
            this.loading = false;
            this.changeDetectorRef.detectChanges();
          }
        });
    }
  }

  initCurrentOpenMonth(): void {
    this.mainQuery
      .select('currentOpenMonth')
      .pipe(untilDestroyed(this))
      .subscribe((date) => {
        const openMonth = dayjs(date);

        this.currentOpenMonth = openMonth.isValid() ? openMonth.format('MMMM YYYY') : '';
      });
  }

  private updateBucketKey(entityId: string): void {
    this.uploadedFiles.forEach((file) => {
      if (file?.rawFile) {
        this.fileManager?.fileStore.update(file.id, {
          ...file,
          bucket_key: `document-storage/upload/${DocumentType.DOCUMENT_EDC_DATA}/${entityId}/${file.fileName}`,
        });
      }
    });
  }
}
