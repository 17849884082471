import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConverterToolComponent } from './converter-tool/converter-tool.component';
import { ExtractorComponent } from './extractor/extractor.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { EdcConverterComponent } from './edc-converter/edc-converter.component';
import { NewEdcConverterComponent } from './new-edc-converter/new-edc-converter.component';

const routes: Routes = [
  {
    path: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.INDEX,
    component: EdcConverterComponent,
    children: [
      {
        path: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.LEGACY,
        component: ConverterToolComponent,
        data: {
          baseUrl: 'https://auxilius-patient-tracker.shinyapps.io/auxilius-edc-converter',
        },
      },
      {
        path: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.NEW,
        component: NewEdcConverterComponent,
      },
      {
        path: '**',
        redirectTo: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.LEGACY,
      },
    ],
  },
  {
    path: ROUTING_PATH.OPS_ADMIN.JE_GENERATOR,
    component: ConverterToolComponent,
    data: {
      baseUrl: 'https://je-generator.shinyapps.io/je_app_v4',
    },
  },
  {
    path: 'extractor',
    component: ExtractorComponent,
  },
  {
    path: ROUTING_PATH.OPS_ADMIN.INVOICE_GENERATOR,
    component: ConverterToolComponent,
    data: {
      baseUrl: 'https://je-generator.shinyapps.io/payments_invoice_generator',
    },
  },
  { path: '**', redirectTo: 'edc-converter' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpsAdminRoutingModule {}
