<aux-input
  class="aux-ag-date-picker"
  [type]="type"
  [placeholder]="formControlConstants.PLACEHOLDER.DATE"
  [formControl]="fc"
  [autofocus]="true"
  [min]="min"
  [max]="max"
  [ignoreValidations]="!!params.ignoreValidations"
/>
