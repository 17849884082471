import { Component, computed, effect, OnInit, signal, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { Utils } from '@services/utils';
import { SiteBudgetVersionPanelComponent } from './site-budget-version-panel';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ButtonComponent } from '@components/button/button.component';
import { PatientGroupsService } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import {
  createSiteBudgetVersionMutation,
  Currency,
  EntityType,
  EventType,
  GqlService,
  listSiteBudgetVersionsQuery,
  PatientGroupType,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';

import { LaunchDarklyService } from '@services/launch-darkly.service';
import { PatientGroupsQuery } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { Option } from '@components/components.type';
import { SkeletonLoaderDirective } from '@shared/directives/skeleton-loader.directive';
import { PatientGroupsModel } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.model';
import { OverlayService } from '@services/overlay.service';
import { SiteBudgetAmendmentModalComponent } from './site-budget-amendment-modal/site-budget-amendment-modal.component';
import { SiteBudgetAmendmentHeaderModalComponent } from './site-budget-amendment-modal/site-budget-amendment-header-modal.component';
import { SitesQuery } from '@models/sites/sites.query';
import { MessagesConstants } from '@constants/messages.constants';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { AgGridModule } from '@ag-grid-community/angular';
import { SiteBudgetGridAmountService } from './site-budget-grid-amount/site-budget-grid-amount.service';
import { GridRowData, getSiteBudgetGridAmountOptions } from './site-budget-grid-amount/column-defs';
import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { cloneDeep, differenceWith, flatten, isEqual, uniq } from 'lodash';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { TableService } from '@services/table.service';
import { IconComponent } from '@components/icon/icon.component';
import { ChangeEffectiveDateModalComponent } from './change-effective-date-modal';
import { SiteBudgetService } from './services/site-budget.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { isDuplicateError } from '@shared/utils';
import { NoProtocolVersionsLinkComponent } from './no-protocol-versions-link/no-protocol-versions-link.component';
import {
  ButtonItemOption,
  ButtonListComponent,
} from '@shared/components/button-list/button-list.component';
import { TableSkeletonComponent } from '@shared/components/table-skeleton/table-skeleton.component';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';

interface SiteBudgetForm {
  protocolVersion: string | null;
  siteBudgetVersion: string | null;
  patientGroup: string | null;
  note: string | null;
  noteId: string | null;
  siteBudgetName: string | null;
  effectiveDate: string | null;
}

@UntilDestroy()
@Component({
  selector: 'aux-site-budget',
  templateUrl: './site-budget.component.html',
  styleUrl: './site-budget.component.scss',
  imports: [
    ReactiveFormsModule,
    SiteBudgetVersionPanelComponent,
    ButtonListComponent,
    AsyncPipe,
    DatePipe,
    NgSelectModule,
    ButtonComponent,
    NgFor,
    NgIf,
    NgClass,
    AgGridModule,
    TableSkeletonComponent,
    SkeletonLoaderDirective,
    InputComponent,
    IconComponent,
    TooltipDirective,
    NoProtocolVersionsLinkComponent,
  ],
  standalone: true,
})
export class SiteBudgetComponent implements OnInit {
  protocolVersionList = signal<ButtonItemOption[]>([]);

  readonly emptyProtocolVersionList: ButtonItemOption[] = Array(10)
    .fill('')
    .map((_, index) => ({
      value: '',
      label: `Version ${index}`,
    }));

  effectiveDateTooltipText =
    'An effective date must be entered in order for the site budget amendment to be applied';

  currency = signal(Currency.USD);

  gridOptions = computed(() =>
    getSiteBudgetGridAmountOptions(this.editMode(), {
      getEditableHeaderClasses: TableService.getEditableHeaderClasses(this.editMode()),
      currency: this.currency(),
    })
  );

  siteBudgetList = signal<listSiteBudgetVersionsQuery[]>([]);

  siteBudgeOptionList = signal<listSiteBudgetVersionsQuery[]>([]);

  gridData = signal<GridRowData[]>([]);

  initialDataState: {
    gridData: GridRowData[];
    formState: SiteBudgetForm | null;
    documents: File[];
  } = {
    gridData: [],
    formState: null,
    documents: [],
  };

  gridLoading = signal(true);

  loadingFilters = signal(true);

  editMode = signal(false);

  private gridAPI?: GridApi<GridRowData>;

  @ViewChild('siteBudgetVersionPanelComponent')
  siteBudgetVersionPanelComponent!: SiteBudgetVersionPanelComponent;

  @ViewChild('effectiveDateInput') effectiveDateInput?: InputComponent;

  private readonly visitCostOption: Option = {
    label: 'Visit Costs',
    value: 'visits_costs',
  };

  private readonly invoiceablesOption: Option = {
    value: 'invoiceables',
    label: 'Invoiceables',
  };

  private readonly disabledFieldsInEditMode: (keyof SiteBudgetForm)[] = [
    'protocolVersion',
    'patientGroup',
    'siteBudgetVersion',
  ];

  readonly selectedSiteId!: string;

  private readonly panelFields: (keyof SiteBudgetForm)[] = ['note', 'siteBudgetName'];

  patientGroupList$ = combineLatest([
    this.launchDarklyService.select$((flags) => flags.visit_costs),
    this.patientGroupsQuery.selectAll(),
  ]).pipe(
    map(([visitCostsEnabled, groups]) => {
      const res: Option[] = [...groups.map((group) => ({ label: group.name, value: group.id }))];

      if (visitCostsEnabled) {
        res.push(this.visitCostOption);
      }

      res.push(this.invoiceablesOption);

      return res;
    })
  );

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  hasUpdateSiteCostPermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPDATE_SITE_COSTS],
  });

  actionButtonsTooltip = computed(() => {
    if (!this.hasUpdateSiteCostPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : '';
  });

  loadingFilters$ = new BehaviorSubject(true);

  siteBudgetForm = this.fb.group<SiteBudgetForm>({
    protocolVersion: null,
    siteBudgetVersion: null,
    patientGroup: null,
    note: null,
    noteId: null,
    siteBudgetName: null,
    effectiveDate: null,
  });

  private fileManager?: FileManagerComponent;

  private deletedFiles: File[] = [];

  constructor(
    private fb: FormBuilder,
    private patientProtocolService: PatientProtocolService,
    private patientGroupsService: PatientGroupsService,
    private patientGroupsQuery: PatientGroupsQuery,
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private siteQuery: SitesQuery,
    public siteBudgetGridAmountService: SiteBudgetGridAmountService,
    private siteBudgetService: SiteBudgetService,
    private authService: AuthService,
    private gqlService: GqlService,
    private authQuery: AuthQuery,
    private router: Router,
    private route: ActivatedRoute,
    private workflowQuery: WorkflowQuery
  ) {
    const [selectedSiteId] = this.siteQuery.getActiveId() || [];
    this.selectedSiteId = selectedSiteId as string;

    effect(
      () => {
        this.handleFieldsAvailabilityByEditMode();
        this.gridAPI?.setGridOption('columnDefs', this.gridOptions().columnDefs);
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    combineLatest([
      this.fetchPatientProtocolVersions(),
      this.patientGroupsService.get([PatientGroupType.PATIENT_GROUP_STANDARD]),
      this.fetchSiteBudgetList(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(async ([versions, { data: patientGroups }, siteBudgetList]) => {
        const firstProtocolVersion = versions?.length ? versions[0] : null;

        const siteBudget = siteBudgetList
          .sort(({ create_date }, { create_date: create_date2 }) =>
            Utils.dateSort(create_date, create_date2)
          )
          .find(
            ({ patient_protocol_version_id }) =>
              patient_protocol_version_id === firstProtocolVersion?.value
          );

        this.updateBudgetOptionList(firstProtocolVersion?.value ?? null);

        let protocolVersion = firstProtocolVersion?.value ?? null;
        let siteBudgetVersion = siteBudget?.id ?? null;
        let patientGroup = this.getInitialPatientGroup(patientGroups);

        const params = await firstValueFrom(this.route.queryParams);

        if (params['patient_protocol_version_id']) {
          protocolVersion = params['patient_protocol_version_id'];
        }
        if (params['site_budget_version_id']) {
          siteBudgetVersion = params['site_budget_version_id'];
        }
        if (params['patient_group_id']) {
          patientGroup = params['patient_group_id'];
        }
        this.router.navigate([], { queryParams: {}, replaceUrl: true });

        this.siteBudgetForm.patchValue({
          protocolVersion,
          siteBudgetVersion,
          patientGroup,
          effectiveDate: siteBudget?.effective_date ?? null,
        });

        this.siteBudgetForm.get('protocolVersionPanel')?.disable();

        this.protocolVersionList.set(versions ?? []);

        if (!versions?.length) {
          this.gridLoading.set(false);
          ['siteBudgetVersion', 'protocolVersion', 'patientGroup'].forEach((controlName) => {
            this.siteBudgetForm.get(controlName)?.disable();
          });
        }

        this.loadingFilters.set(false);
      });

    this.syncProtocolVersionWithPanel();
    this.fetchGridData();
  }

  private handleFieldsAvailabilityByEditMode() {
    if (this.editMode()) {
      this.disabledFieldsInEditMode.forEach((controlName) =>
        this.siteBudgetForm.get(controlName)?.disable()
      );
      this.panelFields.forEach((controlName) => this.siteBudgetForm.get(controlName)?.enable());
    } else {
      this.disabledFieldsInEditMode.forEach((controlName) =>
        this.siteBudgetForm.get(controlName)?.enable()
      );
      this.panelFields.forEach((controlName) => this.siteBudgetForm.get(controlName)?.disable());
    }
  }

  private syncProtocolVersionWithPanel() {
    this.siteBudgetForm
      .get('protocolVersion')
      ?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((protocolVersion) => this.updateFormStateByNewProtocolVersion(protocolVersion));
  }

  private updateFormStateByNewProtocolVersion(protocolVersion: string | null) {
    this.updateBudgetOptionList(protocolVersion);

    const siteBudgetVersion = this.siteBudgetList().find(
      ({ patient_protocol_version_id }) => patient_protocol_version_id === protocolVersion
    );

    const note = siteBudgetVersion?.notes?.length ? siteBudgetVersion?.notes[0] : null;

    this.siteBudgetForm.patchValue({
      effectiveDate: siteBudgetVersion?.effective_date ?? null,
      siteBudgetVersion: siteBudgetVersion?.id ?? null,
      siteBudgetName: siteBudgetVersion?.name ?? null,
      note: note?.message ?? null,
      noteId: note?.id ?? null,
    });
  }

  private updateBudgetOptionList(protocolVersion: string | null) {
    const siteBudgeOptionList = this.siteBudgetList()
      .filter(({ patient_protocol_version_id }) => patient_protocol_version_id === protocolVersion)
      .sort(({ create_date }, { create_date: create_date2 }) =>
        Utils.dateSort(create_date, create_date2)
      );
    this.siteBudgeOptionList.set(siteBudgeOptionList);
  }

  private fetchSiteBudgetList() {
    return this.siteBudgetService
      .getSiteBudgetList$({
        site_id: this.selectedSiteId,
      })
      .pipe(
        tap((siteBudgetList) => {
          this.siteBudgetList.set(siteBudgetList);
        })
      );
  }

  private fetchPatientProtocolVersions() {
    return this.patientProtocolService.getPatientProtocolVersions().pipe(
      untilDestroyed(this),
      map((data) => {
        if (data.length) {
          const latestProtocolWithEffectiveDate = [...data].sort(
            ({ effective_date }, { effective_date: effective_date2 }) =>
              Utils.dateSort(effective_date ?? '', effective_date2 ?? '')
          )[0];

          const latestProtocol = latestProtocolWithEffectiveDate.effective_date
            ? latestProtocolWithEffectiveDate
            : data[0];

          return [latestProtocol, ...data.filter(({ id }) => id !== latestProtocol.id)].map(
            ({ name, id }) => ({
              label: name,
              value: id,
            })
          );
        }
      })
    );
  }

  private fetchGridData() {
    combineLatest([
      <Observable<string | undefined>>this.siteBudgetForm.get('patientGroup')?.valueChanges,
      <Observable<string | undefined>>this.siteBudgetForm.get('protocolVersion')?.valueChanges,
      <Observable<string | undefined>>this.siteBudgetForm.get('siteBudgetVersion')?.valueChanges,
    ])
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(isEqual),
        filter(
          ([patientGroup, protocolVersion, siteBudgetVersion]) =>
            patientGroup && protocolVersion && siteBudgetVersion
        ),
        debounceTime(200),
        switchMap(([patientGroup, protocolVersion, siteBudgetVersion]) => {
          this.gridLoading.set(true);

          return patientGroup
            ? this.siteBudgetGridAmountService.getGridData(
                patientGroup,
                this.selectedSiteId,
                <string>protocolVersion,
                siteBudgetVersion
              )
            : of([]);
        })
      )
      .subscribe((gridData) => {
        this.updateInitialDataState(gridData);

        this.gridData.set(gridData);
        this.currency.update((currency) => (gridData.length ? gridData[0].currency : currency));
        this.deletedFiles = [];
        this.gridLoading.set(false);
      });
  }

  gridReady(event: GridReadyEvent) {
    this.gridAPI = event.api;
    this.gridAPI?.sizeColumnsToFit();
  }

  beforeSelectProtocolVersion = async () => {
    if (!this.editMode()) {
      return true;
    }

    if (this.isFormChanged()) {
      const result = await firstValueFrom(
        this.overlayService.open({ content: GuardWarningComponent }).afterClosed$
      );

      if (result?.data) {
        this.editMode.set(false);
      }

      return !!result?.data;
    }

    return true;
  };

  handleSelectedItem(protocolVersion: string) {
    this.siteBudgetForm.patchValue({
      protocolVersion,
    });
  }

  async createSiteBudgetAmendment() {
    const modalEvent = this.overlayService.open<{ siteBudget?: createSiteBudgetVersionMutation }>({
      content: SiteBudgetAmendmentModalComponent,
      closeButton: false,
      data: {
        useDesignSystemStyling: true,
        headerClass: 'bg-aux-blue-light-2 !py-4 !px-2',
        customHeader: SiteBudgetAmendmentHeaderModalComponent,
        protocolVersionList: this.protocolVersionList(),
        protocolVersion: this.siteBudgetForm.getRawValue().protocolVersion,
        siteId: this.selectedSiteId,
      },
    }).afterClosed$;

    const resp = await firstValueFrom(modalEvent);

    if (resp?.data?.siteBudget?.id) {
      this.overlayService.showFieldTooltip('siteBudgetVersion', 'Amendment Created');
      const siteBudget = resp!.data!.siteBudget as listSiteBudgetVersionsQuery;

      this.siteBudgetList.update((list) => [siteBudget, ...list]);
      this.siteBudgeOptionList.update((list) => [siteBudget, ...list]);

      const note = siteBudget?.notes?.length ? siteBudget?.notes[0] : null;

      this.siteBudgetForm.patchValue({
        protocolVersion: siteBudget.patient_protocol_version_id,
        siteBudgetVersion: siteBudget.id,
        siteBudgetName: siteBudget.name,
        note: note?.message ?? null,
        noteId: note?.id ?? null,
      });

      this.updateInitialDataState();
    }
  }

  private async saveGridData(
    siteBudgetVersion: string,
    gridData: GridRowData[]
  ): Promise<{ errors: string[]; gridData: GridRowData[] }> {
    const errors = await this.siteBudgetGridAmountService.saveGridData(
      differenceWith(gridData, this.initialDataState.gridData, isEqual),
      this.selectedSiteId,
      siteBudgetVersion
    );

    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.UPDATE_INVESTIGATOR_TRANSACTIONS,
        entity_type: EntityType.SITE,
        entity_id: this.selectedSiteId,
      })
    );

    return {
      errors: uniq(flatten(errors as string[][])),
      gridData,
    };
  }

  async saveChanges() {
    if (this.siteBudgetForm.invalid || this.effectiveDateInput?.fc.invalid) {
      return;
    }

    const { effectiveDate, siteBudgetVersion, siteBudgetName, note, noteId, protocolVersion } =
      this.siteBudgetForm.getRawValue();

    const allGridData = this.getAllGridData();

    if (this.initialDataState.formState?.effectiveDate !== effectiveDate) {
      const modalEvent = await firstValueFrom(
        this.overlayService.open<{ success: boolean }>({
          baseComponent: ChangeEffectiveDateModalComponent,
        }).afterClosed$
      );
      if (!modalEvent?.data?.success) return;

      if (siteBudgetVersion) {
        this.gridLoading.set(true);
        firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.SITE_BUDGET_EFFECTIVE_DATE_UPDATED,
            entity_type: EntityType.SITE_BUDGET_VERSION,
            entity_id: siteBudgetVersion,
            payload: JSON.stringify({
              previousEffectiveDate: this.initialDataState.formState?.effectiveDate,
              newEffectiveDate: effectiveDate,
              updatedBy: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
              protocolVersion,
              siteBudgetName,
            }),
          })
        );
      }
    }

    this.gridLoading.set(true);

    if (siteBudgetVersion) {
      const { errors: updateBudgetErrors, errorSource } =
        await this.siteBudgetService.updateSiteBudget(
          {
            effective_date: effectiveDate,
            id: siteBudgetVersion,
            name: siteBudgetName,
            message: note,
            previous_message: this.initialDataState.formState?.note,
            files_uploaded:
              this.deletedFiles.length > 0 ||
              this.initialDataState.documents.length != this.fileManager?.fileQuery.getAll().length
                ? this.fileManager?.fileQuery
                    .getAll()
                    .map((x) => x.fileName)
                    .join(', ') || Utils.zeroHyphen
                : '',
          },
          {
            message: note,
            id: noteId ?? undefined,
          },
          this.selectedSiteId,
          this.fileManager,
          this.deletedFiles
        );

      if (updateBudgetErrors.length && errorSource === 'updateSite') {
        const errorsWithoutDuplicateError = updateBudgetErrors.filter(
          (error) => !isDuplicateError(error)
        );

        const hasSiteBudgetNameError = updateBudgetErrors.some((error) =>
          isDuplicateError(error, 'site_budget_version_site_id_name')
        );

        const hasEffectiveDateError = updateBudgetErrors.some((error) =>
          isDuplicateError(error, 'site_budget_version_site_id_effective_date')
        );

        const showInlineError = (fc: FormControl, error: Record<string, unknown>) => {
          fc.markAsTouched();
          fc.updateValueAndValidity();
          fc.setErrors(error);
        };

        this.gridLoading.set(false);
        this.overlayService.error(errorsWithoutDuplicateError);

        hasSiteBudgetNameError &&
          showInlineError(this.siteBudgetVersionPanelComponent.siteBudgetNameInput.fc, {
            duplicate_site_budget: true,
          });

        hasEffectiveDateError &&
          this.effectiveDateInput &&
          showInlineError(this.effectiveDateInput.fc, {
            incorrect_site_budget_effective_date: true,
          });

        return;
      }

      const { errors: budgetGridErrors, gridData } = await this.saveGridData(
        siteBudgetVersion,
        allGridData
      );

      if (budgetGridErrors.length) {
        this.gridLoading.set(false);
        this.overlayService.error(budgetGridErrors);
      } else {
        // wait when documents will be associated with site budget
        setTimeout(async () => {
          await firstValueFrom(this.fetchSiteBudgetList());
          this.deletedFiles = [];
          this.updateInitialDataState(gridData);
          this.updateBudgetOptionList(protocolVersion);
          this.overlayService.success('Successfully saved!');
          this.editMode.set(false);
          this.gridLoading.set(false);
        }, 500);
      }
    }
  }

  fileManagerReady(file: FileManagerComponent) {
    this.fileManager = file;
  }

  onRemoveFile(file: File) {
    this.deletedFiles.push(file);
  }

  async onRemoveSiteBudget(id: string) {
    const siteBudget = this.siteBudgetList().find((site) => site.id === id);
    if (siteBudget) {
      this.gridLoading.set(true);

      const { success } = await firstValueFrom(
        this.siteBudgetService.removeSiteBudgetVersion$(
          id,
          siteBudget.documents.map(({ id }) => id)
        )
      );

      if (success) {
        this.siteBudgetList.update((list) => list.filter((site) => site.id !== id));
        this.siteBudgeOptionList.update((list) => list.filter((site) => site.id !== id));

        const newSiteBudget = this.siteBudgeOptionList().length
          ? this.siteBudgeOptionList()[0]
          : null;

        this.siteBudgetForm.patchValue({
          siteBudgetVersion: newSiteBudget?.id ?? null,
          siteBudgetName: newSiteBudget?.name ?? null,
        });

        this.overlayService.success('Successfully removed!');
        this.editMode.set(false);
      }

      this.gridLoading.set(false);
    }
  }

  getAllGridData() {
    const gridData: GridRowData[] = [];

    this.gridAPI?.forEachNode((node) => {
      if (node.data) {
        gridData.push(node.data);
      }
    });

    return gridData;
  }

  private updateInitialDataState(gridData?: GridRowData[]) {
    this.initialDataState = {
      formState: this.siteBudgetForm.getRawValue(),
      gridData: gridData ? cloneDeep(gridData) : this.initialDataState.gridData,
      documents: this.fileManager?.fileQuery.getAll() || [],
    };
  }

  cancelChanges() {
    const { effectiveDate, siteBudgetName, note } = this.initialDataState.formState || {};

    this.editMode.set(false);
    this.gridData.set(cloneDeep(this.initialDataState.gridData));
    this.fileManager?.fileStore.set(this.initialDataState.documents);
    this.deletedFiles = [];
    this.siteBudgetForm.patchValue({
      effectiveDate,
      siteBudgetName,
      note,
    });
  }

  private getInitialPatientGroup(patientGroups: PatientGroupsModel[]): string | null {
    const isVisitCostEnabled = this.launchDarklyService.flags$.getValue().visit_costs;

    if (patientGroups.length) {
      return patientGroups[0].id;
    }

    return isVisitCostEnabled ? this.visitCostOption.value : null;
  }

  async canDeactivate(): Promise<boolean> {
    if (this.isFormChanged() && this.editMode()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }

    return true;
  }

  isFormChanged() {
    const currentFormState = this.siteBudgetForm.getRawValue();
    const initialFormState = this.initialDataState.formState;

    const isGridDataChanged = !isEqual(this.gridData(), this.initialDataState.gridData);
    const isDocumentsChanged = !isEqual(
      (this.fileManager?.fileQuery.getAll() || []).map(({ fileName }) => ({ fileName })),
      this.initialDataState.documents.map(({ fileName }) => ({ fileName }))
    );

    const isFormChanged = !isEqual(
      {
        effectiveDate: currentFormState.effectiveDate ?? null,
        note: currentFormState.note ?? null,
        siteBudgetName: currentFormState.siteBudgetName ?? null,
      },
      {
        effectiveDate: initialFormState?.effectiveDate ?? null,
        note: initialFormState?.note ?? null,
        siteBudgetName: initialFormState?.siteBudgetName ?? null,
      }
    );

    return isGridDataChanged || isFormChanged || isDocumentsChanged;
  }
}
