import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OrganizationQuery } from '@models/organization/organization.query';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';

import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { DocumentType, EntityType, GqlService } from '@services/gql.service';
import { ChangeOrderService } from '../state/change-order.service';
import { ChangeOrderQuery } from '../state/change-order.query';
import { ChangeOrderModel } from '../state/change-order.store';
import { CustomValidators } from '@components/form-inputs/custom-validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aux-change-order-upload',
  templateUrl: './change-order-upload.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderUploadComponent implements OnInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  loading$ = new BehaviorSubject(false);

  error_message$ = new BehaviorSubject('');

  fg = this.formBuilder.group({
    organization_id: ['', Validators.required],
    change_order_no: '',
    date_received: '',
    effective_date: '',
    notes: '',
  });

  edit = false;

  changeOrder!: ChangeOrderModel;
  changeOrderList: ChangeOrderModel[] = [];
  changeOrderNo: string | undefined;

  constructor(
    public ref: CustomOverlayRef<unknown, { id?: string }>,
    public vendorQuery: OrganizationQuery,
    private formBuilder: UntypedFormBuilder,
    private overlayService: OverlayService,
    private changeOrderService: ChangeOrderService,
    private mainQuery: MainQuery,
    private changeOrderQuery: ChangeOrderQuery,
    private changeDetectorRef: ChangeDetectorRef,
    private gqlService: GqlService
  ) {
    if (this.ref.data?.id) {
      const co = this.changeOrderQuery.getEntity(this.ref.data.id);
      if (co) {
        this.changeOrder = co;
        this.changeOrderNo = co.change_order_no;
        this.fg.patchValue({
          organization_id: co.organization_id,
          change_order_no: co.change_order_no,
          date_received: co.date_received,
          effective_date: co.effective_date,
          notes: co.notes,
        });

        this.edit = true;
      }
    }
    document.getElementById('closeButton')?.addEventListener('click', () => {
      this.cancelOverlay().then();
    });
  }

  get changeOrderNoValidators(): ValidatorFn[] {
    const changeOrderNumbersToForbid = this.changeOrderList
      .filter(
        (changeOrder) => changeOrder.organization_id === this.fg.controls.organization_id.value
      )
      .map((changeOrder) => changeOrder.change_order_no)
      .filter(
        (changeOrderNo) =>
          !this.loading$.value &&
          (changeOrderNo !== this.changeOrderNo ||
            !this.changeOrder ||
            this.changeOrder.organization_id !== this.fg.controls.organization_id.value)
      );

    return [
      Validators.required,
      CustomValidators.valueNotInList(changeOrderNumbersToForbid, 'duplicatedChangeOrder'),
    ];
  }

  ngOnInit(): void {
    this.fg.controls.organization_id.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
      if (this.fg.controls.change_order_no.pristine && this.changeOrder) {
        this.fg.controls.change_order_no.markAllAsTouched();
        this.fg.controls.change_order_no.updateValueAndValidity();
      }
    });
    this.gqlService
      .listChangeOrders$()
      .pipe(untilDestroyed(this))
      .subscribe(({ success, data, errors }) =>
        success && data
          ? (this.changeOrderList = data.map(this.changeOrderService.toChangeOrderModel))
          : errors?.length && this.overlayService.error(errors)
      );
  }

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }

  async onUpload() {
    if (!this.fileManager) {
      return;
    }

    this.fg.markAllAsTouched();
    this.fg.updateValueAndValidity();
    this.fg.controls.change_order_no.updateValueAndValidity();

    const isFileNotExist = this.fileManager.fileQuery.getCount() === 0;
    if (!this.edit) {
      if (isFileNotExist) {
        this.error_message$.next('You must upload a file in order to continue.');
      } else {
        this.error_message$.next('');
      }
    }

    if (this.fg.invalid || this.loading$.getValue() || (this.edit ? false : isFileNotExist)) {
      return;
    }

    this.loading$.next(true);
    const { change_order_no, notes, effective_date, date_received, organization_id } =
      this.fg.value;

    let id = '';
    if (this.edit) {
      id = this.changeOrder.id;
      const { success, errors, data } = await this.changeOrderService.update({
        id: this.changeOrder.id,
        change_order_no,
        notes,
        effective_date,
        date_received,
        organization_id,
      });

      if (!(success && data)) {
        this.overlayService.error(errors, undefined, true);
      }
    } else {
      const { success, errors, data } = await this.changeOrderService.add({
        change_order_no,
        notes,
        effective_date,
        date_received,
        organization_id,
      });

      if (success && data) {
        id = data.id;
      } else {
        this.overlayService.error(errors, undefined, true);
      }
    }

    if (id) {
      const files = this.fileManager.fileQuery.getAll();
      for (const file of files) {
        this.fileManager.fileStore.update(file.id, {
          ...file,
          bucket_key: `${this.getFilePath(organization_id, id)}${file.bucket_key}`,
        });
      }
      const filesSuccess = await this.fileManager.fileService.uploadFiles(
        {
          status: 'Pending Review',
          documentType: DocumentType.DOCUMENT_CHANGE_ORDER_SUPPORT,
          vendor: organization_id,
          entity_id: id,
          entity_type_id: EntityType.CHANGE_ORDER,
        },
        false,
        true
      );
      if (filesSuccess) {
        if (this.edit) {
          this.overlayService.success(`Change Order successfully updated!`);
        } else {
          this.overlayService.success(`Change Order successfully created!`);
        }
        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }

  removeFile(file: File) {
    if (this.fileManager) {
      this.fileManager.removeFile(file);
    }
  }

  checkChanges() {
    const { change_order_no, notes, effective_date, date_received, organization_id } =
      this.fg.value;
    return (
      (change_order_no === ''
        ? undefined
        : change_order_no !== this.changeOrder?.change_order_no) ||
      (notes === '' ? undefined : notes !== this.changeOrder?.notes) ||
      (effective_date === '' ? undefined : effective_date !== this.changeOrder?.effective_date) ||
      (date_received === '' ? undefined : date_received !== this.changeOrder?.date_received) ||
      (organization_id === '' ? undefined : organization_id !== this.changeOrder?.organization_id)
    );
  }

  async cancelOverlay() {
    if (this.checkChanges()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      if (event.data) {
        this.ref.close();
      }
    } else {
      this.ref.close();
    }
  }
}
