import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { StickyElementService } from '@services/sticky-element.service';
import { ApiService } from '@services/api.service';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { formatDate } from '@angular/common';
import dayjs from 'dayjs';
import {
  ApprovalType,
  Currency,
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';
import { first, get, isEmpty, merge, now } from 'lodash-es';
import { Utils } from '@services/utils';
import { TrialsQuery } from '@models/trials/trials.query';
import { TableConstants } from 'src/app/constants/table.constants';
import {
  AgCellWrapperComponent,
  getWrapperCellOptions,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.query';
import { AuthService } from '@shared/store/auth/auth.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { MainQuery } from '@shared/store/main/main.query';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import {
  MonthCloseTableRowData,
  PeriodCloseComponent,
  QuarterDate,
  QuarterStartDataForBottomRow,
} from '../../period-close.component';
import { AddVendorEstimateUploadComponent } from './add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { AgInMonthGroupHeaderComponent } from './ag-in-month-group-header/ag-in-month-group-header.component';
import { AgQuarterCloseApprovalComponent } from './ag-quarter-close-approval.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { QuarterCloseDialogComponent } from './quarter-close-dialog/quarter-close-dialog.component';
import { QuarterCloseProcessingConfirmationHeaderComponent } from './quarter-close-processing-confirmation-header.component';
import { QuarterCloseProcessingConfirmationComponent } from './quarter-close-processing-confirmation.component';
import { VendorEstimateUploadComponent } from './vendor-estimate-upload/vendor-estimate-upload.component';
import {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnGroupShowType,
  ExcelExportParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IProvidedColumn,
  IRowNode,
  ITooltipParams,
  PostProcessPopupParams,
  ProcessCellForExportParams,
  ProcessGroupHeaderForExportParams,
  RowClassParams,
  RowStyle,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgExpandableGroupHeaderComponent } from '../quarter-close-adjustments/ag-expandable-group-header.component';
import {
  AgQuarterCloseAdjustmentComponent,
  AgQuarterCloseAdjustmentParams,
} from './ag-quarter-close-adjustment.component';
import {
  AgBudgetAttributeComponentParams,
  AgBudgetEnhancedGroupHeaderComponent,
} from '../../../budget-page/tabs/budget-enhanced/ag-budget-enhanced-group-header.component';
import {
  AgQuarterCloseCollapsableHeaderComponent,
  AgQuarterCloseCollapsableHeaderParams,
} from './ag-quarter-close-collapsable-header.component';
import { QuarterCloseChecklistPeriodCloseService } from '../quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { InvoiceService } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { Option } from '@components/components.type';
import { ActivatedRoute, Router } from '@angular/router';
import {
  attributeColumnDef,
  calcColumns,
} from '../../../budget-page/tabs/budget-enhanced/column-defs';
import { QuarterCloseBannerHeight } from './quarter-close-banner/quarter-close-banner.component';
import { AuxExcelStyleKeys, AuxExcelStyles, GetExcelStyle } from '@shared/utils';
import { QuarterCloseAdjustmentsService } from '../quarter-close-adjustments/quarter-close-adjustments.service';
import { EventService } from '@models/event/event.service';

// localstorage key for quarter close attributes
const QCAttributesLSKey = 'quarter_close_activities_header';

@UntilDestroy()
@Component({
  selector: 'aux-quarter-close',
  templateUrl: './quarter-close.component.html',
  styleUrls: ['./quarter-close.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseComponent implements OnInit, OnDestroy {
  refreshDownload$ = new Subject();

  showGrid = true;

  showQuarterCloseBanner$ = new BehaviorSubject(false);

  showRequestJournalEntryButton$: Observable<boolean>;

  isDownloadVendorEstimateButtonDisabled$ = this.refreshDownload$.pipe(
    startWith(null),
    switchMap(() => combineLatest([this.budgetQuery.select(), this.mainQuery.select('trialKey')])),
    switchMap(([{ budget_info }, trialKey]) => {
      const first_vendor_current_month = budget_info[0]?.current_month;

      if (!first_vendor_current_month) {
        return of(0);
      }
      const formatted_current_month = formatDate(first_vendor_current_month, 'MMMM-y', 'en-US');

      return from(
        this.apiService.fileCounts(
          `trials/${trialKey}/vendors/vendor-estimate/${formatted_current_month}`
        )
      ).pipe(map((count) => !count));
    })
  );

  userHasUploadVendorEstimatePermission = new BehaviorSubject<boolean>(false);

  gridDataForFilter$ = new BehaviorSubject<MonthCloseTableRowData[]>([]);

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  isCloseMonthAvailable$ = combineLatest([
    this.workflowQuery.getMonthCloseButtonAvailability$(),
    this.periodCloseComponent.monthClosed$.pipe(map((bool) => !bool)),
  ]).pipe(map((arr) => arr.every((bool) => bool)));

  components = {
    approvalRenderer: AgQuarterCloseApprovalComponent,
  };

  private openMonthFirstColumnId = '';

  postProcessPopup: (params: PostProcessPopupParams) => void = (params: PostProcessPopupParams) => {
    const columnId = params.column ? params.column.getId() : undefined;
    if (columnId === 'account' || columnId === 'dept' || columnId === 'po') {
      const ePopup = params.ePopup;
      let oldTopStr = ePopup.style.top;
      let oldLeftStr = ePopup.style.left;
      // remove 'px' from the string (AG Grid uses px positioning)
      oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf('px'));
      oldLeftStr = oldLeftStr.substring(0, oldLeftStr.indexOf('px'));
      const oldTop = parseInt(oldTopStr);
      const oldLeft = parseInt(oldLeftStr);
      const newTop = oldTop + 35;
      const newLeft = oldLeft + 35;
      ePopup.style.top = newTop + 'px';
      ePopup.style.left = newLeft + 'px';
    }
  };

  currencyFormatter = (params: ValueFormatterParams) => {
    let currency = Currency.USD;
    if (params.data === undefined && params.node) {
      currency = this.organizationQuery.getEntity(params.node.key)?.currency || Currency.USD;
    }
    return Utils.agCurrencyFormatterAccounting(
      params,
      params.data?.contract_direct_cost_currency || params.data?.currency || currency
    );
  };

  getCellClass = () => (params: CellClassParams) => {
    if (params?.node?.data?.contract_direct_cost_currency) {
      return [
        `budgetCost${params?.node?.data?.contract_direct_cost_currency}`,
        'ag-cell-align-right',
      ];
    }
    return ['budgetCostNoSymbol', 'ag-cell-align-right'];
  };

  getCellClassForInvoiceCells = () => (params: CellClassParams) => {
    if (params.node.group || params.node.rowPinned === 'bottom') {
      if (params?.node?.data?.contract_direct_cost_currency) {
        return [
          `budgetCost${params?.node?.data?.contract_direct_cost_currency}`,
          'ag-cell-align-right',
          params.value ? 'aux-link' : 'aux-link-zero-hyphen',
          'cursor-pointer',
        ];
      }
      return [
        'budgetCostNoSymbol',
        'ag-cell-align-right',
        params.value ? 'aux-link' : 'aux-link-zero-hyphen',
        'cursor-pointer',
      ];
    } else {
      if (params?.node?.data?.contract_direct_cost_currency) {
        return [
          `budgetCost${params?.node?.data?.contract_direct_cost_currency}`,
          'ag-cell-align-right',
        ];
      }
      return ['budgetCostNoSymbol', 'ag-cell-align-right'];
    }
  };

  excelOptions = {
    sheetName: 'Quarter Close',
    fileName: 'auxilius-quarter-close.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    skipPinnedBottom: true,
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_id':
          return 280;
        case 'initial_monthly_accrual':
        case 'total_monthly_accrual':
        case 'invoice_amount':
        case 'net_accruals':
          return 170;
        default:
          return 150;
      }
    },
    processGroupHeaderCallback: (params: ProcessGroupHeaderForExportParams): string => {
      const colGroupDef = params.columnGroup.getColGroupDef();
      if (!colGroupDef) {
        return '';
      }
      const headerName = colGroupDef.headerName || '';
      if (headerName && headerName.match(/\d+-\d+/)) {
        return dayjs(headerName).format('MMMM YYYY');
      }
      return headerName;
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      if (params.column.getColId() === 'vendor_id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }

      return params.value;
    },
  } as ExcelExportParams;

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS;

  adjustmentWorkflow$ = this.workflowQuery.getWorkflowByStepType$(this.workflowName);

  isQuarterCloseEnabled$ = this.workflowQuery.isQuarterCloseEnabled$;

  isWorkflowAvailable$ = this.workflowQuery.isWorkflowAvailable$;

  userHasClosingPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_APPROVE_IN_MONTH],
  });

  shouldShowBanner$ = combineLatest([
    this.showQuarterCloseBanner$,
    this.periodCloseComponent.iCloseMonthsProcessing$,
  ]).pipe(
    map(([show, isClosing]) => show && !isClosing),
    tap((bool) => {
      this.stickyServiceOffset = bool ? QuarterCloseBannerHeight : 0;
    })
  );

  userHasAdjustPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS],
  });

  autoAttributeGroupColumnDef: ColDef = {
    headerName: '',
    minWidth: 250,
    width: 250,
    resizable: true,
    field: 'activity_name',
    tooltipField: 'activity_name',
    pinned: 'left',
    headerComponent: AgBudgetEnhancedGroupHeaderComponent,
    headerComponentParams: {
      expandLevel: () => -1,
      template: `Activities`,
      localStorageKey: QCAttributesLSKey,
    } as AgBudgetAttributeComponentParams,
    tooltipValueGetter: (params: ITooltipParams) => {
      return params.node?.group
        ? this.organizationQuery.getEntity(params.value)?.name
        : params.value;
    },
    ...getWrapperCellOptions(),
    cellRendererParams: {
      suppressCount: true,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (_, __, nodeA, nodeB) => {
      if (!nodeA.aggData) {
        return 0;
      }
      return nodeA.aggData.current_lre - nodeB.aggData.current_lre;
    },
  };

  // only needed for Excel export
  isTotalHidden = true;

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Vendor',
      field: 'vendor_id',
      resizable: false,
      rowGroup: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      },
      hide: true,
      cellClass: ['vendor_id'],
    },
    {
      headerName: 'Cost Category',
      field: 'cost_category',
      hide: true,
      cellClass: ['cost_category'],
    },
    TableConstants.SPACER_COLUMN,
    {
      headerName: 'Quarter Start',
      headerClass: [
        'attributes-quarterStart-quarterSummary',
        'ag-header-align-center',
        AuxExcelStyleKeys.ALTERNATE,
        AuxExcelStyleKeys.BORDER_LEFT,
        AuxExcelStyleKeys.BORDER_RIGHT,
      ],
      headerGroupComponent: AgExpandableGroupHeaderComponent,
      headerGroupComponentParams: {
        collapsedByDefault: true,
        filterCols(column: IProvidedColumn) {
          return !['starting_accrual', 'starting_prepaid'].includes(column.getId());
        },
        expandableCols: ['wp_ltd_start', 'invoice_ltd_start'],
      },
      children: [
        {
          headerName: 'WP LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `wp_ltd_start`,
          headerClass: AuxExcelStyleKeys.BORDER_LEFT,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          hide: true,
          cellClass: (p) => [...this.getCellClass()(p), AuxExcelStyleKeys.BORDER_LEFT],
        },
        {
          headerName: 'Invoiced LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          hide: true,
          field: `invoice_ltd_start`,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          cellClass: this.getCellClassForInvoiceCells(),
          onCellClicked: (event) => {
            if (event.node.group || event.rowPinned === 'bottom') {
              return this.navigateByInvoices(event);
            }
          },
        },
        {
          headerName: 'Accrual LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `starting_accrual`,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          cellClass: this.getCellClass(),
        },
        {
          headerName: 'Prepaid LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `starting_prepaid`,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          headerClass: AuxExcelStyleKeys.BORDER_RIGHT,
          cellClass: (p) => [...this.getCellClass()(p), AuxExcelStyleKeys.BORDER_RIGHT],
        },
      ],
    },
  ];

  gridOptions$ = new BehaviorSubject<GridOptions>({
    tooltipShowDelay: 500,
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 70,
      cellRenderer: AgCellWrapperComponent,
      cellClassRules: {
        [AuxExcelStyleKeys.BORDER_BOTTOM]: (p) => {
          return (
            !p.node.group && p.node.lastChild && !!p.node.parent?.lastChild && this.isTotalHidden
          );
        },
      },
    },
    getRowStyle: (param: RowClassParams) => {
      if (param.node.rowPinned) {
        return <RowStyle>{ 'font-weight': 'bold' };
      }
      return undefined;
    },
    initialGroupOrderComparator: (params) => {
      const a = this.organizationQuery.getEntity(params.nodeA.key)?.name || 0;
      const b = this.organizationQuery.getEntity(params.nodeB.key)?.name || 0;

      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    suppressMenuHide: true,
    headerHeight: 40,
    suppressCellFocus: true,
    groupDefaultExpanded: 0,
    suppressAggFuncInHeader: true,
    suppressRowHoverHighlight: false,
    rowSelection: 'single',
    groupSelectsChildren: false,
    columnDefs: [],
    excelStyles: [
      ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
      ...AuxExcelStyles,
      {
        ...GetExcelStyle(AuxExcelStyleKeys.FIRST_ROW),
        id: 'trial_name',
        borders: {
          borderBottom: {
            color: 'black',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
    ],
  });

  budgetInfo$ = this.organizationQuery.selectActiveId().pipe(
    switchMap((org_id) =>
      this.budgetQuery.select('budget_info').pipe(
        map((x) => {
          return x.find((y) => y.vendor_id === org_id);
        })
      )
    )
  );

  showUnpaidColumns$ = new BehaviorSubject(false);

  stickyServiceOffset = 0;

  gridInitializedOnInit = false;

  constructor(
    public periodCloseComponent: PeriodCloseComponent,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private overlayService: OverlayService,
    private apiService: ApiService,
    private budgetQuery: BudgetQuery,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private organizationQuery: OrganizationQuery,
    private authService: AuthService,
    private gqlService: GqlService,
    private workflowQuery: WorkflowQuery,
    private launchDarklyService: LaunchDarklyService,
    private documentLibraryService: DocumentLibraryService,
    private stickyElementService: StickyElementService,
    private invoiceService: InvoiceService,
    private router: Router,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.setUserPermissions();
    this.periodCloseComponent.selectedMonth$.next(null);
    this.periodCloseService.selectedQuarterMonthChanged$.next(null);

    combineLatest([this.mainQuery.select('trialKey'), this.gridAPI$.pipe(take(1), startWith(null))])
      .pipe(
        switchMap(() =>
          this.launchDarklyService.select$((flags) => flags.unpaid_invoices_ltd_column)
        )
      )
      .pipe(untilDestroyed(this))
      .subscribe((bool) => {
        this.showUnpaidColumns$.next(bool);
      });

    this.showRequestJournalEntryButton$ = this.launchDarklyService.select$(
      (flags) => flags.request_journal_entry_report_button
    );
  }

  ngOnInit() {
    this.invoiceService.initialize().pipe(untilDestroyed(this)).subscribe();
    this.gridAPI$
      .pipe(
        take(1),
        switchMap(() => this.periodCloseComponent.gridDataMergedDiscount$)
      )
      .pipe(untilDestroyed(this))
      .subscribe((mergedData) => {
        this.gridDataForFilter$.next(mergedData);
        this.updateGridOptions(mergedData);
        this.gridInitializedOnInit = true;
      });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  onAddVendorEstimateUploadClick = () => {
    const overlay = this.overlayService.open<{ vendorId?: string }>({
      content: AddVendorEstimateUploadComponent,
    });
    overlay.afterClosed$.subscribe((res) => {
      this.refreshDownload$.next(null);

      if (res.data?.vendorId) {
        this.quarterCloseAdjustmentsService.updateFormControlValues('', res.data.vendorId);

        this.periodCloseComponent.selectedQuarter.setValue(
          this.periodCloseComponent.currentQuarter
        );

        this.router.navigate(
          [`/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`],
          {
            queryParams: {
              vendorId: res.data.vendorId,
              editMode: true,
              currentOpenMonth: dayjs(this.currentMonth).add(2, 'day').format('YYYY-MM-DD'),
            },
          }
        );
      }
    });
  };

  getDynamicExcelParams = (): ExcelExportParams => {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }

    const unpaid_invoices_flag = this.showUnpaidColumns$.getValue();

    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;

    let columnKeys = this.getDashboardIDs();

    const attributes =
      this.gridAPI
        .getColumns()
        ?.map((x) => [x.getColId(), x] as [string, Column])
        ?.filter(([x]: [string, Column]) => {
          return x.startsWith('custom_attr_') || ['account', 'po', 'dept'].includes(x);
        })
        ?.map(([x, c]) => {
          return [c.isVisible(), x] as [boolean, string];
        }) || [];

    const shownAttributes = attributes.filter(([bool]) => bool).length;

    const appendContent: ExcelExportParams['appendContent'] = [
      {
        cells: [
          {
            data: { value: `Total`, type: 'String' },
            mergeAcross: shownAttributes ? attributes.length + 1 : 1,
            styleId: ['total_row_header', AuxExcelStyleKeys.BORDER_BOTTOM],
          },
        ],
      },
    ];

    if (totals) {
      const quarter_start_columns: (keyof QuarterStartDataForBottomRow)[] = [
        'wp_ltd_start',
        'invoice_ltd_start',
        'starting_accrual',
        'starting_prepaid',
      ];
      for (let i = 0; i < quarter_start_columns.length; i++) {
        const column = quarter_start_columns[i];

        appendContent[0].cells.push({
          data: { value: !Number.isNaN(totals[column]) ? totals[column] : 0, type: 'Number' },
          styleId: [
            AuxExcelStyleKeys.BORDER_BOTTOM,
            `total_row_${totals?.currency}`,
            i === 0
              ? AuxExcelStyleKeys.BORDER_LEFT
              : i === quarter_start_columns.length - 1
                ? AuxExcelStyleKeys.BORDER_RIGHT
                : '',
          ].filter((z) => z),
        });
      }
    }

    const pushTotalValue = (field: string, styles = [] as string[]) => {
      appendContent[0].cells.push({
        data: { value: get(totals, field) || 0, type: 'Number' },
        styleId: [`total_row_${totals?.currency}`, AuxExcelStyleKeys.BORDER_BOTTOM, ...styles],
      });
    };

    this.periodCloseComponent.quartersObj[
      this.periodCloseComponent.selectedQuarter.value || ''
    ].forEach((month, monthIndex) => {
      this.periodCloseComponent.columnsForMonth.forEach((field, colIndex) => {
        if (field === 'eom_accruals') {
          return;
        }
        if (!unpaid_invoices_flag && field === 'eom_unpaid') {
          return;
        }
        pushTotalValue(
          `${field}.${month.date}`,
          monthIndex === 1
            ? [
                colIndex === 0
                  ? AuxExcelStyleKeys.BORDER_LEFT
                  : colIndex === this.periodCloseComponent.columnsForMonth.length - 1
                    ? AuxExcelStyleKeys.BORDER_RIGHT
                    : '',
              ]
            : []
        );
      });
    });

    const summary_columns = [
      'accrual_adjusted_obj',
      'invoice_amounts',
      'net_accruals',
      'wp_ltd',
      `invoice_ltd`,
      'eom_accrual',
      'eom_prepaid',
    ];

    if (unpaid_invoices_flag) {
      summary_columns.push('eom_unpaid');
    }

    for (let i = 0; i < summary_columns.length; i++) {
      const column = summary_columns[i];
      const styles = [
        i === 0
          ? AuxExcelStyleKeys.BORDER_LEFT
          : i === summary_columns.length - 1
            ? AuxExcelStyleKeys.BORDER_RIGHT
            : '',
      ];
      if (column !== 'wp_ltd' && column !== `invoice_ltd`) {
        pushTotalValue(`${column}.total`, styles);
      } else {
        pushTotalValue(`${column}_end`, styles);
      }
    }

    const selectQ = this.periodCloseComponent.selectedQuarter.value || '';

    columnKeys = shownAttributes
      ? [
          columnKeys[shownAttributes],
          columnKeys[shownAttributes + 1],
          ...columnKeys.slice(0, shownAttributes),
          ...columnKeys.splice(shownAttributes + 2, columnKeys.length - 1),
        ]
      : [...columnKeys.splice(attributes.length)];

    if (!unpaid_invoices_flag) {
      columnKeys = columnKeys.filter(
        (columnKey) => !columnKey.startsWith('eom_unpaid') && columnKey !== 'invoice_unpaid_ltd_end'
      );
    }

    return <ExcelExportParams>{
      ...this.excelOptions,
      fileName: `${trial.short_name}_${selectQ}.xlsx`,
      columnKeys,
      appendContent: totals ? appendContent : [],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: columnKeys.length - 1,
              styleId: 'trial_name',
            },
          ],
        },
      ],
    };
  };

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure({ offset: this.stickyServiceOffset });
  }

  async onDownloadVendorEstimates() {
    const { trialKey } = this.mainQuery.getValue();

    const { success, data } = await this.apiService.getS3ZipFile(
      `trials/${trialKey}/vendors/vendor-estimate/${this.getCurrentMonthToClose()}`
    );
    if (success && data) {
      // [insert trial name]_[insert vendor name]_Change Order [insert Change Order #]_[insert date created YYYY.MM.DD]
      const fileName = `vendor-estimate`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
  }

  async onRequestJournalEntryReport() {
    const result = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REQUEST_JOURNAL_ENTRY_REPORT_NOTIFICATION,
        entity_type: EntityType.DOCUMENT,
        entity_id: '',
        payload: JSON.stringify({
          current_open_month: this.getCurrentMonthToClose().replace('-', ' '),
        }),
      })
    );
    if (result.success) {
      this.overlayService.success('Request for Journal Entry Report successfully processed');
    } else {
      this.overlayService.error('Error Requesting Journal Entry Report');
    }
  }

  async exportExcelToS3() {
    const additionalExcelOption = this.getDynamicExcelParams();
    const defaultExcelOptions: ExcelExportParams = {
      author: 'Auxilius',
      fontSize: 11,
      columnWidth: 105,
    };

    const exportedData = this.gridAPI?.getDataAsExcel({
      ...defaultExcelOptions,
      columnKeys: this.getDashboardIDs(),
      ...this.excelOptions,
      ...additionalExcelOption,
    });

    const month = dayjs(this.getCurrentMonthToClose());
    //formatted per req's for month close reports (the only place used)
    const fileFormattedDate = month.format('MMM-YYYY');

    const { trialKey } = this.mainQuery.getValue();
    const fileName = `${fileFormattedDate}_Accrual_Summary.xlsx`;
    const key = `trials/${trialKey}/period-close/${fileName}`;

    const user = await this.authService.getLoggedInUser();

    if (exportedData) {
      const fileToUpload = new File([exportedData], fileName);
      await this.apiService.uploadFile(key, fileToUpload, {
        uploadedBy: user?.getSub() || '',
      });

      await this.documentLibraryService.uploadDocument(
        {
          id: Utils.uuid(),
          uploaded: false,
          eTag: Utils.uuid(),
          bucket_key: key,
          fileName: fileName,
          lastModified: now(),
          size: fileToUpload.size,
          type: 'csv',
        },
        {
          documentType: DocumentType.DOCUMENT_MONTHLY_AUDIT_PACKAGE,
          target_date: month.format('YYYY-MM-DD'),
        },
        key
      );
    }
  }

  navigateByInvoices(event: CellClickedEvent) {
    const navigateParams: { vendor_id: string; acc_months: Option[] } = {
      vendor_id: '',
      acc_months: [],
    };

    const columnId = event.column.getColId();
    const isTotalRow = event.rowPinned === 'bottom';

    const inQStart = columnId.includes('start');
    const inQSummary = columnId.includes('end') || columnId.includes('total');
    const inQMonth = !inQStart && !inQSummary;

    const vendorId = event.data ? event.data.vendor_id : event.node.key;

    const all_options = this.invoiceService.accrualPeriodOptions;
    const [qtr, year] = this.periodCloseComponent.selectedQuarter.value?.split('-') || [];

    if (!all_options || !qtr || !year) {
      return;
    }

    const selectedQtrDate = new Date(+year, +qtr.slice(1) * 3 - 3, 1);

    navigateParams.vendor_id = isTotalRow ? '' : vendorId;

    if (inQMonth) {
      const isInvoiceReceived = columnId.includes('amount');
      const isInvoiceLtd = columnId.includes('ltd');
      const [colMonth, colYear] = columnId.split('.').reverse().shift()?.split('-') || [];
      if (isInvoiceReceived) {
        const acc_option = this.getAccrualPeriodOptionsForMonths(
          colMonth,
          colYear,
          all_options
        ).acc_option;
        navigateParams.acc_months = [];
        navigateParams.acc_months.push(acc_option);
      }
      if (isInvoiceLtd && !isInvoiceReceived) {
        navigateParams.acc_months = this.getAccrualPeriodOptionsForMonths(
          colMonth,
          colYear,
          all_options
        ).before_and_current_options;
      }
    } else if (inQStart) {
      navigateParams.acc_months = this.getAccrualPeriodOptionsForQStart(
        all_options,
        selectedQtrDate
      ).before_acc_options;
    } else if (inQSummary) {
      const isInvoiceReceived = columnId.includes('amount');
      const isInvoiceLtd = columnId.includes('ltd');
      if (isInvoiceReceived) {
        navigateParams.acc_months = this.getAccrualPeriodOptionsForQSummary(
          all_options,
          selectedQtrDate
        ).quarter_acc_options;
      }
      if (isInvoiceLtd && !isInvoiceReceived) {
        navigateParams.acc_months = this.getAccrualPeriodOptionsForQSummary(
          all_options,
          selectedQtrDate
        ).before_and_quarter_acc_options;
      }
    }

    const acc_mont_values: string[] = [];

    navigateParams.acc_months.forEach((month) => {
      acc_mont_values.push(dayjs(month.value).format('YYYY-MM-DD'));
    });

    this.invoiceService.setAccrualPeriodsAndVendorFilter(acc_mont_values, navigateParams.vendor_id);

    this.router.navigateByUrl(
      `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`
    );
  }

  getAccrualPeriodOptionsForQStart(all_options: Option[], date: Date) {
    const before_acc_options = all_options.filter((option) =>
      dayjs(`${option.value}`).isBefore(date)
    );
    return { before_acc_options };
  }

  getAccrualPeriodOptionsForQSummary(all_options: Option[], date: Date) {
    const quarter_acc_options = all_options.filter(
      (option) =>
        dayjs(`${option.value}`).quarter() === dayjs(date).quarter() &&
        dayjs(`${option.value}`).year() === dayjs(date).year()
    );
    const before_and_quarter_acc_options = all_options.filter(
      (option) =>
        (dayjs(`${option.value}`).quarter() === dayjs(date).quarter() &&
          dayjs(`${option.value}`).year() === dayjs(date).year()) ||
        dayjs(`${option.value}`).isBefore(date)
    );
    return { quarter_acc_options, before_and_quarter_acc_options };
  }

  getAccrualPeriodOptionsForMonths(month_short_name: string, year: string, all_options: Option[]) {
    const acc_option: Option = { label: '', value: '' };

    const shortMonthNames = Utils.SHORT_MONTH_NAMES;
    const longMonthNames = Utils.MONTH_NAMES;
    const monthIndex = shortMonthNames.findIndex(
      (month) => month.toUpperCase() === month_short_name
    );
    const longMonthName = longMonthNames[monthIndex];
    const monthNumber = monthIndex < 9 ? `0${monthIndex + 1}` : `${monthIndex + 1}`;
    acc_option.label = `${longMonthName} ${year}`;
    acc_option.value = `${year}-${monthNumber}`;

    const date = new Date(+year, monthIndex, 1);

    const before_and_current_options = all_options.filter((option) =>
      dayjs(`${option.value}`).isSameOrBefore(date)
    );

    return { acc_option, before_and_current_options };
  }

  async onInMonthClose() {
    const info = this.budgetQuery.getValue().budget_info;

    const first_vendor_current_month = info[0]?.current_month;
    if (!first_vendor_current_month) {
      return;
    }
    const formatted_current_month = formatDate(first_vendor_current_month, 'MMMM y', 'en-US');

    const resp = await firstValueFrom(
      this.overlayService.open<{ note: string; sendToNetsuite?: boolean } | undefined, unknown>({
        content: QuarterCloseDialogComponent,
        data: {
          header: `Close ${formatted_current_month}`,
          useDesignSystemStyling: true,
          displayX: true,
          formatted_current_month,
        },
      }).afterClosed$
    );

    if (!resp.data?.note) {
      return;
    }

    await firstValueFrom(
      this.overlayService.open({
        content: QuarterCloseProcessingConfirmationComponent,
        data: {
          customHeader: QuarterCloseProcessingConfirmationHeaderComponent,
          useDesignSystemStyling: true,
        },
      }).afterClosed$
    );

    const ref = this.overlayService.loading();

    await this.exportExcelToS3();

    const { current_month } = info[0];

    const result = await firstValueFrom(
      this.gqlService.processEvent$({
        entity_id: '',
        entity_type: EntityType.TRIAL,
        type: EventType.CLOSE_TRIAL_MONTH,
        payload: JSON.stringify({
          note: resp.data?.note,
          event_dates: [current_month],
          sendToNetsuite: resp.data?.sendToNetsuite,
        }),
      })
    );

    ref.close();

    if (result.success) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.eventService.trackEvent(result.data!.id);
      this.periodCloseComponent.monthClosed$.next(true);
      // this.overlayService.success('Month Successfully Closed');
      console.log('Month Successfully Closed');
    } else {
      const allErrors: string[] = [];
      allErrors.push(...result.errors);
      this.overlayService.error(allErrors);
    }
  }

  async onCellValueChanged(event: CellValueChangedEvent) {
    const loading = this.overlayService.loading();
    const user = await this.authService.getLoggedInUser();
    const id = event.column.getColId();
    const [, permission_type] = id.split('::');
    const [node] = this.gridAPI.getSelectedNodes();

    const { success, errors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: true,
        comments: '',
        permission: permission_type,
        approval_type: ApprovalType.APPROVAL_IN_MONTH,
        entity_id: node.data.vendor_id,
        entity_type: EntityType.TRIALEVENT,
        activity_details: JSON.stringify({
          category: node.data.activity_name,
        }),
      })
    );

    if (success) {
      if (user?.IsSysAdmin()) {
        node.setData({ ...node.data, [`${id}::disabled`]: true });
      } else {
        const columnsToChange = ((this.gridAPI.getColumns() || []) as Column[]).filter((x) =>
          x.getColId().includes(permission_type)
        );

        columnsToChange.forEach((col) => {
          node.setData({ ...node.data, [`${col.getColId()}::disabled`]: true });
        });
      }

      this.overlayService.success('Approved!');
    } else {
      node.setData({ ...node.data, [id]: false });
      this.overlayService.error(errors);
    }

    loading.close();
  }

  async onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridAPI$.next(api);

    const allColumnIds: string[] = [];
    (this.gridAPI.getColumns() || []).forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });

    this.gridAPI.autoSizeColumns(allColumnIds, false);

    const obj = this.periodCloseComponent.bottomRowDataForMergedDiscount$.getValue();
    // period-close will set all bottomRowData objects empty if there are multiple currencies
    if (
      isEmpty(obj.eom_accruals) &&
      isEmpty(obj.net_accruals) &&
      isEmpty(obj.monthly_wp_obj) &&
      isEmpty(obj.initial_wp_obj)
    ) {
      this.gridAPI.setGridOption('pinnedBottomRowData', []);
      this.isTotalHidden = true;
    } else {
      this.isTotalHidden = false;
      this.gridAPI?.setGridOption('pinnedBottomRowData', [
        merge(
          {
            activity_name: 'Total',
          },
          obj
        ),
      ]);
    }

    this.showQuarterCloseBanner$.next(this.isOpenMonth());
    this.stickyElementService.configure({ offset: this.stickyServiceOffset });

    if (this.openMonthFirstColumnId) {
      setTimeout(() => {
        this.gridAPI.ensureColumnVisible(this.openMonthFirstColumnId, 'start');
      });
    }

    if (this.showGrid && this.gridInitializedOnInit) {
      await this.actionGridByRouteParams();
    }
  }

  private async actionGridByRouteParams() {
    const { jeVendorId, jeSelectedMonth } = await firstValueFrom(this.route.queryParams);

    if (!jeSelectedMonth) {
      return;
    }

    if (jeVendorId) {
      this.gridAPI.forEachNode((node) => {
        if (node.field === 'vendor_id' && node.key === jeVendorId) {
          this.gridAPI.setRowNodeExpanded(node, true);
        }
      });
    }

    if (
      !this.openMonthFirstColumnId ||
      this.openMonthFirstColumnId !== `accrual_adjusted_obj.${jeSelectedMonth.toUpperCase()}`
    ) {
      setTimeout(() => {
        this.gridAPI.ensureColumnVisible(`eom_accrual.${jeSelectedMonth.toUpperCase()}`, 'start');
      });
    }

    this.clearRouterQueryParams();
  }

  private clearRouterQueryParams() {
    this.router.navigate([], {
      queryParams: {
        jeVendorId: null,
        jeSelectedMonth: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  getCurrentMonthToClose() {
    const info = this.budgetQuery.getValue().budget_info;
    const first_vendor_current_month = info[0]?.current_month;
    return formatDate(first_vendor_current_month || '', 'MMMM-y', 'en-US');
  }

  private getMonthColumn({
    headerName,
    isSame,
    isFuture,
    rowKey,
    month,
    hideTotal,
    readOnlyAdjustment,
    isSummary = false,
    alternate,
  }: {
    headerName: string;
    isSame: boolean;
    isFuture: boolean;
    rowKey: string;
    month: QuarterDate | null;
    hideTotal?: boolean;
    readOnlyAdjustment?: boolean;
    isSummary?: boolean;
    alternate: boolean;
  }) {
    const adjustmentColumnProps = !readOnlyAdjustment
      ? {
          cellRenderer: AgQuarterCloseAdjustmentComponent,
          cellRendererParams: <AgQuarterCloseAdjustmentParams>{
            onClicked: (event: ICellRendererParams) => {
              this.periodCloseComponent.onSelectionChanged(event);
            },
            current_month: isSame,
            adjustmentWorkflow$: this.adjustmentWorkflow$,
            isWorkflowAvailable$: this.isWorkflowAvailable$,
            userHasAdjustPermission$: this.userHasAdjustPermission$,
          },
          cellClass: ['cost', '!p-0'],
        }
      : {
          cellClass: ['cost'],
        };

    const getMoneyColumn = () => {
      return {
        maxWidth: 150,
        minWidth: 150,
        resizable: false,
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: this.getCellClass(),
        columnGroupShow: (isSame ? 'closed' : 'open') as ColumnGroupShowType,
      };
    };
    const debit_credit_columns: ColDef[] = isSummary
      ? []
      : [
          {
            ...getMoneyColumn(),
            headerName: 'Accrual Debit',
            hide: true,
            field: `eom_accrual_debit.${rowKey}`,
          },
          {
            ...getMoneyColumn(),
            headerName: 'Accrual Credit',
            hide: true,
            field: `eom_accrual_credit.${rowKey}`,
          },
          {
            ...getMoneyColumn(),
            headerName: 'Prepaid Debit',
            hide: true,
            field: `eom_prepaid_debit.${rowKey}`,
          },
          {
            ...getMoneyColumn(),
            headerName: 'Prepaid Credit',
            hide: true,
            field: `eom_prepaid_credit.${rowKey}`,
          },
        ];

    if (isSame) {
      this.openMonthFirstColumnId = `accrual_adjusted_obj.${rowKey}`;
    }

    const children: ColDef[] = [
      {
        ...getMoneyColumn(),
        ...adjustmentColumnProps,
        headerName: isSummary ? 'Quarterly WP Estimate' : 'Monthly WP Estimate',
        field: `accrual_adjusted_obj.${rowKey}`,
        cellClass: (p) => [
          ...this.getCellClass()(p),
          alternate ? AuxExcelStyleKeys.BORDER_LEFT : '',
        ],
      },
      {
        ...getMoneyColumn(),
        headerName: isSummary ? 'Quarterly Invoices Received' : 'Monthly Invoices Received',
        field: `invoice_amounts.${rowKey}`,
        cellClass: this.getCellClassForInvoiceCells(),
        onCellClicked: (event) => {
          if (event.node.group || event.rowPinned === 'bottom') {
            return this.navigateByInvoices(event);
          }
        },
      },
      {
        headerComponent: AgQuarterCloseCollapsableHeaderComponent,
        headerComponentParams: {
          template: `Net Change in <br/> Accruals / (Prepaid)`,
          columns: isSummary
            ? []
            : [
                `eom_accrual_debit.${rowKey}`,
                `eom_accrual_credit.${rowKey}`,
                `eom_prepaid_debit.${rowKey}`,
                `eom_prepaid_credit.${rowKey}`,
              ],
        } as AgQuarterCloseCollapsableHeaderParams,
        headerName: 'Net Change in Accruals / (Prepaid)',
        maxWidth: 210,
        minWidth: 210,
        resizable: false,
        field: `net_accruals.${rowKey}`,
        columnGroupShow: isSame ? 'closed' : 'open',
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: this.getCellClass(),
        headerClass: 'net_accruals_header',
      },
      ...debit_credit_columns,
      {
        ...getMoneyColumn(),
        headerName: 'WP LTD',
        field: isSummary ? `wp_ltd_end` : `monthly_wp_obj.${rowKey}`,
      },
      {
        ...getMoneyColumn(),
        headerName: 'Invoiced LTD',
        onCellClicked: (event) => {
          if (event.node.group || event.rowPinned === 'bottom') {
            return this.navigateByInvoices(event);
          }
        },
        cellClass: this.getCellClassForInvoiceCells(),
        field: isSummary ? `invoice_ltd_end` : `monthly_invoice_ltd.${rowKey}`,
      },
      {
        minWidth: 150,
        width: 150,
        resizable: false,
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: this.getCellClass(),
        // columnGroupShow: isSame ? 'closed' : 'open',
        headerName: 'Accrual LTD',
        columnGroupShow: undefined,
        field: `eom_accrual.${rowKey}`,
      },
      {
        minWidth: 150,
        width: 150,
        resizable: false,
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: (p) => [
          ...this.getCellClass()(p),
          this.showUnpaidColumns$.getValue() ? '' : alternate ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
        // columnGroupShow: isSame ? 'closed' : 'open',
        headerName: 'Prepaid LTD',
        columnGroupShow: undefined,
        field: `eom_prepaid.${rowKey}`,
      },
      {
        ...getMoneyColumn(),
        headerName: 'Unpaid Invoices LTD',
        field: isSummary ? `invoice_unpaid_ltd_end` : `eom_unpaid.${rowKey}`,
        hide: !this.showUnpaidColumns$.getValue(),
        cellClass: (p) => [
          ...this.getCellClass()(p),
          alternate ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
      },
    ];
    return {
      headerName,
      headerClass: [
        isSame
          ? 'open gray-dark-header-group'
          : headerName.includes('Summary')
            ? 'attributes-quarterStart-quarterSummary'
            : 'closed',
        ...(alternate
          ? [
              AuxExcelStyleKeys.ALTERNATE,
              AuxExcelStyleKeys.BORDER_LEFT,
              AuxExcelStyleKeys.BORDER_RIGHT,
            ]
          : ['']),
      ],
      headerGroupComponent: AgInMonthGroupHeaderComponent,
      headerGroupComponentParams: {
        processing$: this.periodCloseComponent.iCloseMonthsProcessing$,
        isMonthCloseAvailable$: this.isCloseMonthAvailable$,
        isQuarterCloseEnabled$: this.isQuarterCloseEnabled$,
        isWorkflowAvailable$: this.isWorkflowAvailable$,
        userHasClosingPermission$: this.userHasClosingPermission$,
        bottomRowData$: this.periodCloseComponent.bottomRowData$,
        budgetInfo$: this.budgetInfo$,
        mode: isFuture ? 'future' : isSame ? 'open' : 'closed',
        month,
        hideTotal,
        headerName,
        monthButtonClick: () => {
          if (isSame) {
            this.onInMonthClose();
          }
        },
      },
      minWidth: 200,
      children,
    };
  }

  private isColGroup(cell: ColDef | ColGroupDef): cell is ColGroupDef {
    return !!(cell as ColGroupDef)?.children;
  }

  private isColDef(col: ColDef | ColGroupDef): col is ColDef {
    return !!(col as ColDef).colId;
  }

  private getChildrenGroupIds(cols: (ColDef | ColGroupDef)[]): string[] {
    return cols.filter(this.isColDef).map((col) => col.colId) as string[];
  }

  private getDashboardIDs(): string[] {
    if (!this.gridAPI) {
      return [];
    }

    return (this.gridAPI?.getColumnDefs() || [])
      .filter((cel) => !!cel.headerName)
      .reduce<string[]>((accum, cell: ColDef | ColGroupDef) => {
        if (this.isColGroup(cell)) {
          const res = this.getChildrenGroupIds(cell.children);
          // eslint-disable-next-line no-param-reassign
          accum = accum.concat(res || []);
        }

        if (this.isColDef(cell)) {
          accum.push(cell.colId || '');
        }

        return accum;
      }, []);
  }

  onFilterChanged(e: FilterChangedEvent) {
    const notFilteredParentRowAndChilds: {
      whichVendor: string;
      whichChilds: string[];
    }[] = [];

    e.api.forEachNodeAfterFilter((row) => {
      if (row.group) {
        const key = row.key || '';
        const whichChilds: string[] = [];
        row.childrenAfterFilter?.forEach((children) => {
          whichChilds.push(children.data.activity_name);
        });
        notFilteredParentRowAndChilds.push({ whichVendor: key, whichChilds: whichChilds });
      }
    });

    const changedData: MonthCloseTableRowData[] = this.gridDataForFilter$
      .getValue()
      .filter((rowData) => {
        return notFilteredParentRowAndChilds.some(
          (rowGroup) =>
            rowGroup.whichVendor === rowData.vendor_id &&
            rowGroup.whichChilds.includes(<string>rowData.activity_name)
        );
      });

    let { wp_ltd_pinned, invoice_ltd_pinned, starting_accrual_pinned, starting_prepaid_pinned } = {
      wp_ltd_pinned: 0,
      invoice_ltd_pinned: 0,
      starting_accrual_pinned: 0,
      starting_prepaid_pinned: 0,
    };

    let {
      ending_accruals_pinned,
      wp_ltd_end_pinned,
      invoice_ltd_end_pinned,
      invoice_unpaid_ltd_end_pinned,
    } = {
      ending_accruals_pinned: 0,
      wp_ltd_end_pinned: 0,
      invoice_ltd_end_pinned: 0,
      invoice_unpaid_ltd_end_pinned: 0,
    };

    // generatePinnedBottomData function takes the parameters below from directly quarter start datat and quarter end data,
    // so i should calculate these variables seperately. The other variables are filtered inside the function.
    changedData.forEach((data) => {
      wp_ltd_pinned += data.wp_ltd;
      invoice_ltd_pinned += data.invoice_ltd;
      starting_accrual_pinned += data.starting_accrual;
      starting_prepaid_pinned += data.starting_prepaid;
      ending_accruals_pinned += data.ending_accruals;
      wp_ltd_end_pinned += data.wp_ltd_end;
      invoice_ltd_end_pinned += data.invoice_ltd_end;
      invoice_unpaid_ltd_end_pinned += data.invoice_unpaid_ltd_end;
    });

    const { obj } = this.periodCloseComponent.generatePinnedBottomData(
      changedData,
      this.periodCloseComponent.currencies.values().next().value
    );

    const finalObj = {
      ...obj,
      wp_ltd_start: wp_ltd_pinned,
      wp_ltd_end: wp_ltd_end_pinned,
      starting_accrual: starting_accrual_pinned,
      starting_prepaid: starting_prepaid_pinned,
      invoice_ltd_start: invoice_ltd_pinned,
      invoice_ltd_end: invoice_ltd_end_pinned,
      invoice_unpaid_ltd_end: invoice_unpaid_ltd_end_pinned,
      ending_accruals: ending_accruals_pinned,
    };

    // period-close will set all bottomRowData objects empty if there are multiple currencies
    if (
      isEmpty(finalObj.eom_accruals) &&
      isEmpty(finalObj.net_accruals) &&
      isEmpty(finalObj.monthly_wp_obj) &&
      isEmpty(finalObj.initial_wp_obj)
    ) {
      this.gridAPI.setGridOption('pinnedBottomRowData', []);
      this.isTotalHidden = true;
    } else {
      this.isTotalHidden = false;
      this.gridAPI.setGridOption('pinnedBottomRowData', [
        merge(
          {
            activity_name: 'Total',
          },
          finalObj
        ),
      ]);
    }
  }

  updateGridOptions(mergedData: MonthCloseTableRowData[]) {
    this.showGrid = false;
    this.changeDetectorRef.detectChanges();

    let columns = [];

    const attributes = calcColumns({
      attributes: mergedData.map((z) => z.attributes || []),
    });

    const attr = attributeColumnDef(attributes, QCAttributesLSKey);
    attr.headerClass = ['attributes-quarterStart-quarterSummary', 'ag-header-align-center'];
    columns = [attr, ...this.defaultColumns];

    const addEmptyColumn = () => {
      columns.push(TableConstants.SPACER_COLUMN);
    };

    this.openMonthFirstColumnId = '';

    (
      this.periodCloseComponent.quartersObj[
        this.periodCloseComponent.selectedQuarter.value || ''
      ] || []
    ).forEach((month, index) => {
      const isAfter = dayjs(this.periodCloseComponent.currentMonth)
        .date(1)
        .isAfter(dayjs(month.iso).date(1));
      const isSame = dayjs(this.periodCloseComponent.currentMonth)
        .date(1)
        .isSame(dayjs(month.iso).date(1));
      const isFuture = !(isSame || isAfter);
      addEmptyColumn();

      const slicedDate = month.iso.slice(0, 7);

      const monthColumn = this.getMonthColumn({
        headerName: slicedDate,
        isSame: isSame,
        isFuture: isFuture,
        rowKey: month.date,
        month: month,
        alternate: index === 1,
      });

      columns.push(monthColumn);
    });
    addEmptyColumn();

    columns.push(
      this.getMonthColumn({
        headerName: `${
          this.periodCloseComponent.selectedQuarter.value?.replace('-', ' ') || ''
        } Summary`,
        isSame: false,
        isFuture: true,
        rowKey: 'total',
        month: null,
        hideTotal: true,
        readOnlyAdjustment: true,
        isSummary: true,
        alternate: true,
      })
    );

    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
      columnDefs: [...columns],
      autoGroupColumnDef: {
        ...this.autoAttributeGroupColumnDef,
        headerComponentParams: {
          ...this.autoAttributeGroupColumnDef.headerComponentParams,
          columnsToCollapse: attr.children.map((x: ColDef) => x.colId || x.field),
        },
      },
    });

    setTimeout(() => {
      this.showGrid = true;
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  onFirstDataRendered({ api }: FirstDataRenderedEvent) {
    const rowNodes: IRowNode[] = [];
    api.forEachNode((rowNode) => rowNodes.push(rowNode));
    first(rowNodes)?.setSelected(true, true);
  }

  onVendorEstimateUploadClick() {
    this.overlayService.open({ content: VendorEstimateUploadComponent });
  }

  refreshRows(): void {
    this.gridAPI?.redrawRows();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure({ offset: this.stickyServiceOffset });
  }

  get currentMonth(): string {
    return this.budgetQuery.getValue().budget_info[0]?.current_month || '';
  }

  private isOpenMonth(): boolean {
    let isSame = false;
    (
      this.periodCloseComponent.quartersObj[
        this.periodCloseComponent.selectedQuarter.value || ''
      ] || []
    ).forEach((month) => {
      if (dayjs(this.periodCloseComponent.currentMonth).date(1).isSame(dayjs(month.iso).date(1))) {
        isSame = true;
      }
    });
    return isSame;
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPLOAD_VENDOR_ESTIMATE],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasUploadVendorEstimatePermission]) => {
        this.userHasUploadVendorEstimatePermission.next(userHasUploadVendorEstimatePermission);
      });
  }

  protected readonly QuarterCloseBannerHeight = QuarterCloseBannerHeight;
}
