import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  computed,
} from '@angular/core';
import { GridApi } from '@ag-grid-community/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import dayjs from 'dayjs';
import { MainQuery } from '@shared/store/main/main.query';
import { EntityType, EventType, GqlService, WorkflowStep } from '@services/gql.service';
import { ExportType } from '@services/utils';
import { OverlayService } from '@services/overlay.service';
import { map } from 'rxjs/operators';
import { AgSetColumnsVisible } from '@shared/utils';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { MessagesConstants } from '@constants/messages.constants';
import { TransactionMode } from '../investigator-transactions.service';

@Component({
  selector: 'aux-investigator-transactions-header',
  templateUrl: './investigator-transactions-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsHeaderComponent {
  @Input({ required: true }) gridAPI!: GridApi;

  @Input({ required: true }) isContractSelected$!: BehaviorSubject<boolean>;

  @Input({ required: true }) totalCost$!: BehaviorSubject<number>;

  @Input() editMode$!: BehaviorSubject<TransactionMode>;

  @Input({ required: true }) lastSourceRefreshDate$!: BehaviorSubject<string>;

  @Input({ required: true }) dataStreamInputs?: Record<string, unknown>;

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  patientTrackerLockedTooltip = computed(() =>
    this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : ''
  );

  @Output() openImportPatientDataModal = new EventEmitter();

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private workflowQuery: WorkflowQuery
  ) {}

  toggleContractCurrency(selected: boolean) {
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['contract_curr', 'contract_amount', 'exchange_rate'],
      visible: selected,
    });
    this.isContractSelected$.next(selected);
    this.gridAPI.sizeColumnsToFit();
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  async onExportInvestigatorTransactions() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.INVESTIGATOR_TRANSACTIONS,
          filename: `${trialName}_auxilius-investigator-transactions__${dateStr}`,
          sort_model: this.dataStreamInputs?.sort_model || [],
          filter_model: this.dataStreamInputs?.filter_model || [],
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  openImportPatientModal = () => {
    this.openImportPatientDataModal.emit();
  };
}
