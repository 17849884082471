import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { NgIf } from '@angular/common';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { Router } from '@angular/router';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

@Component({
  standalone: true,
  template: `
    <ng-container *ngIf="link">
      <div class="aux-link cursor-pointer" (click)="onLinkClicked()">{{ params.displayName }}</div>
    </ng-container>
    <ng-container *ngIf="!link">
      <div>{{ params.displayName }}</div>
    </ng-container>
  `,
  imports: [NgIf, TooltipDirective],
  styles: [],
})
export class AgAdjustmentLinkHeaderComponent implements IHeaderAngularComp {
  params!: IHeaderParams;

  link!: boolean;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private router: Router
  ) {}

  agInit(params: IHeaderParams) {
    this.link = this.launchDarklyService.flags$.getValue().expense_defaults;
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onLinkClicked() {
    const expenseDefaultsUrl = `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.EXPENSE_DEFAULTS}`;
    this.router.navigate([expenseDefaultsUrl]);
  }
}
