<ng-container *ngIf="(periodCloseComponent.loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(periodCloseComponent.loading$ | async) === false">
  <div class="pt-4 pb-6 px-7">
    <div class="flex flex-col items-center mb-4 px-7">
      <div class="flex self-end space-x-4 text-sm items-center">
        <button
          *ngIf="showRequestJournalEntryButton$ | async"
          class="btn--secondary pl-3"
          data-pendo-id="request-journal-entry-report"
          type="button"
          (click)="onRequestJournalEntryReport()"
        >
          <aux-icon name="Message" />
          <span class="ml-2">Request Journal Entry Report</span>
        </button>
        <button
          class="btn--secondary pl-3"
          type="button"
          [disabled]="(isDownloadVendorEstimateButtonDisabled$ | async) !== false"
          (click)="onDownloadVendorEstimates()"
        >
          <aux-icon name="Download" />
          <span class="ml-2">Download Vendor Estimate(s)</span>
        </button>

        <button
          type="button"
          class="btn--secondary pl-3"
          (click)="onAddVendorEstimateUploadClick()"
        >
          <aux-icon name="Send" />
          <span class="ml-2">Send Vendor Estimate Support</span>
        </button>

        <aux-export-excel-button
          [gridAPI]="gridAPI"
          [excelOptions]="excelOptions"
          [getDynamicExcelParamsCallback]="getDynamicExcelParams"
          pendoTag="quarter-close-excel-export-button"
        />
        <div>
          <aux-menu
            #forecast_down_up_load_menu
            [showMenuForExternal]="userHasUploadVendorEstimatePermission.getValue()"
          >
            <button
              type="button"
              role="menuitem"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
              (click)="onVendorEstimateUploadClick(); forecast_down_up_load_menu.close()"
            >
              <aux-icon name="Upload" />
              <span class="ml-2">Upload Vendor Estimate</span>
            </button>
          </aux-menu>
        </div>
      </div>
    </div>

    <div *ngIf="showGrid" class="w-full">
      <ag-grid-angular
        class="ag-theme-aux tabular-nums quarter-close-table w-full"
        domLayout="autoHeight"
        [rowData]="periodCloseComponent.gridDataMergedDiscount$ | async"
        [gridOptions]="$any(gridOptions$ | async)"
        [components]="components"
        [postProcessPopup]="postProcessPopup"
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (modelUpdated)="refreshRows()"
        (viewportChanged)="onWindowScroll()"
        (filterChanged)="onFilterChanged($event)"
      />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="shouldShowBanner$ | async">
  <!-- empty div for banner-->
  <div [ngStyle]="{ height: QuarterCloseBannerHeight + 'px' }"></div>
  <aux-quarter-close-banner
    [disabled]="(isCloseMonthAvailable$ | async) === false"
    [currentMonth]="currentMonth"
    (closeMonth)="onInMonthClose()"
    [hasClosePermission]="!!(userHasClosingPermission$ | async)"
  />
</ng-container>
