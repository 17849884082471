import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ColDef,
  ExcelExportParams,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { compactGridOptions } from './compact-grid-options';
import { checkboxGridOptions } from './checkbox-grid-option';
import { gridOptions } from './grid-options';
import { compactGridData, checkboxGridData, gridData } from './grid-data';
import { rowData } from './trial-insights-data';
import { SortOrder, UserAuditLog } from '@services/gql.service';
import { TrialInsightsClinicalSiteCostChartService } from '../../trial-insights/components/trial-insights-clinical-site-cost/site-cost-chart.service';
import { TrialInsightsClinicalPatientCostQueryService } from '../../trial-insights/components/trial-insights-clinical-patient-cost/patient-cost-query.service';
import { TrialInsightsClinicalPatientCostTableService } from '../../trial-insights/components/trial-insights-clinical-patient-cost/patient-cost-table.service';
import { TrialInsightsPatientCostKey } from '../../trial-insights/models/trial-insights-table.model';
import { createLegend as trialInsightsClinicalCreateLegend } from '../../trial-insights/components/trial-insights-clinical-timeline/trial-insights-clinical-timeline-chart';
import { FormBuilder } from '@angular/forms';
import {
  ServerSideFilterInfo,
  ServerSideColumnFilterType,
  ServerSideSortOrder,
  AuxExcelStyleKeys,
  AuxExcelStyles,
} from '@shared/utils';
import { BehaviorSubject, skip } from 'rxjs';
import { DatasourceService } from '@shared/services/datasource.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Utils } from '@services/utils';
import { MainQuery } from '@shared/store/main/main.query';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { ApiService } from '@services/api.service';

@UntilDestroy()
@Component({
  selector: 'aux-tables',
  templateUrl: './tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TrialInsightsClinicalSiteCostChartService,
    TrialInsightsClinicalPatientCostQueryService,
    TrialInsightsClinicalPatientCostTableService,
  ],
})
export class TablesComponent {
  readonly datasource = this.datasourceService.userAuditLogDatasource;

  private paginationColumnDefs: ColDef[] = [
    {
      headerName: 'Action Text',
      headerClass: 'ag-header-align-center font-bold',
      field: 'action_text',
      cellClass: 'text-left cell-align-center',
    },
    {
      headerName: 'Category',
      headerClass: 'ag-header-align-center font-bold',
      field: 'category',
      cellClass: 'text-left cell-align-center',
    },
    {
      headerName: 'Date Uploaded',
      headerClass: 'ag-header-align-center font-bold',
      field: 'create_date',
      cellClass: 'text-right cell-align-center',
      maxWidth: 180,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toLocaleString() : Utils.zeroHyphen,
    },
    {
      headerName: 'Uploaded by',
      headerClass: 'ag-header-align-center font-bold',
      field: 'entity_type',
      cellClass: 'text-left cell-align-center',
    },
  ];

  gridOptions$ = new BehaviorSubject<GridOptions>({
    columnDefs: this.paginationColumnDefs,
    onModelUpdated: (event) => {
      if (event.api.getDisplayedRowCount() === 0) {
        event.api.showNoRowsOverlay();
      } else {
        event.api.hideOverlay();
      }
    },
  });

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  sortModel$ = new BehaviorSubject<Array<ServerSideSortOrder<UserAuditLog>>>([]);

  trialInsightsClinicalCreateLegend = trialInsightsClinicalCreateLegend;

  exportGridOptions$ = new BehaviorSubject<GridOptions>({
    columnDefs: this.paginationColumnDefs,
    excelStyles: [
      ...AuxExcelStyles.filter((s) =>
        (
          [
            AuxExcelStyleKeys.HEADER,
            AuxExcelStyleKeys.FIRST_ROW,
            AuxExcelStyleKeys.CELL_RIGHT,
          ] as string[]
        ).includes(s.id)
      ),
      {
        id: 'cell',
        alignment: { horizontal: 'Left' },
        font: { fontName: 'Arial', size: 11 },
      },
    ],
    onGridReady: (event: GridReadyEvent) => {
      this.exportGridApi = event.api;
    },
  });

  exportGridApi!: GridApi;

  exporting$ = new BehaviorSubject(false);

  constructor(
    public trialInsightsSiteChartService: TrialInsightsClinicalSiteCostChartService,
    private trialInsightsPatientTableService: TrialInsightsClinicalPatientCostTableService,
    private formBuilder: FormBuilder,
    private datasourceService: DatasourceService,
    private mainQuery: MainQuery,
    private authQuery: AuthQuery,
    private apiService: ApiService
  ) {}

  compactGridOptions = compactGridOptions;

  compactGridData = compactGridData;

  checkboxGridOptions = checkboxGridOptions;

  checkboxGridData = checkboxGridData;

  gridOptions = gridOptions;

  gridData = gridData;

  tableOptions = this.trialInsightsPatientTableService.createTable(
    TrialInsightsPatientCostKey.SITE,
    rowData
  );

  isLoadingRemaining = false;

  selectedKey = TrialInsightsPatientCostKey.SITE;

  sortOrder = SortOrder.DESC;

  changeSelectedKey = () => {};

  toggleSortOrder = () => {};

  onDataRendered(e: FirstDataRenderedEvent) {
    const gridAPI = e.api;
    gridAPI?.setGridOption('pinnedBottomRowData', [e.lastRow]);
  }

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((_, i) => i + 1);
  }

  serverSideFilters: ServerSideFilterInfo<UserAuditLog>[] = [
    {
      column: 'create_date',
      type: ServerSideColumnFilterType.IsGreaterThanOrEqualTo,
      inputPropertyName: 'afterDate',
    },
    {
      column: 'action_text',
      type: ServerSideColumnFilterType.Contains,
      inputPropertyName: 'actionSearch',
      transformFunction: (v: unknown) => (typeof v === 'string' ? v.trim() : v),
    },
  ];

  gridFiltersFormGroup = this.formBuilder.group(
    this.serverSideFilters.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.inputPropertyName]: [],
      }),
      {}
    )
  );

  gridReady() {
    this.datasource.initialize({
      untilDestroyedPipeOperator: untilDestroyed(this),
      filters: this.serverSideFilters,
      filterValues$: this.filterValues$,
      sortModel$: this.sortModel$,
    });

    this.datasource.refresh$.pipe(untilDestroyed(this), skip(1)).subscribe(() => {
      this.generateCsvFile().catch((err) => console.error('Failed to generate the csv file', err));
    });
  }

  getDynamicExcelParams = async (): Promise<ExcelExportParams> => {
    this.exporting$.next(true);

    const data = await this.datasource.downloadAll();

    this.exportGridApi.getColumns()?.forEach((col) => col.setSort(null));

    this.exportGridApi.setGridOption('rowData', data.items);

    const columnOptionsLookup = (<ColDef[]>[
      {
        colId: 'action_text',
        width: 180,
      },
      {
        colId: 'category',
        width: 300,
      },
      {
        colId: 'create_date',
      },
      {
        colId: 'entity_type',
      },
    ]).reduce(
      (acc, curr) => ({ ...acc, [curr.colId as string]: curr }),
      {} as Record<string, ColDef>
    );

    return {
      author: 'Auxilius',
      fontSize: 11,
      sheetName: 'Document Library',
      fileName: 'auxilius-design-system-test.xlsx',
      columnWidth(params) {
        const columnOptions = columnOptionsLookup[params.column?.getColId() || ''];
        if (Number.isFinite(columnOptions?.width)) {
          return columnOptions.width;
        }
        return 225;
      },
      processCellCallback: (params): string => {
        const columnOptions = columnOptionsLookup[params.column?.getColId() || ''];
        if (typeof columnOptions?.valueFormatter === 'function') {
          return columnOptions.valueFormatter(params.value);
        }
        return params.value;
      },
      prependContent: [
        {
          cells: [
            {
              data: {
                value: `Trial: ${this.mainQuery.getSelectedTrial()?.short_name || ''}`,
                type: 'String',
              },
              mergeAcross: 3,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: [
        {
          cells: [
            {
              data: {
                value: `User: ${this.authQuery.getValue()?.email || ''}`,
                type: 'String',
              },
              mergeAcross: 3,
              styleId: 'cell-right',
            },
          ],
        },
      ],
      //
    } as ExcelExportParams;
  };

  onExportSuccess = (): void => {
    this.exportGridApi.setGridOption('rowData', []);
    this.exporting$.next(false);
  };

  onExportCsvClick = () => {
    // ? To apply filter before exporting
    // this.filterValues$.next({
    //   afterDate: convertLocalDateToIsoTimestamp('2023-09-01'),
    // });

    this.sortModel$.next([
      {
        column: 'create_date',
        descending: true,
      },
    ]);

    this.datasource.forceRefresh();
  };

  async generateCsvFile() {
    this.exporting$.next(true);
    try {
      console.log('Downloading all data for input', this.datasource.currentServerInput);
      const data = await this.datasource.downloadAll();
      const csv = Utils.objectToCsv(data.items);
      const blob = new Blob([csv]);
      this.apiService.downloadBlob(blob, 'audit-logs.csv');
    } finally {
      this.exporting$.next(false);
    }
  }
}
