import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { AuthService } from '@shared/store/auth/auth.service';
import { OverlayService } from '@services/overlay.service';
import { AppInitService } from '@services/app-init.service';
import { EntityType, EventType, GqlService } from '@services/gql.service';
import { CustomValidators } from '@components/form-inputs/custom-validators';
import { MessagesConstants } from '@constants/messages.constants';
import { firstValueFrom } from 'rxjs';
import { resetPassword, confirmResetPassword } from '@aws-amplify/auth';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective | undefined;

  public year = new Date().getFullYear();

  loginLink = `/${ROUTING_PATH.LOGIN}`;

  errorMessage = '';

  successMessage = '';

  forgotForm = this.fb.group({
    email: ['', [Validators.required, CustomValidators.emailValidator()]],
  });

  newPasswordForm = this.fb.group({
    email: ['', [Validators.required, CustomValidators.emailValidator()]],
    code: ['', [Validators.required]],
    password: [
      '',
      [
        Validators.required,
        CustomValidators.mustHaveLowercaseCharacter(),
        CustomValidators.mustHaveUppercaseCharacter(),
        CustomValidators.mustHaveNumber(),
        Validators.minLength(8),
      ],
    ],
  });

  showSecondForm = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private overlayService: OverlayService,
    public appInitService: AppInitService,
    private changeDetector: ChangeDetectorRef,
    private gqlService: GqlService
  ) {}

  async forgotPassword() {
    if (this.forgotForm.valid) {
      this.successMessage = '';
      this.errorMessage = '';
      const username = this.forgotForm.get('email')?.value;
      await resetPassword({
        username,
      });
      this.successMessage = MessagesConstants.FORGOT_PASSWORD.EMAIL_HAS_BEEN_SEND;
      this.showSecondForm = true;
      this.newPasswordForm.get('email')?.setValue(this.forgotForm.get('email')?.value);
      this.changeDetector.detectChanges();
    }
  }

  async newPasswordSubmit() {
    if (this.newPasswordForm.valid) {
      this.successMessage = '';
      this.errorMessage = '';
      const {
        email: username,
        code: confirmationCode,
        password: newPassword,
      } = this.newPasswordForm.value;
      try {
        await confirmResetPassword({
          username,
          newPassword,
          confirmationCode,
        });
      } catch (e) {
        if ((e as { name: string })?.name === 'InvalidPasswordException') {
          this.errorMessage = 'Invalid password';
          return;
        }
        console.error(e);
      }

      this.overlayService.success(MessagesConstants.FORGOT_PASSWORD.PASSWORD_RESET_SUCCESSFUL);
      await this.authService.signIn(username, newPassword);
      const userSet = await this.authService.setUserAttributes();
      await firstValueFrom(
        this.gqlService.processEvent$({
          type: EventType.USER_PASSWORD_UPDATED,
          entity_type: EntityType.USER,
          entity_id: '',
          payload: JSON.stringify({
            password_changed_for: `${userSet.email}`,
          }),
        })
      );
      await this.router.navigateByUrl('/');
    }
  }
}
