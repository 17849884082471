import { Injectable } from '@angular/core';
import { listSitesQuery } from '@services/gql.service';
import { tap } from 'rxjs/operators';
import { first } from 'lodash-es';
import { Utils } from '@services/utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { PaymentSchedulesState } from '@models/payment-schedules/payment-schedules.store';
import { SitesService } from '@models/sites/sites.service';
import { convertFilterToMap, mapSiteToSiteOption } from '@shared/utils';
import { SiteOption } from '@models/site-option.model';

@Injectable({ providedIn: 'root' })
export class PatientTrackerService {
  sitePaymentScheduleCache = new Map<string, PaymentSchedulesState[]>();

  siteOptions$ = new BehaviorSubject<SiteOption[]>([]);

  siteMap = new Map<string, listSitesQuery>();

  investigatorMap = new Map<string, string>();

  constructor(private sitesService: SitesService) {}
  getSiteOptions(): Observable<[GraphqlResponse<Array<listSitesQuery>>]> {
    return combineLatest([this.sitesService.get()]).pipe(
      tap(([siteList]) => {
        this.siteMap = convertFilterToMap<listSitesQuery>(siteList?.data || []);
        this.investigatorMap = new Map(
          (siteList?.data || []).map(({ id }) => [id, this.getPrincipalInvestigatorName(id)])
        );

        this.siteOptions$.next(
          (siteList.data || [])
            .sort(({ site_no }, { site_no: site_no2 }) =>
              Utils.localeAlphaNumSort(site_no, site_no2)
            )
            .map((site: listSitesQuery) =>
              mapSiteToSiteOption(site, this.getPrincipalInvestigatorName(site.id))
            )
        );
      })
    );
  }

  private getPrincipalInvestigatorName(siteId: string): string {
    const principalInvestigator = first(this.siteMap.get(siteId)?.contacts);

    return principalInvestigator
      ? `${principalInvestigator.given_name} ${principalInvestigator.family_name}`.trim()
      : '';
  }
}
