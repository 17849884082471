import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { listTimelineMilestonesQuery } from '@services/gql.service';
import { MilestoneCategoryQuery } from '@models/milestone-category/milestone-category.query';
import { MilestoneQuery } from '@models/milestone-category/milestone/milestone.query';
import { MilestoneModel } from '@models/milestone-category/milestone/milestone.store';

import { map, startWith } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';

import { InputComponent } from '@components/form-inputs/input/input.component';
import {
  TimelineDialogDependencyFn,
  TimelineDialogDependencyGraphFn,
  TimelineDialogFormValue,
} from './timeline-dialog.model';
import { TimelineService, UpdateDependencyInput } from '../state/timeline.service';
import { TimelineQuery } from '../state/timeline.query';
import { TimelineDependencyComponent } from '../timeline-dependency/timeline-dependency.component';
import { MessagesConstants } from '@constants/messages.constants';
import dayjs from 'dayjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from '@directives/directives.module';
import { AsyncPipe, NgForOf } from '@angular/common';

@Component({
  selector: 'aux-timeline-dialog',
  templateUrl: './timeline-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    DirectivesModule,
    NgForOf,
    AsyncPipe,
    InputComponent,
  ],
})
export class TimelineDialogComponent {
  customErrorMessages = {
    startDate: () => {
      const str = dayjs(this.ref.data?.startDate).endOf('month').format('MM/DD/YYYY');
      return `Trial Timeline cannot be shifted after ${str}`;
    },
    endDate: () => {
      const str = dayjs(this.ref.data?.endDate).format('MM/01/YYYY');
      return `Trial Timeline cannot be shifted before ${str}`;
    },
  };
  mode: 'edit' | 'add' = 'edit';

  forbiddenNames: string[] = [];

  @ViewChild('name') milestoneNameInput!: InputComponent;

  startDateValidators: ValidatorFn[] = [
    Validators.required,
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value == null) {
        return null;
      }

      let isInvalid = false;

      if (this.ref.data?.startDate) {
        isInvalid = dayjs(value).startOf('month').isAfter(this.ref.data.startDate);
      }

      return isInvalid ? { startDate: true } : null;
    },
  ];

  endDateValidators: ValidatorFn[] = [
    Validators.required,
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value == null) {
        return null;
      }

      let isInvalid = false;

      if (this.ref.data?.endDate) {
        isInvalid = dayjs(value).startOf('month').isBefore(this.ref.data.endDate);
      }

      return isInvalid ? { endDate: true } : null;
    },
  ];

  addTimelineMilestoneForm = this.formBuilder.group({
    id: null,
    milestone_id: null,
    actual_end_date: null,
    actual_start_date: null,
    name: '',
    contract_end_date: '',
    contract_start_date: '',
    revised_start_date: null,
    revised_end_date: null,
    description: '',
    milestone_category_id: ['', Validators.required],
    track_from_milestone_id: null,
  });

  trackFromMilestones$: Observable<MilestoneModel[]> =
    this.addTimelineMilestoneForm.valueChanges.pipe(
      startWith(this.addTimelineMilestoneForm.value as TimelineDialogFormValue),
      map(() => {
        const value: TimelineDialogFormValue = this.addTimelineMilestoneForm.getRawValue();
        if (value.contract_start_date) {
          return this.timelineQuery
            .getValue()
            .items.filter((x) => {
              return (
                new Date(x.contract_end_date) <= new Date(value.contract_start_date) &&
                x.milestone.id !== value.milestone_id
              );
            })
            .map((x) => this.milestoneQuery.getEntity(x.milestone.id)) as MilestoneModel[];
        }

        return [];
      })
    );

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: CustomOverlayRef<
      unknown,
      {
        mode: 'edit' | 'add';
        forbiddenNames?: string[];
        formValue?: TimelineDialogFormValue;
        timeline_milestone_id?: string;
        milestone_id?: string;
        startDate: string | null;
        endDate: string | null;
      }
    >,
    private overlayService: OverlayService,
    private timelineService: TimelineService,
    public milestoneCategoryQuery: MilestoneCategoryQuery,
    public milestoneQuery: MilestoneQuery,
    private timelineQuery: TimelineQuery
  ) {
    if (this.ref.data?.mode) {
      this.mode = this.ref.data.mode;
    }

    if (this.ref.data?.formValue) {
      this.addTimelineMilestoneForm.patchValue(this.ref.data?.formValue);
    }

    if (this.ref.data?.forbiddenNames) {
      this.forbiddenNames = this.ref.data.forbiddenNames;
    }
  }

  async onSubmit() {
    if (this.addTimelineMilestoneForm.valid) {
      const data: TimelineDialogFormValue = this.addTimelineMilestoneForm.value;
      if (data.contract_end_date < data.contract_start_date) {
        this.overlayService.error(MessagesConstants.START_DATE_BEFORE_END);
        return;
      }
      if (this.forbiddenNames.includes(data.name.toString())) {
        this.milestoneNameInput.fc.setErrors({ duplicatedMilestoneNames: true });
        return;
      }
      if (
        this.mode === 'edit' &&
        this.ref.data?.timeline_milestone_id &&
        this.ref.data.milestone_id
      ) {
        const { milestone_id, timeline_milestone_id, formValue } = this.ref.data;
        const updateDependencyInputs: UpdateDependencyInput[] = [];
        if (formValue) {
          const arr = (['revised', 'contract', 'actual'] as const).map((str) => ({
            bool: data[`${str}_end_date`] !== formValue[`${str}_end_date`],
            name: str,
          }));

          let { items } = this.timelineQuery.getValue();

          // Find the milestone that this milestone
          // has selected to track from
          const tracked_milestone = items.find(
            (x) => x.milestone.id === data.track_from_milestone_id
          );

          // Find the milestones (dependent milestones)
          // that are tracking directly or indirectly from this milestone
          const dependencyGraph = this.createDependencyGraph(milestone_id, items);

          // This milestone cannot track from a milestone that
          // it's already being tracked from (dependent milestones)
          if (tracked_milestone && dependencyGraph.includes(tracked_milestone.milestone.id)) {
            const formControls = this.addTimelineMilestoneForm.controls;
            formControls.track_from_milestone_id.setErrors({ milestonesTrackEachOther: true });

            return;
          }

          // Confirm tracked-from milestone dates (dependent milestones)
          if (tracked_milestone && data.contract_start_date < tracked_milestone.contract_end_date) {
            this.overlayService.error('Start Date must be after tracked milestone End Date');
            return;
          }

          // Find the dependent milestones that are
          // tracking (directly only) from this milestone
          items = items.filter((x) => x.track_from_milestone?.id === milestone_id);

          // Update dependent milestones
          if (items.length > 0 && arr.some((x) => x.bool)) {
            for (const { name, bool } of arr) {
              if (bool) {
                // eslint-disable-next-line no-await-in-loop
                const response = await firstValueFrom(
                  this.overlayService.open<
                    UpdateDependencyInput[],
                    {
                      oldFormValue: TimelineDialogFormValue;
                      newFormValue: TimelineDialogFormValue;
                      id: string;
                      milestone_id: string;
                      field_name: string;
                    }
                  >({
                    content: TimelineDependencyComponent,
                    data: {
                      oldFormValue: formValue,
                      newFormValue: data,
                      id: timeline_milestone_id,
                      milestone_id,
                      field_name: name,
                    },
                  }).afterClosed$
                );
                if (response.data) {
                  updateDependencyInputs.push(...response.data);
                } else {
                  return;
                }
              }
            }
          }
        }

        const { success } = await this.timelineService.updateTimelineMilestone({
          ...data,
          id: timeline_milestone_id,
          milestone_id,
        });
        if (success) {
          this.ref.close({
            update: {
              ...data,
              id: timeline_milestone_id,
              milestone_id,
            },
            updateDependency: updateDependencyInputs,
          });
        }
      } else {
        const { success, createdMilestoneId, milestoneId } =
          await this.timelineService.createTimelineMilestone(data);
        if (success) {
          this.ref.close({ ...data, id: createdMilestoneId, milestone_id: milestoneId });
        }
      }
    }
  }

  onCancel() {
    this.ref.close();
  }

  // Prevents milestones tracking from other milestones that
  // they're already directly or indirectly tracked from
  createDependencyGraph: TimelineDialogDependencyGraphFn = (
    initialMilestoneId: string,
    timelineItems: listTimelineMilestonesQuery[]
  ) => {
    // Store dependent milestone ids
    const dependentIds: string[] = [];

    // Recursive function to find directly and
    // indirectly dependent milestones
    const findDependentIds: TimelineDialogDependencyFn = (milestoneId: string) => {
      const dependentItems = timelineItems.filter(
        (item) => item.track_from_milestone?.id === milestoneId
      );

      if (!dependentItems.length) {
        return;
      }

      dependentItems.forEach((item) => {
        const id = item.milestone.id;

        if (!dependentIds.includes(id)) {
          dependentIds.push(id);
          findDependentIds(id);
        }
      });
    };

    // Find all dependent milestone ids
    findDependentIds(initialMilestoneId);

    // Return dependent milestone ids
    return dependentIds;
  };
}
