function measure(target, propertyKey, descriptor) {
  const originalMethod = descriptor.value;

  descriptor.value = function () {
    const start = performance.now();

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    const result = originalMethod.apply(this, args);
    const end = performance.now();
    console.log("Call to ".concat(propertyKey, " took ").concat((end - start).toFixed(2), " milliseconds."));
    return result;
  };

  return descriptor;
}

function delay() {
  let milliseconds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      setTimeout(() => {
        originalMethod.apply(this, args);
      }, milliseconds);
    };

    return descriptor;
  };
}

const debounceFn = require('lodash.debounce');

function debounce() {
  let milliseconds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return function (target, propertyKey, descriptor) {
    const map = new WeakMap();
    const originalMethod = descriptor.value;

    descriptor.value = function () {
      let debounced = map.get(this);

      if (!debounced) {
        debounced = debounceFn(originalMethod, milliseconds, options).bind(this);
        map.set(this, debounced);
      }

      debounced(...arguments);
    };

    return descriptor;
  };
}

const throttleFn = require('lodash.throttle');

function throttle() {
  let milliseconds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = throttleFn(originalMethod, milliseconds, options);
    return descriptor;
  };
}

const onceFn = require('lodash.once');

function once(target, propertyKey, descriptor) {
  const originalMethod = descriptor.value;
  descriptor.value = onceFn(originalMethod);
  return descriptor;
}

function Mixin(baseCtors) {
  return function (derivedCtor) {
    baseCtors.forEach(baseCtor => {
      Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
        derivedCtor.prototype[name] = baseCtor.prototype[name];
      });
    });
  };
}

const memoizeFn = require('lodash.memoize');

function memo(resolver) {
  return function (target, propertyKey, descriptor) {
    descriptor.value = memoizeFn(descriptor.value, resolver);
    return descriptor;
  };
}

/**
 *
 * Credit: https://github.com/andreypopp/autobind-decorator/blob/master/src/index.js
 * Return a descriptor removing the value and returning a getter
 * The getter will return a .bind version of the function
 * and memoize the result against a symbol on the instance
 */
function bind(target, key, descriptor) {
  let fn = descriptor.value;

  if (typeof fn !== 'function') {
    throw new Error("@autobind decorator can only be applied to methods not: ".concat(typeof fn));
  } // In IE11 calling Object.defineProperty has a side-effect of evaluating the
  // getter for the property which is being replaced. This causes infinite
  // recursion and an "Out of stack space" error.


  let definingProperty = false;
  return {
    configurable: true,

    get() {
      if (definingProperty || this === target.prototype || this.hasOwnProperty(key) || typeof fn !== 'function') {
        return fn;
      }

      let boundFn = fn.bind(this);
      definingProperty = true;
      Object.defineProperty(this, key, {
        configurable: true,

        get() {
          return boundFn;
        },

        set(value) {
          fn = value;
          delete this[key];
        }

      });
      definingProperty = false;
      return boundFn;
    },

    set(value) {
      fn = value;
    }

  };
}

const sortFunc = (a, b) => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  }

  return 0;
};
/**
  * Sort array by a specific element property, its value type must be one of string, number and date
  * @param {string | undefined} sortByProperty specify a property from each element that sorting will be based on, undefined means sorty by element itself
  * @param {
      isDescending: boolean;
      sortByPropertyType: string;
    } options 
  * @returns script version
  */


function SortBy(sortByProperty) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    isDescending: true,
    type: 'string'
  };
  const cachedValueKey = Symbol();
  return function (target, propertyKey, descriptor) {
    Object.defineProperty(target, propertyKey, {
      set: function set(arr) {
        if (!arr || !Array.isArray(arr)) {
          throw "Value of property ".concat(propertyKey, " is not a valid array!");
        } // Perform sorting logic


        const isDateType = options.type === 'date';

        if (sortByProperty) {
          this[cachedValueKey] = arr.sort(function (a, b) {
            const aValue = isDateType ? new Date(a[sortByProperty]) : a[sortByProperty];
            const bValue = isDateType ? new Date(b[sortByProperty]) : b[sortByProperty];
            const sortResult = sortFunc(aValue, bValue);
            return options.isDescending ? sortResult * -1 : sortResult;
          });
        } else {
          this[cachedValueKey] = arr.sort(function (a, b) {
            const aValue = isDateType ? new Date(a) : a;
            const bValue = isDateType ? new Date(b) : b;
            const sortResult = sortFunc(aValue, bValue);
            return options.isDescending ? sortResult * -1 : sortResult;
          });
        }
      },
      get: function get() {
        return this[cachedValueKey];
      }
    });
  };
}

export { Mixin, SortBy, bind, debounce, delay, measure, memo, once, throttle };
