import { HeaderClassParams, GridOptions } from '@ag-grid-community/core';
import { TableConstants } from '@constants/table.constants';
import { Currency } from '@services/gql.service';
import { Utils } from '@services/utils';

export interface GridRowData {
  id: string;
  name: string;
  amount: number;
  note: string;
  paymentScheduleId: string | null;
  currency: Currency;
}

interface Params {
  getEditableHeaderClasses: (
    cellClasses: string[]
  ) => (cellClassParams: HeaderClassParams) => string[];
  currency: Currency;
}

export const getSiteBudgetGridAmountOptions = (
  editMode: boolean,
  { getEditableHeaderClasses, currency }: Params
): GridOptions<GridRowData> => ({
  ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
  ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  suppressMenuHide: true,
  fillHandleDirection: 'y',
  defaultColDef: {
    ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    editable: editMode,
  },
  columnDefs: [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'paymentScheduleId',
      hide: true,
    },
    {
      field: 'currency',
      hide: true,
    },
    {
      headerName: 'Item',
      field: 'name',
      minWidth: 120,
      editable: false,
      tooltipField: 'name',
    },
    {
      headerValueGetter: () => `Amount (${currency})`,
      field: 'amount',
      valueFormatter: (params) => Utils.agCurrencyFormatter(params, currency || Currency.USD),
      headerClass: getEditableHeaderClasses(['ag-header-cell']),
      editable: editMode,
      minWidth: 90,
    },
    {
      headerName: 'Notes',
      field: 'note',
      minWidth: 200,
      headerClass: getEditableHeaderClasses(['ag-header-cell']),
      editable: editMode,
      tooltipField: 'note',
    },
  ],
});
