import { Flags } from './launch-darkly.service';
import { Route } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';

export type SideNavigationFeatureFlags = Pick<
  Flags,
  | 'nav_home'
  | 'nav_trial_insights'
  | 'nav_budget'
  | 'nav_forecast'
  | 'tab_forecast_in_month'
  | 'nav_investigator'
  | 'nav_invoices'
  | 'tab_invoices'
  | 'tab_purchase_orders'
  | 'tab_payment_milestones'
  | 'tab_vendors'
  | 'tab_budget_payment_schedule'
  | 'tab_compare_scenario_manager'
  | 'nav_risk'
  | 'nav_audit_history'
  | 'nav_design_system'
  | 'nav_ops_admin'
  | 'nav_document_library'
>;

export type PriorityRouteConfig = {
  isActive: boolean;
  redirectTo: string;
};

const routesByPrior: { featureFlag: keyof SideNavigationFeatureFlags | null; path: string }[] = [
  {
    path: ROUTING_PATH.DASHBOARD,
    featureFlag: 'nav_home',
  },
  {
    path: ROUTING_PATH.TRIAL_INSIGHTS.INDEX,
    featureFlag: 'nav_trial_insights',
  },
  {
    path: ROUTING_PATH.BUDGET.INDEX,
    featureFlag: 'nav_budget',
  },
  {
    path: ROUTING_PATH.FORECAST_ROUTING.INDEX,
    featureFlag: 'nav_forecast',
  },
  {
    path: ROUTING_PATH.CLOSING.INDEX,
    featureFlag: 'tab_forecast_in_month',
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.INDEX,
    featureFlag: 'nav_investigator',
  },
  {
    path: ROUTING_PATH.VENDOR_PAYMENTS.INDEX,
    featureFlag: 'nav_invoices',
  },
  {
    path: ROUTING_PATH.MANAGER,
    featureFlag: 'nav_home',
  },
  {
    path: ROUTING_PATH.RISK_ANALYTICS,
    featureFlag: 'nav_risk',
  },
  {
    path: ROUTING_PATH.DOCUMENTS,
    featureFlag: 'nav_document_library',
  },
  {
    path: ROUTING_PATH.AUDIT_HISTORY,
    featureFlag: 'nav_audit_history',
  },
  {
    path: ROUTING_PATH.SETTINGS.INDEX,
    featureFlag: null,
  },
  {
    path: ROUTING_PATH.DESIGN_SYSTEM,
    featureFlag: 'nav_design_system',
  },
  {
    path: ROUTING_PATH.OPS_ADMIN.INDEX,
    featureFlag: 'nav_ops_admin',
  },
];

const findFirstAvailableRouteToRedirect = (
  routeFlag: keyof SideNavigationFeatureFlags,
  flags: Flags,
  descCompare?: boolean
): string => {
  const currentRoutePrior = routesByPrior.findIndex(({ featureFlag }) => featureFlag === routeFlag);

  if (currentRoutePrior === -1) {
    return ROUTING_PATH.DASHBOARD;
  }

  for (let index = 0; index < routesByPrior.length; index++) {
    const route = routesByPrior[index];

    const condition = descCompare ? index < currentRoutePrior : index > currentRoutePrior;

    if (!route.featureFlag && condition) {
      return route.path;
    }

    const isEnabledRoute = route.featureFlag ? flags[route.featureFlag] : false;

    if (condition && isEnabledRoute) {
      return route.path;
    } else if (index === routesByPrior.length - 1 && !isEnabledRoute) {
      return findFirstAvailableRouteToRedirect(routeFlag, flags, true);
    }
  }

  return ROUTING_PATH.DASHBOARD;
};

export const redirectNestingRoutesByFeatureFlag = (
  enabledRoutes: Route[],
  allRoutes: Route[],
  url: string,
  redirectStrategy: {
    firstPriorRoute: PriorityRouteConfig;
    secondPriorRoute: PriorityRouteConfig;
  }
): Route => {
  const routeForNoneOption = redirectStrategy.firstPriorRoute.isActive
    ? { path: '**', redirectTo: redirectStrategy.firstPriorRoute.redirectTo }
    : redirectStrategy.secondPriorRoute.isActive
      ? { path: '**', redirectTo: redirectStrategy.secondPriorRoute.redirectTo }
      : { path: '**', redirectTo: `/` };

  const splittedUrl = url.split('/');
  if (!url || !enabledRoutes.length || !allRoutes.length || splittedUrl.length !== 3) {
    return routeForNoneOption;
  }
  console.log(url);

  const currentNestingUrl = splittedUrl[2].includes('?trial=')
    ? splittedUrl[2].split('?trial=')[0]
    : splittedUrl[2];

  const previousRouteIndex = allRoutes.findIndex((route) => route.path === currentNestingUrl);

  const nextAvailableRoutes = allRoutes.filter(
    (routes, index) =>
      index > previousRouteIndex && enabledRoutes.find((route) => route.path === routes.path)
  );

  return nextAvailableRoutes.length
    ? {
        path: '**',
        redirectTo: nextAvailableRoutes[0].path,
      }
    : {
        path: '**',
        redirectTo: enabledRoutes[0].path,
      };
};

export const reflectOnFeatureFlagChange = (
  featureFlag: keyof SideNavigationFeatureFlags,
  featureFlags: Flags | null,
  primaryRoute: string,
  callback: (featureFlags: Flags | null, lastUrl?: string) => Route,
  lastUrl?: string
): Route => {
  if (!featureFlags) {
    return callback(featureFlags, lastUrl);
  }

  const isPageEnabled = !!(featureFlags || {})[featureFlag];

  if (!isPageEnabled) {
    const redirectTo = findFirstAvailableRouteToRedirect(featureFlag, featureFlags);
    return {
      path: primaryRoute,
      redirectTo: `/${redirectTo}`,
    };
  }

  return callback(featureFlags, lastUrl);
};
