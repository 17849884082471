<div class="flex items-start filters flex-wrap gap-4" [formGroup]="form">
  <div class="max-w-sm w-full">
    <div class="h-5 mb-1"></div>
    <aux-input placeholder="Search" formControlName="search" icon="Search" class="w-full" />
  </div>
  <div>
    <span class="text-xs mb-1">Document Type:</span>
    <ng-select
      class="w-60 tabular-nums"
      placeholder="All"
      formControlName="documentTypes"
      bindValue="value"
      [multiple]="true"
      [searchable]="true"
      [items]="documentTypeOptions"
      (change)="onFilterChange()"
    >
      <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        <div *ngIf="items.length === 1" class="ng-value">
          <div *ngFor="let item of items | slice: 0 : 1" class="flex">
            <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
            <span class="ng-value-label overflow-hidden text-ellipsis">{{ $any(item).label }}</span>
          </div>
        </div>
        <div *ngIf="items.length > 1" class="ng-value">
          <span class="ng-value-label">{{ items.length }} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div>
    <span class="text-xs mb-1">Vendor:</span>
    <ng-select
      class="tabular-nums big-dropdown"
      style="width: 320px"
      placeholder="All"
      formControlName="vendors"
      bindValue="value"
      [multiple]="true"
      [searchable]="true"
      [items]="this.documentLibrary.vendors"
      (change)="onFilterChange()"
    >
      <ng-template let-item="item" ng-option-tmp>
        <div
          class="block truncate"
          #text
          [auxTooltip]="text.offsetWidth < text.scrollWidth ? item.label : ''"
          [auxTooltipPositions]="optionTooltipPositions"
        >
          {{ item.label }}
        </div>
      </ng-template>
      <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        <div *ngIf="items.length === 1" class="ng-value">
          <div *ngFor="let item of items | slice: 0 : 1" class="flex">
            <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
            <span
              class="ng-value-label overflow-hidden text-ellipsis max-w-[200px]"
              #text
              [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).label : ''"
              [auxTooltipPositions]="optionTooltipPositions"
            >
              {{ $any(item).label }}
            </span>
          </div>
        </div>
        <div *ngIf="items.length > 1" class="ng-value">
          <span class="ng-value-label">{{ items.length }} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div>
    <span class="text-xs mb-1">Site:</span>
    <ng-select
      class="w-56 tabular-nums"
      placeholder="All"
      formControlName="sites"
      bindValue="value"
      [multiple]="true"
      [searchable]="true"
      [items]="this.documentLibrary.sites"
      (change)="onFilterChange()"
    >
      <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        <div *ngIf="items.length === 1" class="ng-value max-w-[130px]">
          <div *ngFor="let item of items | slice: 0 : 1" class="flex">
            <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
            <span class="ng-value-label overflow-hidden text-ellipsis">{{ $any(item).label }}</span>
          </div>
        </div>
        <div *ngIf="items.length > 1" class="ng-value">
          <span class="ng-value-label">{{ items.length }} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div>
    <span class="text-xs mb-1">From:</span>
    <aux-input
      placeholder="YYYY-MM-DD"
      formControlName="dateFrom"
      class="w-36"
      [type]="'date'"
      (change)="onFilterChange()"
    />
  </div>
  <div>
    <span class="text-xs mb-1">To:</span>
    <aux-input
      placeholder="YYYY-MM-DD"
      formControlName="dateTo"
      class="w-36"
      [type]="'date'"
      (change)="onFilterChange()"
    />
  </div>
  <button
    class="pt-8 focus:outline-none aux-link underline whitespace-nowrap"
    (click)="resetAllFilters()"
  >
    Clear All
  </button>
</div>
