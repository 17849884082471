import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { File } from '@components/file-manager/state/file.model';
import { EntityType, EventType, GqlService, TemplateType } from '@services/gql.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ApiService, FileMetadata } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { MainQuery } from '@shared/store/main/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';

@Component({
  selector: 'aux-add-bulk-sites-dialog',
  templateUrl: './add-bulk-sites-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBulkSitesDialogComponent implements AfterViewInit {
  @ViewChild('bulkFileManager') bulkFileManager: FileManagerComponent | undefined;

  loading$ = new BehaviorSubject(false);

  countries: { label: string; value: string }[];

  errorMessage = '';

  path = '';

  bulkFiles$: Observable<File[]> | undefined;

  constructor(
    public ref: CustomOverlayRef,
    public organizationQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private apiService: ApiService
  ) {
    this.countries = Utils.getCountriesForSelectOptions();
  }

  getMetadata(): FileMetadata {
    return { onboarding: 'true' };
  }

  removeBulkFile(file: File) {
    if (this.bulkFileManager) {
      this.bulkFileManager.removeFile(file);
    }
  }

  getBulkFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/bulk-site-templates/`;
  }

  async onGetBulkSiteTemplate() {
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.BULK_SITE_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
  }

  async onBulkUpload() {
    this.errorMessage = '';

    if (this.bulkFileManager && !this.loading$.getValue()) {
      const files = this.bulkFileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const bucket_key = `${this.getBulkFilePath()}${file.bucket_key}`;

      this.bulkFileManager.fileStore.update(file.id, {
        ...file,
        bucket_key,
      });

      const fileSuccess = await this.bulkFileManager.fileService.uploadFiles();

      if (fileSuccess) {
        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.BULK_SITE_TEMPLATE_UPLOADED,
            entity_type: EntityType.SITE,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${bucket_key}`,
          })
        );
        if (success) {
          this.overlayService.success();
        } else {
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }

  ngAfterViewInit(): void {
    if (this.bulkFileManager) {
      this.bulkFiles$ = this.bulkFileManager.fileQuery.selectAll();
    }
  }
}
