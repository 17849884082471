import { ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { BaseFormControlComponent } from '@components/form-inputs/base-form-control';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { TrialStatusMap } from '@constants/status.constants';
import { NgSelectModule } from '@ng-select/ng-select';
import { Trial, TrialImplementationStatus } from '@services/gql.service';
import { RequireSome } from '@services/utils';

@Component({
  selector: 'aux-trial-dropdown',
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, CommonModule, TooltipDirective],
  templateUrl: './trial-dropdown.component.html',
  styleUrls: ['trial-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrialDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TrialDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialDropdownComponent extends BaseFormControlComponent<string | null> {
  @Input() trials: (
    | (Omit<RequireSome<Partial<Trial>, 'id'>, 'sponsor_organization'> & {
        sponsor_organization: { id: string; name: string };
      })
    | {
        id: string | undefined;
        short_name: string | undefined;
        onboarding_complete: boolean | undefined;
        implementation_status?: string;
      }
  )[] = [];

  @Input() searchable = false;

  @Input() trialFormControl = new UntypedFormControl('');

  @Input() mainPage = true;

  tooltipPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 8,
  } as ConnectedPosition;

  tooltipColor = '';

  tooltipValue = '';

  trialStatusMap = TrialStatusMap;

  typecastingForTrialStatus(item_status: unknown): TrialImplementationStatus {
    const implementation_status = item_status as TrialImplementationStatus;
    return implementation_status;
  }

  mouseEnter(implementation_status: TrialImplementationStatus) {
    this.tooltipColor = this.trialStatusMap[implementation_status].color;
    this.tooltipValue = this.trialStatusMap[implementation_status].tooltipValue;
  }
}
