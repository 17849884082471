import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { UntypedFormBuilder } from '@angular/forms';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ChangeLogItemStatus,
  listChangeOrderNosQuery,
  TemplateType,
  DocumentType,
  EntityType,
} from '@services/gql.service';
import { ApiService, FileMetadata } from '@services/api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationQuery } from '@models/organization/organization.query';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { ChangeLogItemStatusConstants } from 'src/app/constants/status.constants';
import { ChangeLogService } from '../state/change-log.service';
import { ChangeLogQuery } from '../state/change-log.query';
import { ChangeOrderService } from '../../change-order/state/change-order.service';

export interface ChangeLogItemDialogComponentInput {
  change_log_id: string;
  formValues?: {
    cnf_no?: string;
    change_order_reference?: string;
    change_log_item_status?: ChangeLogItemStatus;
    description?: string;
    request_date?: string;
    start_date?: string;
    service_fee?: string;
    passthrough_fee?: string;
    investigator_fee?: string;
    is_sponsor?: boolean;
    requester?: string;
    cause?: string;
    is_planned?: boolean;
  };
  change_log_item_id?: string;
  mode?: 'create' | 'update';
}
@UntilDestroy()
@Component({
  selector: 'aux-change-log-item-dialog',
  templateUrl: './change-log-item-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogItemDialogComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  tabs: { label: string; show: Observable<boolean> }[] = [
    {
      label: 'New CNF Item',
      show: of(true),
    },
    {
      label: 'Bulk Upload',
      show: of(true),
    },
  ];

  statusConstant = ChangeLogItemStatusConstants.config;

  activeTabIndex = 0;

  fgNewCNF = this.formBuilder.group({
    cnf_no: '',
    change_order_reference: null,
    change_log_item_status: ChangeLogItemStatus.STATUS_NO_STATUS,
    description: '',
    request_date: null,
    start_date: null,
    service_fee: '0',
    passthrough_fee: '0',
    investigator_fee: '0',
    is_sponsor: false,
    requester: 'REQUESTER_UNKNOWN',
    cause: '',
    is_planned: true,
  });

  mode: 'create' | 'update' = 'create';

  changeOrdersNumbers: { change_order_no: string; id: string }[] = [];

  templateLoading$ = new BehaviorSubject(false);

  constructor(
    public ref: CustomOverlayRef<unknown, ChangeLogItemDialogComponentInput>,
    private formBuilder: UntypedFormBuilder,
    private changeLogService: ChangeLogService,
    private changeLogQuery: ChangeLogQuery,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private changeOrderService: ChangeOrderService,
    private vendorsQuery: OrganizationQuery
  ) {
    if (this.ref.data?.mode) {
      this.mode = this.ref.data.mode;
    }
    this.changeOrderService
      .listChangeOrders()
      .pipe(untilDestroyed(this))
      .subscribe((change_order) => {
        change_order.data?.forEach((changeOrder: listChangeOrderNosQuery) => {
          this.changeOrdersNumbers.push({
            change_order_no: changeOrder.change_order_no,
            id: changeOrder.id,
          });
        });
        if (this.ref.data?.formValues) {
          this.fgNewCNF.patchValue(this.ref.data.formValues);
        }
      });
  }

  getFilePaths(text: string, type: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return () => `trials/${trialId}/sites/${text}/${type}/`;
  }

  getFilePathForCisItems() {
    const trialId = this.mainQuery.getValue().trialKey;
    const vendorId = this.vendorsQuery.getActiveId() || '';
    const changeLogId = this.changeLogQuery.getActiveId() || '';
    return () =>
      `trials/${trialId}/vendors/${vendorId}/changelogs/${changeLogId}/changelogitems/${this.fgNewCNF.controls.cnf_no.value}`;
  }

  async onUpload(change_log_item_id: string) {
    if (this.fileManager) {
      const path = this.getFilePathForCisItems();
      const files = this.fileManager.fileQuery.getAll();

      if (files) {
        for (const file of files) {
          this.fileManager.fileStore.update(file.id, {
            ...file,
            bucket_key: `${path()}${file.bucket_key}`,
          });
        }
        const fileSuccess = await this.fileManager.fileService.uploadFiles(
          {
            documentType: DocumentType.DOCUMENT_CNF_LOG_ITEM,
            entity_id: change_log_item_id,
            entity_type_id: EntityType.CLI,
          },
          false,
          true
        );
        if (fileSuccess) {
          this.ref.close(true);
        }
      }
    }
  }

  async onNewCNFSubmit() {
    const { id } = this.mainQuery.getSelectedTrial()?.sponsor_organization || {};
    const change_log = this.changeLogQuery.getActive();
    if (this.fgNewCNF.valid && this.ref.data) {
      const submitData = this.fgNewCNF.value;
      const { success, data } = await this.changeLogService.addLogItem({
        cnf_no: submitData.cnf_no,
        change_order_reference: submitData.change_order_reference,
        change_log_item_status: submitData.change_log_item_status,
        change_log_id: this.ref.data.change_log_id,
        description: submitData.description,
        request_date: submitData.request_date,
        start_date: submitData.start_date,
        organization_id: (submitData.is_sponsor ? id : change_log?.vendor_id) || '',
        service_fee: submitData.service_fee ? +submitData.service_fee : null,
        passthrough_fee: submitData.passthrough_fee ? +submitData.passthrough_fee : null,
        investigator_fee: submitData.investigator_fee ? +submitData.investigator_fee : null,
        requester: submitData.requester,
        cause: submitData.cause,
        is_planned: submitData.is_planned,
      });

      if (success && data) {
        this.onUpload(data.id);
        this.overlayService.success('Change log successfully created.');
        this.ref.close(true);
      }
    }
  }

  onTabChange(i: number) {
    if (this.activeTabIndex === i) {
      return;
    }

    this.activeTabIndex = i;
  }

  onCancel() {
    this.ref.close();
  }

  async downloadBulkTemplate() {
    this.templateLoading$.next(true);
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.BULK_INVOICE_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
    this.templateLoading$.next(false);
  }

  getMetadata(): () => FileMetadata {
    return () => ({
      admin: '1',
      documentType: DocumentType.DOCUMENT_CNF_LOG_ITEM,
      entity_id: this.fgNewCNF.controls.cnf_no.value,
      entity_type_id: EntityType.CLI,
    });
  }

  async onUpdateClick() {
    const { id } = this.mainQuery.getSelectedTrial()?.sponsor_organization || {};
    const change_log = this.changeLogQuery.getActive();
    if (this.fgNewCNF.valid && this.ref.data) {
      const updateData = this.fgNewCNF.value;

      const { success } = await this.changeLogService.updateLogItem({
        cnf_no: updateData.cnf_no,
        change_order_reference:
          updateData.change_order_reference === '' ? null : updateData.change_order_reference,
        change_log_item_status: updateData.change_log_item_status,
        change_log_id: this.ref.data.change_log_id,
        change_log_item_id: this.ref.data.change_log_item_id || '',
        service_fee: updateData.service_fee ? updateData.service_fee : null,
        passthrough_fee: updateData.passthrough_fee ? updateData.passthrough_fee : null,
        investigator_fee: updateData.investigator_fee ? updateData.investigator_fee : null,
        start_date: updateData.start_date,
        request_date: updateData.request_date,
        description: updateData.description,
        organization_id: (updateData.is_sponsor ? id : change_log?.vendor_id) || '',
        requester: updateData.requester,
        cause: updateData.cause,
        is_planned: updateData.is_planned,
      });
      if (success) {
        this.onUpload(this.ref.data.change_log_item_id || '');
        this.overlayService.success('Change log successfully updated.');
        this.ref.close(true);
      }
    }
  }
}
