import { Component, ViewChild, AfterViewInit, model } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { CommonModule, NgClass, NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import {
  BeActivitiesAttributesModalParams,
  BeActivitiesAttributesModalReturnData,
  BeActivitiesTabState,
  BeActivitiesTabToSave,
  BeAttributesTabState,
  BeAttributesTabToSave,
  InAppDocumentState,
} from './be-activities-attributes-modal.model';
import { AttributesTabComponent } from './attributes-tab/attributes-tab.component';
import { ActivitiesTabComponent } from './activities-tab/activities-tab.component';
import { BeActivitiesAttributesModalRowData } from './be-activities-attributes-modal.model';
import { BeInlineCategoryDropdownOption } from '../be-inline-category-dropdown/be-inline-category-dropdown.model';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { FileMetadata } from '@services/api.service';
import { MainQuery } from '@shared/store/main/main.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { File } from '@components/file-manager/state/file.model';

@UntilDestroy()
@Component({
  standalone: true,
  templateUrl: 'be-activities-attributes-modal.component.html',
  imports: [
    NgSelectModule,
    FormsModule,
    ComponentsModule,
    NgForOf,
    IconComponent,
    NgIf,
    AgGridAngular,
    AttributesTabComponent,
    ActivitiesTabComponent,
    NgClass,
    CommonModule,
  ],
})
export class BeActivitiesAttributesModalComponent implements AfterViewInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  @ViewChild('activitiesTabComponent') activitiesTabComponent!: ActivitiesTabComponent;

  @ViewChild('attributesTabComponent') attributesTabComponent!: AttributesTabComponent;

  notes = '';

  vendors: { id: string; label: string }[] = [];

  metadata: FileMetadata = {};

  files: File[] = [];

  inAppDocumentState = model<InAppDocumentState>({ notes: '', bucket_keys: [] });

  selectedVendorId = '';

  isActivitiesTabVisible = true;

  gridData$ = new BehaviorSubject<BeActivitiesAttributesModalRowData[]>([]);

  categories: BeInlineCategoryDropdownOption[] = [];

  deletedColumns: string[] = [];

  renamedColumns: { oldValue: string; newValue: string }[] = [];

  attributesTabState: Record<string, Record<string, string>> = {};

  attributesTabHasUnsavedChanges = false;

  headerNames: Record<string, string> = {};

  isCategoryListChanged = false;

  isSaving = false;

  loading = false;

  constructor(
    public ref: CustomOverlayRef<
      BeActivitiesAttributesModalReturnData,
      BeActivitiesAttributesModalParams
    >,
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {
    if (!this.ref.data) {
      throw new Error("You didn't pass data to BeActivitiesModal");
    }
    this.selectedVendorId = this.ref.data.vendor.id;
    this.vendors = [this.ref.data.vendor];
    this.ref.canDeactivate = this.canDeactivate;
    this.categories = this.ref.data.categories;
    this.isActivitiesTabVisible = this.ref.data.isActivityTabVisible;
    this.gridData$.next(this.ref.data.rows.map((x) => ({ ...x, attributes: { ...x.attributes } })));
  }

  ngAfterViewInit(): void {
    if (this.fileManager) {
      this.fileManager.fileQuery
        .selectAll()
        .pipe(untilDestroyed(this))
        .subscribe((files) => this.setFiles(files));
    }
  }

  setFiles(files: File[]): void {
    const bucket_keys = files.map((file) => {
      return this.getBucketKey(file.bucket_key);
    });
    this.files = files;
    this.inAppDocumentState.set({
      notes: this.notes,
      bucket_keys: bucket_keys,
    });
  }

  async saveFiles() {
    if (this.files) {
      this.loading = true;
      this.files.forEach((file) => {
        this.fileManager?.fileStore.update(file.id, {
          ...file,
          bucket_key: this.getBucketKey(file.bucket_key),
        });
      });
      const metadata: FileMetadata = { admin: '1' };
      const fileSuccess = await this.fileManager?.fileService.uploadFiles(metadata);
      if (fileSuccess) {
        this.overlayService.success();
        this.loading = false;
        return fileSuccess;
      } else {
        this.overlayService.error('Error uploading files.');
        this.loading = false;
        return false;
      }
    }
    return true;
  }

  noteChange(note: string) {
    this.inAppDocumentState.update((value) => {
      return { notes: note, bucket_keys: value.bucket_keys };
    });
  }

  canDeactivate = async (): Promise<boolean> => {
    if (this.isSaving) {
      return true;
    }
    if (
      this.activitiesTabComponent.isActivitiesTabHasChanges() ||
      this.attributesTabComponent.isAttributesTabHasChanges() ||
      this.notes ||
      this.inAppDocumentState().bucket_keys.length
    ) {
      return await this.openUnsavedChanges();
    }

    return true;
  };

  async openUnsavedChanges(): Promise<boolean> {
    const result = this.overlayService.open({ content: GuardWarningComponent });
    const event = await firstValueFrom(result.afterClosed$);
    return !!event?.data;
  }

  toggleToAttributesTab(activityTabState: BeActivitiesTabState): void {
    this.isCategoryListChanged = activityTabState.isCategoryListChanged;
    this.categories = activityTabState.categories;
    this.isActivitiesTabVisible = false;
  }

  toggleToActivitiesTab(attributesTabState: BeAttributesTabState): void {
    this.deletedColumns = attributesTabState.deletedColumns;
    this.renamedColumns = attributesTabState.renamedColumns;
    this.attributesTabState = attributesTabState.state;
    this.headerNames = attributesTabState.headerNames;
    this.attributesTabHasUnsavedChanges = attributesTabState.attributesTabHasUnsavedChanges;
    this.isActivitiesTabVisible = true;
  }

  async saveFromAttributesTab(data: BeAttributesTabToSave): Promise<void> {
    this.isSaving = true;
    const success = await this.saveFiles();
    if (success) {
      this.ref.close({
        deletedColumns: data.deletedColumns,
        renamedColumns: data.renamedColumns,
        rows: data.rows,
        isCategoryListChanged: this.isCategoryListChanged,
        temporaryCategories: this.categories,
        type: 'save',
        notes: data.notes,
        supporting_document_s3_bucket_keys: data.supporting_document_s3_bucket_keys,
      });
    }
  }

  async saveFromActivitiesTab(data: BeActivitiesTabToSave): Promise<void> {
    this.isSaving = true;
    const success = await this.saveFiles();
    if (success) {
      if (this.attributesTabHasUnsavedChanges) {
        this.ref.close({
          deletedColumns: this.deletedColumns,
          renamedColumns: this.renamedColumns,
          rows: this.getAttributesRowData(data.rows),
          isCategoryListChanged: data.isCategoryListChanged,
          temporaryCategories: data.temporaryCategories,
          type: 'save',
          notes: data.notes,
          supporting_document_s3_bucket_keys: data.supporting_document_s3_bucket_keys,
        });
      }

      this.ref.close({
        deletedColumns: [],
        renamedColumns: [],
        rows: data.rows,
        isCategoryListChanged: data.isCategoryListChanged,
        temporaryCategories: data.temporaryCategories,
        type: 'save',
        notes: data.notes,
        supporting_document_s3_bucket_keys: data.supporting_document_s3_bucket_keys,
      });
    }
  }

  close(): void {
    this.ref.close({
      deletedColumns: [],
      renamedColumns: [],
      rows: [],
      isCategoryListChanged: false,
      temporaryCategories: [],
      type: 'close',
      notes: '',
      supporting_document_s3_bucket_keys: [],
    });
  }

  private getAttributesRowData(
    rows: BeActivitiesAttributesModalRowData[]
  ): BeActivitiesAttributesModalRowData[] {
    return rows.map((row: BeActivitiesAttributesModalRowData) => {
      let attributes = row.attributes;
      const obj: Record<string, string> = {};
      Object.entries(this.headerNames).forEach(([key, value]) => {
        obj[value] =
          this.attributesTabState[row.id]?.[key] != null
            ? this.attributesTabState[row.id][key]
            : attributes[key.replace('attributes.', '')] || '';
      });
      attributes = obj;

      return {
        ...row,
        attributes,
        changed: row.changed || !!this.attributesTabState[row.id],
      };
    });
  }

  getBucketKey(bucket_key: string): string {
    const trialId = this.mainQuery.getSelectedTrial()?.id;
    const vendorId = this.selectedVendorId;
    return `trials/${trialId}/vendors/${vendorId}/budget/in-app-budget-edit/${bucket_key}`;
  }

  getFilePath() {
    return () => ``;
  }

  getMetadata(): () => FileMetadata {
    return () => ({});
  }
}
