@if (isQuarterCloseEnabled() && isClosingPanelEnabled()) {
  <aux-workflow-panel
    className="mb-4"
    [workflowName]="workflowName"
    [processing]="iCloseMonthsProcessing()!"
    [hasUserPermissions]="userHasLockInvoicesPermission()"
  />
}

<div class="flex justify-between items-center">
  <div class="flex items-center">
    <h1 class="text-2xl font-bold">Invoice Library</h1>

    <div class="ml-7">
      <aux-checkbox
        class="mt-3 mb-3 text-sm"
        [disabled]="editMode()"
        (customChange)="checkRequireCostBreakdown($event)"
      >
        Require Cost Breakdown
      </aux-checkbox>
    </div>
    <div class="ml-7">
      <aux-checkbox
        class="mt-3 mb-3 text-sm"
        [disabled]="editMode()"
        (customChange)="checkRequireAccrualPeriod($event)"
      >
        Require Accrual Period
      </aux-checkbox>
    </div>
    <div class="ml-7">
      <aux-checkbox
        class="mt-3 mb-3 text-sm"
        [disabled]="editMode()"
        (customChange)="checkInvalidCostCategorization($event)"
      >
        Invalid Cost Categorization
      </aux-checkbox>
    </div>
  </div>

  <div class="grid grid-flow-col gap-x-1.5">
    <aux-invoices-sync-buttons />

    <aux-export-excel-button
      [clickFN]="onExportInvoices.bind(this)"
      [spinnerSize]="7"
      pendoTag="invoices-excel-export-button"
    />

    <aux-button
      classList="pl-3"
      variant="primary"
      label="Add New"
      [auxTooltip]="invoiceLockTooltip()"
      [onClick]="this.onNewInvoice.bind(this)"
      [disabled]="isInvoiceFinalized() || iCloseMonthsProcessing() || editMode()"
      icon="CirclePlus"
    />
  </div>
</div>

<div class="flex items-end justify-between mt-2">
  <div class="flex items-end flex-wrap">
    @if (editMode()) {
      <aux-button
        variant="secondary"
        label="Bulk Edit"
        classList="mr-4"
        [auxTooltip]="invoiceLockTooltip()!"
        [disabled]="
          savingChanges() ||
          isInvoiceFinalized() ||
          iCloseMonthsProcessing() ||
          !selectedRows().length
        "
        icon="Pencil"
        [spinnerSize]="7"
        [onClick]="onBulkApplyButtonClick"
      />
      <aux-button
        variant="negative"
        label="Delete"
        classList="mr-4"
        icon="Trash"
        [auxTooltip]="invoiceLockTooltip()!"
        [disabled]="
          savingChanges() ||
          isInvoiceFinalized() ||
          iCloseMonthsProcessing() ||
          !selectedRows().length ||
          !userHasDeleteInvoicePermission()
        "
        [onClick]="removeInvoices"
        [spinnerSize]="7"
      />
    }

    <aux-invoice-filters [form]="invoiceService.filtersForm" />

    @if (vendorCurrencyEnabled() && !vendorHasOneCurrency) {
      <div class="pl-6 mb-2">
        <aux-toggle-budget-currency
          class="pl-6 mb-2"
          [value]="isVendorCurrency()"
          (nameSelectedCurrency)="selectedBudgetCurrencyType.set($event)"
        />
      </div>
    }
  </div>
  @if (editMode()) {
    <div class="grid grid-flow-col gap-x-1.5">
      <aux-button
        variant="secondary"
        label="Cancel"
        icon="X"
        [onClick]="onCancel"
        [disabled]="savingChanges()"
      />
      <aux-button
        variant="success"
        label="Save"
        icon="Check"
        [disabled]="!hasChanges()"
        [onClick]="onSave"
        [spinnerSize]="7"
      />
    </div>
  }

  @if (!editMode()) {
    <aux-button
      variant="secondary"
      label="Edit"
      icon="Pencil"
      [auxTooltip]="editButtonTooltip()"
      [disabled]="isInvoiceFinalized() || iCloseMonthsProcessing()"
      [onClick]="enableEditMode"
    />
  }
</div>

<aux-pagination-grid
  class="block mt-4"
  [idTable]="'invoiceLibraryGrid'"
  [gridOptions]="$any(gridOptions())"
  [dataSource]="datasource"
  [filterForm]="invoiceService.filtersForm"
  [serverSideFilters]="serverSideFilters"
  [filterValues$]="filterValues$"
  [sortModel$]="sortModel$"
  [hidePinnedRow]="true"
  [enableFillHandle]="editMode()"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="firstDataRendered($event)"
  (paginationChange)="paginationChange()"
  (cellValueChanged)="cellValueChanged($event)"
  (rowSelected)="rowSelected($event)"
  (selectionChanged)="selectionChanged($event)"
  (modelUpdated)="modelUpdated($event)"
/>
