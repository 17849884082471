import { EventQuery } from '@models/event/event.query';
import { DistributionTimelineService } from '../services/distribution-timeline.service';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  CellEditingStartedEvent,
  CellPosition,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  SuppressKeyboardEventParams,
  TabToNextCellParams,
  ValueFormatterParams,
  ValueParserParams,
} from '@ag-grid-community/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';
import {
  CurveType,
  DistributionMode,
  EntityType,
  EventType,
  GqlService,
  listDriverPatientDistributionsQuery,
  Organization,
  PermissionType,
  UpdateDriverPatientDistributionInput,
  WorkflowStep,
} from '@services/gql.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationStore } from '@models/organization/organization.store';
import { ExportType, Utils } from '@services/utils';
import { EventManager } from '@angular/platform-browser';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { TimelineService } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { ApiService } from '@services/api.service';
import { PatientGroupsQuery } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { first, isEqual, last, omit, pick } from 'lodash-es';
import { AuthService } from '@shared/store/auth/auth.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import {
  RemoveDialogComponent,
  RemoveDialogInput,
} from '@components/remove-dialog/remove-dialog.component';
import { MessagesConstants } from '@constants/messages.constants';
import { PatientDriverUploadComponent } from './patient-driver-upload/patient-driver-upload.component';
import { PatientsService } from './state/patients.service';
import { PatientBlendedCurveModalComponent } from './patient-blended-curve-modal/patient-blended-curve-modal.component';
import { PatientCurveService } from '../../patient-curve/patient-curve.service';
import { PatientCurveQuery } from '../../patient-curve/patient-curve.query';
import { PatientGroupsService } from './patient-groups/state/patient-groups.service';
import { PatientCurveModel, PatientCurveStore } from '../../patient-curve/patient-curve.store';
import { PatientsQuery } from './state/patients.query';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { BlendedCurveModalDataModel } from '../models/blended-curve-modal-data.model';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';
import {
  ComparisonCard,
  ForecastAndActualType,
  PatientCurvesConstants,
  TopCard,
} from './patient-curves.constants';
import { TableConstants } from '@constants/table.constants';
import { TableService } from '@services/table.service';
import {
  batchPromises,
  DateUtils,
  decimalAdd,
  decimalDifference,
  decimalEquality,
  decimalRoundingToNumber,
  decimalRoundingToString,
} from '@shared/utils';
import { StickyElementService } from '@services/sticky-element.service';
import dayjs from 'dayjs';
import { ChartConfiguration } from 'chart.js';
import { AgGridGetData } from '@shared/utils/ag-grid-data.utils';

@UntilDestroy()
@Component({
  selector: 'aux-patient-curves',
  templateUrl: './patient-curves.component.html',
  styleUrls: ['patient-curves.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCurvesComponent implements OnInit, OnDestroy {
  readonly messagesConstants = MessagesConstants;

  gridData$ = new BehaviorSubject<listDriverPatientDistributionsQuery[]>([]);

  isPatientsFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType$(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES
  );

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH);

  currentPatientGroupId = '';

  currentPatientGroupName$ = new BehaviorSubject<string>('');

  selectedMonth$ = new BehaviorSubject<string>('');

  patientDriversEdcFlag = new BehaviorSubject<boolean>(false);

  topCards$ = new BehaviorSubject<TopCard[]>([]);

  comparisonCards$ = new BehaviorSubject<ComparisonCard[]>([]);

  userHasUploadPatientDriverPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPLOAD_PATIENT_DRIVER],
  });

  multiChart$: Observable<ChartConfiguration<'line'>> = this.gridData$.pipe(
    map((data) => {
      const labels = data.map(({ distribution_month }) =>
        Utils.dateFormatter(distribution_month, { day: undefined, year: '2-digit' })
      );
      return PatientCurvesConstants.multiChartOptions(data, labels);
    })
  );

  editCell = false;

  editMode$ = new BehaviorSubject(false);

  monthList: { start_date: string; end_date: Date | string } = { start_date: '', end_date: '' };

  timelineList$ = new BehaviorSubject<{ startDate?: string; endDate?: string }>({
    startDate: '',
    endDate: '',
  });

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (!params.editing) {
          switch (params.event.key) {
            case 'Backspace':
            case 'Delete':
              if (this.editMode$.getValue()) {
                TableService.clearCellRange(params, () => this.calculatePatientCurves());
              }
              return true;
            default:
              return false;
          }
        }
        return false;
      },
      editable: () => {
        return this.editMode$.getValue();
      },
      cellClass: PatientCurvesConstants.getCellClasses(this.editMode$),
      headerClass: PatientCurvesConstants.getHeaderClasses(this.editMode$),
      cellClassRules: {
        '!bg-aux-error': (params) => {
          let ret = false;
          switch (params.colDef.field) {
            case 'forecast.patients_discontinued':
            case 'forecast.patients_enrolled':
            case 'forecast.patients_complete':
              if (params.data.distribution_month === 'TOTAL' && !this.isBlended$.getValue()) {
                const diffEnrolledToDiscontinued = decimalDifference(
                  params.data.forecast.patients_enrolled,
                  params.data.forecast.patients_discontinued
                );
                ret = !decimalEquality(
                  diffEnrolledToDiscontinued,
                  params.data.forecast.patients_complete
                );
                this.isEqual$.next(!ret);
              }
              break;
            case 'forecast.net_patients_enrolled':
              if (
                !this.isBlended$.getValue() &&
                Utils.isNegativeAndNotCloseEnoughToZero(params.data.forecast.net_patients_enrolled)
              ) {
                ret = true;
                this.isNetPatientNegative$.next(true);
              }
              break;
            default:
              break;
          }

          return ret;
        },
      },
      cellStyle: (params) => {
        if (
          (params.colDef.field === 'forecast.cumulative_enrollment_percentage' ||
            params.colDef.field?.includes('actual') ||
            params.colDef.field?.includes('net_patients_enrolled') ||
            params.colDef.field?.includes('total_patients_enrolled')) &&
          params.data.distribution_month !== 'TOTAL'
        ) {
          return this.editCell
            ? { opacity: 0.7, 'justify-item': 'end' }
            : { 'justify-item': 'end' };
        }
        return {};
      },
    },
    ...PatientCurvesConstants.GRID_OPTIONS,
    ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
    columnDefs: PatientCurvesConstants.allColDefs(
      this.valueFormatter,
      this.numberParser,
      this.editCell,
      this.launchDarklyService.flags$.getValue().patient_drivers_edc,
      this.currentPatientGroupName$,
      this.timelineList$
    ),
    excelStyles: PatientCurvesConstants.GRID_OPTIONS_EXEL_STYLES,
  } as GridOptions;

  gridApi!: GridApi;

  loading$ = new BehaviorSubject(false);

  isBlended$ = new BehaviorSubject(false);

  driverSettingId$ = new BehaviorSubject<string | null>(null);

  showEnrollmentSettings$: Observable<boolean>;

  PatientCurveGroup: PatientCurveModel[] = [];

  patientCurveGroups: PatientCurveModel[] = [];

  patientCurveListOptions: EditableListDropdownItem[] = [];

  editingGridCell$ = new BehaviorSubject(false);

  selectedVendor = new UntypedFormControl('');

  curveControl = new FormControl('');

  clonedPatient: listDriverPatientDistributionsQuery[] = [];

  setTimelineSubscription!: Subscription;

  btnLoading$ = new BehaviorSubject(false);

  isEqual$ = new BehaviorSubject(true);

  isNetPatientNegative$ = new BehaviorSubject(false);

  zeroValues = {
    patients_enrolled: 0,
    patients_discontinued: 0,
    patients_complete: 0,
    net_patients_enrolled: 0,
    cumulative_enrollment_percentage: 0,
    total_patients_enrolled: 0,
  };

  isFromScratch = false;

  userHasModifyPermissions = false;

  isInitialValueAlreadySet = false;

  curveEditedVal = '';

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private patientGroupsService: PatientGroupsService,
    private mainQuery: MainQuery,
    private patientService: PatientsService,
    private vendorsService: OrganizationService,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private patientCurveService: PatientCurveService,
    public patientCurveQuery: PatientCurveQuery,
    private timelineService: TimelineService,
    private timelineQuery: TimelineQuery,
    private apiService: ApiService,
    private patientCurveStore: PatientCurveStore,
    public patientGroupsQuery: PatientGroupsQuery,
    private patientQuery: PatientsQuery,
    private eventManager: EventManager,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery,
    private stickyElementService: StickyElementService,
    private distributionTimelineService: DistributionTimelineService,
    private eventQuery: EventQuery
  ) {
    this.timelineQuery
      .selectStartEndDateTrial()
      .pipe(untilDestroyed(this))
      .subscribe((dates) => {
        this.timelineList$.next(dates);
      });

    this.showEnrollmentSettings$ = this.launchDarklyService.select$(
      (flags) => flags.section_patient_driver_enrollment_settings
    );

    this.editMode$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.gridApi) {
        this.gridApi.refreshHeader();
      }
    });

    this.getSelectedMonth();

    this.patientDriversEdcFlag.next(this.launchDarklyService.flags$.getValue().patient_drivers_edc);

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_PATIENT_CURVE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasModifyPermissions = x;
      });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  ngOnInit() {
    this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();

    this.vendorsService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          this.loading$.next(true);
          return combineLatest([this.patientCurveService.get(), this.patientGroupsService.get()]);
        }),
        tap(() => {
          this.isInitialValueAlreadySet = false;
          this.monthList.start_date = '';
          this.clonedPatient = Utils.clone([]);
          this.gridData$.next(this.clonedPatient);
        }),
        switchMap(() => {
          return combineLatest([
            this.patientCurveQuery.selectAll(),
            this.patientGroupsQuery.selectAll(),
          ]).pipe(
            map(([curves, patientGroups]) => {
              this.PatientCurveGroup = curves;
              const pgIds: string[] = [];
              curves.forEach((c) => {
                pgIds.push(c.patient_group_id);
              });
              const uniqPGs: PatientCurveModel[] = [];
              patientGroups.forEach((pg) => {
                if (!pgIds.includes(pg.id)) {
                  uniqPGs.push({
                    __typename: 'DriverPatientGroup',
                    curve_type: CurveType.NET,
                    constituent_patient_groups: [],
                    driver_setting_id: '',
                    is_blended: false,
                    name: pg.name,
                    patient_group_ids: [],
                    showLine: false,
                    organizations_with_forecasts: [],
                    patient_group_id: pg.id,
                  });
                }
              });
              const patientItems = [...curves, ...uniqPGs];

              this.updatePatientCurveList(patientItems);

              if (patientItems.length === 0) {
                this.curveControl.setValue('');
              } else if (
                this.curveControl.value !== patientItems[0].name &&
                !this.isInitialValueAlreadySet
              ) {
                this.curveControl.setValue(patientItems[0].name);
              } else if (this.curveEditedVal !== '') {
                this.curveControl.setValue(this.curveEditedVal);
                this.curveEditedVal = '';
              }
              return patientItems;
            })
          );
        })
      )
      .subscribe(() => {
        this.loading$.next(false);
        this.isInitialValueAlreadySet = true;
      });

    this.eventManager.addEventListener(document.body, 'keydown', (event: KeyboardEvent) => {
      switch (event.code) {
        case 'Tab':
          this.gridApi.stopEditing();
          setTimeout(() => {
            const getCellCor = this.gridApi.getFocusedCell();
            this.gridApi.startEditingCell({
              rowIndex: getCellCor?.rowIndex || 0,
              colKey: getCellCor?.column?.getColId() || '',
            });
          }, 0);
          break;
        default:
          break;
      }
    });

    //temporary fix, should be investigated later
    combineLatest([this.curveControl.valueChanges, this.mainQuery.select('trialKey')])
      .pipe(untilDestroyed(this))
      .subscribe(([value]) => {
        const driverPatientGroup =
          this.patientCurveGroups?.find((item) => item.name === value) || null;
        if (driverPatientGroup) {
          this.selectCurve(driverPatientGroup);
        }
      });
  }

  async resetPatientCurveGroup() {
    this.PatientCurveGroup = [];
  }

  async canDeactivate(): Promise<boolean> {
    const fieldsToCompare = [
      'forecast.patients_enrolled',
      'forecast.patients_discontinued',
      'forecast.patients_complete',
    ];
    const currentValuesToCompare = AgGridGetData(<GridApi>this.gridApi).map((curve) =>
      pick(curve, fieldsToCompare)
    );
    const initialValuesToCompare = this.clonedPatient.map((curve) => pick(curve, fieldsToCompare));

    const hasChanges =
      !isEqual(currentValuesToCompare, initialValuesToCompare) && this.editMode$.getValue();

    if (hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  async getSelectedMonth() {
    const currentOpenMonth = this.mainQuery.getValue().currentOpenMonth;
    if (currentOpenMonth) {
      this.selectedMonth$.next(dayjs(currentOpenMonth).endOf('month').format('YYYY-MM-DD'));
    } else {
      this.selectedMonth$.next(dayjs().endOf('month').format('YYYY-MM-DD'));
    }
  }

  valueFormatter(val: ValueFormatterParams) {
    const newValue = val.value === '0' ? 0 : val.value;
    return newValue ? decimalRoundingToString(newValue) : Utils.zeroHyphen;
  }

  numberParser(params: ValueParserParams): number | string {
    return Number.isNaN(Number(params.newValue))
      ? params.oldValue
      : Math.abs(Number(params.newValue));
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
  }

  calculatePinnedBottomData(fillComparisonCards = false) {
    const currentMonth = this.selectedMonth$.getValue();
    const gridData =
      fillComparisonCards && currentMonth
        ? this.gridData$
            .getValue()
            .filter((row) => !dayjs(row.distribution_month).isAfter(currentMonth))
        : this.gridData$.getValue();

    const forecast = {
      patients_enrolled: 0,
      patients_discontinued: 0,
      patients_complete: 0,
      total_patients_enrolled: 0,
      net_patients_enrolled: 0,
      cumulative_enrollment_percentage: 0,
    };
    const actual = {
      patients_enrolled: 0,
      patients_discontinued: 0,
      patients_complete: 0,
      total_patients_enrolled: 0,
      net_patients_enrolled: 0,
      cumulative_enrollment_percentage: 0,
    };

    const columnsWithAggregation = Object.keys(actual).filter((key) => {
      return key !== 'net_patients_enrolled';
    }) as unknown as (keyof ForecastAndActualType)[];

    gridData.forEach((val) => {
      for (const key of columnsWithAggregation) {
        const currentValForForecast = forecast[key];
        const additionalValForForecast = val.forecast[key];

        const currentValForActual = actual[key];
        const additionalValForActual = val.actual ? val.actual[key] : 0;

        if (Utils.isNumber(currentValForForecast) && Utils.isNumber(additionalValForForecast)) {
          if ('total_patients_enrolled' === key) {
            forecast[key] = additionalValForForecast ? additionalValForForecast : 0; //always set total patients enrolled to last months value, not net
          } else {
            forecast[key] = decimalAdd(
              currentValForForecast,
              additionalValForForecast ? additionalValForForecast : 0
            );
          }
        }
        if (Utils.isNumber(currentValForActual) && Utils.isNumber(additionalValForActual)) {
          if ('total_patients_enrolled' === key) {
            actual[key] = additionalValForActual ? additionalValForActual : 0; //always set total patients enrolled to last months value, not net
          } else {
            actual[key] = decimalAdd(
              currentValForActual,
              additionalValForActual ? additionalValForActual : 0
            );
          }
        }
      }
      forecast.total_patients_enrolled = 0;
      actual.total_patients_enrolled = 0;
    });

    return { forecast, actual };
  }

  onGridReady(e: GridReadyEvent) {
    this.gridApi = e.api;
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.editMode$.getValue()) {
        this.fillAllCards();
      }
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), distribution_month: 'TOTAL' };
      this.gridApi.setGridOption('pinnedBottomRowData', [pinnedBottomData]);
    });
  }

  onPatientDriverUploadClick() {
    this.overlayService.open({ content: PatientDriverUploadComponent });
  }

  selectCurve(item: PatientCurveModel): void {
    this.loading$.next(true);
    this.currentPatientGroupId = item?.patient_group_id;
    this.currentPatientGroupName$.next(item?.name);

    if (item) {
      if (this.editMode$.getValue()) {
        this.gridApi.stopEditing();
      }
      this.editMode$.next(false);
      this.driverSettingId$.next(item.driver_setting_id);
      this.editCell = false;
      if (item.driver_setting_id === '') {
        this.isFromScratch = true;
        this.patientService
          .get(item.patient_group_id)
          .pipe(take(1))
          .subscribe(() => {
            this.populateGridDataWithTimeline();
            this.isBlended$.next(false);
            this.loading$.next(false);
          });
      } else {
        this.isFromScratch = false;
        this.isBlended$.next(!!item?.is_blended);
        this.patientService
          .get(item.patient_group_id)
          .pipe(take(1))
          .subscribe(() => {
            this.clonePatient();
            this.loading$.next(false);
          });
      }
    } else {
      this.loading$.next(false);
    }
  }

  populateGridDataWithTimeline(afterRemove = false) {
    const patientQueryData = this.patientQuery.getAll();

    if (afterRemove) {
      this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
      this.timelineQuery
        .select()
        .pipe(take(2))
        .subscribe(({ items }) => {
          const start_date = first(items)?.contract_start_date;
          let end_date = Utils.dateParse(`${last(items)?.contract_end_date.slice(0, 8)}01`);
          items.forEach((i) => {
            const parsedDate = Utils.dateParse(`${i.contract_end_date.slice(0, 8)}01`);
            if (parsedDate > end_date) {
              end_date = parsedDate;
            }
          });
          if (start_date) {
            this.monthList.start_date = start_date;
            this.monthList.end_date = end_date || '';

            this.setEmptyGridData();
          }
        });
    } else {
      this.setGridData(patientQueryData);
    }
    this.isFromScratch = true;
  }

  editCurve(patientCurveGroup: EditableListDropdownItem | null = null) {
    const driverPatientGroup: PatientCurveModel | null = patientCurveGroup
      ? this.patientCurveGroups?.find((item) => item.name === patientCurveGroup.name) || null
      : null;

    const modalRef = this.overlayService.open<{
      data: {
        name: string;
      };
    }>({
      content: PatientBlendedCurveModalComponent,
      data: this.blendedCurveModalParams(driverPatientGroup),
    });

    modalRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(({ data }) => {
      // cancel modal will be null
      if (data?.data?.name) {
        this.curveEditedVal = data.data.name; //for edit reselection, name edit
        this.curveControl.setValue(data.data.name); //for add selection
      }
    });
  }

  async deleteCurve(patientCurveGroup: EditableListDropdownItem) {
    const driverPatientGroup = this.patientCurveGroups.find(
      (item) => item.name === patientCurveGroup.name
    );

    if (!driverPatientGroup) {
      return;
    }
    const cannotDeleteMessage = `${driverPatientGroup.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${driverPatientGroup.name} can be successfully deleted.<br>`;

    let blendedCurves: PatientCurveModel[] = [];
    if (!driverPatientGroup.is_blended) {
      const patientCurves = this.patientCurveQuery.getAll();

      blendedCurves = patientCurves.filter((el) =>
        el.constituent_patient_groups.find((c) => c.id === driverPatientGroup.patient_group_id)
      );
    }

    if (
      driverPatientGroup.is_blended &&
      driverPatientGroup.organizations_with_forecasts.length > 0
    ) {
      const routeInputs = [
        {
          componentName: 'Forecast Methodology:',
          name: driverPatientGroup.organizations_with_forecasts.map(
            ({ name }: Organization) => name
          ),
          link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
        },
      ];

      const removeDialogInput: RemoveDialogInput = {
        header: 'Remove Patient Curve',
        cannotDeleteMessage,
        routeInputs,
      };

      const resp = this.overlayService.open({
        content: RemoveDialogComponent,
        data: removeDialogInput,
      });

      await firstValueFrom(resp.afterClosed$);
    } else if (
      !driverPatientGroup.is_blended &&
      (driverPatientGroup.organizations_with_forecasts.length > 0 || blendedCurves.length > 0)
    ) {
      const routeInputs = [
        {
          componentName: 'Blended Curves:',
          name: blendedCurves.map(({ name }) => name),
          link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
        },
      ];

      if (driverPatientGroup.organizations_with_forecasts.length) {
        const routeInputsPatientGroup = [
          {
            componentName: 'Forecast Methodology:',
            name: driverPatientGroup.organizations_with_forecasts.map(({ name }) => name),
            link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
          },
        ];
        routeInputs.push(...routeInputsPatientGroup);
      }

      const removeDialogInput: RemoveDialogInput = {
        header: 'Remove Patient Curve',
        cannotDeleteMessage,
        routeInputs,
      };

      const resp = this.overlayService.open({
        content: RemoveDialogComponent,
        data: removeDialogInput,
      });

      await firstValueFrom(resp.afterClosed$);
    } else {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Patient Curve',
        message: `Are you sure you want to remove Patient Curve ${driverPatientGroup.name}?`,
        okBtnText: 'Remove',
      });
      const event = await firstValueFrom(resp.afterClosed$);

      if (event.data?.result) {
        this.curveControl.setValue('');
        await this.patientCurveService.remove(driverPatientGroup);
        await firstValueFrom(this.patientCurveService.get().pipe(take(1)));
        const patientCurveServiceData = this.patientCurveQuery.getAll();
        if (patientCurveServiceData) {
          this.PatientCurveGroup = patientCurveServiceData;
        }
        this.gridData$.next([]);
        this.gridApi.refreshCells({ force: true });
        this.populateGridDataWithTimeline(true);
      }
    }
  }

  async onExportForPatientDriver() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next(true);

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.PATIENT_CURVE,
          filename: `${trialName}_patient_curves`,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  shouldPDBeCreatedFromScratch(patient_driver: listDriverPatientDistributionsQuery): boolean {
    const keys = Object.keys(
      omit(patient_driver.forecast, ['id', '__typename', 'distribution_mode'])
    ) as (keyof ForecastAndActualType)[];

    return keys.every((key) => patient_driver.forecast[key] === null);
  }

  calculatePatientCurves(): void {
    let totalNetPatientEnrolled = 0;
    let sumOfNetPatientEnrolled = 0;
    let patientEnrolledTotal = 0;
    let isNetPatientNegative = false;

    this.gridData$.getValue().forEach((x: listDriverPatientDistributionsQuery) => {
      x.forecast.patients_enrolled = decimalRoundingToNumber(x.forecast.patients_enrolled || 0, 8);
      x.forecast.patients_discontinued = decimalRoundingToNumber(
        x.forecast.patients_discontinued || 0,
        8
      );
      x.forecast.patients_complete = decimalRoundingToNumber(x.forecast.patients_complete || 0, 8);

      const enrolledLessDiscontinued = decimalDifference(
        x.forecast.patients_enrolled,
        x.forecast.patients_discontinued
      );

      const lessPatientsComplete = decimalDifference(
        enrolledLessDiscontinued,
        x.forecast.patients_complete
      );
      totalNetPatientEnrolled = decimalAdd(totalNetPatientEnrolled, lessPatientsComplete);
      sumOfNetPatientEnrolled = decimalAdd(sumOfNetPatientEnrolled, totalNetPatientEnrolled);

      x.forecast.net_patients_enrolled = decimalRoundingToNumber(totalNetPatientEnrolled, 8);
      patientEnrolledTotal = decimalAdd(patientEnrolledTotal, x.forecast.patients_enrolled);
      x.forecast.total_patients_enrolled = decimalRoundingToNumber(patientEnrolledTotal, 8);

      isNetPatientNegative = Utils.isNegativeAndNotCloseEnoughToZero(
        x.forecast.net_patients_enrolled
      );
    });
    this.isNetPatientNegative$.next(isNetPatientNegative);

    this.gridData$.getValue().forEach((x: listDriverPatientDistributionsQuery) => {
      x.forecast.cumulative_enrollment_percentage = Utils.calculateAsPercentage(
        sumOfNetPatientEnrolled,
        x.forecast.net_patients_enrolled || 0
      );
    });

    this.gridData$.next(this.gridData$.getValue());
    this.gridApi.refreshCells();
  }

  private showErrors(): boolean {
    const rowNodes: IRowNode[] = [];
    let isThereAnyInvalidRow = false;
    this.gridApi.forEachNode((node) => {
      const pddq = node.data as listDriverPatientDistributionsQuery;
      const { patients_enrolled, patients_discontinued, patients_complete } = pddq.forecast;

      if (
        Utils.isNumber(patients_enrolled) &&
        Utils.isNumber(patients_discontinued) &&
        Utils.isNumber(patients_complete)
      ) {
        if (node.data.showError) {
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else {
        isThereAnyInvalidRow = true;
        node.data.showError = true;
        rowNodes.push(node);
      }
    });

    this.gridApi.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  fillAllCards() {
    const totalsToDate = this.calculatePinnedBottomData(true);
    const targetTotals = this.calculatePinnedBottomData();
    this.fillComparisonCards(totalsToDate, targetTotals.forecast);
    this.fillForecastCards(targetTotals.forecast);
  }

  fillForecastCards(forecast: ForecastAndActualType) {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    this.topCards$.next([
      {
        title: 'Total Target Enrollment',
        value: Utils.decimalFormatter(forecast.patients_enrolled || 0, options),
      },
      {
        title: 'Total Forecast Patients Discontinued',
        value: Utils.decimalFormatter(forecast.patients_discontinued || 0, options),
      },
      {
        title: 'Total Forecast Patients Completed',
        value: Utils.decimalFormatter(forecast.patients_complete || 0, options),
      },
    ]);
  }

  fillComparisonCards(
    totalsToDate: { forecast: ForecastAndActualType; actual: ForecastAndActualType },
    targetForecast: ForecastAndActualType
  ) {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    this.comparisonCards$.next([
      {
        title: 'Total Patients Enrolled',
        compareFrom: {
          title: 'Forecast',
          value: Utils.decimalFormatter(totalsToDate.forecast.patients_enrolled || 0, options),
          percent: this.percentageFormatterForCards(
            'Enrolled',
            targetForecast.patients_enrolled || 0,
            totalsToDate.forecast.patients_enrolled || 0
          ),
        },
        compareTo: {
          title: 'EDC Actuals',
          value: Utils.decimalFormatter(totalsToDate.actual.patients_enrolled || 0, options),
          percent: this.percentageFormatterForCards(
            'Enrolled',
            targetForecast.patients_enrolled || 0,
            totalsToDate.actual.patients_enrolled || 0
          ),
        },
      },
      {
        title: 'Total Patients Discontinued',
        compareFrom: {
          title: 'Forecast',
          value: Utils.decimalFormatter(totalsToDate.forecast.patients_discontinued || 0, options),
          percent: this.percentageFormatterForCards(
            'Discontinued',
            targetForecast.patients_enrolled || 0,
            totalsToDate.forecast.patients_discontinued || 0
          ),
        },
        compareTo: {
          title: 'EDC Actuals',
          value: Utils.decimalFormatter(totalsToDate.actual.patients_discontinued || 0, options),
          percent: this.percentageFormatterForCards(
            'Discontinued',
            targetForecast.patients_enrolled || 0,
            totalsToDate.actual.patients_discontinued || 0
          ),
        },
      },
      {
        title: 'Total Patients Completed',
        compareFrom: {
          title: 'Forecast',
          value: Utils.decimalFormatter(totalsToDate.forecast.patients_complete || 0, options),
          percent: this.percentageFormatterForCards(
            'Complete',
            targetForecast.patients_enrolled || 0,
            totalsToDate.forecast.patients_complete || 0
          ),
        },
        compareTo: {
          title: 'EDC Actuals',
          value: Utils.decimalFormatter(totalsToDate.actual.patients_complete || 0, options),
          percent: this.percentageFormatterForCards(
            'Complete',
            targetForecast.patients_enrolled || 0,
            totalsToDate.actual.patients_complete || 0
          ),
        },
      },
    ]);
  }

  percentageFormatterForCards(type: string, num1: number, num2: number): string {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    const formattedValue = Utils.percentageFormatter(
      Utils.calculateAsPercentage(num1, num2) / 100,
      options
    );

    return formattedValue === Utils.zeroHyphen ? `0% ${type}` : `${formattedValue} ${type}`;
  }

  async onSaveAll() {
    this.btnLoading$.next(true);
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.btnLoading$.next(false);
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    if (!isInvalid) {
      if (this.isFromScratch) {
        await this.processPatientsCreatedFromScratch();
        this.fillAllCards();
      } else {
        this.patientCurveStore.setLoading(true);

        const gData: UpdateDriverPatientDistributionInput[] = this.gridData$.getValue().map((x) => {
          return {
            id: x.forecast.id || '',
            distribution_month: x.distribution_month,
            cumulative_enrollment_percentage: x.forecast.cumulative_enrollment_percentage,
            distribution_mode: x.forecast.distribution_mode,
            net_patients_enrolled: x.forecast.net_patients_enrolled,
            patients_complete: x.forecast.patients_complete,
            patients_discontinued: x.forecast.patients_discontinued,
            patients_enrolled: x.forecast.patients_enrolled,
            total_patients_enrolled: x.forecast.total_patients_enrolled,
          };
        });

        const responses = await batchPromises(gData, (p) =>
          this.patientService.updatePatientDistribution(p)
        );
        if (!responses.every((x) => x)) {
          this.btnLoading$.next(false);
          this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
          return;
        }
        this.overlayService.success('Patients Table updated successfully!');

        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.PATIENT_DRIVER_DISTRIBUTION_UPDATED,
            entity_type: EntityType.DRIVER,
            entity_id:
              this.currentPatientGroupId === null || this.currentPatientGroupId === ''
                ? ''
                : this.currentPatientGroupId.toString(),
          })
        );
        if (success) {
          this.overlayService.success();
        } else {
          this.overlayService.error(errors);
        }
        await this.cancelEditMode();
        this.fillAllCards();
      }
    }
    this.editMode$.next(false);
    this.btnLoading$.next(false);
  }

  async processPatientsCreatedFromScratch() {
    const thingsToUpdate: listDriverPatientDistributionsQuery[] = [];
    this.gridApi.forEachNode((node) => {
      thingsToUpdate.push(node.data);
    });
    const data = thingsToUpdate.map((row) => ({
      month: Utils.dateFormatter(row.distribution_month, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }),

      patients_enrolled: row.forecast.patients_enrolled,
      patients_discontinued: row.forecast.patients_discontinued,
      patients_complete: row.forecast.patients_complete,
      total_patients_enrolled: row.forecast.total_patients_enrolled,
      net_patients_enrolled: row.forecast.net_patients_enrolled,
      cumulative_enrollment_percentage: row.forecast.cumulative_enrollment_percentage,
    }));
    const key = `${this.getFilePath(
      this.curveControl.value !== '' ? this.currentPatientGroupId.toString() : 'Patient-Curve'
    )}patient-curve-from-scratch.csv`;
    const blob = new Blob([Utils.objectToCsv(data)], { type: 'text/csv' });
    const fileSuccess = await this.apiService.uploadFile(key, new File([blob], key));

    if (fileSuccess) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.processEvent$({
          type: EventType.PATIENT_DRIVER_TEMPLATE_UPLOADED,
          entity_type: EntityType.DRIVER,
          entity_id: this.curveControl.value !== '' ? this.currentPatientGroupId.toString() : '',
          bucket_key: `public/${key}`,
          payload: JSON.stringify({
            should_show_on_document_library: false,
          }),
        })
      );
      if (success) {
        this.loading$.next(true);
        // allows time for PATIENT_DRIVER_TEMPLATE_UPLOADED event to complete
        await new Promise((resolve) => setTimeout(resolve, 5000));
        this.overlayService.success();
        this.isFromScratch = false;
        await this.resetPatientCurveGroup();
        await firstValueFrom(this.patientGroupsService.get().pipe(take(1)));
        await firstValueFrom(
          this.patientService
            .get(this.curveControl.value === '' ? undefined : this.currentPatientGroupId.toString())
            .pipe(take(1))
        );
        if (this.curveControl.value === '') {
          this.curveControl.setValue('Patient Curve');
        }
        this.clonePatient();
        this.gridApi.stopEditing();
        this.editMode$.next(false);
        this.editCell = false;
        this.gridApi.refreshCells({ force: true });
        this.loading$.next(false);
      } else {
        this.overlayService.error(errors);
      }
    } else {
      this.overlayService.error(MessagesConstants.FILE.ERROR_UPLOADING_FILE);
    }
  }

  setEmptyGridData() {
    const { start_date } = this.monthList;
    const { end_date } = this.monthList;

    this.clonedPatient = [];
    if (start_date) {
      let date = Utils.dateParse(`${start_date.slice(0, 8)}01`);
      const month_list: { label: string; value: string }[] = [];

      while (date <= end_date) {
        const value = new Intl.DateTimeFormat('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(date);
        date = Utils.addMonths(date, 1);
        month_list.push({
          value,
          label: Utils.dateFormatter(value, { day: undefined, year: '2-digit' }),
        });
        const dummyPD: listDriverPatientDistributionsQuery = {
          __typename: 'DriverPatientDistribution',
          distribution_month: value,
          forecast: {
            __typename: 'PatientEnrollmentData',
            id: uuidv4(),
            ...omit(this.zeroValues),
            distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
          },
          actual: null,
        };
        if (this.clonedPatient.length < month_list.length) {
          this.clonedPatient.push(dummyPD);
        }
      }
    }
    const newData = Utils.clone(this.clonedPatient);
    newData.forEach((x) => {
      x.forecast.net_patients_enrolled = decimalRoundingToNumber(
        x.forecast.net_patients_enrolled || 0,
        8
      );
      x.forecast.cumulative_enrollment_percentage = decimalRoundingToNumber(
        x.forecast.cumulative_enrollment_percentage || 0,
        8
      );
    });
    this.gridData$.next(newData);
  }

  setGridData(pData: listDriverPatientDistributionsQuery[]) {
    const currentTimeline = this.timelineQuery.getStartEndDateTrial();
    let patientData = [...pData];

    if (currentTimeline.startDate) {
      const timeline = this.distributionTimelineService.getDistributionTimeline(
        {
          startDate: currentTimeline.startDate,
          endDate: currentTimeline.endDate,
        },
        pData
      );

      patientData = patientData.filter(({ distribution_month }) =>
        DateUtils.isInRange(distribution_month, timeline)
      );
    }

    this.clonedPatient = [];
    this.clonedPatient.push(...patientData);

    if (this.clonedPatient.length && this.driverSettingId$.getValue()) {
      this.clonedPatient = this.clonedPatient.map((data) => {
        if (this.shouldPDBeCreatedFromScratch(data)) {
          const newDataId = data.forecast.id ? data.forecast.id : uuidv4();
          this.patientService.createPatient({
            distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
            driver_patient_distribution_id: newDataId,
            distribution_month: data.distribution_month,
            driver_setting_id: this.driverSettingId$.getValue() || '',
            ...omit(this.zeroValues),
          });
          const newPD: listDriverPatientDistributionsQuery = {
            ...omit(data, ['forecast']),
            forecast: {
              __typename: 'PatientEnrollmentData',
              distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
              id: newDataId,
              ...omit(this.zeroValues),
            },
          };
          return newPD;
        }
        return data;
      });
    }

    const newData = Utils.clone(this.clonedPatient);
    newData.forEach((x) => {
      x.forecast.net_patients_enrolled = decimalRoundingToNumber(
        x.forecast.net_patients_enrolled || 0,
        8
      );
      x.forecast.cumulative_enrollment_percentage = decimalRoundingToNumber(
        x.forecast.cumulative_enrollment_percentage || 0,
        8
      );
    });
    this.gridData$.next(newData);
  }

  getFilePath(id: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/sites/${id}/patient-driver/`;
  }

  editGrid() {
    this.editCell = true;
    this.gridApi.refreshCells({ force: true });

    this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: 'forecast.patients_enrolled',
    });
  }

  async cancelEditMode() {
    if (this.isFromScratch) {
      this.populateGridDataWithTimeline();
    } else {
      this.clonePatient();
    }
    this.gridApi.stopEditing(true);
    this.editMode$.next(false);
    this.isNetPatientNegative$.next(false);
    this.editCell = false;
    this.gridApi.refreshCells({ force: true });
  }

  async saveEditMode() {
    const isEqual = this.isEqual$.getValue();
    if (!isEqual) {
      this.overlayService.error(
        'This patient curve is unable to be saved until the following issue is resolved: Patients Enrolled must be equal to the sum of Patients Complete and Patients Discontinued.'
      );
      return;
    }
    await this.onSaveAll();
    this.gridApi.stopEditing(false);
  }

  cellValueChanged() {
    this.calculatePatientCurves();
  }

  cellEditingStopped() {
    this.editingGridCell$.next(false);
  }

  rowPinnedCheck(event: CellEditingStartedEvent) {
    if (event.node.rowPinned) {
      this.gridApi.stopEditing();
    } else {
      this.editingGridCell$.next(true);
    }
  }

  clonePatient() {
    const pData = this.patientQuery.getAll();
    this.patientCurveService.get().pipe(untilDestroyed(this)).subscribe();

    this.setGridData(pData);
  }

  onEditClick(): void {
    this.editMode$.next(true);
    this.editGrid();
  }

  private blendedCurveModalParams(
    item?: PatientCurveModel | null
  ): BlendedCurveModalDataModel<PatientCurveModel> {
    return {
      availableGroups: this.PatientCurveGroup.filter(
        (group) => !group.is_blended && group.patient_group_id
      ).filter(
        (value, index, self) =>
          index === self.findIndex((group) => group.driver_setting_id === value.driver_setting_id)
      ),
      text: {
        title: 'Blended Patient Curve',
        subTitle: 'Select Patient Groups',
      },
      blendedCurve: item || null,
    };
  }

  private updatePatientCurveList(items: PatientCurveModel[]): void {
    this.patientCurveGroups = items;
    this.patientCurveListOptions = items.map((item: PatientCurveModel) => ({
      name: item.name,
      value: item.name,
      showLine: item.showLine,
      isEditable: item.is_blended,
      isDeletable: item.driver_setting_id.length > 1,
    }));
  }

  tabToNextCell(params: TabToNextCellParams): CellPosition | null {
    const previousCell = params.previousCellPosition;
    const lastRowIndex = previousCell.rowIndex;
    const renderedRowCount = params.api?.getModel()?.getRowCount() || 0;
    let nextRowIndex = lastRowIndex;
    let nextCol = previousCell.column;
    if (params.editing) {
      const columnEnum = {
        ENROLLED: 'forecast.patients_enrolled',
        DISCONTINUED: 'forecast.patients_discontinued',
        COMPLETE: 'forecast.patients_complete',
      };
      if (previousCell.column.getColId() === columnEnum.ENROLLED) {
        nextCol = params.api.getColumn(columnEnum.DISCONTINUED) || previousCell.column;
      }
      if (previousCell.column.getColId() === columnEnum.DISCONTINUED) {
        nextCol = params.api.getColumn(columnEnum.COMPLETE) || previousCell.column;
      }
      if (
        previousCell.column.getColId() === columnEnum.COMPLETE &&
        renderedRowCount > lastRowIndex
      ) {
        nextCol = params.api.getColumn(columnEnum.ENROLLED) || previousCell.column;
        nextRowIndex = lastRowIndex + 1;
      }
      if (nextRowIndex >= renderedRowCount) {
        nextRowIndex = renderedRowCount - 1;
      }
      return {
        rowIndex: nextRowIndex,
        column: nextCol,
        rowPinned: previousCell.rowPinned,
      };
    } else {
      if (params.nextCellPosition?.column.getColId().includes('spacer')) {
        const nextColIndex = params.api
          .getAllDisplayedColumns()
          .findIndex((col) => col.getColId() === params.nextCellPosition?.column.getColId());
        const nextColumn = params.api.getAllDisplayedColumns()[nextColIndex + 1];
        return {
          rowIndex: params.previousCellPosition.rowIndex,
          column: nextColumn,
          rowPinned: params.previousCellPosition.rowPinned,
        };
      } else {
        return params.nextCellPosition;
      }
    }
  }
}
