<ng-container *ngIf="(loadedOnce$ | async) === false">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(loadedOnce$ | async) === true">
  <div class="px-8 py-8 bg-aux-gray-light border-b">
    <div class="flex justify-between items-center mb-3.5">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-end">Audit History</h1>
      <div class="flex space-x-2">
        <div class="flex justify-between items-center space-x-4">
          <aux-processing-loader *ngIf="loading$ | async" [message]="processorMessage" />
          <aux-button
            *ngIf="(loading$ | async) === false"
            classList="h-8 bg-white"
            variant="secondary"
            label="Refresh Audit History"
            icon="CloudDownload"
            (click)="triggerFetchAuditHistory()"
            [iconSize]="20"
          />
          <button
            class="focus:outline-none aux-link underline mr-1 text-sm"
            (click)="resetFilter()"
          >
            Reset Filters
          </button>
          <aux-export-excel-button
            [className]="'ml-1 h-8 bg-white'"
            [gridAPI]="exportGridApi"
            [getDynamicExcelParamsCallback]="getDynamicExcelParams"
            pendoTag="audit-history-excel-export-button"
          />
        </div>
      </div>
    </div>

    <form [formGroup]="gridFiltersFormGroup">
      <div class="grid grid-cols-10 content-start mb-6 w-full">
        <div class="flex justify-start col-span-10 w-full">
          <div class="w-full mr-2 mb-5 flex justify-start">
            <div class="min-w-[340px] w-full">
              <div class="h-4 mb-1"></div>
              <aux-input
                placeholder="Search"
                class="mr-2"
                icon="Search"
                formControlName="actionSearch"
                (keyup)="updateSearch($event.target)"
              />
            </div>

            <div class="w-full min-w-[340px]">
              <div class="text-xs mb-1">Action:</div>
              <ng-select
                placeholder="All"
                [clearable]="false"
                [multiple]="true"
                formControlName="selectedAction"
                (add)="onMultiselectChange($event, 'selectedAction')"
              >
                <ng-container *ngVar="actions$ | async as actions">
                  <ng-option *ngIf="actions && actions.length > 1" [value]="''">All</ng-option>
                  <ng-option *ngFor="let action of actions" [value]="action.id">
                    <span [title]="action.name">{{ action.name }}</span>
                  </ng-option>
                </ng-container>
              </ng-select>
            </div>
          </div>
          <div class="w-full mr-2 mb-5" style="width: 480px">
            <div class="text-xs mb-1">Category</div>
            <ng-select
              placeholder="All"
              [clearable]="false"
              [multiple]="true"
              formControlName="selectedCategory"
              (add)="onMultiselectChange($event, 'selectedCategory')"
            >
              <ng-container *ngVar="category$ | async as categories">
                <ng-option *ngIf="categories && categories.length > 1" [value]="''">All</ng-option>
                <ng-option *ngFor="let category of categories" [value]="category.id">
                  <span [title]="category.name">{{ category.name }}</span>
                </ng-option>
              </ng-container>
            </ng-select>
          </div>
          <div class="w-full mb-5" style="width: 480px">
            <div class="text-xs mb-1">User</div>
            <ng-select
              placeholder="All"
              [multiple]="true"
              [clearable]="false"
              formControlName="selectedUser"
              (add)="onMultiselectChange($event, 'selectedUser')"
            >
              <ng-container *ngVar="users$ | async as users">
                <ng-option *ngIf="users && users.length > 1" [value]="''">All</ng-option>
                <ng-option *ngFor="let user of users" [value]="user.sub">
                  <span [title]="user.given_name">{{
                    user.given_name + ' ' + user.family_name
                  }}</span>
                </ng-option>
              </ng-container>
            </ng-select>
          </div>
        </div>
        <div class="flex justify-start col-span-10 w-full">
          <div class="flex justify-start items-start">
            <aux-input
              class="mr-2 w-64"
              label="From"
              placeholder="YYYY-MM-DD"
              [type]="'date'"
              formControlName="fromDate"
            />
            <aux-input
              class="mr-2 w-64"
              label="To"
              placeholder="YYYY-MM-DD"
              [type]="'date'"
              formControlName="toDate"
            />
          </div>
          <div class="w-full mr-2">
            <div class="text-xs mb-1">Vendor</div>
            <ng-select
              class="big-dropdown"
              placeholder="All"
              [multiple]="true"
              [clearable]="false"
              formControlName="selectedVendor"
              [items]="vendorOptions$ | async"
              bindValue="id"
              bindLabel="name"
              (add)="onMultiselectChange($event, 'selectedVendor')"
            >
              <ng-template let-item="item" ng-option-tmp>
                <div
                  class="block truncate"
                  #text
                  [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).name : ''"
                  [auxTooltipPositions]="optionTooltipPositions"
                >
                  {{ item.name }}
                </div>
              </ng-template>
              <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
                <div *ngIf="items.length === 1" class="ng-value">
                  <div *ngFor="let item of items | slice: 0 : 1" class="flex">
                    <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)"
                      >×</span
                    >
                    <span
                      class="ng-value-label overflow-hidden text-ellipsis max-w-[200px]"
                      #text
                      [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).name : ''"
                      [auxTooltipPositions]="optionTooltipPositions"
                    >
                      {{ $any(item).name }}
                    </span>
                  </div>
                </div>
                <div *ngIf="items.length > 1" class="ng-value">
                  <span class="ng-value-label">{{ items.length }} Selected</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="w-full">
            <div class="text-xs mb-1">Site</div>
            <div>
              <aux-multi-select-dropdown
                placeholder="All"
                [items]="$any(siteOptions$ | async)"
                formControlName="selectedSite"
                class="w-full"
                [searchable]="true"
                [customOption]="true"
                bindLabel="textToSearch"
                bindValue="value"
              ></aux-multi-select-dropdown>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>

<div class="px-8 py-6">
  <aux-pagination-grid
    class="audit-history-table"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    [dataSource]="datasource"
    [filterForm]="gridFiltersFormGroup"
    [serverSideFilters]="serverSideFilters"
    [filterValues$]="filterValues$"
    [sortModel$]="sortModel$"
    [exportGridOptions]="exportGridOptions"
    [hidePinnedRow]="true"
    [isAgThemeAlpine]="true"
    [idTable]="'auditHistoryGrid'"
  />
</div>
