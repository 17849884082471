import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  ExcelExportParams,
  GridOptions,
  GridReadyEvent,
  GridApi,
  ColDef,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, map, switchMap } from 'rxjs/operators';
import { ExchangeRatesService } from './exchange-rates.service';
import { MainQuery } from '@shared/store/main/main.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { TimelineService } from '../../forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { TimelineQuery } from '../../forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { AuxExcelStyles } from '@shared/utils';

type GridRowData = { date: string } & Record<string, number>;

const DELAY_TIME_BEFORE_GRID_READY = 100;

const getCurrencyColumn = (currencyName: string): ColDef => ({
  headerName: currencyName,
  headerClass: 'ag-header-align-center font-bold',
  width: 100,
  field: currencyName,
  colId: currencyName,
  maxWidth: 100,
  valueFormatter: Utils.exchangeRateFormatter,
});

@UntilDestroy()
@Component({
  selector: 'aux-currencies',
  templateUrl: './exchange-rates.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangeRatesComponent implements OnInit {
  gridData$ = new BehaviorSubject<GridRowData[]>([]);

  isLoading$ = this.currenciesService.isLoading$;

  gridAPI?: GridApi;

  gridOptions: GridOptions = {
    getRowClass: Utils.oddEvenRowClass,
    defaultColDef: {
      suppressMovable: true,
      suppressMenu: true,
    },
    columnDefs: [
      {
        headerName: 'Date',
        headerClass: 'ag-header-align-center font-bold',
        field: 'date',
        comparator: (date1: string, date2: string) => Utils.dateSort(date1, date2, true),
        width: 140,
        maxWidth: 140,
        resizable: true,
        sortable: true,
        sort: 'desc',
      },
    ],
    excelStyles: [...AuxExcelStyles],
  };

  noRowsMessage$: Observable<string> = this.timelineQuery.select().pipe(
    map(({ timelineExists }) => {
      if (!timelineExists) {
        return 'There is no data available.';
      }

      const routerLink = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`;

      return `<div style="pointer-events: auto;">Please <a class="aux-link" href="${routerLink}">add a site</a> for this page to populate.</div>`;
    })
  );

  constructor(
    private currenciesService: ExchangeRatesService,
    private mainQuery: MainQuery,
    private timelineService: TimelineService,
    private timelineQuery: TimelineQuery
  ) {}

  ngOnInit(): void {
    this.currenciesService.isLoading$.next(true);

    this.timelineService
      .checkIfTimelineMilestoneExist()
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.currenciesService.getCurrenciesGridData$()),
        delay(DELAY_TIME_BEFORE_GRID_READY)
      )
      .subscribe((data) => {
        this.gridData$.next(data.rows as GridRowData[]);
        this.updateColumns(data.columns);
      });
  }

  private updateColumns(currencyColumnNames: string[]) {
    const { columnDefs } = this.gridOptions;

    this.gridAPI?.setGridOption('columnDefs', [
      ...(columnDefs || []),
      ...this.getCurrencyColumns(currencyColumnNames),
    ]);

    this.gridAPI?.sizeColumnsToFit();
  }

  private getCurrencyColumns(currencyColumnNames: string[]) {
    return currencyColumnNames.reduce<ColDef[]>((accum, currencyName) => {
      accum.push(getCurrencyColumn(currencyName));

      return accum;
    }, []);
  }

  onGridReady = ({ api }: GridReadyEvent) => {
    this.gridAPI = api;
  };

  getDynamicExcelParams = (): ExcelExportParams => {
    const name = this.mainQuery.getSelectedTrial()?.short_name;

    return {
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      sheetName: 'Exchange Rates',
      fileName: 'auxilius-exchange-rates.xlsx',
    };
  };
}
