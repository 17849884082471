import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabGroupConfig } from '@components/tab-group/route-tab-group.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-ops-admin',
  templateUrl: './ops-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class OpsAdminComponent implements OnInit {
  rightSideContainer = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  activeTabIndex$ = new BehaviorSubject<number>(0);

  tabs: TabGroupConfig[] = [
    {
      label: 'EDC Converter',
      route: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.INDEX,
    },
    {
      label: 'JE Generator',
      route: ROUTING_PATH.OPS_ADMIN.JE_GENERATOR,
    },
    {
      label: 'Extractor',
      route: ROUTING_PATH.OPS_ADMIN.EXTRACTOR,
    },
    {
      label: 'Site Payments: Invoice Generator',
      route: ROUTING_PATH.OPS_ADMIN.INVOICE_GENERATOR,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.activeTabIndex) {
        this.activeTabIndex$.next(parseInt(params.activeTabIndex, 10));
        this.router.navigate([], { queryParams: {}, replaceUrl: true });
      }
    });
  }
}
