import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FileManagerUploadedFilesComponent } from '@components/file-manager/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { DocumentType } from '@services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';

@Component({
  selector: 'aux-investigator-transaction-supporting-section',
  template: `
    <div class="grid grid-cols-2 gap-7">
      <div>
        <aux-input
          label="Note"
          inputClassName="grow resize-none"
          class="text-xs h-full !flex flex-col"
          [textArea]="true"
          [rows]="2"
          [validators]="'required'"
          [formControl]="noteFormControl"
          [showRequiredAsterisk]="true"
          [fieldName]="'Note'"
        />
      </div>
      <div>
        <p class="block mb-1 text-xs">Supporting Documents</p>
        <aux-file-manager
          #fileManager
          class="h-32"
          [fetchFilesOnInit]="false"
          [eager]="false"
          [insertDocument]="false"
        />
        <aux-file-manager-uploaded-files [fileManager]="fileManager" />
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    FileManagerComponent,
    FileManagerUploadedFilesComponent,
  ],
})
export class InvestigatorTransactionSupportingSectionComponent {
  @Input({ required: true }) noteFormControl!: FormControl;

  @ViewChild('fileManager') fileManager?: FileManagerComponent;

  constructor(private mainQuery: MainQuery) {}

  async saveSupportingSection() {
    if (this.noteFormControl.invalid) {
      return;
    }

    const files =
      this.fileManager?.fileQuery.getAll({ filterBy: ({ uploaded }) => !uploaded }) || [];

    files.forEach((file) => {
      if (file?.rawFile) {
        this.fileManager?.fileStore.update(file.id, {
          ...file,
          bucket_key: `investigator-transactions/${this.mainQuery.getValue().trialKey}/${new Date().toISOString()}_${file.fileName}`,
        });
      }
    });

    await this.fileManager?.fileService.uploadFiles({
      documentType: DocumentType.DOCUMENT_MANUAL_TRANSACTION_SUPPORT,
    });

    return {
      note: this.noteFormControl.value,
      documents: this.fileManager?.fileQuery.getAll({ filterBy: ({ uploaded }) => uploaded }) || [],
    };
  }
}
