import dayjs from 'dayjs';

export const isVisitMonthEqualWithCurrentOpenMonth = (
  visitDate: string,
  currentOpenMonth: string
): boolean => {
  const date = dayjs(visitDate, 'YYYY-MM-DD');
  const compareDate = dayjs(currentOpenMonth, 'YYYY-MM-DD');

  return date.year() === compareDate.year() && date.month() === compareDate.month();
};
