import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Option } from '@components/components.type';
import { AgControlComponent, AgControlComponentParams, noop } from './ag-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

interface AgDropdownComponentParams extends AgControlComponentParams {
  options: Option[];
  disableTooltip?: string;
  classes?: string;
  searchable?: boolean;
}

@Component({
  template: `<div *ngIf="formGroup$ | async" [formGroup]="$any(formGroup$ | async)">
    <ng-select
      *ngIf="isReady$ | async"
      placeholder="Select"
      [multiple]="false"
      [appendTo]="'body'"
      [searchable]="!!params.searchable"
      [items]="params.options"
      [bindValue]="'value'"
      [clearable]="true"
      [ngClass]="hasError ? 'invalid ' + params.classes : params.classes"
      [formControl]="formControl"
      [auxTooltip]="formControl.disabled ? params.disableTooltip || '' : ''"
      (change)="changeHandler($event)"
    >
      <ng-template let-item="item" ng-option-tmp>
        <p
          class="pr-4 text-sm font-bold leading-5 overflow-hidden text-ellipsis"
          [ngClass]="{ 'font-bold': !!item.subTitle }"
        >
          {{ item.label }}
        </p>

        <p class="pr-4 text-sm italic leading-5 overflow-hidden text-ellipsis">
          {{ item.subTitle }}
        </p>
      </ng-template>
    </ng-select>
  </div>`,
  styleUrls: ['./ag-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule, TooltipDirective, AsyncPipe, NgIf, NgFor, NgClass],
})
export class AgDropdownComponent extends AgControlComponent {
  params!: AgDropdownComponentParams;

  agInit(params: AgDropdownComponentParams): void {
    this.params = params;
    this.value = params.value;
    this.fieldName = params.colDef?.field;
    this.changeHandler = params.changeHandler ?? noop;
    this.updateView(params);
  }
}
