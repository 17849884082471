<div>
  @if (isQuarterCloseEnabled() && isClosingPanelEnabled()) {
    <aux-workflow-panel
      className="mb-6"
      [workflowName]="workflowName"
      [processing]="iCloseMonthsProcessing()!"
      [hasUserPermissions]="userHasLockPatientSiteCurvePermission()"
    />
  }

  <div class="relative">
    <aux-route-tab-group class="absolute" [tabs]="tabs" [variant]="'button'" />
  </div>

  <router-outlet />
</div>
