<div class="bg-aux-gray-light">
  <div class="px-7 pt-8">
    <div class="flex justify-between items-center">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11">Settings</h1>
      <div class="flex">
        <button
          *ngIf="(editMode | async) === false"
          [disabled]="(hasTrialSettingsPermission$ | async) === false"
          class="btn--primary pl-3"
          (click)="editMode.next(true)"
          [auxTooltip]="
            (hasTrialSettingsPermission$ | async) === false ? editButtonDisabledTooltip : ''
          "
        >
          <aux-icon name="Pencil" class="mr-2" />
          Edit
        </button>
        <button
          *ngIf="(editMode | async) === true"
          class="btn--success pl-3"
          (click)="onSaveEdits()"
        >
          <aux-icon name="Check" class="mr-2" />
          Save
        </button>
        <button
          *ngIf="(editMode | async) === true"
          class="aux-link focus:outline-none mx-4"
          type="button"
          (click)="editModeCancel()"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="flex space-x-2">
      <ng-container [ngTemplateOutlet]="service.rightSideContainer | async" />
    </div>
  </div>
  <div class="px-7 py-4 border-b">
    <div class="flex -ml-1">
      <aux-icon name="InfoCircle" class="mr-2" />
      <h2 class="text-xl text-aux-gray-darkest font-bold h-9">Trial Info</h2>
    </div>
    <ng-container *ngIf="(editMode | async) === false">
      <div *ngIf="currentTrial" class="grid grid-cols-4 gap-5 pr-4 col-span-1 tabular-nums">
        <div>
          <label for="nct_id" class="block mb-1 text-xs">Trial Name</label>
          <span [title]="currentTrial.nct_id">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              id="nct_id"
              [disabled]="true"
              placeholder="{{ currentTrial.nct_id }}"
            />
          </span>
        </div>
        <div>
          <label for="short_name" class="block mb-1 text-xs">Trial Short Name</label>
          <span [title]="currentTrial.short_name">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              id="short_name"
              [disabled]="true"
              placeholder="{{ currentTrial.short_name }}"
            />
          </span>
        </div>
        <div>
          <label for="organization_name" class="block mb-1 text-xs">Sponsor</label>
          <span [title]="currentTrial.sponsor_organization.name">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              id="organization_name"
              [disabled]="true"
              placeholder="{{ currentTrial.sponsor_organization.name }}"
            />
          </span>
        </div>
        <div>
          <label for="trial_status" class="block mb-1 text-xs">Auxilius Trial Status</label>
          <ng-select
            id="trial_status"
            class="select h-10 text-sm text-aux-gray-lightest/75"
            [ngModel]="currentTrial.implementation_status"
            bindLabel="label"
            bindValue="key"
            label="Implementation Status"
            auxFormError
            [items]="implementationStatusOptions"
            [clearable]="false"
            [searchable]="false"
            [disabled]="true"
            [multiple]="false"
            [required]="false"
          />
        </div>
        <div>
          <label for="therapy_area" class="block mb-1 text-xs">Therapy Area</label>
          <span [title]="currentTrial.therapy_area">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              id="therapy_area"
              [disabled]="true"
              placeholder="{{ currentTrial.therapy_area }}"
            />
          </span>
        </div>
        <div>
          <label for="start_date" class="block mb-1 text-xs">Auxilius Start Date</label>
          <input
            type="text"
            class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
            id="start_date"
            [disabled]="true"
            placeholder="{{ currentTrial.auxilius_start_date }}"
          />
        </div>
        <div>
          <label for="trial_program" class="block mb-1 text-xs">Program</label>
          <span [title]="currentTrial.program">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              id="trial_program"
              [disabled]="true"
              placeholder="{{ currentTrial.program }}"
            />
          </span>
        </div>
        <div>
          <label for="trial_project" class="block mb-1 text-xs">Project</label>
          <span [title]="currentTrial.project">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              id="trial_project"
              [disabled]="true"
              placeholder="{{ currentTrial.project }}"
            />
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(editMode | async) === true">
      <form *ngIf="currentTrial" [formGroup]="fg" (ngSubmit)="onSaveEdits()">
        <div class="grid grid-cols-4 gap-5 pr-4 col-span-1 tabular-nums">
          <div>
            <label for="nct_id_edit" class="block mb-1 text-xs">Trial Name</label>
            <span [title]="currentTrial.nct_id">
              <input
                type="text"
                class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
                id="nct_id_edit"
                [disabled]="true"
                placeholder="{{ currentTrial.nct_id }}"
              />
            </span>
          </div>
          <aux-input formControlName="short_name" label="Trial Short Name" validators="required" />
          <div>
            <label for="organization_name_edit" class="block mb-1 text-xs">Sponsor</label>
            <span [title]="currentTrial.sponsor_organization.name">
              <input
                type="text"
                class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
                id="organization_name_edit"
                [disabled]="true"
                placeholder="{{ currentTrial.sponsor_organization.name }}"
              />
            </span>
          </div>
          <div>
            <label for="trial_status_edit" class="block mb-1 text-xs">Auxilius Trial Status</label>
            <ng-select
              #select
              id="trial_status_edit"
              class="select h-10 text-sm custom-outline"
              formControlName="implementation_status"
              bindLabel="label"
              bindValue="key"
              label="Implementation Status"
              auxFormError
              [ngClass]="select.isOpen ? '' : 'text-aux-gray-lightest/75'"
              [items]="implementationStatusOptions"
              [clearable]="false"
              [searchable]="false"
              [disabled]="false"
              [multiple]="false"
              [required]="false"
            />
          </div>
          <aux-input formControlName="therapy_area" label="Therapy Area" validators="required" />
          <aux-input
            label="Auxilius Start Date"
            class="grow"
            formControlName="auxilius_start_date"
            [type]="'date'"
          />
          <aux-input class="grow" formControlName="program" label="Program" />
          <aux-input class="grow" formControlName="project" label="Project" />
        </div>
      </form>
    </ng-container>
  </div>
</div>
<div>
  <div class="pl-4 mb-8 bg-aux-gray-light border-b">
    <aux-route-tab-group [tabs]="tabs" />
  </div>

  <div class="px-7">
    <router-outlet />
  </div>
</div>
