import { ChangeDetectionStrategy, Component, inject, input, viewChild } from '@angular/core';
import { TabGroupComponent } from '@shared/components/tab-group/tab-group.component';
import { AuxTabComponent } from '@shared/components/tab-group/aux-tab.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { InvoiceItemsComponent } from './invoice-items.component';
import { InvoiceItemsViaPdfComponent } from './invoice-items-via-pdf.component';

@Component({
  standalone: true,
  selector: 'aux-invoice-tabs',
  template: `
    @if (isOcrItemsEnabled() || isIntegrationItemsEnabled()) {
      <aux-tab-group>
        @if (isOcrItemsEnabled()) {
          <aux-tab label="Line Items (via PDF Parser)">
            <aux-invoice-items-via-pdf [invoice]="invoice()" />
          </aux-tab>
        }
        @if (isIntegrationItemsEnabled()) {
          <aux-tab label="Line Items (via Integration)">
            <aux-invoice-items-tab [invoice]="invoice()" [items]="invoice().line_items || []" />
          </aux-tab>
        }
      </aux-tab-group>
    }
  `,
  imports: [TabGroupComponent, AuxTabComponent, InvoiceItemsComponent, InvoiceItemsViaPdfComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceTabsComponent {
  launchDarklyService = inject(LaunchDarklyService);

  invoice = input.required<InvoiceModel>();

  invoiceItemsViaPdf = viewChild(InvoiceItemsViaPdfComponent);

  isOcrItemsEnabled = this.launchDarklyService.$select((f) => f.tab_invoice_line_items_ocr);
  isIntegrationItemsEnabled = this.launchDarklyService.$select(
    (f) => f.tab_invoice_line_items_integration
  );

  canDeactivate() {
    const cmp = this.invoiceItemsViaPdf();
    return cmp ? cmp.canDeactivate() : true;
  }
}
