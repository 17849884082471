import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BudgetType, Currency, GqlService } from '@services/gql.service';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Utils } from '@services/utils';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { MainQuery } from '@shared/store/main/main.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { CompareBudgetVersion, CompareGridData } from '@components/compare/compare.component';

interface CompareBudgetVersionList {
  budget_version_id: string;
  budget_type: string;
  budget_name: string;
  version: string;
  create_date: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-compare-new',
  templateUrl: './compare-new.component.html',
  styleUrls: ['./compare-new.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareNewComponent implements OnInit {
  selectedFrom = new UntypedFormControl('');

  selectedTo = new UntypedFormControl('');

  selectedVendor = new UntypedFormControl('');

  budgetVersionList$ = new BehaviorSubject<CompareBudgetVersionList[]>([]);

  budgetVersionToList$ = new BehaviorSubject<CompareBudgetVersionList[]>([]);

  loading$ = new BehaviorSubject(false);

  showAnalyticsSection$: Observable<boolean>;

  fromBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  toBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  orgCurrency = Currency.USD;

  currencySymbol = '$';

  totals = {
    from_total_cost: 0,
    to_total_cost: 0,
    variance_total_cost: 0,
    variance_total_cost_AC: '0',
    variance_percentage_AC: '0',
    services_total_percent_AC: 0,
    services_total_percent_AC_Formatted: '0%',
    services_total_AC: `${this.currencySymbol}0`,
    investigator_total_percent_AC: 0,
    investigator_total_percent_AC_Formatted: '0%',
    investigator_total_AC: `${this.currencySymbol}0`,
    pass_through_total_percent_AC: 0,
    pass_through_total_percent_AC_Formatted: '0%',
    pass_through_total_AC: `${this.currencySymbol}0`,
    category_type_AC: Utils.zeroHyphen,
    category_amount_AC: Utils.zeroHyphen,
  };

  constructor(
    private gqlService: GqlService,
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private organizationService: OrganizationService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.selectedFrom.disable();
    this.selectedTo.disable();
    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_compare_analytics
    );
    this.organizationQuery
      .selectActive()
      .pipe(
        switchMap((org) => {
          if (org) {
            this.loading$.next(true);
            return combineLatest([
              this.gqlService.listBudgetVersions$([BudgetType.BUDGET_PRIMARY], org.id),
              this.gqlService.listBudgetVersions$([BudgetType.BUDGET_SECONDARY], org.id),
            ]).pipe(
              tap(([primary, secondary]) => {
                let max_create_date = Number.MIN_SAFE_INTEGER;
                let max_create_id = '';

                let primaryData = (primary.data || []).map((x) => {
                  if (new Date(x.create_date).valueOf() > max_create_date) {
                    max_create_id = x.budget_version_id;
                    max_create_date = new Date(x.create_date).valueOf();
                  }
                  return {
                    budget_version_id: x.budget_version_id,
                    budget_type: x.is_change_order ? 'Change Order' : 'Budget Upload',
                    budget_name: x.is_change_order
                      ? Utils.replaceChangeOrderFormat(x.budget_name)
                      : x.budget_name,
                    version: x.version,
                    create_date: Utils.dateFormatter(x.create_date),
                  };
                });
                // Adds (Current) to the latest budget version
                primaryData = primaryData.map((x) => {
                  return {
                    budget_version_id: x?.budget_version_id,
                    budget_type: x.budget_type,
                    budget_name: `${x?.budget_name}${
                      x.budget_version_id === max_create_id ? ' (Current)' : ''
                    }`,
                    version: x?.version,
                    create_date: x.create_date,
                  };
                });
                const secondaryData = (secondary.data || []).map((x) => {
                  return {
                    budget_version_id: x.budget_version_id,
                    budget_type: 'Scenario Budget',
                    budget_name: x.budget_name,
                    version: x.version,
                    create_date: Utils.dateFormatter(x.create_date),
                  };
                });
                this.handleSubscribe();
                this.budgetVersionList$.next([...primaryData, ...secondaryData]);
                this.loading$.next(false);
              })
            );
          }
          return EMPTY;
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe();

    this.selectedVendor.valueChanges.pipe(untilDestroyed(this)).subscribe((x) => {
      if (x) {
        this.selectedFrom.enable();
      }
    });
  }

  ngOnInit(): void {
    this.organizationService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);

          if (vendors[0].currency) {
            this.orgCurrency = Currency[vendors[0].currency];
            this.setOrgCurrencySymbol();
            this.setDefaultTotals();
          }
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });

    this.handleSubscribe();
  }

  handleSubscribe() {
    this.selectedFrom.valueChanges.pipe(untilDestroyed(this)).subscribe((x) => {
      this.selectedTo.enable();
      const toList = this.budgetVersionList$
        .getValue()
        .filter((z) => z.budget_version_id !== x.budget_version_id);

      this.budgetVersionToList$.next(toList);
    });
  }

  onVendorSelected(vendorId: string) {
    this.budgetVersionList$.next([]);
    this.budgetVersionToList$.next([]);
    this.selectedFrom = new UntypedFormControl('');
    this.selectedTo = new UntypedFormControl('');
    this.organizationStore.setActive(vendorId || null);
    const activeOrg = this.organizationQuery.getActive();
    if (activeOrg?.currency) {
      this.orgCurrency = Currency[activeOrg.currency];
      this.setOrgCurrencySymbol();
      this.setDefaultTotals();
    }
  }

  compareBudget() {
    const from = this.selectedFrom.value as CompareBudgetVersionList;
    const to = this.selectedTo.value as CompareBudgetVersionList;

    this.fromBudgetVersion$.next({
      budget_type:
        ['Budget Upload', 'Change Order'].indexOf(from.budget_type) !== -1
          ? BudgetType.BUDGET_PRIMARY
          : BudgetType.BUDGET_SECONDARY,
      budget_name: `${from.budget_name} (Baseline)`.replace('(Current) ', ''),
      budget_version_id: from.budget_version_id,
    });

    this.toBudgetVersion$.next({
      budget_type:
        ['Budget Upload', 'Change Order'].indexOf(to.budget_type) !== -1
          ? BudgetType.BUDGET_PRIMARY
          : BudgetType.BUDGET_SECONDARY,
      budget_name: `${to.budget_name}`.replace('(Current)', ''),
      budget_version_id: to.budget_version_id,
    });
  }

  acPercentFormat(v: number) {
    return Utils.agPercentageFormatterAbsolute(
      {
        value: v,
      },
      { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    );
  }

  acCurrFormat(v: number) {
    return Utils.currencyFormatter(
      v,
      {
        currencySign: 'accounting',
      },
      this.orgCurrency
    );
  }

  onBudgetData(arr: CompareGridData[]) {
    // do some calculations with array and update the totals in this component
    const diffs = Utils.getCompareDiffs(arr);

    this.totals.variance_total_cost_AC = this.acCurrFormat(diffs.variance_total_cost);
    this.totals.variance_percentage_AC = this.acPercentFormat(diffs.variance_total_cost_percentage);
    this.totals.services_total_AC = this.acCurrFormat(diffs.delta_services);
    this.totals.services_total_percent_AC = diffs.delta_services / diffs.variance_total_cost;
    this.totals.services_total_percent_AC_Formatted = this.acPercentFormat(
      this.totals.services_total_percent_AC
    );
    this.totals.investigator_total_AC = this.acCurrFormat(diffs.delta_investigator);
    this.totals.investigator_total_percent_AC =
      diffs.delta_investigator / diffs.variance_total_cost;
    this.totals.investigator_total_percent_AC_Formatted = this.acPercentFormat(
      this.totals.investigator_total_percent_AC
    );
    this.totals.pass_through_total_AC = this.acCurrFormat(diffs.delta_pass_through);
    this.totals.pass_through_total_percent_AC =
      diffs.delta_pass_through / diffs.variance_total_cost;
    this.totals.pass_through_total_percent_AC_Formatted = this.acPercentFormat(
      this.totals.pass_through_total_percent_AC
    );
    this.totals.category_type_AC = diffs.max_diff_activity;
    this.totals.category_amount_AC = this.acCurrFormat(diffs.max_activity_diff);
  }

  setOrgCurrencySymbol() {
    const currency: string = Currency[this.orgCurrency];
    const l = Utils.getCurrenySymbol(currency as Currency);
    if (l) {
      this.currencySymbol = l;
    }
  }

  setDefaultTotals() {
    this.totals = {
      from_total_cost: 0,
      to_total_cost: 0,
      variance_total_cost: 0,
      variance_total_cost_AC: '0',
      variance_percentage_AC: '0',
      services_total_percent_AC: 0,
      services_total_percent_AC_Formatted: '0%',
      services_total_AC: `${this.currencySymbol}0`,
      investigator_total_percent_AC: 0,
      investigator_total_percent_AC_Formatted: '0%',
      investigator_total_AC: `${this.currencySymbol}0`,
      pass_through_total_percent_AC: 0,
      pass_through_total_percent_AC_Formatted: '0%',
      pass_through_total_AC: `${this.currencySymbol}0`,
      category_type_AC: Utils.zeroHyphen,
      category_amount_AC: Utils.zeroHyphen,
    };
  }
}
