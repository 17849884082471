import { NgModule } from '@angular/core';
import { CardComponent } from '@shared/components/card/card.component';
import { ButtonListComponent } from '@shared/components/button-list/button-list.component';
import { PaginationGridComponent } from '@shared/components/pagination-grid/pagination-grid.component';
import { TableSkeletonComponent } from '@shared/components/table-skeleton/table-skeleton.component';
import { VendorDropdownComponent } from '@shared/components/vendor-dropdown/vendor-dropdown.component';
import { PaginationPanelComponent } from '@shared/components/pagination-panel/pagination-panel.component';
import { ConfirmationActionModalComponent } from '@shared/components/confirmation-action-modal';
import { SkeletonLoaderDirective } from '@shared/directives/skeleton-loader.directive';

const sharedComponents = [
  CardComponent,
  PaginationGridComponent,
  PaginationPanelComponent,
  TableSkeletonComponent,
  VendorDropdownComponent,
  ButtonListComponent,
  SkeletonLoaderDirective,
  ConfirmationActionModalComponent,
] as const;

@NgModule({
  imports: [...sharedComponents],
  exports: [...sharedComponents],
})
export class SharedModule {}
