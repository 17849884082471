import { MainService } from '@shared/store/main/main.service';
import { ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { OverlayService } from '@services/overlay.service';
import {
  UserDialogComponent,
  UserDialogSubmit,
} from '@components/dialogs/user-dialog/user-dialog.component';
import { TrialUserQuery } from '@models/trial-users/trial-user.query';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { TrialUserState, UserModel } from '@models/trial-users/trial-user.store';
import { MainQuery } from '@shared/store/main/main.query';
import { DepartmentType, PermissionType } from '@services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { Utils } from '@services/utils';
import { getEntityType } from '@datorama/akita';
import { MessagesConstants } from '@constants/messages.constants';

@UntilDestroy()
@Component({
  selector: 'aux-users',
  templateUrl: './users.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent {
  users$?: Observable<getEntityType<TrialUserState>[]>;

  auxUsers$?: Observable<getEntityType<TrialUserState>[]>;

  showAuxSupport = false;

  filterValue = '';

  loading$ = combineLatest([
    this.trialUserQuery.selectLoading(),
    this.vendorsQuery.selectLoading(),
  ]).pipe(
    map(([users, vendors]) => {
      this.showAuxSupport = false;
      return users || vendors;
    })
  );

  userHasUpdateUserPermission = false;

  private _searchSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private overlayService: OverlayService,
    public trialUserQuery: TrialUserQuery,
    private trialUserService: TrialUserService,
    private mainQuery: MainQuery,
    private mainService: MainService,
    private vendorsQuery: OrganizationQuery,
    public authService: AuthService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.setUserPermissions();
    this.setUserLists();
    this.subscribeToSearch();
  }

  onAddUser() {
    const firstOrganization = this.vendorsQuery.getAll()[0]?.id;
    const ref = this.overlayService.open<
      UserDialogSubmit,
      { mode: 'edit' | 'add'; formValue?: unknown }
    >({ content: UserDialogComponent, data: { mode: 'add' } });
    ref.afterClosed$.subscribe(async (value) => {
      if (value.data) {
        const { email, family_name, given_name, organization_id, title, role, department } =
          value.data;

        await this.trialUserService.add({
          trial_id: this.mainQuery.getValue().trialKey,
          organization_id: organization_id === 'Aux Support' ? firstOrganization : organization_id,
          given_name,
          department,
          family_name,
          email,
          title,
          role,
        });

        await firstValueFrom(this.mainService.getUserList$());
      }
    });
  }

  getDepartmentString(departments: (DepartmentType | null)[] | null | undefined) {
    if (departments) {
      return departments
        .map((x) => {
          if (x) {
            return Utils.DEPARTMENT_OPTIONS[x];
          }
          return '';
        })
        .join(', ');
    }
    return '';
  }

  onEditUser(user: UserModel) {
    console.log('user', user);
    let user_organization_id = user.organization?.id;
    if (user.email?.includes('@auxili.us')) {
      user_organization_id = 'Aux Support';
    }
    const firstOrganization = this.vendorsQuery.getAll()[0]?.id;
    const ref = this.overlayService.open<
      UserDialogSubmit,
      { mode: 'edit' | 'add'; formValue?: unknown }
    >({
      content: UserDialogComponent,
      data: { mode: 'edit', formValue: { ...user, organization_id: user_organization_id } },
    });

    ref.afterClosed$.subscribe(async (value) => {
      if (value.data) {
        const { email, family_name, given_name, title, organization_id, role, department } =
          value.data;
        const { success } = await this.trialUserService.update({
          email,
          family_name,
          given_name,
          department,
          organization_id: organization_id === 'Aux Support' ? firstOrganization : organization_id,
          role,
          title,
          sub: user.sub as string,
        });

        if (success) {
          this.overlayService.success('User updated successfully!');
        }
      }
    });
  }

  onRemoveUser(user: UserModel) {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove User',
      message: `Are you sure that you want to remove ${user.given_name} ${user.family_name}?`,
      okBtnText: 'Remove',
    });

    resp.afterClosed$.subscribe(async (value) => {
      if (value.data?.result) {
        await this.trialUserService.remove(user.sub as string);
      }
    });
  }

  toggleAuxSupport() {
    this.showAuxSupport = !this.showAuxSupport;
  }

  filterChanges(): void {
    if (this.filterValue !== this._searchSubject.getValue()) {
      this.setUserLists();
    }
  }

  updateSearch(target: EventTarget | null): void {
    const searchText = target as HTMLInputElement;
    if (searchText.value.length > 2 || searchText.value.length === 0) {
      this._searchSubject.next(this.filterValue);
    }
  }

  noPermissionsTooltipText(): string {
    return this.userHasUpdateUserPermission
      ? ''
      : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  }

  private filterUserByText = (user: getEntityType<TrialUserState>) => {
    const comparedValue = this.filterValue.toLowerCase();
    return (
      (user.given_name && user.given_name.toLowerCase().includes(comparedValue)) ||
      (user.family_name && user.family_name.toLowerCase().includes(comparedValue)) ||
      (user.title && user.title.toLowerCase().includes(comparedValue)) ||
      !!(user.email && user.email.toLowerCase().includes(comparedValue))
    );
  };

  private sortByUserName = (user1: TrialUserState, user2: TrialUserState) =>
    Utils.alphaNumSort(
      `${user1.given_name} ${user1.family_name}`.toLowerCase(),
      `${user2.given_name} ${user2.family_name}`.toLowerCase()
    );

  private setUsers$(): void {
    this.users$ = this.trialUserQuery.selectAll({
      filterBy: [
        (user) => (user.email ? !user.email.includes('@auxili.us') : true),
        this.filterUserByText,
      ],
      sortBy: this.sortByUserName,
    });
  }

  private setAuxUsers$(): void {
    this.auxUsers$ = this.trialUserQuery.selectAll({
      filterBy: [
        (user) => (user.email ? user.email.includes('@auxili.us') : false),
        this.filterUserByText,
      ],
      sortBy: this.sortByUserName,
    });
  }

  private subscribeToSearch(): void {
    this._searchSubject.pipe(untilDestroyed(this), debounceTime(500)).subscribe((searchText) => {
      if (searchText.length > 2 || searchText.length === 0) {
        this.setUserLists();
        this.changeDetector.detectChanges();
      }
    });
  }

  private setUserLists(): void {
    this.setUsers$();
    this.setAuxUsers$();
  }

  async resendInvitation(user: UserModel) {
    const { email } = user;

    if (email) {
      await this.trialUserService.reinvite(email);
    }
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_USERS],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasUpdateUserPermission]) => {
        this.userHasUpdateUserPermission = userHasUpdateUserPermission;
      });
  }
}
