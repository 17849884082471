import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { BudgetCurrencyQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget-currency.query';
import { BudgetCurrencyType } from '@pages/budget-page/tabs/budget-enhanced/budget-type';
import { AgSetColumnsVisible } from '@shared/utils';
import { isNull } from 'lodash';

@Component({
  templateUrl: './ag-header-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderActionsComponent {
  constructor(private budgetCurrencyQuery: BudgetCurrencyQuery) {}

  params!: ICellRendererParams;

  change$ = new BehaviorSubject(true);

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (
      !isNull(this.params.context) &&
      Object.prototype.hasOwnProperty.call(this.params.context, 'isExpanded')
    ) {
      this.change$.next(this.params.context.isExpanded);
    } else {
      this.params.api.setGridOption('context', { isExpanded: true });
    }
  }

  onChangeClick() {
    if (this.budgetCurrencyQuery.getValue().currency === BudgetCurrencyType.USD) {
      AgSetColumnsVisible({
        gridApi: this.params.api,
        keys: [
          'investigator_contract_amount',
          'passthrough_contract_amount',
          'service_contract_amount',
          'discount_contract_amount',
          'investigator_amount',
          'passthrough_amount',
          'service_amount',
          'discount_amount',
        ],
        visible: !this.change$.getValue(),
      });
    } else {
      AgSetColumnsVisible({
        gridApi: this.params.api,
        keys: [
          'investigator_contract_amount',
          'passthrough_contract_amount',
          'service_contract_amount',
          'discount_contract_amount',
        ],
        visible: !this.change$.getValue(),
      });
    }
    this.change$.next(!this.change$.getValue());
    this.params.api.setGridOption('context', { isExpanded: this.change$.getValue() });
  }
}
