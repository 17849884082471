import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { ModalDialogComponent } from './modal-dialog.component';
import { ConfirmationActionModalComponent } from '@shared/components/confirmation-action-modal';

@Component({
  selector: 'aux-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  constructor(private overlayService: OverlayService) {}

  openOneButtonModal() {
    this.overlayService.open<
      unknown,
      { header: string; useDesignSystemStyling: boolean; displayX: boolean; formValue?: null }
    >({
      content: ModalDialogComponent,
      data: { header: '1 Button', useDesignSystemStyling: true, displayX: true },
    });
  }

  openTwoButtonsModal() {
    this.overlayService.open<
      unknown,
      { header: string; useDesignSystemStyling: boolean; displayX: boolean; formValue?: null }
    >({
      content: ModalDialogComponent,
      data: { header: '2 Buttons', useDesignSystemStyling: true, displayX: false },
    });
  }

  openModalWithExecutableAction() {
    this.overlayService.open<{ success: boolean }>({
      baseComponent: ConfirmationActionModalComponent,
      content:
        'This will permanently delete the selected vendor. This action cannot be undone and will remove all associated data including budget and accruals.',
      data: {
        header: 'Delete Vendor?',
        keywordToExecuteAction: 'Delete Vendor',
        okButtonText: 'Delete Vendor',
      },
    });
  }

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((_, i) => i + 1);
  }
}
