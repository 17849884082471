import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { IconComponent } from '@components/icon/icon.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

interface AgDotsParams extends ICellRendererParams {
  disabled?: boolean;
  tooltip?: string;
  options: {
    optionClass?: string;
    onClick: VoidFunction;
    title: string;
  }[];
}

@Component({
  template: `
    <aux-icon
      *ngIf="params.disabled"
      name="DotsVertical"
      [size]="20"
      class="text-aux-gray-dark cursor-not-allowed pt-1"
      [auxTooltip]="params.tooltip || ''"
    />
    <aux-icon
      *ngIf="!params.disabled"
      [cdkMenuTriggerFor]="menu"
      name="DotsVertical"
      [size]="20"
      class="text-aux-blue pt-1"
    />
    <ng-template #menu>
      <div class="menu" cdkMenu>
        <button
          class="menu-item"
          *ngFor="let option of params.options"
          cdkMenuItem
          (click)="option.onClick()"
          [ngClass]="option.optionClass"
        >
          {{ option.title }}
        </button>
      </div>
    </ng-template>
  `,
  styleUrls: ['./ag-dots.components.scss'],
  standalone: true,
  imports: [
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    IconComponent,
    NgClass,
    NgFor,
    NgIf,
    TooltipDirective,
  ],
})
export class AgDotsComponent implements ICellRendererAngularComp {
  params!: AgDotsParams;

  agInit(params: AgDotsParams): void {
    this.params = params;
  }
  refresh(): boolean {
    return false;
  }
}
