import { Component, computed, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { ComponentsModule } from '@components/components.module';
import {
  BeActivitiesAttributesModalRowData,
  InAppDocumentState,
} from '../be-activities-attributes-modal.model';
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { OverlayService } from '@services/overlay.service';
import { TableConstants } from '@constants/table.constants';
import {
  AgInlineHeaderComponent,
  AgInlineHeaderComponentParams,
} from '../../ag-inline-header.component';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AgTooltipComponent } from '../../ag-tooltip.component';
import { FormsModule } from '@angular/forms';
import { WarningModalComponent, WarningModalParams } from '../../warning-modal.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { BeInlineCategoryDropdownOption } from '../../be-inline-category-dropdown/be-inline-category-dropdown.model';
import { ActivitySubType } from '@services/gql.service';
import {
  BeActivitiesAttributesModalParams,
  BeAttributesTabState,
  BeAttributesTabToSave,
} from '../be-activities-attributes-modal.model';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MainQuery } from '@shared/store/main/main.query';

const ERROR_TABLE_TOOLTIP = 'Please resolve errors to continue';

@Component({
  standalone: true,
  selector: 'aux-attributes-tab',
  templateUrl: 'attributes-tab.component.html',
  imports: [IconComponent, ComponentsModule, FormsModule, AgGridAngular, AsyncPipe, CommonModule],
  styleUrls: ['attributes-tab.component.scss'],
})
export class AttributesTabComponent implements OnInit {
  @Input() gridData$ = new BehaviorSubject<BeActivitiesAttributesModalRowData[]>([]);

  @Input() data: BeActivitiesAttributesModalParams | null = null;

  @Input() categories: BeInlineCategoryDropdownOption[] = [];

  @Input() hasExternalChanges = false;

  @Input() inAppDocumentState: InAppDocumentState = { notes: '', bucket_keys: [] };

  @Output() toggleTabs = new EventEmitter<BeAttributesTabState>();

  @Output() save = new EventEmitter<BeAttributesTabToSave>();

  @Output() closeTab = new EventEmitter<void>();

  saveButtonTooltipForNotes = 'Enter required note to save.';

  rowData: BeActivitiesAttributesModalRowData[] = [];
  filteredRowData: BeActivitiesAttributesModalRowData[] = [];
  deletedRows: BeActivitiesAttributesModalRowData[] = [];

  gridAPI!: GridApi<BeActivitiesAttributesModalRowData>;
  columns: ColGroupDef[] = [];
  gridOptions: GridOptions = {};

  emptyRowMsg = 'Fill out at least 1 attribute to Save';

  newColumnCounter = 0;

  addNewColumnDisabled = false;

  headerNames: Record<string, string> = {};

  originalHeaders = '{}';

  headerErrorTooltip = ERROR_TABLE_TOOLTIP;

  headerErrorState = signal<Record<string, boolean>>({});

  doesHeaderHasError = computed(() => {
    return Object.values(this.headerErrorState()).some((z) => z);
  });

  state = signal<Record<string, Record<string, string>>>({});

  atLeastOneRowHasChanged = computed(() => {
    const bool = !!(this.deletedColumns().length || this.renamedColumns().length);

    return bool || !!Object.keys(this.state()).length;
  });

  addedColumns: string[] = [];
  deletedColumns = signal<string[]>([]);
  renamedColumns = signal<string[]>([]);

  originalColumns: string[] = [];

  selectedVendor = '';
  vendors: { id: string; label: string }[] = [];

  constructor(
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {}

  ngOnInit(): void {
    this.gridData$.subscribe((value) => {
      this.rowData = value;
      this.deletedRows = this.rowData.filter((row) => row.deleted);
      this.filteredRowData = this.rowData
        .filter((row) => !row.deleted && row.activity_name)
        .slice(0);
    });

    if (this.data) {
      this.vendors = [this.data.vendor];
      this.selectedVendor = this.data.vendor.id;

      this.columns = [
        {
          headerName: 'Attributes',
          headerClass: 'ag-header-align-center',
          children: [
            {
              headerName: 'Category',
              headerClass: 'ag-header-align-center text-sm',
              field: 'category',
              valueFormatter: (params: ValueFormatterParams) => this.getCategoryName(params),
              tooltipValueGetter: (params: ITooltipParams) => this.getCategoryName(params),
              editable: false,
              cellClass: '!block truncate text-left',
            },
            {
              headerName: 'Activity',
              headerClass: 'ag-header-align-center text-sm',
              field: 'activity_name',
              tooltipField: 'activity_name',
              editable: false,
              cellClass: '!block truncate text-left',
            },
          ],
        },
      ];

      // todo get all attribute options here
      // const options: Record<string, string[]> = {};
      this.data.attributes.forEach((attr) => {
        const field = `attributes.custom_attr_${btoa(encodeURIComponent(attr.attribute_name))}`;

        this.columns[0].children.push(
          this.getAttributeColumn({
            field,
            headerName: attr.attribute_name,
            autoFocus: false,
          })
        );
        this.headerNames[field] = attr.attribute_name;
        this.originalColumns.push(field);
      });

      this.originalHeaders = JSON.stringify(this.headerNames);

      this.isAddNewColumnDisabled();

      this.gridOptions = <GridOptions>{
        ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
        tooltipShowDelay: 0,
        headerHeight: 32,
        stopEditingWhenCellsLoseFocus: true,
        defaultColDef: {
          editable: true,
          suppressMenu: true,
          suppressMovable: true,
          tooltipComponent: AgTooltipComponent,
        },
        rowClassRules: {},
        enableFillHandle: true,
        enableRangeSelection: true,
        columnDefs: this.columns,
      };
    }
  }

  isAttributesTabHasChanges(): boolean {
    return !!(
      this.atLeastOneRowHasChanged() ||
      this.addedColumns.length ||
      this.renamedColumns.length ||
      this.deletedColumns.length
    );
  }

  onSave(): void {
    this.save.emit({
      ...this.getDeletedAndRenamedColumns(),
      rows: this.getRowData(),
      notes: this.inAppDocumentState.notes,
      supporting_document_s3_bucket_keys: this.inAppDocumentState.bucket_keys,
    });
  }

  onClose(): void {
    this.closeTab.emit();
  }

  private getRowData(): BeActivitiesAttributesModalRowData[] {
    const state = this.state();
    return this.rowData.map((row) => {
      let attributes = row.attributes;
      const obj: Record<string, string> = {};
      Object.entries(this.headerNames).forEach(([key, value]) => {
        obj[value] =
          state[row.id]?.[key] != null
            ? state[row.id][key]
            : attributes[key.replace('attributes.', '')] || '';
      });
      attributes = obj;

      return {
        ...row,
        attributes,
        changed: row.changed || !!state[row.id],
      };
    });
  }

  private isAddNewColumnDisabled(): void {
    this.addNewColumnDisabled = this.columns[0].children.length > 7;
  }

  getRowId: GetRowIdFunc<BeActivitiesAttributesModalRowData> = (d) => {
    return d.data.id;
  };

  checkNotes(): boolean {
    return !!this.inAppDocumentState.notes;
  }

  private getAttributeColumn = ({
    field,
    headerName = '',
    autoFocus,
  }: {
    field: string;
    headerName?: string;
    autoFocus: boolean;
  }) => {
    return <ColDef>{
      headerName,
      headerClass: () => {
        const name = this.headerNames[field];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [field]: _, ...rest } = this.headerNames;

        const bool = !name || Object.values(rest).includes(name);
        this.headerErrorState.update((state) => {
          return {
            ...state,
            [field]: bool,
          };
        });

        return ['ag-header-align-center text-sm !p-0', bool ? 'error-header' : ''];
      },
      field,
      headerComponent: AgInlineHeaderComponent,
      suppressHeaderKeyboardEvent: () => {
        return true;
      },
      tooltipField: field,
      headerComponentParams: <AgInlineHeaderComponentParams>{
        autoFocus,
        getHeaderNames: () => this.headerNames,
        onBlur: (name: string) => {
          if (this.originalColumns.includes(field) && this.headerNames[field] != name) {
            this.renamedColumns.update((c) => {
              return [...c.filter((f) => f != field), field];
            });
          }
          this.headerNames[field] = name;
          this.gridAPI.setGridOption('columnDefs', [...this.columns]);
        },
        onRemoveColumn: async () => {
          if (!autoFocus) {
            const ref = this.overlayService.open<'cancel' | 'confirm', WarningModalParams>({
              baseComponent: WarningModalComponent,
              data: {
                header: 'Delete Attribute?',
                message:
                  'Deleting this attribute column will delete the attribute column for the entire vendor budget. Are you sure you want to continue?',
                maxWidth: 455,
                cancelLabel: 'Go Back',
                confirmLabel: 'Delete',
              },
            });

            const { data: refData } = await firstValueFrom(ref.afterClosed$);

            if (refData !== 'confirm') {
              return;
            }
          }

          const name = this.headerNames[field];
          const [def] = this.columns;
          def.children = def.children.filter((c) => (c as ColDef).field !== field);
          this.columns = [def];
          this.addedColumns = this.addedColumns.filter((f) => f !== field);
          if (this.originalColumns.includes(field)) {
            this.renamedColumns.update((c) => {
              return c.filter((f) => f != field);
            });
            this.deletedColumns.update((c) => {
              return [...c, field];
            });
          }
          delete this.headerNames[field];
          this.headerErrorState.update((state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [field]: _, ...rest } = state;
            return {
              ...rest,
            };
          });

          const columnsWithTheSameName = Object.keys(this.headerNames).filter(
            (key) => name === this.headerNames[key]
          );

          if (columnsWithTheSameName.length === 1) {
            this.headerErrorState.update((state) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { [`${columnsWithTheSameName[0]}`]: _, ...rest } = state;
              return {
                ...rest,
              };
            });
          }

          this.gridAPI?.setGridOption('columnDefs', [...this.columns]);
          this.gridAPI.sizeColumnsToFit();
          this.isAddNewColumnDisabled();

          this.gridAPI.forEachNode((n) => {
            const data = n.data;
            if (data) {
              this.state.update((state) => {
                const row = state[data.id] || {};
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [field]: _, ...rest } = row;
                if (Object.keys(rest).length) {
                  return {
                    ...state,
                    [data.id]: {
                      ...rest,
                    },
                  };
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { [data.id]: __, ...restRows } = state;
                  return restRows;
                }
              });
            }
          });
        },
      },
      cellClass: '!block truncate text-left',
    };
  };

  addNewColumn(): void {
    const headerName = `NAME${++this.newColumnCounter}`;
    const field = `attributes.custom_attr_${headerName}`;
    this.headerNames[field] = '';
    this.columns[0].children.push(
      this.getAttributeColumn({
        field,
        autoFocus: true,
      })
    );
    this.gridAPI.setGridOption('columnDefs', this.columns);
    this.gridAPI.sizeColumnsToFit();
    this.addedColumns.push(field);
    this.isAddNewColumnDisabled();

    this.gridAPI.forEachNode((n) => {
      if (n.data) {
        const property = field.replace('attributes.', '');
        n.updateData({
          ...n.data,
          attributes: { ...n.data.attributes, [property]: '' },
        });
        this.updateState(n.data.id, field, '');
      }
    });
  }

  onGridReady(event: GridReadyEvent<BeActivitiesAttributesModalRowData>): void {
    this.gridAPI = event.api;
    this.gridAPI.sizeColumnsToFit();
  }

  onCellChanged(event: CellValueChangedEvent<BeActivitiesAttributesModalRowData>): void {
    const rowData = event.data;
    const colID = event.column.getColId();
    this.updateState(rowData.id, colID, event.newValue || '');
  }

  private updateState(id: string, colID: string, value: ''): void {
    this.state.update((state) => {
      const row = state[id] || {};

      return {
        ...state,
        [id]: {
          ...row,
          [colID]: value,
        },
      };
    });
  }

  goToActivitiesTab(): void {
    this.gridAPI.selectAll();
    this.gridData$.next([...this.gridAPI.getSelectedRows(), ...this.deletedRows]);
    this.toggleTabs.emit({
      ...this.getDeletedAndRenamedColumns(),
      state: this.state(),
      headerNames: this.headerNames,
      attributesTabHasUnsavedChanges: this.atLeastOneRowHasChanged() && !this.doesHeaderHasError(),
    });
    this.gridAPI.deselectAll();
  }

  isSaveBtnDisabled(): boolean {
    if (!this.checkNotes()) {
      return true;
    }
    if (!this.hasExternalChanges && !this.isAttributesTabHasChanges()) {
      return true;
    }
    return this.doesHeaderHasError();
  }

  saveBtnTooltip(): string {
    if (this.doesHeaderHasError()) {
      return this.headerErrorTooltip;
    }
    if (!this.hasExternalChanges && !this.isAttributesTabHasChanges()) {
      return this.emptyRowMsg;
    }
    if (!this.checkNotes()) {
      return this.saveButtonTooltipForNotes;
    }
    return '';
  }

  private getDeletedAndRenamedColumns() {
    const headers: Record<string, string> = JSON.parse(this.originalHeaders);
    const deletedColumns: string[] = [];
    const renamedColumns: { oldValue: string; newValue: string }[] = [];

    Object.entries(headers).forEach(([key, value]) => {
      if (key in this.headerNames) {
        if (value !== this.headerNames[key]) {
          renamedColumns.push({ oldValue: value, newValue: this.headerNames[key] });
        }
      } else {
        deletedColumns.push(value);
      }
    });

    return {
      deletedColumns,
      renamedColumns,
    };
  }

  private getCategoryName(params: ValueFormatterParams | ITooltipParams): string {
    const categoryName =
      this.categories.find(
        (category) => category.id === params.value && category.type === params.data.activity_type
      )?.name || '';

    switch (params.data.activity_type) {
      case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
      case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
      case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
        return `Investigator > ${categoryName}`;
      default:
        return categoryName;
    }
  }

  scrollToNotes() {
    if (
      !this.checkNotes() &&
      !this.doesHeaderHasError() &&
      (this.hasExternalChanges || this.isAttributesTabHasChanges())
    ) {
      const elem = document.getElementById('notes');
      const auxInputElemId = elem?.children[0].id || '';
      const auxInputElem = document.getElementById(auxInputElemId);
      if (auxInputElem) {
        auxInputElem.focus();
        auxInputElem.blur();
      }
    }
  }
}
