<ng-container *ngIf="{ activeIndex: activeTabIndex$ | async } as obj">
  <div class="flex justify-between items-center mb-8">
    <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">Ops Admin</h1>
    <div class="flex space-x-2">
      <ng-container [ngTemplateOutlet]="rightSideContainer | async" />
    </div>
  </div>
  <div>
    <div class="mb-8">
      <aux-route-tab-group [tabs]="tabs" />
    </div>

    <router-outlet />
  </div>
</ng-container>
