<div class="flex items-center justify-between mb-[20px]">
  <div class="font-semibold text-[20px]">User Permissions</div>
  <div class="flex justify-end">
    <aux-export-excel-button
      classList="ml-1"
      [clickFN]="onExport.bind(this)"
      [spinnerSize]="7"
      pendoTag="user-permissions-excel-export-button"
    />
  </div>
</div>

<ng-container
  *ngIf="{
    gridData: gridData$ | async,
    gridOptions: gridOptions$ | async
  } as obj"
>
  @if (!loading()) {
    <ag-grid-angular
      domLayout="autoHeight"
      class="ag-theme-aux tabular-nums user-permissions-table mb-16"
      [gridOptions]="obj.gridOptions || undefined"
      (gridReady)="gridReady($event)"
      [rowData]="obj.gridData"
      (viewportChanged)="onWindowScroll()"
    />
  }

  @if (loading()) {
    <aux-table-skeleton />
  }
</ng-container>

<!-- Save changes -->
<aux-save-changes
  *ngIf="changedValues.length"
  [onSaveChanges]="onSaveChanges"
  [showDiscardChangesBtn]="true"
  (cancel)="onDiscardChanges()"
/>
