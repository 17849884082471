import { Component, computed, effect, inject, model, signal } from '@angular/core';
import { ButtonComponent } from '@components/button/button.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { InvoicesGridFormatterService } from '@pages/vendor-payments-page/tabs/invoices/invoices-grid-formatter.service';
import { Utils } from '@services/utils';
import { Option } from '@components/components.type';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { PermissionType, TrialImplementationStatus } from '@services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { MainQuery } from '@shared/store/main/main.query';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

export interface BulkEditInvoicesData {
  rowLength: number;
}

export interface BulkEditInvoicesResponse {
  success: boolean;
  data: {
    vendor: string | null;
    status: string | null;
    paymentStatus: string | null;
    accrualPeriod: string | null;
    purchaseOrder: string | null;
  };
}

@Component({
  standalone: true,
  template: `
    <div class="font-inter max-w-2xl" [ngClass]="{ 'w-screen': showInvoicesPaymentStatus() }">
      <div class="text-xl font-bold mb-4">
        Bulk Edit: <span class="font-bold">{{ rowLength() }} Selected Invoices</span>
      </div>
      <div class="flex pb-4">
        <div class="w-56">
          <span class="text-xs mb-1">Vendor:</span>
          <ng-select
            placeholder="Select"
            bindValue="value"
            [appendTo]="'body'"
            [(ngModel)]="vendor"
            (clear)="vendor.set(null)"
          >
            @for (option of vendorOptions(); track option.value) {
              <ng-option [value]="option.value">
                <span [title]="option.label">{{ option.label }}</span>
              </ng-option>
            }
          </ng-select>
        </div>
        <div class="w-60 pl-4">
          <span class="text-xs mb-1">Status:</span>
          <ng-select
            placeholder="Select"
            bindValue="value"
            [appendTo]="'body'"
            [items]="invoiceStatusOptions()"
            [(ngModel)]="status"
            (clear)="status.set(null)"
          >
            <ng-template let-item="item" ng-label-tmp ng-option-tmp>
              <div class="flex items-center whitespace-nowrap">
                <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
                <div [ngClass]="item.textClass">{{ item.label }}</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        @if (showInvoicesPaymentStatus()) {
          <div class="w-52 pl-4">
            <span class="text-xs mb-1">Payment Status:</span>
            <ng-select
              placeholder="Select"
              bindValue="value"
              [appendTo]="'body'"
              [items]="paymentStatusOptions()"
              [(ngModel)]="paymentStatus"
              (clear)="paymentStatus.set(null)"
            >
              <ng-template let-item="item" ng-label-tmp ng-option-tmp>
                <div class="flex items-center whitespace-nowrap">
                  <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
                  <div [ngClass]="item.textClass">{{ item.label }}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        }
      </div>
      <div class="flex border-b pb-4">
        <div class="w-56">
          <span class="text-xs mb-1">Purchase Order:</span>
          <ng-select
            placeholder="Select"
            bindValue="value"
            [appendTo]="'body'"
            [(ngModel)]="purchaseOrder"
            (clear)="purchaseOrder.set(null)"
          >
            @for (option of purchaseOrderOptions(); track option.value) {
              <ng-option [value]="option.value">
                <span [title]="option.label">{{ option.label }}</span>
              </ng-option>
            }
          </ng-select>
        </div>
        <div class="w-60 pl-4">
          <span class="text-xs mb-1">Accrual Period:</span>
          <ng-select
            placeholder="Select"
            bindValue="value"
            [appendTo]="'body'"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="accrualPeriod"
            (clear)="accrualPeriod.set(null)"
          >
            @for (option of accrualPeriodOptions(); track option.value) {
              <ng-option [value]="option.value">
                <span [title]="option.label">{{ option.label }}</span>
              </ng-option>
            }
          </ng-select>
        </div>
      </div>

      <div class="flex justify-between mt-4">
        <aux-button
          variant="custom"
          classList="text-sm h-full"
          label="Cancel"
          (click)="ref.close()"
        />
        <aux-button
          [disabled]="isApplyButtonDisabled()"
          [auxTooltip]="
            isApplyButtonDisabled() ? 'At least 1 field required to Apply Changes.' : ''
          "
          variant="primary"
          label="Apply"
          [spinnerSize]="6"
          (clickEmit)="onSubmit()"
        />
      </div>
    </div>
  `,
  imports: [NgClass, NgSelectModule, FormsModule, ButtonComponent, TooltipDirective],
  styles: [],
})
export class BulkEditInvoicesComponent {
  ref = inject(CustomOverlayRef<BulkEditInvoicesResponse, BulkEditInvoicesData>);
  invoiceService = inject(InvoiceService);
  invoicesGridFormatterService = inject(InvoicesGridFormatterService);
  launchDarklyService = inject(LaunchDarklyService);
  authService = inject(AuthService);
  mainQuery = inject(MainQuery);

  rowLength = signal(0);
  showInvoicesPaymentStatus = this.launchDarklyService.$select(
    (flags) => flags.invoices_payment_status
  );
  userHasApproveInvoicePermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_APPROVE_INVOICE],
  });

  vendor = model<string | null>(null);
  status = model<string | null>(null);
  paymentStatus = model<string | null>(null);
  accrualPeriod = model<string | null>(null);
  purchaseOrder = model<string | null>(null);

  vendorOptions = computed(() => {
    return this.invoiceService.vendorOptions;
  });
  invoiceStatusOptions = computed(() => {
    const hasPermission = this.userHasApproveInvoicePermission();

    return hasPermission
      ? this.invoiceService.invoiceStatusOptions.map((status) => ({
          value: status,
          ...InvoicesGridFormatterService.getInvoiceStatusStyles(status),
        }))
      : [];
  });
  paymentStatusOptions = computed(() => {
    return this.invoiceService.paymentStatusOptions.map((status) => ({
      value: status || '',
      ...InvoicesGridFormatterService.getInvoicePaymentStatusStyles(status),
    }));
  });
  accrualPeriodOptions = computed(() => {
    const { currentOpenMonth } = this.mainQuery.mainState();
    const trial = this.mainQuery.selectedTrial();
    const isTrialArchivedOrLive =
      trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
      trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE;
    const options = isTrialArchivedOrLive
      ? this.invoiceService.accrualPeriodOptions.filter((option) => {
          return `${option.value}-01` >= currentOpenMonth;
        })
      : this.invoiceService.accrualPeriodOptions;
    return [{ value: '', label: Utils.zeroHyphen }, ...options];
  });
  purchaseOrderOptions = signal<Option<string | null>[]>([{ value: '', label: Utils.zeroHyphen }]);

  isApplyButtonDisabled = computed(() => {
    return (
      this.vendor() == null &&
      this.status() == null &&
      this.paymentStatus() == null &&
      this.accrualPeriod() == null &&
      this.purchaseOrder() == null
    );
  });

  updatePOWhenVendorChanges = effect(
    () => {
      this.purchaseOrder.set(null);

      if (this.vendor()) {
        this.purchaseOrderOptions.set([
          { value: '', label: Utils.zeroHyphen },
          ...this.invoiceService.purchaseOrdersQuery
            .getAll()
            .filter((order) => order.organization?.id === this.vendor())
            .map(({ id }) => ({
              value: id,
              label: this.invoicesGridFormatterService.getFormattedPurchaseOrder(id),
            })),
        ]);
      } else {
        this.purchaseOrderOptions.set([{ value: '', label: Utils.zeroHyphen }]);
      }
    },
    {
      allowSignalWrites: true,
    }
  );

  constructor() {
    if (!this.ref.data) {
      throw new Error('No data provided');
    }

    this.rowLength.set(this.ref.data.rowLength);
  }

  onSubmit() {
    this.ref.close({
      success: true,
      data: {
        vendor: this.vendor(),
        status: this.status(),
        paymentStatus: this.paymentStatus(),
        accrualPeriod: this.accrualPeriod(),
        purchaseOrder: this.purchaseOrder(),
      },
    });
  }
}
