<div style="width: 500px">
  <aux-investigator-transaction-supporting-section
    #supportingSection
    [noteFormControl]="noteFormControl"
  />
  <hr class="mt-7 bg-white" />
  <div class="bg-white pt-5 flex justify-between">
    <button
      class="focus:outline-none"
      type="button"
      (click)="ref.close()"
      [disabled]="uploadingFiles()"
    >
      Cancel
    </button>
    <button
      class="btn--success"
      data-pendo-id="save-transaction"
      (click)="save()"
      [disabled]="uploadingFiles() || noteFormControl.invalid"
    >
      Save
    </button>
  </div>
</div>
