import { Component, OnInit } from '@angular/core';
import { NgComponentOutlet, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { isString } from 'lodash';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { IconComponent } from '@components/icon/icon.component';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { ButtonComponent } from '@components/button/button.component';

import { MODAL_ANIMATIONS } from '../../animations/modal.animation';
import { CustomValidators } from '@components/form-inputs/custom-validators';

interface ConfirmationActionModalParams {
  header: string;
  keywordToExecuteAction: string;
  okButtonText?: string;
  maxWidth?: string;
  submitButtonText?: 'primary' | 'negative';
}

@Component({
  templateUrl: './confirmation-action-modal.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  animations: MODAL_ANIMATIONS,
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgComponentOutlet,
    NgTemplateOutlet,
    IconComponent,
    InputComponent,
    ButtonComponent,
    ReactiveFormsModule,
  ],
})
export class ConfirmationActionModalComponent implements OnInit {
  contentType?: 'string' | 'component';

  confirmationFc = new FormControl(null, [
    Validators.required,
    CustomValidators.mustEqualWith(this.ref.data?.keywordToExecuteAction ?? ''),
  ]);

  okButtonLabel = '';

  submitButtonText: 'primary' | 'negative' = 'negative';

  constructor(public ref: CustomOverlayRef<{ success: boolean }, ConfirmationActionModalParams>) {}

  ngOnInit(): void {
    this.okButtonLabel = this.ref.data?.okButtonText ?? 'Delete';
    this.submitButtonText = this.ref.data?.submitButtonText ?? 'negative';

    this.setContentType();
  }

  setContentType() {
    const content = this.ref.content;

    if (!content) {
      return;
    }

    if (isString(content)) {
      this.contentType = 'string';
    } else {
      this.contentType = 'component';
    }
  }

  closeModal() {
    this.ref.close({ success: false });
  }
}
