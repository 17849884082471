import { Injectable } from '@angular/core';
import {
  batchCreateInvestigatorTransactionsMutation,
  CreateInvestigatorTransactionInput,
  Currency,
  fetchPatientTransactionsDataStreamQuery,
  FetchPatientTransactionsInput,
  GqlService,
  InvestigatorTransactionSourceType,
  listPatientGroupsQuery,
  listPatientProtocolListQuery,
  listPatientProtocolSubTypesQuery,
  listPatientProtocolVersionsQuery,
  listPatientsQuery,
  listSitesQuery,
  PatientGroupType,
  PatientProtocolType,
  RemoveInvestigatorTransactionInput,
  UpdateInvestigatorTransactionInput,
} from '@services/gql.service';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { Maybe, Utils } from '@services/utils';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { Option } from '@components/components.type';
import { first, uniqBy } from 'lodash-es';
import { SitesService } from '@models/sites/sites.service';
import { SiteOption } from '@models/site-option.model';
import { convertFilterToMap, mapSiteToSiteOption } from '@shared/utils';

export type InvestigatorTransactions = fetchPatientTransactionsDataStreamQuery['items'];

export type InvestigatorTransactionsFilterForm = Omit<
  FetchPatientTransactionsInput,
  'page' | 'sort_by'
>;

export interface InvestigatorTransactionsDataRow {
  id: string;
  activity_date: string;
  source_name: string;
  source_type: InvestigatorTransactionSourceType | null;
  source_created_date: string | null | undefined;
  site_no: string;
  site_name: string;
  investigator_name: string;
  external_patient_id?: string | null;
  patient_protocol_category: string;
  patient_group_id?: string | null;
  patient_protocol_version_name: string | null;
  site_budget_version_name: string | null;
  site_address: string | null;
  description?: string | null;
  contract_curr: Currency;
  currency: Currency;
  contract_amount: number;
  exchange_rate: string;
  total_cost: number;
  country: string;
  mode: 'static' | 'create' | 'edit';
}

export type TransactionMode = 'create' | 'edit' | null;

@Injectable({
  providedIn: 'root',
})
export class InvestigatorTransactionsService {
  totalCost$ = new BehaviorSubject(0);

  totalItems$ = new BehaviorSubject(0);

  lastSourceRefreshDate$ = new BehaviorSubject('');

  initialFilters: InvestigatorTransactionsFilterForm = {
    end_activity_date: null,
    start_activity_date: null,
    patient_group_ids: null,
    patient_protocol_version_ids: null,
    patient_ids: null,
    descriptions: null,
    site_ids: null,
    countries: null,
    patient_protocol_sub_types: null,
    sources: null,
  };

  currentFilters$ = new BehaviorSubject<InvestigatorTransactionsFilterForm>(this.initialFilters);

  private _siteMap = new Map<string, listSitesQuery>();

  get siteMap() {
    return this._siteMap;
  }

  set siteMap(value: Map<string, listSitesQuery>) {
    this._siteMap = value;
  }

  siteOptions$ = new BehaviorSubject<SiteOption[]>([]);

  patientOptions$ = new BehaviorSubject<listPatientsQuery[]>([]);

  allPatientOptions$ = new BehaviorSubject<listPatientsQuery[]>([]);

  descriptionOptions$ = new BehaviorSubject<listPatientProtocolListQuery[]>([]);

  patientGroupOptions$ = new BehaviorSubject<listPatientGroupsQuery[]>([]);

  protocolVersionOptions$ = new BehaviorSubject<listPatientProtocolVersionsQuery[]>([]);

  countryOptions$ = new BehaviorSubject<Option[]>([]);

  categoryOptions$ = new BehaviorSubject<listPatientProtocolSubTypesQuery[]>([]);

  private patientGroupMap = new Map<string, listPatientGroupsQuery>();

  inMonthAdjustmentsFilter$ = new BehaviorSubject<boolean>(false);

  inMonthAdjustmentsFilterDateStart = '';

  inMonthAdjustmentsFilterDateEnd = '';

  inMonthAdjustmentsFilterVendorId = '';

  inMonthAdjustmentsFilterSiteIds: string[] = [];

  constructor(
    private gqlService: GqlService,
    private sitesService: SitesService
  ) {}

  getTransactionFilters() {
    return combineLatest([
      this.sitesService.get(),
      this.gqlService.listPatients$(),
      this.gqlService.listPatientGroups$([PatientGroupType.PATIENT_GROUP_STANDARD]),
      this.gqlService.listPatientProtocolVersions$(),
      this.gqlService.listPatientProtocolList$([], true),
      this.gqlService.listPatientProtocolSubTypes$([]),
    ]).pipe(
      tap(
        ([
          siteList,
          patientList,
          patientGroupList,
          protocolVersionList,
          descriptionList,
          patientProtocolSubTypelist,
        ]) => {
          this.siteMap = convertFilterToMap<listSitesQuery>(siteList?.data || []);
          this.patientGroupMap = convertFilterToMap<listPatientGroupsQuery>(
            patientGroupList?.data || []
          );

          this.setOptions(
            siteList,
            patientList,
            patientGroupList,
            protocolVersionList,
            descriptionList,
            patientProtocolSubTypelist
          );

          this.setInMonthAdjustmentsFilters();
        }
      )
    );
  }

  getPatientTransactionsGridData(
    list: InvestigatorTransactions
  ): InvestigatorTransactionsDataRow[] {
    return list.map<InvestigatorTransactionsDataRow>((item) => ({
      id: item.id,
      activity_date: item.activity_date,
      source_name: item.source?.name || '',
      source_type: item.source?.id || null,
      site_no: this.siteMap.get(item.site_id || '')?.site_no || '',
      site_name: this.siteMap.get(item.site_id || '')?.name || '',
      investigator_name: this.getPrincipalInvestigatorName(item.site_id || ''),
      external_patient_id: item.external_patient_id,
      patient_protocol_category: item.patient_protocol?.patient_protocol_sub_type?.name || '',
      patient_group_id: this.patientGroupMap.get(item.patient_group_id || '')?.name || '',
      patient_protocol_version_name: item.patient_protocol?.patient_protocol_version?.name || '',
      site_budget_version_name: item.site_budget_version_name || '',
      site_address: this.getSiteAddress(this.siteMap.get(item.site_id || '')),
      description: item.description,
      contract_curr: item.amount?.contract_currency as Currency,
      currency: item.amount?.amount_currency as Currency,
      contract_amount: item.amount?.contract_amount || 0,
      exchange_rate: Utils.exchangeRateFormatter({
        value: item.amount?.exchange_rate.rate || 0,
      } as ValueFormatterParams),
      total_cost: item.amount?.amount || 0,
      country: Utils.getCountryName(item.country || ''),
      source_created_date: item.source_created_date,
      mode: 'static',
    }));
  }

  getSiteAddress(site?: listSitesQuery): string {
    const citySubStr = this.getSafeAddressName(site?.city);

    const address = `${this.getSafeAddressName(site?.address_line_1)} ${this.getSafeAddressName(
      site?.address_line_2
    )} ${this.getSafeAddressName(site?.address_line_3)}`.trim();

    return site
      ? `${address} ${citySubStr} ${site.state || ''} ${site.zip || ''} ${Utils.getCountryName(
          site.country || ''
        )}`.trim()
      : Utils.zeroHyphen;
  }

  private getSafeAddressName(address: Maybe<string>, suffix = ','): string {
    return address ? `${address}${suffix}` : '';
  }

  private siteIdsByVendor(vendorId: string): string[] {
    const sitesByVendor = [...this.siteMap].filter(([, site]) => {
      return site.managed_by.id === vendorId;
    });

    const siteIds = sitesByVendor.map(([siteId]) => siteId);

    return siteIds;
  }

  getInMonthAdjustmentsFilters(): [string, string, string[]] {
    return [
      this.inMonthAdjustmentsFilterDateStart,
      this.inMonthAdjustmentsFilterDateEnd,
      this.inMonthAdjustmentsFilterSiteIds,
    ];
  }

  resetInMonthAdjustmentsFilters(): void {
    this.inMonthAdjustmentsFilterDateStart = '';
    this.inMonthAdjustmentsFilterDateEnd = '';
    this.inMonthAdjustmentsFilterVendorId = '';
    this.inMonthAdjustmentsFilterSiteIds = [];
  }

  addInMonthAdjustmentsFilters(monthStart: string, monthEnd: string, vendorId: string): void {
    if (!monthEnd) {
      return;
    }

    this.inMonthAdjustmentsFilterDateStart = monthStart;
    this.inMonthAdjustmentsFilterDateEnd = monthEnd;
    this.inMonthAdjustmentsFilterVendorId = vendorId;
    if (this.inMonthAdjustmentsFilterVendorId) {
      this.setFilterSiteIds();
    }
    this.inMonthAdjustmentsFilter$.next(true);
  }

  private setFilterSiteIds(): void {
    const siteIds = this.siteIdsByVendor(this.inMonthAdjustmentsFilterVendorId);

    if (!siteIds.length) {
      return;
    }

    this.inMonthAdjustmentsFilterSiteIds = siteIds;
  }

  setInMonthAdjustmentsFilters(): void {
    if (!this.inMonthAdjustmentsFilterDateEnd) {
      return;
    }

    if (this.inMonthAdjustmentsFilterVendorId) {
      this.setFilterSiteIds();
    }
  }

  dynamicPatientIdFilter(site_ids: string[], patient_ids: string[] | null): string[] {
    const filteredValues = this.allPatientOptions$
      .getValue()
      .filter((option) => site_ids.includes(option.site_id) || !site_ids.length);
    const arrWithoutDuplicatePatients: listPatientsQuery[] = [];
    filteredValues.forEach((value) => {
      if (
        !arrWithoutDuplicatePatients.includes(value) &&
        !arrWithoutDuplicatePatients.some((eachValue) => eachValue.id === value.id)
      ) {
        arrWithoutDuplicatePatients.push(value);
      }
    });
    this.patientOptions$.next(arrWithoutDuplicatePatients);
    return patient_ids
      ? this.patientOptions$
          .getValue()
          .map((option) => {
            return option.id;
          })
          .filter((option) => patient_ids.includes(option))
      : [];
  }

  private setOptions(
    siteList: GraphqlResponse<listSitesQuery[]>,
    patientList: GraphqlResponse<listPatientsQuery[]>,
    patientGroupList: GraphqlResponse<listPatientGroupsQuery[]>,
    protocolVersionList: GraphqlResponse<listPatientProtocolVersionsQuery[]>,
    descriptionList: GraphqlResponse<listPatientProtocolListQuery[]>,
    patientProtocolSubTypeList: GraphqlResponse<listPatientProtocolSubTypesQuery[]>
  ) {
    this.siteOptions$.next(
      (siteList.data || [])
        .sort(({ site_no }, { site_no: site_no2 }) => Utils.localeAlphaNumSort(site_no, site_no2))
        .map((site: listSitesQuery) =>
          mapSiteToSiteOption(site, this.getPrincipalInvestigatorName(site.id))
        )
    );
    this.patientOptions$.next(
      (patientList.data || []).sort(
        ({ external_patient_id }, { external_patient_id: external_patient_id2 }) =>
          Utils.alphaNumSort(external_patient_id, external_patient_id2)
      )
    );
    this.allPatientOptions$.next(this.patientOptions$.getValue());

    this.descriptionOptions$.next(
      (uniqBy(descriptionList.data, 'name') || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.patientGroupOptions$.next(
      (patientGroupList.data || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.protocolVersionOptions$.next(
      (protocolVersionList.data || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.categoryOptions$.next(
      (patientProtocolSubTypeList.data || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.countryOptions$.next(this.getCountryOptions(siteList?.data || []));
  }

  saveTransaction(
    transactions: InvestigatorTransactionsDataRow[],
    note: string,
    documentsPaths: string[]
  ): Promise<GraphqlResponse<batchCreateInvestigatorTransactionsMutation[]>> {
    const inputs: CreateInvestigatorTransactionInput = {
      transactions: transactions.map((transaction) => ({
        amount: transaction.contract_amount,
        completion_date: transaction.activity_date,
        description: transaction.description ?? '',
        external_patient_id: transaction.external_patient_id,
        patient_protocol: {
          patient_group_id: transaction.patient_group_id ?? null,
          patient_protocol_type: transaction.patient_protocol_category as PatientProtocolType,
          patient_protocol_version_id: transaction.patient_protocol_version_name ?? '',
          patient_protocol_sub_type_id:
            this.getPatientProtocolSubTypeId(
              transaction.patient_protocol_category as PatientProtocolType
            ) ?? '',
        },
        site_id: transaction.site_no,
        source_created_date: transaction.source_created_date,
      })),
      supporting_doc_bucket_keys: documentsPaths,
      note,
    };

    return firstValueFrom(this.gqlService.batchCreateInvestigatorTransactions$(inputs));
  }

  updateTransactions(
    transactions: InvestigatorTransactionsDataRow[],
    note: string,
    documentsPaths: string[]
  ) {
    const inputs: UpdateInvestigatorTransactionInput = {
      transactions: transactions.map((transaction) => ({
        id: transaction.id,
        amount: transaction.contract_amount,
        completion_date: transaction.activity_date,
        description: transaction.description,
        external_patient_id: transaction.external_patient_id,
        patient_protocol: {
          patient_group_id: transaction.patient_group_id ?? null,
          patient_protocol_sub_type_id:
            this.getPatientProtocolSubTypeId(
              transaction.patient_protocol_category as PatientProtocolType
            ) ?? '',
          patient_protocol_type: transaction.patient_protocol_category as PatientProtocolType,
          patient_protocol_version_id: transaction.patient_protocol_version_name as string,
        },
        site_id: transaction.site_no,
      })),
      supporting_doc_bucket_keys: documentsPaths,
      note: note,
    };

    return firstValueFrom(this.gqlService.batchUpdateInvestigatorTransactions$(inputs));
  }

  removeTransaction(transactionId: string, note: string, documentsPaths: string[]) {
    return firstValueFrom(
      this.gqlService.batchRemoveInvestigatorTransactions$({
        ids: [transactionId],
        supporting_doc_bucket_keys: documentsPaths,
        note,
      } as RemoveInvestigatorTransactionInput)
    );
  }

  private getPatientProtocolSubTypeId = (category: PatientProtocolType): string | undefined => {
    const categoryOptions = this.categoryOptions$.getValue();

    return categoryOptions.find(
      ({ name, patient_protocol_type }) =>
        patient_protocol_type === category &&
        ['Patient Visit', 'Patient Invoiceable', 'Site Invoiceable'].includes(name)
    )?.id;
  };

  private getCountryOptions(list: listSitesQuery[]): Option[] {
    return uniqBy(list, 'country')
      .map(({ country }) => ({
        value: country || '',
        label: Utils.getCountryName(country || ''),
      }))
      .filter(({ value }) => !!value)
      .sort(({ label }, { label: label2 }) => Utils.alphaNumSort(label, label2));
  }

  private nullifyInvalidFilters(
    filers: InvestigatorTransactionsFilterForm = {}
  ): InvestigatorTransactionsFilterForm {
    return Object.entries(filers).reduce((accum, [key, value]) => {
      return {
        ...accum,
        [key]: ['end_activity_date', 'start_activity_date'].includes(key) ? value || null : value,
      };
    }, {});
  }

  getPrincipalInvestigatorName(siteId: string): string {
    const principalInvestigator = first(this.siteMap.get(siteId)?.contacts);

    return principalInvestigator
      ? `${principalInvestigator.given_name} ${principalInvestigator.family_name}`
      : '';
  }

  getterForPrincipalInvestigatorName(siteId: string): string {
    return this.getPrincipalInvestigatorName(siteId);
  }
}
