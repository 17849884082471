import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TabGroupConfig } from '@components/tab-group/route-tab-group.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-edc-converter',
  templateUrl: './edc-converter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EdcConverterComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Legacy',
      route: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.LEGACY,
    },
    {
      label: 'New',
      route: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.NEW,
    },
  ];
}
