<form class="rounded border border-aux-gray-dark" [formGroup]="siteBudgetForm">
  <div class="bg-aux-gray-light p-4 border-b border-aux-gray-dark">
    <p class="text-aux-black font-bold text-base">{{ siteBudgetName }}</p>
  </div>

  <div class="p-4 flex flex-col space-y-4">
    <div>
      <div class="mb-1 text-xs">Protocol Version</div>
      <ng-select
        formControlName="protocolVersion"
        bindLabel="label"
        bindValue="value"
        class="select"
        label="Site Budget Version"
        [items]="protocolVersionList"
        [searchable]="true"
        [clearable]="false"
        auxFormError
        required
      />
    </div>
    <aux-input
      #siteBudgetNameInput
      label="Site Budget Amendment"
      formControlName="siteBudgetName"
      validators="required"
    />

    <div>
      <div class="mb-1 text-xs">Site Contract Documents</div>
      <aux-file-manager
        #fileManager
        class="h-28 bg-aux-gray-light"
        [pathFn]="pathFn"
        [eager]="false"
        [metadata]="{}"
        [showSuccessOnUpload]="true"
        [disabled]="!editMode()"
        (fileManagerReady)="fileManagerReady($event)"
      >
        <div class="flex space-x-2 items-center justify-center text-aux-gray-dark-100">
          <aux-icon name="Upload" class="w-[32px] h-[32px] 2xl:!w-[42px] 2xl:!h-[42px]" />
          <p class="text-sm 2xl:text-lg">
            Drag & Drop Documents Here or <span class="aux-link">Browse</span>
          </p>
        </div>
      </aux-file-manager>
    </div>

    <aux-file-manager-uploaded-files
      *ngIf="fileManager"
      [fileManager]="fileManager"
      [disabled]="!editMode()"
      [removeImmediately]="false"
      (removeFile)="removeFile($event)"
    />

    <aux-input label="Reason for Amendment" formControlName="note" [textArea]="true" [rows]="3" />

    <div class="flex justify-end pt-8" *ngIf="editMode()">
      <aux-remove-site-budget
        (removeSiteBudgetEvent)="removeSiteBudget($event)"
        [disabled]="baseLineSiteBudget()"
      />
    </div>
  </div>
</form>
