import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  model,
  Output,
  signal,
  viewChild,
} from '@angular/core';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { PermissionType } from '@services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { MessagesConstants } from '@constants/messages.constants';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-invoice-notes',
  template: `
    <div class="text-xl font-semibold mb-4">Notes</div>

    <div class="p-4 rounded-lg bg-aux-gray-light flex">
      <aux-input
        class="flex-grow"
        inputClassName="resize-none border-0 bg-transparent !outline-none !shadow-none w-full !max-w-full !text-lg text-aux-gray-darkest !font-normal"
        [textArea]="true"
        [rows]="1"
        [(ngModel)]="notes"
        placeholder="Add a note..."
        [textareaAutoresize]="true"
        [disabled]="!editMode()"
      />

      <div class="w-[150px] min-w-[150px] flex justify-end items-start pt-0.5">
        @if (editMode()) {
          <aux-button class="mr-2" variant="primary" label="Save" (click)="onSave()" />

          <aux-button variant="secondary" classList="text-sm" label="Cancel" (click)="onCancel()" />
        } @else {
          <aux-button
            variant="custom"
            icon="Pencil"
            svgAttributes="!mr-0 text-aux-blue"
            classList="py-0.5 mt-[5px]"
            [ngClass]="{
              'opacity-50': !hasEditPermission()
            }"
            type="button"
            [auxTooltip]="!hasEditPermission() ? disabledTooltip : ''"
            [disabled]="!hasEditPermission()"
            (click)="onEdit()"
          />
        }
      </div>
    </div>
  `,
  imports: [InputComponent, FormsModule, ButtonComponent, TooltipDirective, NgClass],
  styles: [
    `
      :host ::ng-deep textarea {
        box-shadow: none !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceNotesComponent {
  authService = inject(AuthService);

  notes = model.required<string>();
  editMode = signal(false);
  textarea = viewChild.required(InputComponent);

  @Output() saveNotes = new EventEmitter();
  @Output() cancelNotes = new EventEmitter();

  hasEditPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });

  disabledTooltip = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  onEdit() {
    this.editMode.set(true);
    const cmp = this.textarea();
    if (!cmp.input) {
      return;
    }

    const end = cmp.input.nativeElement.value.length || 0;
    cmp.input.nativeElement.focus();
    cmp.input.nativeElement.setSelectionRange(end, end);
  }

  onSave() {
    this.saveNotes.emit();
    this.editMode.set(false);
  }

  onCancel() {
    this.cancelNotes.emit();
    this.editMode.set(false);
  }
}
