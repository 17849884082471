import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabGroupConfig } from '@components/tab-group/route-tab-group.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, of } from 'rxjs';

@Component({
  selector: 'aux-design-system',
  templateUrl: './design-system.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class DesignSystemComponent implements OnInit {
  rightSideContainer = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  activeTabIndex$ = new BehaviorSubject<number>(0);

  tabs: TabGroupConfig[] = [
    {
      label: 'Colors',
      show: of(true),
      route: '/design-system/colors',
    },
    {
      label: 'Iconography',
      show: of(true),
      route: '/design-system/iconography',
    },
    {
      label: 'Buttons',
      show: of(true),
      route: '/design-system/buttons',
    },
    {
      label: 'Button Toggle Group',
      show: of(true),
      route: '/design-system/button-toggle-group-demo',
    },
    {
      label: 'Forms & Controls',
      show: of(true),
      route: '/design-system/forms-and-controls',
    },
    {
      label: 'Tables',
      show: of(true),
      route: '/design-system/tables',
    },
    {
      label: 'Modals & Cards',
      show: of(true),
      route: '/design-system/modals',
    },
    {
      label: 'Typography',
      show: of(true),
      route: '/design-system/typography',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.activeTabIndex) {
        this.activeTabIndex$.next(parseInt(params.activeTabIndex, 10));
        this.router.navigate([], { queryParams: {}, replaceUrl: true });
      }
    });
  }
}
