<form [formGroup]="siteBudgetForm" class="site-budget">
  <p class="text-lg text-aux-gray-darkest">Protocol Versions</p>
  <aux-button-list
    auxSkeletonLoader
    [loading]="loadingFilters()"
    [options]="loadingFilters() ? emptyProtocolVersionList : protocolVersionList()"
    [beforeSelect]="beforeSelectProtocolVersion"
    (selectedItem)="handleSelectedItem($event)"
    [value]="siteBudgetForm.get('protocolVersion')?.valueChanges | async"
    class="block"
  />
  <aux-no-protocol-versions-link
    *ngIf="!protocolVersionList().length && !loadingFilters()"
    class="my-4 block"
  />

  <div class="grid grid-cols-[2fr_1fr] gap-8 mt-4">
    <section>
      <div class="flex justify-between" [ngClass]="{ 'items-end': !editMode() }">
        <div class="flex space-x-4">
          <div class="min-w-52">
            <div class="mb-1 text-xs">Site Budget Version</div>
            <ng-select
              formControlName="siteBudgetVersion"
              placeholder="Select"
              id="siteBudgetVersion"
              bindLabel="name"
              bindValue="id"
              [items]="siteBudgeOptionList()"
              [loading]="loadingFilters()"
              [searchable]="true"
              [clearable]="false"
              [appendTo]="'body'"
            />
          </div>
          <div class="min-w-52">
            <div class="mb-1 text-xs">Patient Group/Invoiceables</div>
            <ng-select
              placeholder="Select"
              formControlName="patientGroup"
              [loading]="loadingFilters()"
              [items]="patientGroupList$ | async"
              bindLabel="label"
              bindValue="value"
              [searchable]="true"
              [clearable]="false"
            />
          </div>
        </div>

        <div
          *ngIf="{
            effectiveDate:
              siteBudgetForm.get('effectiveDate')?.valueChanges | async | date: 'MM/dd/yyyy'
          } as obj"
        >
          <div
            *ngIf="!editMode()"
            auxSkeletonLoader
            [loading]="gridLoading()"
            class="flex items-center"
          >
            <ng-template #effectiveDateTooltip>
              <p class="w-56">
                {{ effectiveDateTooltipText }}
              </p>
            </ng-template>
            <aux-icon
              name="AlertTriangle"
              auxTooltip
              [template]="effectiveDateTooltip"
              class="text-aux-error mr-2"
              *ngIf="!obj.effectiveDate"
            />
            <p>
              Effective Date:
              {{ obj.effectiveDate || 'N/A' }}
            </p>
          </div>

          <aux-input
            #effectiveDateInput
            auxSkeletonLoader
            [loading]="gridLoading()"
            formControlName="effectiveDate"
            [type]="'date'"
            label="Effective Date"
            class="max-w-36"
            *ngIf="editMode()"
          />
        </div>
      </div>

      <ag-grid-angular
        class="ag-theme-alpine mb-8 w-full tabular-nums site-budget-table mt-4"
        *ngIf="!gridLoading()"
        style="height: 700px"
        [rowData]="gridData()"
        [gridOptions]="gridOptions()"
        (gridReady)="gridReady($event)"
        [enableFillHandle]="editMode()"
      />
      <aux-table-skeleton *ngIf="gridLoading()" class="block mt-4" />
    </section>

    <section>
      <div class="flex justify-end space-x-4 mt-4">
        <aux-button
          icon="X"
          label="Cancel"
          variant="secondary"
          [disabled]="gridLoading()"
          *ngIf="editMode()"
          (click)="cancelChanges()"
        />
        <aux-button
          icon="Check"
          label="Save"
          variant="success"
          [disabled]="gridLoading() || siteBudgetForm.invalid || !!effectiveDateInput?.fc?.invalid"
          *ngIf="editMode()"
          (click)="saveChanges()"
        />
        <aux-button
          icon="Pencil"
          label="Edit"
          variant="secondary"
          [disabled]="
            !hasUpdateSiteCostPermission() ||
            isPatientsFinalized() ||
            gridLoading() ||
            loadingFilters() ||
            !protocolVersionList().length
          "
          [auxTooltip]="actionButtonsTooltip()"
          *ngIf="!editMode()"
          (click)="editMode.set(true)"
        />
        <aux-button
          icon="Plus"
          label="Site Amendment"
          variant="secondary"
          [disabled]="
            !hasUpdateSiteCostPermission() ||
            isPatientsFinalized() ||
            gridLoading() ||
            loadingFilters() ||
            editMode() ||
            !protocolVersionList().length
          "
          [auxTooltip]="actionButtonsTooltip()"
          (click)="createSiteBudgetAmendment()"
        />
      </div>
      <aux-site-budget-version-panel
        #siteBudgetVersionPanelComponent
        auxSkeletonLoader
        [loading]="gridLoading()"
        class="mt-4 block"
        [siteBudgetForm]="siteBudgetForm"
        [siteBudgeOptionList]="siteBudgeOptionList()"
        [siteBudgetName]="initialDataState.formState?.siteBudgetName"
        [editMode]="editMode()"
        [siteId]="selectedSiteId"
        [protocolVersionList]="protocolVersionList()"
        (fileManagerInstance)="fileManagerReady($event)"
        (removeFileEvent)="onRemoveFile($event)"
        (removeSiteBudgetEvent)="onRemoveSiteBudget($event)"
      />
    </section>
  </div>
</form>
