import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PurchaseOrdersStore, PurchaseOrdersState } from './purchase-orders.store';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class PurchaseOrdersQuery extends QueryEntity<PurchaseOrdersState> {
  $selectAll = toSignal(this.selectAll(), { requireSync: true });

  constructor(protected store: PurchaseOrdersStore) {
    super(store);
  }
}
