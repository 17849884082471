import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@shared/store/auth/auth.service';
import { AppInitService } from '@services/app-init.service';
import { OverlayService } from '@services/overlay.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { LocalStorageKey } from '@shared/constants/localStorageKey';

@Component({
  selector: 'aux-confirmation',
  templateUrl: './confirmation.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  loginLink = `/${ROUTING_PATH.LOGIN}`;

  fg = new UntypedFormGroup({
    code: new UntypedFormControl(''),
  });

  public year = new Date().getFullYear();

  constructor(
    private overlayService: OverlayService,
    private authService: AuthService,
    private router: Router,
    public appInitService: AppInitService
  ) {
    if (!this.authService.latestUserParams) {
      this.router.navigate([`/${ROUTING_PATH.LOGIN}`]);
    }
  }

  async onSubmit() {
    if (this.fg.valid && this.authService.latestUserParams) {
      const { code } = this.fg.value;
      const { email, password } = this.authService.latestUserParams;

      const resp = await this.authService.confirmSignUp({ username: email, code });
      if (resp) {
        this.overlayService.success('User confirmed!');
        await this.authService.signIn(email, password);
        await this.authService.setUserAttributes();
        const lastURL = localStorage.getItem(LocalStorageKey.LOGIN_LAST_URL);
        if (lastURL) {
          localStorage.removeItem(LocalStorageKey.LOGIN_LAST_URL);
        }
        await this.router.navigate([`${lastURL || '/'}`]);
      }
    }
  }
}
