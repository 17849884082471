import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  inject,
} from '@angular/core';
import { MainQuery } from '@shared/store/main/main.query';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OrganizationService } from '@models/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { map, switchMap } from 'rxjs/operators';
import { DocumentType, EntityType, EventType, GqlService } from '@services/gql.service';
import { INVOICE_STATUSES, InvoiceModel } from '../state/invoice.model';
import { batchPromises } from '@shared/utils';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import { InvoiceService } from '../state/invoice.service';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ComponentsModule } from '@components/components.module';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '@components/icon/icon.component';
import { ButtonComponent } from '@components/button/button.component';
import { MainStore } from '@shared/store/main/main.store';

const invoiceType = DocumentType.DOCUMENT_INVOICE;
const supportingType = DocumentType.DOCUMENT_INVOICE_SUPPORT;

@UntilDestroy()
@Component({
  selector: 'aux-upload-documents-dialog',
  templateUrl: './upload-documents-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ComponentsModule,
    AsyncPipe,
    NgForOf,
    FormsModule,
    IconComponent,
    ButtonComponent,
  ],
})
export class UploadDocumentsDialogComponent implements AfterViewInit {
  mainStore = inject(MainStore);

  @ViewChild('fileManager') fileManager!: FileManagerComponent;

  pdf = 'application/pdf';

  files$ = new BehaviorSubject<File[]>([]);

  documentTypes: Record<
    string,
    DocumentType.DOCUMENT_INVOICE | DocumentType.DOCUMENT_INVOICE_SUPPORT
  > = {};

  loadingPreviousFiles$ = new BehaviorSubject(true);

  oneInvoiceSelected = false;

  updateValidation = new Subject<File | null>();

  invoice?: InvoiceModel;

  vendor = '';

  invoiceNumber = '';

  filesToDelete: File[] = [];

  hasChanges = false;

  INVOICE = EntityType.INVOICE;

  invoiceId: string | undefined;

  constructor(
    public ref: CustomOverlayRef<unknown, { invoice: InvoiceModel }>,
    public organizationService: OrganizationService,
    private mainQuery: MainQuery,
    private documentLibraryService: DocumentLibraryService,
    private invoiceService: InvoiceService,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {
    if (this.ref.data) {
      this.invoice = this.ref.data.invoice;
      this.invoiceId = this.invoice.id;
      this.vendor = this.invoice.organization.name;
      this.invoiceNumber = this.invoice.invoice_no || '—';
    }
  }

  getFilePath = (type?: typeof invoiceType | typeof supportingType) => {
    const trialId = this.mainQuery.getValue().trialKey;

    if (this.invoice && type) {
      return `trials/${trialId}/vendors/${this.invoice.organization.id}/invoices/${this.invoice.id}/${type}/`;
    }

    return `trials/${trialId}/vendors/`;
  };

  onSave = async () => {
    if (this.fileManager) {
      await batchPromises(this.filesToDelete, (file) => this.fileManager.removeFile(file));

      // filtering out uploaded files since we don't need to upload or modify them
      const files = this.fileManager.fileQuery.getAll().filter((f) => !f.uploaded);

      const filesWithChangedDocumentType = this.fileManager.fileQuery.getAll().filter((f) => {
        return f.uploaded && f.document_type_id !== this.documentTypes[f.id];
      });

      if (filesWithChangedDocumentType.length) {
        this.documentLibraryService.updateDocuments(
          filesWithChangedDocumentType.map((f) => ({
            document_type_id: this.documentTypes[f.id],
            id: f.id,
          }))
        );
      }

      let bucketKeyOfNewInvoicePdf =
        filesWithChangedDocumentType.find((f) => this.documentTypes[f.id] === invoiceType)
          ?.bucket_key || '';

      for (const file of files) {
        const documentType = this.documentTypes[file.id];
        const bucket_key = `${this.getFilePath(documentType)}${file.bucket_key}`;
        if (documentType === invoiceType) {
          bucketKeyOfNewInvoicePdf = bucket_key;
        }
        this.fileManager.fileStore.update(file.id, {
          ...file,
          document_type_id: this.documentTypes[file.id],
          bucket_key: bucket_key,
        });
      }

      const filesSuccess = await this.fileManager.fileService.uploadFiles(
        {
          status: INVOICE_STATUSES.STATUS_IN_QUEUE,
          vendor: this.invoice?.organization.id || '',
          documentType: DocumentType.DOCUMENT_INVOICE,
          entity_id: this.invoice !== undefined ? this.invoice.id : '',
          entity_type_id: EntityType.INVOICE,
        },
        false,
        true,
        undefined,
        undefined,
        undefined,
        true
      );

      if (filesSuccess) {
        if (bucketKeyOfNewInvoicePdf) {
          this.mainStore.setProcessingLoadingState(EventType.INVOICE_DOCUMENT_UPLOADED, true);
          const { success: eventSuccess, errors: eventErrors } = await firstValueFrom(
            this.gqlService.processEvent$({
              type: EventType.INVOICE_DOCUMENT_UPLOADED,
              entity_type: EntityType.INVOICE,
              entity_id: this.invoice?.id || '',
              bucket_key: `public/${bucketKeyOfNewInvoicePdf}`,
            })
          );

          if (eventSuccess) {
            this.overlayService.success(`Invoice successfully updated!`);
          } else {
            this.mainStore.setProcessingLoadingState(EventType.INVOICE_DOCUMENT_UPLOADED, false);
            this.overlayService.error(`Invoice could not be processed ${eventErrors}`);
          }
        }

        this.ref.close(this.fileManager.fileQuery.getAll());
      }
    }
  };

  removeFile(file: File) {
    this.filesToDelete.push(file);
    this.fileManager.fileService.removeFromStore(file);
  }

  getTooltip(isInvoice = false, file: File) {
    if (isInvoice && !this.isFilePdf(file)) {
      return 'Invoice file type must be PDF';
    }

    return '';
  }

  isFilePdf(file: File) {
    return file.bucket_key.endsWith('.pdf');
  }

  async ngAfterViewInit() {
    await this.fileManager.fetchFiles();

    this.fileManager.fileQuery
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((files: File[]) => {
        this.loadingPreviousFiles$.next(false);
        this.files$.next(files);
      });

    this.updateValidation
      .pipe(
        switchMap((passedFile: File | null) => {
          return this.files$.pipe(map((files) => ({ passedFile, files })));
        }),
        untilDestroyed(this)
      )
      .subscribe(({ passedFile, files }) => {
        let assignOtherPdfsToSupport = false;
        let fileChangedDesignation = false;
        // this will only be called if the file has changed designation so checking for passedFile is a sufficient condition
        if (passedFile) {
          fileChangedDesignation = true;
          if (this.documentTypes[passedFile.id] === invoiceType) {
            this.invoiceService.invoiceDesignationChanged$.next(true);
            assignOtherPdfsToSupport = true;
          }
        }
        files.forEach((file) => {
          if (this.documentTypes[file.id] === undefined) {
            this.documentTypes[file.id] =
              file.document_type_id === invoiceType && file.uploaded ? invoiceType : supportingType;
          }
          if (
            assignOtherPdfsToSupport &&
            this.documentTypes[file.id] === invoiceType &&
            file.id !== passedFile?.id
          ) {
            this.documentTypes[file.id] = supportingType;
          }
        });
        this.hasChanges =
          files.some((file) => !file.uploaded) ||
          !!this.filesToDelete.filter((file) => file.uploaded).length ||
          fileChangedDesignation;
      });

    this.updateValidation.next(null);
  }
}
