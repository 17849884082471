import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MAP_PATH_BY_TASK_TYPE } from '@models/user-tasks';
import { Router } from '@angular/router';
import { listUserTasksQuery } from '@services/gql.service';
import { MainService } from '@shared/store/main/main.service';
import { MainStore } from '@shared/store/main/main.store';
import { MainQuery } from '@shared/store/main/main.query';

@Component({
  selector: 'aux-portfolio-task-list',
  templateUrl: './portfolio-task-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioTaskListComponent {
  @Input() tasks!: listUserTasksQuery[];

  @Input() noTasksMessage = '';

  constructor(
    private mainService: MainService,
    private mainStore: MainStore,
    private mainQuery: MainQuery,
    private router: Router
  ) {}

  getTaskPath(task: listUserTasksQuery) {
    const getPathFn = MAP_PATH_BY_TASK_TYPE.get(task.user_task);
    return getPathFn ? getPathFn(task.task_id, task.organization_id || '') : '/';
  }

  async navigateToTask(task: listUserTasksQuery) {
    const currentTrial = this.mainQuery.getSelectedTrial()?.id;

    if (currentTrial !== task.trial_id) {
      await this.mainService.setTrial(task.trial_id);
      await this.mainStore.setTrial();
    }

    this.router.navigateByUrl(this.getTaskPath(task));
  }
}
