import { Component } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { NgClass, NgIf } from '@angular/common';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { BeActivitiesAttributesModalRowData } from './be-activities-attributes-modal/be-activities-attributes-modal.model';

export type AgDeleteCellComponentParams =
  ICellRendererParams<BeActivitiesAttributesModalRowData> & {
    onDelete: (params: AgDeleteCellComponentParams) => Promise<void>;
    isDisabled: (params: AgDeleteCellComponentParams) => string | null;
  };

@Component({
  standalone: true,
  template: `
    <div
      class="text-aux-error flex items-center justify-center w-[32px] h-full"
      (click)="onDelete()"
      [ngClass]="{
        'cursor-not-allowed opacity-50': disabled,
        'cursor-pointer': !disabled
      }"
      [auxTooltip]="disabled ? tooltip : ''"
    >
      <aux-icon name="X" [size]="16" />
    </div>
  `,
  imports: [IconComponent, NgIf, NgClass, TooltipDirective],
  styles: [],
})
export class AgDeleteCellComponent implements ICellRendererAngularComp {
  params!: AgDeleteCellComponentParams;
  disabled = false;
  tooltip = '';

  agInit(params: AgDeleteCellComponentParams): void {
    this.params = params;
    const resp = params.isDisabled(this.params);
    if (resp) {
      this.disabled = true;
      this.tooltip = resp;
    }
  }

  refresh(): boolean {
    return false;
  }

  onDelete() {
    if (this.disabled) {
      return;
    }
    this.params.onDelete(this.params);
  }
}
