import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import dayjs from 'dayjs';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { groupBy, omit } from 'lodash-es';
import { Utils } from '@services/utils';
import { OverlayService } from '@services/overlay.service';
import {
  ActivityType,
  AdjustmentType,
  AmountType,
  Currency,
  ExpenseSourceType,
  ExpenseType,
  GqlService,
} from '@services/gql.service';

import { ChecklistComponentData } from '../../models/quarter-close-checklist.model';
import { PeriodCloseComponent } from '../../../../period-close.component';
import { ExtendedBudgetData } from '../../../../../budget-page/tabs/budget-enhanced/state/budget.model';
import { QuarterCloseChecklistService } from '../../services/quarter-close-checklist.service';
import { QuarterCloseChecklistPeriodCloseService } from '../../services/quarter-close-checklist-period-close.service';
import { BudgetQuery } from '../../../../../budget-page/tabs/budget-enhanced/state/budget.query';
import { QuarterCloseChecklistComponent } from '../../quarter-close-checklist.component';
import { ChecklistRowInfoComponent } from '../checklist-row-info/checklist-row-info.component';
import { evenRounding } from '@shared/utils';

export interface DiscountExpenseDetail {
  which_vendor?: string;
  discount_type?:
    | 'contracted_calculated_discount'
    | 'custom_calculated_discount'
    | 'vendor_estimate_discount'
    | 'custom_discount'
    | 'none';
  calculated_percentage?: number;
  amount?: number;
}

@UntilDestroy()
@Component({
  selector: 'aux-checklist-section-discount',
  templateUrl: './checklist-section-discount.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistSectionDiscountComponent implements ChecklistComponentData {
  parent!: ChecklistRowInfoComponent;

  id = 'CloseDiscounts';

  data: unknown;

  vendors$ = new BehaviorSubject<
    {
      name: string;
      currency: Currency;
      vendor_id: string;
      discount_value: number | null;
      services_cost: number;
    }[]
  >([]);

  gridData$ = this.periodCloseComponent.gridData$.pipe(
    switchMap((data) => {
      return this.qcChecklistComponent.selectedQuarterMonthFormControl.valueChanges.pipe(
        startWith(this.qcChecklistComponent.selectedQuarterMonthFormControl.value),
        map(() => {
          return {
            data,
            month: this.qcChecklistComponent.selectedQuarterMonthFormControl.value,
          };
        })
      );
    }),
    map(({ data, month }) => {
      const groupedData = groupBy(data as unknown as ExtendedBudgetData[], 'vendor_id');
      const arr: {
        name: string;
        currency: Currency;
        vendor_id: string;
        discount_value: number | null;
        services_cost: number;
      }[] = [];

      for (const [vendor_id, categories] of Object.entries(groupedData)) {
        const discountRow = categories.find((c) => c.cost_category === 'Discount');
        const servicesRow = categories.find((c) => c.cost_category === 'Services');
        if (discountRow) {
          const current_month = dayjs(month).format('MMM-YYYY').toUpperCase();
          const organization = this.organizationQuery.getEntity(vendor_id);

          arr.push({
            vendor_id,
            name: organization?.name || '',
            currency: organization?.currency || Currency.USD,
            discount_value: discountRow?.accrual_override?.[current_month] ?? null,
            services_cost: servicesRow?.accrual_adjusted_obj[current_month] || 0,
          });
        }
      }

      this.vendors$.next(arr);

      return groupedData;
    }),
    shareReplay(1)
  );

  selectedVendor = new FormControl();

  discountCalc: 'custom' | 'contracted' | '' = '';

  customPercentage = '';

  contractedPercentage = 0;

  contractedPercentageStr = Utils.zeroHyphen;

  selectedDiscountType: 'calculated' | 'vendor' | 'custom' | 'none' | '' = '';

  customAmount = '';

  serviceCost = 0;

  vendorEstDiscount: number | null = null;

  calculatedAmount = 0;

  calculatedAmountType: {
    type: 'custom' | 'contracted' | '';
    perc: string;
  } = {
    type: '',
    perc: '',
  };

  isCalculatedSelectionDisabled = true;

  saveLoading$ = new BehaviorSubject(false);

  loading$ = new BehaviorSubject(false);

  activity_id = '';

  selectedCurrency: Currency = Currency.USD;

  expense_detail: DiscountExpenseDetail = {};

  saveButtonDisabled$ = new BehaviorSubject(true);

  constructor(
    private periodCloseComponent: PeriodCloseComponent,
    private organizationQuery: OrganizationQuery,
    private checklistService: QuarterCloseChecklistService,
    private gqlService: GqlService,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private budgetQuery: BudgetQuery,
    private overlayService: OverlayService,
    private qcChecklistComponent: QuarterCloseChecklistComponent
  ) {
    this.gridData$.pipe(untilDestroyed(this)).subscribe();

    combineLatest([
      this.qcChecklistComponent.selectedQuarterMonthFormControl.valueChanges.pipe(
        startWith(this.qcChecklistComponent.selectedQuarterMonthFormControl.value)
      ),
      this.selectedVendor.valueChanges.pipe(startWith(this.selectedVendor.value)),
      this.periodCloseService.isCurrentQuarterSelected,
    ])
      .pipe(
        switchMap(([month]) => {
          if (month && this.periodCloseService.selectedQuarterMonth && this.selectedVendor.value) {
            this.loading$.next(true);
            return this.gqlService.listDiscountExpenses$({
              amount_types: [AmountType.AMOUNT_DISCOUNT],
              period: dayjs(month).format('MMM-YYYY').toUpperCase(),
              organization_id: this.selectedVendor.value,
            });
          }

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((resp) => {
        if (resp.data) {
          const { activity_id, manual_adjustment, vendor_estimate } = resp.data[0];
          this.vendorEstDiscount = vendor_estimate?.amount ?? null;
          this.activity_id = activity_id;
          if (manual_adjustment) {
            const amount = manual_adjustment.amount || 0;
            try {
              this.expense_detail = {
                which_vendor: this.selectedVendor.value,
                ...JSON.parse(manual_adjustment.expense_detail || '{}'),
                amount,
              };
              this.setOldState(amount);
            } catch (e) {
              console.error(e);
            }
          }
        }
        this.loading$.next(false);
      });

    if (this.checklistService.discountSelectedVendor) {
      this.selectVendor(this.checklistService.discountSelectedVendor);
      this.checklistService.discountSelectedVendor = '';
    }
  }

  setOldState(amount: number) {
    switch (this.expense_detail.discount_type) {
      case 'contracted_calculated_discount':
        this.discountCalc = 'contracted';
        this.selectedDiscountType = 'calculated';
        this.calculatedAmount = amount;
        break;
      case 'custom_calculated_discount':
        this.discountCalc = 'custom';
        this.customPercentage = (this.expense_detail.calculated_percentage ?? '') + '';
        this.selectedDiscountType = 'calculated';
        this.calculatedAmount = amount;
        break;
      case 'vendor_estimate_discount':
        this.selectedDiscountType = 'vendor';
        break;
      case 'custom_discount':
        this.selectedDiscountType = 'custom';
        this.customAmount = amount + '';
        break;
      case 'none':
        this.selectedDiscountType = 'none';
        break;
      default:
        break;
    }
  }

  logic() {
    if (!this.selectedVendor.value) {
      return true;
    }
    if (
      this.selectedDiscountType === 'custom' &&
      (!this.customAmount || Number.isNaN(+this.customAmount))
    ) {
      return true;
    }
    const bool = !this.selectedDiscountType;
    if (bool) {
      return true;
    }
    if (this.selectedDiscountType === 'calculated') {
      const { type, perc } = this.calculatedAmountType;

      if (
        this.discountCalc !== type ||
        (this.discountCalc === 'custom' && perc !== this.customPercentage)
      ) {
        return true;
      }
    }
    if (!this.expense_detail.discount_type) {
      return false;
    }

    const { expense_detail } = this.getCurrentExpenseDetail();

    switch (this.expense_detail.discount_type) {
      case 'contracted_calculated_discount':
      case 'custom_calculated_discount':
      case 'custom_discount':
        return (
          this.expense_detail.amount === expense_detail?.amount && expense_detail?.amount !== 0
        );
      case 'vendor_estimate_discount':
      case 'none':
        return (
          this.expense_detail.discount_type === expense_detail?.discount_type &&
          this.expense_detail.which_vendor === expense_detail?.which_vendor
        );
    }
  }

  onChange() {
    this.saveButtonDisabled$.next(this.logic());
  }

  getCurrentExpenseDetail() {
    const expense_detail: DiscountExpenseDetail = {};
    expense_detail.which_vendor = this.selectedVendor.value;
    let amount = 0;
    switch (this.selectedDiscountType) {
      case 'calculated':
        amount = this.calculatedAmount;
        if (this.discountCalc === 'contracted') {
          expense_detail.discount_type = 'contracted_calculated_discount';
          expense_detail.calculated_percentage = this.contractedPercentage;
        } else {
          expense_detail.discount_type = 'custom_calculated_discount';
          expense_detail.calculated_percentage = +this.customPercentage;
        }
        break;
      case 'vendor':
        amount = this.vendorEstDiscount || 0;
        expense_detail.discount_type = 'vendor_estimate_discount';
        break;
      case 'custom':
        amount = +this.customAmount;
        expense_detail.discount_type = 'custom_discount';
        break;
      case 'none':
        expense_detail.discount_type = 'none';
        break;
      case '':
        return {};
    }
    expense_detail.amount = amount;
    return {
      expense_detail,
    };
  }

  getExpenseSource(
    discountCalc: 'custom' | 'contracted' | '',
    discountType: 'calculated' | 'vendor' | 'custom' | 'none' | ''
  ) {
    if (discountType === 'vendor') {
      return ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE;
    }
    if (discountCalc === 'contracted' && discountType === 'calculated') {
      return ExpenseSourceType.EXPENSE_SOURCE_FORECAST;
    }
    return ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT;
  }

  onVendorSelected() {
    this.reset();
    this.onChange();
    const vendor_id = this.selectedVendor.value;
    const vendor = this.vendors$.getValue().find((v) => v.vendor_id === vendor_id);
    if (vendor) {
      this.serviceCost = vendor.services_cost;
    }

    const info = this.budgetQuery.getValue().budget_info.find((i) => i.vendor_id === vendor_id);

    this.contractedPercentage = info?.expense_amounts?.[0]?.amount_perct || 0;
    this.contractedPercentageStr = Utils.percentageFormatter(this.contractedPercentage / 100, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    this.selectedCurrency = vendor?.currency || Currency.USD;
  }

  onCalculate() {
    this.calculatedAmountType = {
      type: '',
      perc: '',
    };
    switch (this.discountCalc) {
      case 'custom':
        {
          const perc = +this.customPercentage;
          if (Number.isNaN(perc)) {
            this.isCalculatedSelectionDisabled = true;
            this.calculatedAmount = 0;

            if (this.selectedDiscountType === 'calculated') {
              this.saveButtonDisabled$.next(true);
            }
            return;
          }
          this.calculatedAmount = evenRounding((perc / 100) * -this.serviceCost);
        }
        break;
      case 'contracted':
        this.calculatedAmount = evenRounding(
          ((+this.contractedPercentage || 100) / 100) * -this.serviceCost
        );
        break;
      case '':
        break;
    }
    this.calculatedAmountType = {
      type: this.discountCalc,
      perc: this.customPercentage,
    };
    this.isCalculatedSelectionDisabled = false;
    this.onChange();
  }

  async onSave() {
    const vendor_id = this.selectedVendor.value;
    const vendor = this.vendors$.getValue().find((v) => v.vendor_id === vendor_id);
    if (!vendor) {
      return;
    }
    const current_month = this.periodCloseService.selectedQuarterMonth;
    const budget_info = this.budgetQuery
      .getValue()
      .budget_info.find((i) => i.vendor_id === vendor_id);

    const { expense_detail } = this.getCurrentExpenseDetail();
    if (!expense_detail) {
      return;
    }

    this.saveLoading$.next(true);

    const resp = await firstValueFrom(
      this.gqlService.createBudgetExpense$({
        budget_version_id: budget_info?.budget_version_id,
        activity_id: this.activity_id,
        expense_type_id: ExpenseType.EXPENSE_ACCRUAL_OVERRIDE,
        expense_detail: JSON.stringify(omit(expense_detail, 'amount')),
        period_start: current_month,
        period_end: current_month,
        source: 'BASE',
        amount_type: AmountType.AMOUNT_DISCOUNT,
        amount_curr: `CURRENCY_${vendor.currency || 'USD'}`,
        amount: expense_detail.amount ?? 0,
        adjustment_type: AdjustmentType.ADJUSTMENT_AMOUNT,
        expense_source: this.getExpenseSource(this.discountCalc, this.selectedDiscountType),
      })
    );
    if (resp.success && resp.data) {
      await firstValueFrom(
        this.gqlService.updateAccruals$({
          organization_id: vendor.vendor_id,
          period: current_month,
          activity_types: [ActivityType.ACTIVITY_DISCOUNT],
          activity_ids: [],
        })
      );
      this.checklistService.discountSelectedVendor = vendor_id;
      await firstValueFrom(this.gqlService.invalidateBudgetCache$(vendor_id));
      this.periodCloseComponent.refresh$.next(null);
      this.overlayService.success();
      this.expense_detail = expense_detail;
      this.onChange();
      this.vendors$.next(
        this.vendors$.getValue().map((v) => {
          if (v.vendor_id === vendor_id) {
            return {
              ...v,
              discount_value: expense_detail.amount || 0,
            };
          }
          return v;
        })
      );
    } else {
      this.overlayService.error(resp.errors);
    }
    this.saveLoading$.next(false);
  }

  reset() {
    this.serviceCost = 0;
    this.customAmount = '';
    this.selectedDiscountType = '';
    this.customPercentage = '';
    this.discountCalc = '';
    this.vendorEstDiscount = null;
    this.calculatedAmount = 0;
    this.contractedPercentage = 0;
    this.contractedPercentageStr = Utils.zeroHyphen;
    this.activity_id = '';
    this.isCalculatedSelectionDisabled = true;
    this.calculatedAmountType = {
      type: '',
      perc: '',
    };
  }

  clearIfNecessary(radioButtonName: 'calc' | 'selection', val: string) {
    switch (radioButtonName) {
      case 'calc':
        if (this.discountCalc === val) {
          this.discountCalc = '';
        }
        break;
      case 'selection':
        if (this.selectedDiscountType === val) {
          this.selectedDiscountType = '';
        }
        break;
    }
    this.onChange();
  }

  selectVendor(vendor_id: string) {
    this.selectedVendor.setValue(vendor_id);
    this.onVendorSelected();
  }

  getCurrencySymbol = (currency: Currency) => {
    return Utils.getCurrenySymbol(currency);
  };
}
