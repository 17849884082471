import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { listWorkflowDetailsQuery } from '@services/gql.service';
import {
  QuarterCloseChecklistRowPermissionFields,
  QuarterCloseChecklistRowSections,
  QuarterCloseChecklistRowTitles,
} from '@pages/closing-page/tabs/quarter-close-checklist/models/quarter-close-checklist.model';

export interface WorkflowProperty {
  route: string;
  locked: boolean;
  month: string;
  assigned_by: string;
  assigned_to: string;
  assigned_date: string;
  last_update_info?: {
    update_date: string | null;
    updated_by: string | null;
    ts: string;
  };
}

export interface Workflow extends Omit<listWorkflowDetailsQuery, 'properties'> {
  properties: WorkflowProperty;
  updatedAuthorFullName: string;
  last_updated_by_full_name: string;
  last_updated_by: string;
  last_update_date: string;
  workflowName: QuarterCloseChecklistRowTitles;
  section: QuarterCloseChecklistRowSections;
  permissionField: QuarterCloseChecklistRowPermissionFields;
}

export type WorkflowState = EntityState<Workflow>;

export type WorkflowModel = getEntityType<WorkflowState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workflow' })
export class WorkflowStore extends EntityStore<WorkflowState> {
  constructor() {
    super({});
  }
}
