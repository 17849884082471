import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import dayjs from 'dayjs';

import { MainQuery } from '@shared/store/main/main.query';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { userFormatter } from '@shared/utils/user-formatter';

import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';

import { InvoiceDetailApproveBtnComponent } from './status-banner-buttons/invoice-detail-banner-approve-button.component';
import { InvoiceDetailSaveBtnComponent } from './status-banner-buttons/invoice-detail-banner-save-button.component';
import { InvoiceDetailDeleteBtnComponent } from './status-banner-buttons/invoice-detail-banner-delete-button.component';
import { InvoiceDetailDeclineBtnComponent } from './status-banner-buttons/invoice-detail-banner-decline-button.component';
import { InvoiceDetailDownloadBtnComponent } from './status-banner-buttons/invoice-detail-banner-download-button.component';
import { InvoiceFormComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.component';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-status-banner',
  template: `
    <div class="flex items-center justify-between h-16">
      <div>
        <span>Status:</span>
        <span class="font-bold ml-2">{{ status() }}</span>
        @if ((isApproved() || isDecline()) && approvedBy()) {
          <span> {{ approvedBy() }}</span>
        }
      </div>

      <div class="space-x-2 flex items-center">
        <aux-invoice-detail-status-banner-save-button
          [invoice]="invoice()"
          [invoiceFormComponent]="invoiceFormComponent()"
        />

        <aux-invoice-detail-status-banner-download-button [invoice]="invoice()" />

        @if (isPendingReview() || isInQueue()) {
          <aux-invoice-detail-status-banner-delete-button [invoice]="invoice()" />
        }

        @if (isPendingApproval()) {
          <aux-invoice-detail-status-banner-decline-button [invoice]="invoice()" />
          <aux-invoice-detail-status-banner-approve-button [invoice]="invoice()" />
          <aux-invoice-detail-status-banner-delete-button [invoice]="invoice()" />
        }
      </div>
    </div>

    @if (isDecline()) {
      <div class="bg-aux-gray-light mt-4 p-2 text-aux-black">
        <div class="font-bold">Reason for Decline</div>
        @if (declineReason()) {
          <div>{{ declineReason() }}</div>
        }
      </div>
    }
  `,
  imports: [
    InvoiceDetailApproveBtnComponent,
    InvoiceDetailSaveBtnComponent,
    InvoiceDetailDeleteBtnComponent,
    InvoiceDetailDeclineBtnComponent,
    InvoiceDetailDownloadBtnComponent,
  ],
  styles: [
    `
      :host {
        @apply block border px-4 mb-4;
      }
    `,
  ],
  host: {
    '[class]': 'hostClasses()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailStatusBannerComponent {
  mainQuery = inject(MainQuery);
  authQuery = inject(AuthQuery);

  invoice = input.required<InvoiceModel>();
  invoiceFormComponent = input.required<InvoiceFormComponent>();

  isDecline = computed(() => this.invoice().invoice_status === 'STATUS_DECLINED');
  isPendingApproval = computed(() => this.invoice().invoice_status === 'STATUS_PENDING_APPROVAL');
  isInQueue = computed(() => this.invoice().invoice_status === 'STATUS_IN_QUEUE');
  isPendingReview = computed(() => this.invoice().invoice_status === 'STATUS_PENDING_REVIEW');
  isApproved = computed(() => this.invoice().invoice_status === 'STATUS_APPROVED');

  hostClasses = computed(() => {
    const classList: string[] = [];

    if (this.isPendingReview() || this.isInQueue() || this.isPendingApproval()) {
      classList.push('bg-aux-blue-light-50', 'text-aux-black');
    }

    if (this.isApproved()) {
      classList.push('bg-aux-green-dark', 'text-white');
    }

    if (this.isDecline()) {
      classList.push('bg-aux-red-dark', 'pb-4', 'text-white');
    }

    return classList;
  });
  declineReason = computed(() => this.invoice().decline_reason);
  status = computed(() => {
    const invoice = this.invoice();

    switch (invoice.invoice_status) {
      case 'STATUS_PENDING_APPROVAL':
        return 'Pending Approval';
      case 'STATUS_IN_QUEUE':
        return 'In Queue';
      case 'STATUS_PENDING_REVIEW':
        return 'Pending Review';
      case 'STATUS_APPROVED':
        return 'Approved';
      case 'STATUS_DECLINED':
        return 'Declined';
      default:
        return '';
    }
  });
  approvedBy = computed(() => {
    const invoice = this.invoice();
    const userMap = this.mainQuery.userMap();
    const adminUser = this.authQuery.adminUser();

    const approval = invoice.approvals.at(-1);
    if (!approval) {
      return '';
    }

    const approvedBy = userFormatter({
      sub: approval.aux_user_id,
      userMap,
      adminUser,
    });

    return `by ${approvedBy} on ${dayjs(approval.approval_time || '').format('LL')}`;
  });
}
