import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { AgGridAngular } from '@ag-grid-community/angular';
import {
  ExcelExportParams,
  GridApi,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { cloneDeep } from 'lodash-es';

import { ButtonComponent } from '@components/button/button.component';
import {
  InvoiceLineItem,
  InvoiceModel,
} from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { Currency, DataSource } from '@services/gql.service';
import { TrialsQuery } from '@models/trials/trials.query';
import { MainQuery } from '@shared/store/main/main.query';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { TableConstants } from '@constants/table.constants';
import { Utils } from '@services/utils';
import { AuxExcelStyles, decimalAdd } from '@shared/utils';

const ExcelOptions: ExcelExportParams = {
  author: 'Auxilius',
  fontSize: 11,
  sheetName: 'Invoice Line Items',
  skipPinnedBottom: true,
  columnWidth(params) {
    switch (params.column?.getId()) {
      case 'description':
        return 400;
      default:
        return 200;
    }
  },
};

const calculatePinnedBottomData = (gridData: InvoiceLineItem[], dataSource: DataSource) => {
  let amountKey: keyof InvoiceLineItem = 'amount';
  let descriptionKey = 'description';

  switch (dataSource) {
    case DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE:
      amountKey = 'Amount';
      descriptionKey = 'Description';
      break;
    case DataSource.DATA_SOURCE_DYNAMICS365:
      amountKey = 'netAmountIncludingTax';
      break;
    default:
      break;
  }

  const totalAmount = gridData.reduce((acc, currentRecord) => {
    const amt = currentRecord[amountKey];
    if (Utils.isNumber(amt)) {
      acc = decimalAdd(acc, amt);
    }
    return acc;
  }, 0);

  return { [amountKey]: totalAmount, [descriptionKey]: 'Total' };
};

@Component({
  standalone: true,
  selector: 'aux-invoice-items-tab',
  template: `
    <div class="my-4 flex flex-row-reverse">
      <aux-button
        variant="secondary"
        label="Export"
        icon="FileExport"
        (click)="getDynamicExcelParams()"
      />
    </div>

    <ag-grid-angular
      class="ag-theme-aux tabular-nums"
      [rowData]="gridData()"
      [gridOptions]="gridOptions()"
      [suppressDragLeaveHidesColumns]="false"
      [domLayout]="'autoHeight'"
      (gridReady)="gridAPI.set($event.api)"
    />
  `,
  imports: [AgGridAngular, AsyncPipe, ButtonComponent],
  styles: [
    `
      :host {
        @apply block my-4;
      }
    `,
  ],
  hostDirectives: [StickyGridDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceItemsComponent {
  trialsQuery = inject(TrialsQuery);
  mainQuery = inject(MainQuery);

  invoice = input.required<InvoiceModel>();
  items = input.required<InvoiceLineItem[]>();

  gridAPI = signal<GridApi | null>(null);

  gridData = signal<InvoiceLineItem[]>([]);

  getGridData = effect(
    () => {
      const items = this.items();

      this.gridData.set(cloneDeep(items));
    },
    { allowSignalWrites: true }
  );

  setBottomData = effect(() => {
    const gridAPI = this.gridAPI();
    const invoice = this.invoice();
    const gridData = this.gridData();
    if (!gridAPI) {
      return;
    }

    const pinnedBottomData = calculatePinnedBottomData(
      gridData,
      invoice.data_source_id || DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE
    );
    gridAPI.setGridOption('pinnedBottomRowData', [pinnedBottomData]);
  });

  gridOptions = computed(() => {
    const invoice = this.invoice();

    return <GridOptions>untracked(() => ({
      defaultColDef: {
        ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      },
      ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
      enableRangeSelection: true,
      suppressCellFocus: false,
      columnDefs: [
        {
          headerName: 'Description',
          field: 'description',
          tooltipField: 'description',
          valueGetter: (value: ValueGetterParams<InvoiceLineItem>) => {
            if (invoice.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
              return value?.data?.Description || '';
            }
            return value?.data?.description || '';
          },
          tooltipValueGetter: (params) => {
            return params.valueFormatted !== Utils.zeroHyphen ? params.valueFormatted : '';
          },
          minWidth: 300,
          flex: 2,
          sortable: false,
          resizable: true,
          cellClass: '!block text-left max-w whitespace-nowrap overflow-hidden text-ellipsis',
        },
        {
          headerName: 'Total Amount',
          headerClass: 'ag-header-align-center justify-center font-bold',
          field: 'amount',
          valueGetter: (value) => {
            if (invoice.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
              return value?.data?.Amount || 0;
            }
            if (invoice.data_source_id === DataSource.DATA_SOURCE_DYNAMICS365) {
              return value?.data?.netAmountIncludingTax || 0;
            }
            return value?.data?.amount || 0;
          },
          valueFormatter: (params: ValueFormatterParams) => {
            return Utils.agCurrencyFormatterAccounting(
              params,
              invoice.organization?.currency || Currency.USD
            );
          },
          valueParser: (params) => Number(params.newValue),
          cellClass: () => {
            if (invoice.organization?.currency) {
              return [`budgetCost${invoice.organization?.currency}`, 'ag-cell-align-right'];
            }
            return ['budgetCostNoSymbol', 'ag-cell-align-right'];
          },
          minWidth: 300,
          flex: 1,
          resizable: true,
          sortable: false,
        },
      ],
      excelStyles: [
        ...AuxExcelStyles,
        ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
      ],
    }));
  });

  getDynamicExcelParams() {
    const invoice = this.invoice();
    const gridAPI = this.gridAPI();

    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial || !gridAPI) {
      return;
    }
    const totals = gridAPI.getPinnedBottomRow(0)?.data || {};

    let amount = totals.amount;

    switch (invoice.data_source_id) {
      case DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE:
        amount = totals.Amount;
        break;
      case DataSource.DATA_SOURCE_DYNAMICS365:
        amount = totals.netAmountIncludingTax;
        break;
      default:
        break;
    }

    gridAPI.exportDataAsExcel({
      ...ExcelOptions,
      fileName: `auxilius-invoice-line-items-${trial.short_name}-${invoice.invoice_no}.xlsx`,
      columnKeys: ['description', 'amount'],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: [
        {
          cells: [
            {
              data: { value: `Total`, type: 'String' },
              styleId: 'total_row_header',
            },
            {
              data: { value: `${amount}`, type: 'Number' },
              styleId: `total_row_${invoice.organization.currency || Currency.USD}`,
            },
          ],
        },
      ],
    });
  }
}
