import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  OnInit,
  signal,
} from '@angular/core';
import { GridReadyEvent, GridApi, GetRowIdFunc, IRowNode } from '@ag-grid-community/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, skip, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import {
  EventType,
  InvestigatorTransaction,
  PermissionType,
  WorkflowStep,
  batchCreateInvestigatorTransactionsMutation,
  GqlService,
  EntityType,
  InvestigatorTransactionSourceType,
} from '@services/gql.service';
import { EventService } from '@services/event.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import {
  CATEGORY_OPTIONS,
  getInvestigatorTransactionCols,
} from './investigator-transactions-grid-cols';
import {
  InvestigatorTransactionsDataRow,
  InvestigatorTransactionsService,
  TransactionMode,
} from './investigator-transactions.service';
import { ImportPatientDataModalComponent } from './import-patient-data-modal/import-patient-data-modal.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { EventQuery } from '@models/event/event.query';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AgSetColumnsVisible,
  ServerSideColumnFilterType,
  ServerSideFilterInfo,
  ServerSideSortOrder,
} from '@shared/utils';
import { DatasourceService } from '@shared/services/datasource.service';
import { FormBuilder } from '@angular/forms';
import { isEqual } from 'lodash';
import { FormArray, FormGroup } from '@angular/forms';

import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { File } from '@components/file-manager/state/file.model';
import { InvestigatorTransactionTableFormService } from './investigator-transactions-table-form.service';
import { MessagesConstants } from '@constants/messages.constants';
import { RemoveTransactionModalComponent } from './remove-transaction-modal/remove-transaction-modal.components';
import { MainQuery } from '@shared/store/main/main.query';
import dayjs from 'dayjs';
import { ExportType } from '@services/utils';
import {
  EtlTaskStatus,
  createEtlTaskStatusConsoleSubscriber,
} from '@shared/utils/etl-task-tracker';
import { ApiService } from '@services/api.service';
import { InvestigatorTransactionsSaveModalComponent } from './investigator-transactions-save-modal/investigator-transactions-save-modal.component';

@UntilDestroy()
@Component({
  selector: 'aux-investigator-transactions',
  templateUrl: './investigator-transactions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsComponent implements OnInit {
  readonly workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  isAdminUser$ = this.authQuery.adminUser$.pipe(untilDestroyed(this));

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  showSourceColumn$ = this.launchDarklyService.select$(
    (flags) => flags.investigator_transactions_source_column
  );

  showSiteBudgetVersionColumn$ = this.launchDarklyService.select$(
    (flags) => flags.site_budget_version_column
  );

  protocolVersionOptions$ = this.investigatorTransactionsService.protocolVersionOptions$;

  siteOptions$ = this.investigatorTransactionsService.siteOptions$;

  patientGroupOptions$ = this.investigatorTransactionsService.patientGroupOptions$;

  editMode$ = new BehaviorSubject<TransactionMode>(null);

  addTransactionDisabled$ = new BehaviorSubject(false);

  transactionForm = new FormGroup({
    table: new FormArray([]),
  });

  isCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH);

  savedTransactionIds$ = new BehaviorSubject<string[]>([]);

  gridAPI!: GridApi;

  private tableFormInstance!: InvestigatorTransactionTableFormService;

  isSiteColumnCollapsed$ = new BehaviorSubject(true);

  isContractSelected$ = new BehaviorSubject(false);

  submitInProgress = signal(false);

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  totalRecords$ = new BehaviorSubject(0);

  showBottomTotals$ = new BehaviorSubject(false);

  serverSideFilters: ServerSideFilterInfo<InvestigatorTransaction>[] = [
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'activity_date',
      type: ServerSideColumnFilterType.IsGreaterThanOrEqualTo,
      inputPropertyName: 'start_activity_date',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'activity_date',
      type: ServerSideColumnFilterType.IsLessThanOrEqualTo,
      inputPropertyName: 'end_activity_date',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'site_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'site_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_group_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_group_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_protocol_version_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_protocol_version_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'description',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'descriptions',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'country',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'countries',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_protocol_sub_type',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_protocol_sub_types',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'source',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'sources',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'description',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_protocol_names',
    },
  ];

  gridFiltersFormGroup = this.formBuilder.group(
    this.serverSideFilters.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.inputPropertyName]: [null],
      }),
      {}
    )
  );

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  addTransactionTooltip = computed(() => {
    if (!this.addTransactionPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : '';
  });

  addTransactionPermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_ADD_TRANSACTION],
  });

  visitCostsEnabled$ = new BehaviorSubject(false);

  sortModel$ = new BehaviorSubject<ServerSideSortOrder<InvestigatorTransaction>[]>([]);

  gridOptions$ = combineLatest([
    this.isContractSelected$,
    this.launchDarklyService.select$((flags) => flags.investigator_transactions_edc_created_date),
    this.showSourceColumn$,
    this.showSiteBudgetVersionColumn$,
    this.savedTransactionIds$,
  ]).pipe(
    map(([isSelected, isEDCColumnBeShown, showSourceColumn, showSiteBudgetVersionColumn]) => {
      return getInvestigatorTransactionCols({
        isContractCurrency: isSelected,
        isEDCColumnBeShown,
        showSourceColumn,
        showSiteBudgetVersionColumn,
        isSiteColumnCollapsed$: this.isSiteColumnCollapsed$,
        protocolVersionOptions$: this.protocolVersionOptions$,
        siteOptions$: this.siteOptions$,
        patientGroups$: this.patientGroupOptions$,
        updateSiteFields: (rowId: string, id?: string) => {
          this.tableFormInstance.updateSiteFields(
            rowId,
            id || '',
            this.investigatorTransactionsService.siteMap,
            this.investigatorTransactionsService.getSiteAddress.bind(
              this.investigatorTransactionsService
            ),
            this.investigatorTransactionsService.getPrincipalInvestigatorName.bind(
              this.investigatorTransactionsService
            )
          );
        },
        updatePatientIdField: (rowId: string, category?: string) =>
          this.tableFormInstance.updateValidationByCategory(rowId, category || ''),
        editRow: (rowId: string) => {
          this.toggleEditMode('edit');

          this.tableFormInstance.editRow(rowId, {
            protocolVersionOptions: this.protocolVersionOptions$.getValue(),
            patientGroups: this.patientGroupOptions$.getValue(),
            siteOptions: this.siteOptions$.getValue(),
            categoryOptions: CATEGORY_OPTIONS,
          });
        },
        removeRow: (rowId: string, createMode: boolean) => {
          this.removeRow(rowId, createMode);
        },
        savedTransactionIds$: this.savedTransactionIds$,
        transactionMode$: this.editMode$,
        isPatientsFinalized: this.isPatientsFinalized,
      });
    })
  );

  filterLoading$ = new BehaviorSubject(true);

  userHasLockPatientDataPermission = false;

  readonly datasource = this.datasourceService.transactionDatasource;

  totalCost$ = new BehaviorSubject(0);

  lastSourceRefreshDate$ = new BehaviorSubject('');

  getRowId?: GetRowIdFunc = undefined;

  // SAMPLE CODE FOR ETL TASK STATUS CONSUMPTION
  patientDataTrackingId$ = new BehaviorSubject('');
  patientDataTrackingEvents$: Observable<EtlTaskStatus> | undefined;
  // END-SAMPLE

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private investigatorTransactionsService: InvestigatorTransactionsService,
    private overlayService: OverlayService,
    private eventService: EventService,
    private authQuery: AuthQuery,
    private workflowQuery: WorkflowQuery,
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService,
    private eventQuery: EventQuery,
    private router: Router,
    private route: ActivatedRoute,
    private datasourceService: DatasourceService,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    this.setUserPermissions();

    effect(() => {
      this.isPatientsFinalized();
      const manualTransactionRows: IRowNode[] = [];

      this.gridAPI.forEachNode((node) => {
        if (
          node.data.source_type ===
          InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_MANUAL
        ) {
          manualTransactionRows.push(node);
        }
      });

      this.gridAPI.redrawRows({
        rowNodes: manualTransactionRows,
      });
    });

    this.savedTransactionIds$
      .pipe(untilDestroyed(this), distinctUntilChanged(isEqual))
      .subscribe((savedTransactionIds) => {
        this.datasource.updateOptions({
          overrideFilter: {
            sort_to_top_ids: savedTransactionIds,
          },
        });
      });

    this.getRowId = (params) => params.data.id;
  }

  ngOnInit() {
    this.fetchFilters();

    this.launchDarklyService
      .select$((flags) => flags.visit_costs)
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.visitCostsEnabled$.next(enabled);

        if (this.tableFormInstance) {
          this.tableFormInstance.refreshAllFormRows();
        }
      });

    this.eventService
      .select$(EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.datasource.forceRefresh();
      });

    this.eventService
      .select$(EventType.UPDATE_INVESTIGATOR_TRANSACTIONS)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.datasource.forceRefresh();
      });

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params['endDate']) {
        const endDate = dayjs(params['endDate']).endOf('month').format('YYYY-MM-DD');
        this.investigatorTransactionsService.addInMonthAdjustmentsFilters('', endDate, '');
        this.router.navigate([], { queryParams: {}, replaceUrl: true });
      }
    });

    // SAMPLE CODE FOR ETL TASK STATUS CONSUMPTION
    this.patientDataTrackingId$
      .pipe(
        filter((id) => !!id),
        // get the observable for the id
        // note that getEtlTaskTracker$ function works across hard-refreshes (i.e. if you want to persist the id somewhere and call getEtlTracker during control load as necessary)
        switchMap((id) => this.eventService.getEtlTaskTracker$(id)),
        untilDestroyed(this)
      )
      .subscribe(createEtlTaskStatusConsoleSubscriber(this.apiService, this.overlayService));
    // END-SAMPLE
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;

    this.datasource.initialize({
      untilDestroyedPipeOperator: untilDestroyed(this),
      filters: this.serverSideFilters,
      filterValues$: this.filterValues$,
      sortModel$: this.sortModel$,
      parseFunction: (items) =>
        this.investigatorTransactionsService.getPatientTransactionsGridData(items),
    });

    // Default sorting
    this.gridAPI.applyColumnState({
      state: [{ colId: 'activity_date', sort: 'desc', sortIndex: 1 }],
    });

    this.datasource.loading$
      .pipe(
        untilDestroyed(this),
        filter((value) => !value),
        skip(1) // Skip initial loading state
      )
      .subscribe(() => {
        this.tableFormInstance.reinitializeTableRows();
      });

    this.datasource.refresh$
      .pipe(untilDestroyed(this))
      .pipe(
        switchMap(() =>
          this.datasourceService.retrieveTransactionAnalytics({
            filter_model: this.datasource.currentServerInput?.filter_model || {},
          })
        )
      )
      .subscribe(({ aggregation }) => {
        this.totalCost$.next(aggregation?.total_cost || 0);
        this.totalRecords$.next(isNaN(Number(aggregation?.id)) ? 0 : Number(aggregation?.id));
        this.showBottomTotals$.next(
          (isNaN(Number(aggregation?.id)) ? 0 : Number(aggregation?.id)) > 250
        );
        this.lastSourceRefreshDate$.next(aggregation?.latest_source_date || '');
      });

    this.tableFormInstance = new InvestigatorTransactionTableFormService(
      this.transactionForm as FormGroup,
      api,
      this.visitCostsEnabled$
    );

    combineLatest([
      this.launchDarklyService.select$((flags) => flags.investigator_transactions_site_address),
      this.isSiteColumnCollapsed$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([isSiteAddressEnabled, isSiteColumnCollapsed]) => {
        if (!isSiteColumnCollapsed) {
          AgSetColumnsVisible({
            gridApi: this.gridAPI,
            keys: ['site_address'],
            visible: isSiteAddressEnabled,
          });
          this.gridAPI.sizeColumnsToFit();
        }
      });
  }

  private toggleLoaders(isLoading: boolean) {
    this.filterLoading$.next(isLoading);
  }

  openImportPatientDataModal() {
    this.overlayService.open({
      content: ImportPatientDataModalComponent,
      data: {
        onSuccess: (id: string) => {
          if (!this.patientDataTrackingId$.closed) {
            this.patientDataTrackingId$.next(id);
          }
        },
      },
    });
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockPatientDataPermission]) => {
        this.userHasLockPatientDataPermission = userHasLockPatientDataPermission;
      });
  }

  toggleEditMode(mode: TransactionMode) {
    const prevEditMode = this.editMode$.getValue();

    this.editMode$.next(mode);

    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['total_cost'],
      visible: !mode,
    });

    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['exchange_rate', 'contract_curr'],
      visible: this.isContractSelected$.getValue() && !mode,
    });

    if (!this.isContractSelected$.getValue()) {
      AgSetColumnsVisible({
        gridApi: this.gridAPI,
        keys: ['contract_amount'],
        visible: !!mode,
      });
    }

    if (mode) {
      this.gridFiltersFormGroup.disable();
    }

    this.gridAPI.sizeColumnsToFit();

    if (this.editMode$.getValue() !== prevEditMode) {
      this.gridAPI.redrawRows();
    }
  }

  cancelChanges = (softRefresh = false) => {
    if (!softRefresh) {
      this.gridAPI?.refreshServerSide({ purge: true });
    }

    this.toggleEditMode(null);

    this.tableFormInstance.clearForm();

    this.gridFiltersFormGroup.enable();

    this.addTransactionDisabled$.next(false);
  };

  goToFistPage() {
    if (this.gridAPI.paginationGetCurrentPage() !== 0) {
      this.gridAPI?.paginationGoToFirstPage();
    }
  }

  addTransaction = () => {
    this.goToFistPage();
    this.toggleEditMode('create');

    const latestProtocolVersion = this.protocolVersionOptions$
      .getValue()
      .find(({ is_current }) => is_current);

    this.tableFormInstance.addEntity(latestProtocolVersion);

    this.checkAvailabilityAddNewEntity();
  };

  private async fetchFilters() {
    this.toggleLoaders(true);

    await firstValueFrom(this.investigatorTransactionsService.getTransactionFilters());

    this.toggleLoaders(false);
  }

  getGridContext = () => {
    return {
      formGroup: this.transactionForm,
    };
  };

  saveTransactions = async () => {
    this.tableFormInstance.markAllFieldsAsTouched();

    if (this.transactionForm.invalid) {
      const errorMessage = this.tableFormInstance.getTableErrorMessage();
      this.overlayService.error(errorMessage);
      return;
    }

    const resp = await firstValueFrom(
      this.overlayService.open<{ note: string; documents: File[] }>({
        content: InvestigatorTransactionsSaveModalComponent,
        data: {
          header: 'Save Manual Transaction',
          useDesignSystemStyling: true,
          displayX: true,
        },
      }).afterClosed$
    );

    if (!resp?.data) return;

    this.submitInProgress.set(true);

    const allFormValues: InvestigatorTransactionsDataRow[] =
      this.transactionForm.getRawValue().table;

    const uploadedDocumentBucketPaths = resp.data.documents.map(({ bucket_key }) => bucket_key);

    const transactionsToUpdate = allFormValues.filter(({ mode }) => mode === 'edit');
    const transactionsToSave = allFormValues.filter(({ mode }) => mode === 'create');

    const updatesPromises: Promise<unknown>[] = [];

    if (transactionsToUpdate.length) {
      updatesPromises.push(
        this.investigatorTransactionsService.updateTransactions(
          transactionsToUpdate,
          resp.data.note,
          uploadedDocumentBucketPaths
        )
      );
    }

    if (transactionsToSave.length) {
      updatesPromises.push(
        this.investigatorTransactionsService.saveTransaction(
          transactionsToSave,
          resp.data.note,
          uploadedDocumentBucketPaths
        )
      );
    }

    const res = await Promise.allSettled(updatesPromises);

    this.storeSavedTransactions(res[updatesPromises.length - 1], transactionsToSave.length);
    this.fetchFilters();
    this.datasource.forceRefresh();

    if (this.editMode$.getValue() === 'create') {
      this.updateSortColumnsForNewTransactions();
    }

    this.cancelChanges(true);

    setTimeout(() => {
      this.submitInProgress.set(false);
      this.overlayService.success('Successfully saved!');
    }, 1000);
  };

  private updateSortColumnsForNewTransactions() {
    const appliedSortColumns = this.gridAPI
      .getColumnState()
      .filter(({ sort }) => !!sort)
      .map(({ colId }) => colId);

    this.gridAPI.applyColumnState({
      state: [
        ...appliedSortColumns.map((colId) => ({
          colId,
          sort: null,
        })),
        { colId: 'sort_to_top_ids', sort: 'desc', sortIndex: 1 },
        { colId: 'create_date', sort: 'desc', sortIndex: 2 },
      ],
    });
  }

  private storeSavedTransactions(
    savedTransactionsResponse: PromiseSettledResult<Awaited<unknown>>,
    transactionsToSaveCount: number
  ) {
    if (transactionsToSaveCount) {
      if (savedTransactionsResponse.status === 'fulfilled') {
        const ids = (
          savedTransactionsResponse.value as GraphqlResponse<
            batchCreateInvestigatorTransactionsMutation[]
          >
        ).data?.map(({ id }) => id);

        if (ids?.length) {
          this.savedTransactionIds$.next([...this.savedTransactionIds$.getValue(), ...ids]);
        }
      }
    }
  }

  checkAvailabilityAddNewEntity() {
    this.addTransactionDisabled$.next(
      this.tableFormInstance.getTableFormArray().length >= this.gridAPI.paginationGetPageSize()
    );
  }

  paginationChange() {
    this.checkAvailabilityAddNewEntity();
  }

  async canDeactivate(): Promise<boolean> {
    if (this.transactionForm.controls.table.controls.length) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event?.data;
    }
    return true;
  }

  private async removeRow(rowId: string, createMode: boolean) {
    const modal = this.overlayService.open<{ note: string; documents: File[]; remove: true }>({
      content: RemoveTransactionModalComponent,
      closeButton: false,
      data: {
        createMode,
      },
    });

    const response = await firstValueFrom(modal.afterClosed$);

    if (createMode && response.data?.remove) {
      this.tableFormInstance.removeTableRow(rowId);
      return;
    }

    if (response.data) {
      const { success } = await this.investigatorTransactionsService.removeTransaction(
        rowId,
        response.data.note,
        response.data.documents.map(({ bucket_key }) => bucket_key)
      );

      if (success) {
        this.tableFormInstance.removeRow(rowId);
        this.datasource.forceRefresh();
        this.fetchFilters();
      }
    }
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  async onExportInvestigatorTransactions() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.INVESTIGATOR_TRANSACTIONS,
          filename: `${trialName}_auxilius-investigator-transactions__${dateStr}`,
          sort_model: this.datasource.currentServerInput?.sort_model || [],
          filter_model: this.datasource.currentServerInput?.filter_model || [],
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }
}
