import { Route, Routes } from '@angular/router';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { VendorPaymentsPageComponent } from './vendor-payments-page.component';
import { InvoicesComponent } from './tabs/invoices/invoices.component';
import { PurchaseOrdersComponent } from './tabs/purchase-orders/purchase-orders.component';
import { InvoiceDetailComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-detail.component';
import { VendorsComponent } from './tabs/vendors/vendors.component';
import { PaymentScheduleComponent } from './tabs/payment-schedule/payment-schedule.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { PaymentMilestonesComponent } from './tabs/payment-milestones/payment-milestones.component';
import { redirectNestingRoutesByFeatureFlag } from '@services/routing.service';

export const VENDOR_PAYMENTS_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.VENDOR_PAYMENTS.INVOICES,
    component: InvoicesComponent,
    data: {
      feature: FeatureFlag.VENDOR_PAYMENTS_INVOICES,
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS,
    component: PurchaseOrdersComponent,
    data: {
      feature: FeatureFlag.VENDOR_PAYMENTS_PURCHASE_ORDERS,
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ROUTING_PATH.VENDOR_PAYMENTS.VENDORS,
    component: VendorsComponent,
    data: {
      feature: FeatureFlag.VENDOR_PAYMENTS_VENDORS,
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES,
    component: PaymentMilestonesComponent,
    data: {
      feature: FeatureFlag.VENDOR_PAYMENTS_PAYMENT_MILESTONES,
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE,
    component: PaymentScheduleComponent,
    data: {
      feature: FeatureFlag.VENDOR_PAYMENTS_PAYMENT_SCHEDULE,
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: `${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}/:id`,
    component: InvoiceDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      feature: FeatureFlag.VENDOR_PAYMENTS_INVOICES,
    },
  },
];

export const buildVendorPaymentsRoutes = (featureFlags: Flags | null, lastUrl?: string): Route => {
  const enabledRoutes: Routes = [
    ...buildRoutesByFeatureFlags(featureFlags, VENDOR_PAYMENTS_ROUTES_FF),
  ];
  const redirectTo = redirectNestingRoutesByFeatureFlag(
    enabledRoutes,
    VENDOR_PAYMENTS_ROUTES_FF.slice(0, -1),
    lastUrl || '',
    {
      firstPriorRoute: {
        isActive: !!(featureFlags || {})['nav_budget'],
        redirectTo: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`,
      },
      secondPriorRoute: {
        isActive: !!(featureFlags || {})['nav_document_library'],
        redirectTo: `/${ROUTING_PATH.DOCUMENTS}`,
      },
    }
  );

  return {
    path: ROUTING_PATH.VENDOR_PAYMENTS.INDEX,
    component: VendorPaymentsPageComponent,
    children: [...enabledRoutes, redirectTo],
  };
};
