import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ApprovalType, EntityType, InvoiceStatus, PermissionType } from '@services/gql.service';
import { ButtonComponent } from '@components/button/button.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

import { InvoiceBannerButtonDirective } from './invoice-detail-banner-button.directive';
import { invoiceDisabledTooltip } from '@pages/vendor-payments-page/tabs/invoices/invoices.component';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-status-banner-approve-button',
  template: `
    <aux-button
      [auxTooltip]="tooltip()"
      [disabled]="disabled()"
      [onClick]="onApprove"
      icon="Check"
      variant="success"
      classList="h-9"
      label="Approve Invoice"
      [iconSize]="20"
    />
  `,
  imports: [ButtonComponent, TooltipDirective],
  hostDirectives: [
    {
      directive: InvoiceBannerButtonDirective,
      inputs: ['invoice'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailApproveBtnComponent {
  ctx = inject(InvoiceBannerButtonDirective);

  hasApprovePermission = this.ctx.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_APPROVE_INVOICE],
  });

  disabled = computed(() => {
    return !this.hasApprovePermission() || this.ctx.buttonDisabled() || this.ctx.statusCheck();
  });

  tooltip = computed(() => {
    if (!this.hasApprovePermission()) {
      return this.ctx.dontHavePermission;
    }

    if (this.ctx.buttonDisabled()) {
      return this.ctx.invoiceLockTooltip();
    }

    if (this.ctx.statusCheck()) {
      return invoiceDisabledTooltip;
    }

    return '';
  });

  onApprove = async () => {
    const { overlayService, gqlService, invoiceService, userTaskService } = this.ctx;
    const invoice = this.ctx.invoice();
    const resp = overlayService.openConfirmDialog({
      header: `Approve Invoice?`,
      message: `Are you sure you want to approve Invoice ${invoice.invoice_no}?`,
      okBtnText: `Approve`,
    });
    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data?.result) {
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      gqlService.approveRule$({
        approved: true,
        comments: '',
        permission: 'PERMISSION_APPROVE_INVOICE',
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: invoice.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      overlayService.error(approveErrors);
      return;
    }
    await invoiceService.update({
      ...invoice,
      invoice_status: InvoiceStatus.STATUS_APPROVED,
    });
    await userTaskService.triggerUserTaskList$();
  };
}
