import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgControlComponent, AgControlComponentParams, noop } from './ag-control.component';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgIf } from '@angular/common';

type AgDatepickerComponentParams = AgControlComponentParams;

@Component({
  template: `
    <div *ngIf="formGroup$ | async" [formGroup]="$any(formGroup$ | async)">
      <aux-input
        *ngIf="isReady$ | async"
        class="w-full border-none bg-transparent p-0"
        [inputClassName]="
          hasError ? 'border-aux-red-dark !rounded ' + controlHeightClass : controlHeightClass
        "
        type="date"
        [formControl]="formControl"
        (change)="changeHandler()"
      />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, InputComponent, AsyncPipe, NgIf],
})
export class AgDatepickerComponent extends AgControlComponent {
  params!: AgDatepickerComponentParams;

  agInit(params: AgDatepickerComponentParams): void {
    this.params = params;
    this.value = params.value;
    this.fieldName = params.colDef?.field;
    this.changeHandler = params.changeHandler ?? noop;
    this.updateView(params);
  }
}
