import { GridApi, IRowNode } from '@ag-grid-community/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Currency,
  InvestigatorTransactionSourceType,
  PatientProtocolType,
  listPatientGroupsQuery,
  listPatientProtocolVersionsQuery,
  listSitesQuery,
} from '@services/gql.service';
import { Nullable, Utils } from '@services/utils';
import dayjs from 'dayjs';
import { InvestigatorTransactionsDataRow } from './investigator-transactions.service';
import { uniq } from 'lodash';
import { Option } from '@components/components.type';
import { SiteOption } from '@models/site-option.model';
import { BehaviorSubject } from 'rxjs';

export function checkFormRow(
  target: InvestigatorTransactionTableFormService,
  _: string,
  descriptor: PropertyDescriptor
) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: [string, ...unknown[]]) {
    const [rowId] = args;
    const index = target.getTableRowIndex.call(this, rowId);

    if (index === -1) {
      console.warn(`The form entity was not found with the given ID(${rowId})`);
      return;
    }

    originalMethod.apply(this, args);
  };

  return descriptor;
}

export class InvestigatorTransactionTableFormService {
  private gridAPI!: GridApi;

  private transactionForm: FormGroup<{ table: FormArray }>;

  private visitCostsEnabled$: BehaviorSubject<boolean>;

  private readonly mapNameColumnByField = new Map<keyof InvestigatorTransactionsDataRow, string>([
    ['activity_date', 'Completion Date'],
    ['contract_amount', 'Total Cost(Contract)'],
    ['description', 'Description'],
    ['patient_protocol_version_name', 'Protocol Version'],
    ['site_budget_version_name', 'Site Budget Version'],
    ['site_no', 'Site #'],
    ['patient_protocol_category', 'Category'],
    ['external_patient_id', 'Patient ID'],
    ['patient_group_id', 'Patient Group'],
  ]);

  constructor(
    transactionForm: FormGroup<{ table: FormArray }>,
    gridApi: GridApi,
    visitCostsEnabled$: BehaviorSubject<boolean>
  ) {
    this.transactionForm = transactionForm;
    this.gridAPI = gridApi;
    this.visitCostsEnabled$ = visitCostsEnabled$;
  }

  getTableFormArray(): FormArray {
    return <FormArray>this.transactionForm.get('table');
  }

  clearForm() {
    this.getTableFormArray().clear();
  }

  getTableRowIndex(rowId: string): number {
    return this.getTableFormArray().controls.findIndex((control) => control.value.id === rowId);
  }

  private insertFormEntity(id: string, entity: Nullable<InvestigatorTransactionsDataRow>) {
    this.getTableFormArray().push(
      new FormGroup({
        id: new FormControl(id),
        contract_amount: new FormControl(entity.contract_amount || null, [Validators.required]),
        activity_date: new FormControl(entity.activity_date || null, [Validators.required]),
        description: new FormControl(entity.description || null, [Validators.required]),
        patient_protocol_version_name: new FormControl(
          entity.patient_protocol_version_name || null,
          [Validators.required]
        ),
        site_no: new FormControl(entity.site_no || null, [Validators.required]),
        patient_protocol_category: new FormControl(entity.patient_protocol_category || null, [
          Validators.required,
        ]),
        external_patient_id: new FormControl(entity.external_patient_id || null, [
          Validators.required,
        ]),
        patient_group_id: new FormControl(entity.patient_group_id || null),
        source_name: new FormControl(entity.source_name || null),
        source_created_date: new FormControl(entity.source_created_date || null),
        contract_curr: new FormControl(entity.contract_curr || null),

        // Sites non editable fields
        site_name: new FormControl(entity.site_name || null),
        site_address: new FormControl(entity.site_address || null),
        country: new FormControl(entity.country || null),
        investigator_name: new FormControl(entity.investigator_name || null),

        mode: new FormControl(entity.mode),
      })
    );
  }

  addEntity(latestProtocolVersion?: listPatientProtocolVersionsQuery) {
    const id = crypto.randomUUID();

    const newEntity: Nullable<InvestigatorTransactionsDataRow> = {
      id,
      activity_date: null,
      source_name: 'Manual',
      source_type: InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_MANUAL,
      source_created_date: dayjs().format('YYYY-MM-DD'),
      site_no: null,
      site_name: '',
      investigator_name: '',
      external_patient_id: null,
      patient_protocol_category: null,
      patient_group_id: null,
      patient_protocol_version_name: latestProtocolVersion?.id ?? null,
      site_budget_version_name: null,
      site_address: null,
      description: null,
      contract_curr: Currency.USD,
      currency: Currency.USD,
      contract_amount: null,
      exchange_rate: '',
      total_cost: 0,
      country: '',
      mode: 'create',
    };

    this.insertFormEntity(id, newEntity);

    this.gridAPI.applyServerSideTransactionAsync({
      addIndex: 0,
      add: [newEntity],
    });
  }

  reinitializeTableRows() {
    const tableControls = this.getTableFormArray();

    const formValues = tableControls.controls
      .map((rowControl, index) => ({ ...rowControl.getRawValue(), sortIndex: index }))
      .sort(({ sortIndex }, { sortIndex: sortIndex2 }) => Utils.alphaNumSort(sortIndex, sortIndex2))
      .reverse()
      .reduce<InvestigatorTransactionsDataRow[]>((accum, rowFormValues) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { sortIndex, ...restValues } = rowFormValues;

        accum.push(restValues);

        return accum;
      }, []);

    this.gridAPI.applyServerSideTransactionAsync({
      addIndex: 0,
      add: formValues,
    });
  }

  refreshAllFormRows() {
    const rowValues = this.getTableFormArray().getRawValue();

    if (!rowValues.length) return;

    rowValues.forEach(({ id, patient_protocol_category }) =>
      this.updateValidationByCategory(id, patient_protocol_category)
    );
  }

  private refreshRowsView(rowIds: string[]): void {
    const rowNodes = rowIds.map((id) => this.gridAPI.getRowNode(id)).filter(Boolean) as IRowNode[];

    if (rowNodes.length) {
      this.gridAPI.redrawRows({ rowNodes });
    }
  }

  @checkFormRow
  updateSiteFields(
    rowId: string,
    siteId: string,
    siteMap: Map<string, listSitesQuery>,
    getSiteAddress: (site?: listSitesQuery) => string,
    getPrincipalInvestigatorName: (siteId: string) => string
  ) {
    const index = this.getTableRowIndex(rowId);

    const rowControls = this.getTableFormArray().at(index);

    const rowValues = rowControls.getRawValue();

    const siteData = siteMap.get(siteId);

    if (siteData) {
      const siteEntity = {
        site_name: siteData.name,
        site_address: getSiteAddress(siteData),
        country: Utils.getCountryName(siteData?.country ?? ''),
        investigator_name: getPrincipalInvestigatorName(siteData.id),
        contract_curr: siteData.currency ?? Currency.USD,
      };

      rowControls.patchValue(siteEntity);

      this.gridAPI.applyServerSideTransactionAsync(
        {
          update: [
            {
              id: rowId,
              ...rowControls.getRawValue(),
              ...siteEntity,
            },
          ],
        },
        () => this.refreshRowsView([rowValues.id])
      );
    } else {
      const emptySiteInfo = {
        site_name: '',
        site_address: '',
        country: '',
        investigator_name: '',
        contract_curr: Currency.USD,
      };

      rowControls.patchValue(emptySiteInfo);

      this.gridAPI.applyServerSideTransactionAsync(
        {
          update: [
            {
              id: rowId,
              ...rowControls.getRawValue(),
              ...emptySiteInfo,
            },
          ],
        },
        () => this.refreshRowsView([rowValues.id])
      );
    }
  }

  editRow(
    rowId: string,
    {
      protocolVersionOptions,
      patientGroups,
      siteOptions,
      categoryOptions,
    }: {
      protocolVersionOptions: listPatientProtocolVersionsQuery[];
      patientGroups: listPatientGroupsQuery[];
      siteOptions: SiteOption[];
      categoryOptions: Option[];
    }
  ) {
    const rowNode = this.gridAPI.getRowNode(rowId);

    if (!rowNode) {
      console.warn('Row not found');
      return;
    }

    const patient_protocol_version_name = protocolVersionOptions.find(
      (item) => item.name === rowNode.data.patient_protocol_version_name
    )?.id;

    const patient_group_id = patientGroups.find(
      (item) => item.name === rowNode.data.patient_group_id
    )?.id;

    const site_no = siteOptions.find((item) => item.title === rowNode.data.site_no)?.value;

    const patient_protocol_category = categoryOptions.find(
      (item) => item.label === rowNode.data.patient_protocol_category
    )?.value;

    const rowValues = {
      ...rowNode.data,
      mode: 'edit',
      patient_protocol_version_name: patient_protocol_version_name ?? null,
      patient_group_id: patient_group_id ?? null,
      site_no: site_no ?? null,
      patient_protocol_category: patient_protocol_category ?? null,
    };

    this.gridAPI.applyServerSideTransactionAsync(
      {
        update: [rowValues],
      },
      () => {
        const id = rowValues.id;
        this.insertFormEntity(id, rowValues);
        this.updateValueAndValidityPatientId(id, rowValues.patient_protocol_category);
        this.updateValueAndValidityPatientGroup(id, rowValues.patient_protocol_category);
        this.refreshRowsView([id]);
      }
    );
  }

  @checkFormRow
  removeTableRow(rowId: string) {
    this.gridAPI.applyServerSideTransactionAsync(
      {
        remove: [{ id: rowId }],
      },
      () => this.removeRow(rowId)
    );
  }

  @checkFormRow
  removeRow(rowId: string) {
    const index = this.getTableRowIndex(rowId);
    this.getTableFormArray().removeAt(index);
  }

  private addRequiredValidator(control: AbstractControl | null) {
    if (!control?.hasValidator(Validators.required)) {
      control?.addValidators(Validators.required);
      control?.updateValueAndValidity();
    }
  }

  @checkFormRow
  private updateValueAndValidityPatientId(rowId: string, category: string) {
    const index = this.getTableRowIndex(rowId);
    const tableControls = this.getTableFormArray() as FormArray;
    const patientIdControl = tableControls.get([index, 'external_patient_id']);

    const isPatientCategory = [
      PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
      PatientProtocolType.PATIENT_PROTOCOL_OTHER,
    ].includes(category as PatientProtocolType);

    if (isPatientCategory || category === '') {
      this.addRequiredValidator(patientIdControl);
      patientIdControl?.enable();
    } else {
      tableControls.at(index).patchValue({
        external_patient_id: null,
      });

      patientIdControl?.removeValidators(Validators.required);
      patientIdControl?.disable();

      this.gridAPI.applyServerSideTransactionAsync({
        update: [
          {
            id: rowId,
            ...tableControls.at(index).getRawValue(),
            external_patient_id: null,
          },
        ],
      });
    }
  }

  @checkFormRow
  private updateValueAndValidityPatientGroup(rowId: string, category: string) {
    const index = this.getTableRowIndex(rowId);

    const tableControls = this.getTableFormArray();

    const patientGroupControl = tableControls.get([index, 'patient_group_id']);

    const patientGroupShouldBeRequired =
      !this.visitCostsEnabled$.getValue() &&
      category === PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT;

    if (patientGroupShouldBeRequired) {
      this.addRequiredValidator(patientGroupControl);
      patientGroupControl?.enable();
    } else if (category === PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD) {
      patientGroupControl?.removeValidators(Validators.required);
      patientGroupControl?.disable();

      tableControls.at(index).patchValue({
        patient_group_id: null,
      });

      this.gridAPI.applyServerSideTransactionAsync({
        update: [
          {
            id: rowId,
            ...tableControls.at(index).getRawValue(),
            patient_group_id: null,
          },
        ],
      });
    } else {
      patientGroupControl?.removeValidators(Validators.required);
      patientGroupControl?.enable();
      patientGroupControl?.updateValueAndValidity();
    }
  }

  @checkFormRow
  updateValidationByCategory(rowId: string, category: string) {
    this.updateValueAndValidityPatientId(rowId, category);
    this.updateValueAndValidityPatientGroup(rowId, category);

    this.refreshRowsView([rowId]);
  }

  getTransactionRowErrors(formGroup: FormGroup) {
    return Object.keys(formGroup.controls)
      .map((fieldName) => {
        const field = formGroup.get(fieldName);

        return field?.errors?.required ? fieldName : null;
      })
      .filter(Boolean) as (keyof InvestigatorTransactionsDataRow)[];
  }

  markAllFieldsAsTouched() {
    const rowsToRefreshView: string[] = [];
    const tableRows = this.getTableFormArray();

    for (const row of tableRows.controls) {
      if (row instanceof FormGroup) {
        row.markAllAsTouched();
        rowsToRefreshView.push(row.value.id);
      }
    }

    this.refreshRowsView(rowsToRefreshView);
  }

  getTableErrorMessage(): string {
    const invalidFieldNames = Object.values(this.transactionForm.controls.table.controls).reduce<
      (keyof InvestigatorTransactionsDataRow)[]
    >((fields, formGroup) => {
      if (formGroup instanceof FormGroup) {
        fields = fields.concat(this.getTransactionRowErrors(formGroup));
      }

      return fields;
    }, []);

    return uniq(invalidFieldNames).reduce((message, invalidField, index, fields) => {
      const fieldColumnName = this.mapNameColumnByField.get(invalidField);

      if (!fieldColumnName) {
        return message;
      }

      if (fields.length === 1) {
        message = `${fieldColumnName} is required`;
      } else if (index === 0) {
        message = fieldColumnName;
      } else {
        message =
          index === fields.length - 1
            ? `${message}, ${fieldColumnName} are required`
            : `${message}, ${fieldColumnName}`;
      }

      return message;
    }, '');
  }
}
