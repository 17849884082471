import { Injectable } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import {
  TrialInsightsSiteCostKey,
  TrialInsightsTableModel,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsClinicalSiteCostTableService implements TrialInsightsTableModel {
  route = ROUTING_PATH.INVESTIGATOR.INDEX;
  subRoute = ROUTING_PATH.INVESTIGATOR.SITES.INDEX;

  createTable = (
    selectedKey: TrialInsightsSiteCostKey = TrialInsightsSiteCostKey.SPEND,
    rowData: TrialInsightsTableRowData[] = []
  ): TrialInsightsTableOptions => {
    return {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [
          { key: 'spend', value: 'Spend Per Site' },
          { key: 'lag', value: 'Lagging Data' },
        ],
      },
      header: {
        display: true,
        data: [
          { buttonKey: 'spend', leftValue: 'Site', rightValue: 'Total Spend' },
          { buttonKey: 'lag', leftValue: 'Site', rightValue: 'Last Data' },
        ],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'See All Sites',
      },
    };
  };
}
