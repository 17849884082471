import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';

import { QuarterCloseComponent } from './tabs/quarter-close/quarter-close.component';
import { ReconciliationComponent } from './tabs/reconciliation/reconciliation.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { PeriodCloseComponent } from './period-close.component';
import { QuarterCloseAdjustmentsComponent } from './tabs/quarter-close-adjustments/quarter-close-adjustments.component';
import { NotesHistoryComponent } from './tabs/notes-history/notes-history.component';
import { QuarterCloseChecklistComponent } from './tabs/quarter-close-checklist/quarter-close-checklist.component';
import { JournalEntriesComponent } from './tabs/journal -entries/journal-entries.component';

export const PERIOD_CLOSE_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.CLOSING.RECONCILIATION,
    component: ReconciliationComponent,
    data: {
      feature: FeatureFlag.CLOSING_RECONCILIATION,
    },
  },
  {
    path: ROUTING_PATH.CLOSING.JOURNAL_ENTRIES,
    component: JournalEntriesComponent,
    data: {
      feature: FeatureFlag.JOURNAL_ENTRIES,
    },
  },
];

export const buildPeriodCloseRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    {
      path: ROUTING_PATH.CLOSING.CHECKLIST,
      component: QuarterCloseChecklistComponent,
    },
    {
      path: ROUTING_PATH.CLOSING.QUARTER_CLOSE,
      component: QuarterCloseComponent,
    },
    {
      path: ROUTING_PATH.CLOSING.ADJUSTMENTS,
      component: QuarterCloseAdjustmentsComponent,
      canDeactivate: [CanDeactivateGuard],
    },
    {
      path: ROUTING_PATH.CLOSING.NOTES,
      component: NotesHistoryComponent,
    },
    ...buildRoutesByFeatureFlags(featureFlags, PERIOD_CLOSE_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.CLOSING.INDEX,
    component: PeriodCloseComponent,
    children: [...enabledRoutes, { path: '**', redirectTo: ROUTING_PATH.CLOSING.QUARTER_CLOSE }],
  };
};
