<div *ngIf="(isLoading$ | async) === false">
  <div class="flex mb-8 justify-end">
    <aux-export-excel-button
      [gridAPI]="gridAPI"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams"
      pendoTag="exchange-rates-excel-export-button"
    />
  </div>

  <ag-grid-angular
    auxGridDynamicHeight
    class="ag-theme-aux tabular-nums mb-8 w-full"
    id="currencyRatesGrid"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [overlayNoRowsTemplate]="(noRowsMessage$ | async)!"
    (gridReady)="onGridReady($event)"
  />
</div>

<div *ngIf="isLoading$ | async" class="border-8 h-32 w-32 m-auto mt-32 spinner"></div>
