import { Injectable } from '@angular/core';
import { MainQuery } from '@shared/store/main/main.query';
import {
  createInitialState,
  ExpenseDefaultsStore,
  ExpenseSourceItems,
} from './expense-defaults.store';
import { switchMap, tap } from 'rxjs/operators';
import {
  CategoryType,
  DefaultExpenseSource,
  EntityType,
  EventType,
  ExpenseSourceType,
  GqlService,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { batchPromises } from '@shared/utils';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExpenseDefaultsService {
  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private expenseDefaultsStore: ExpenseDefaultsStore,
    private overlayService: OverlayService
  ) {}

  mapExpenseCategory = new Map<CategoryType, string>([
    [CategoryType.CATEGORY_INVESTIGATOR, 'Investigator'],
    [CategoryType.CATEGORY_PASSTHROUGH, 'Pass-through'],
    [CategoryType.CATEGORY_SERVICE, 'Services'],
  ]);

  mapExpenseType = new Map<ExpenseSourceType, string>([
    [ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE, 'Vendor Estimate'],
    [ExpenseSourceType.EXPENSE_SOURCE_FORECAST, 'Forecast'],
    [ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED, 'Evidence Based'],
  ]);

  getExpenseDefaults() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.expenseDefaultsStore.setLoading(true);
        this.expenseDefaultsStore.update(createInitialState());
        return this.gqlService.listDefaultExpenseSources$().pipe(
          tap(({ data, success }) => {
            if (success && data) {
              const nData = data.map((x) => {
                return {
                  ...x,
                  primary: this.mapExpenseType.get(x?.sources?.[0] as ExpenseSourceType) || '',
                  secondary: this.mapExpenseType.get(x?.sources?.[1] as ExpenseSourceType) || '',
                  tertiary:
                    this.mapExpenseType.get(x?.sources?.[2] as ExpenseSourceType) ||
                    Utils.zeroHyphen,
                  cat: this.mapExpenseCategory.get(x.cost_category) || '',
                } as ExpenseSourceItems;
              });
              this.expenseDefaultsStore.update({
                items: nData || [],
              });
            }
            this.expenseDefaultsStore.setLoading(false);
          })
        );
      })
    );
  }

  async updateExpenseDefaults(expenseSources: DefaultExpenseSource[]) {
    this.expenseDefaultsStore.setLoading(true);
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.batchUpdateDefaultExpenseSources$(expenseSources)
    );
    if (errors.length) {
      this.overlayService.error(errors);
      return false;
    }
    if (success) {
      const uData = this.expenseDefaultsStore.getValue().items.map((x) => {
        const isChange = data?.find(
          (y) => y.organization_id === x.organization_id && y.cost_category === x.cost_category
        );
        if (isChange) {
          const [primary, secondary, tertiary] = isChange.sources;
          return {
            organization_id: isChange.organization_id,
            cost_category: isChange.cost_category,
            sources: isChange.sources,
            primary: this.mapExpenseType.get(primary),
            secondary: this.mapExpenseType.get(secondary),
            tertiary: this.mapExpenseType.get(tertiary) || '',
            cat: this.mapExpenseCategory.get(x.cost_category) || '',
          } as ExpenseSourceItems;
        }
        return x;
      });
      this.expenseDefaultsStore.update({ items: uData });

      const organizationIds = [...new Set(expenseSources.map((source) => source.organization_id))];
      const processEventPromises = organizationIds.map((organizationId) => {
        return firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.UPDATE_QUARTER_ACCRUALS,
            entity_type: EntityType.ORGANIZATION,
            entity_id: organizationId,
            payload: JSON.stringify({
              activity_types: expenseSources
                .filter((expenseSource) => expenseSource.organization_id === organizationId)
                .map((expenseSource) =>
                  expenseSource.cost_category.replace('CATEGORY', 'ACTIVITY')
                ),
            }),
          })
        );
      });

      await batchPromises(
        processEventPromises.filter((p) => p),
        (p) => p
      );
    }
    this.expenseDefaultsStore.setLoading(false);
    return true;
  }
}
