import { Component } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { ActivitySubType, ActivityType } from '@services/gql.service';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { FormsModule } from '@angular/forms';
import {
  BeActivitiesAttributesModalRowData,
  BeActivityTypes,
} from '../be-activities-attributes-modal/be-activities-attributes-modal.model';
import {
  BeInlineCategoryDropdownOption,
  BeInlineCategoryDropdownParams,
} from './be-inline-category-dropdown.model';
import { BeInlineCategoryOptionComponent } from './be-inline-category-item/be-inline-category-option.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  standalone: true,
  templateUrl: 'be-inline-category-dropdown.component.html',
  imports: [
    IconComponent,
    NgIf,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    NgForOf,
    TooltipDirective,
    NgStyle,
    InputComponent,
    FormsModule,
    NgClass,
    BeInlineCategoryOptionComponent,
  ],
  styleUrls: ['be-inline-category-dropdown.component.scss'],
})
export class BeInlineCategoryDropdownComponent implements ICellRendererAngularComp {
  categories: BeInlineCategoryDropdownOption[] = [];
  filteredCategories: BeInlineCategoryDropdownOption[] = [];
  filterText = '';

  dropdownOpened = false;
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -34,
    },
  ];

  categoryTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -11,
    },
  ];

  topLevelTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
    },
  ];

  params!: BeInlineCategoryDropdownParams;

  selectedCategory = '';

  disabled = false;

  msg = 'Auxilius Product restricts Category and Activity Name for Investigator and Discount.';

  disableChangingCategoriesTooltip = 'Cannot change cost category for existing activities';

  isGenerated = true;

  activityType: BeActivityTypes | '' = '';

  agInit(params: BeInlineCategoryDropdownParams) {
    this.params = params;

    params.categories$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.categories = value;
      this.onFilter();
      this.updateSelectedName();
    });

    if (this.params.node.data?.category) {
      this.selectedCategory =
        this.categories.find(
          (c) =>
            c.type === this.params.node.data?.activity_type &&
            c.id === this.params.node.data?.category
        )?.name || '';

      this.activityType = this.params.node.data?.activity_type;
      this.isGenerated = this.params.node.data?.isGenerated;

      switch (this.params.node.data?.activity_type) {
        case ActivityType.ACTIVITY_DISCOUNT:
          this.disabled = true;
          break;
        case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
        case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
        case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
          this.disabled = true;
          this.selectedCategory = `Investigator > ${this.selectedCategory}`;
          break;
        default:
          this.disabled = false;
          break;
      }
    }

    this.onFilter();
  }

  refresh() {
    return false;
  }

  onDropdown() {
    if (!this.disabled) {
      this.onFilter();
      this.dropdownOpened = true;
    }
  }

  onDropdownSelected(option: BeInlineCategoryDropdownOption) {
    const { id, type } = option;

    this.dropdownOpened = false;
    // clean state
    this.params.node.updateData(<BeActivitiesAttributesModalRowData>{
      ...this.params.node.data,
      category: '',
      activity_type: '',
    });

    // refill
    this.params.node.updateData(<BeActivitiesAttributesModalRowData>{
      ...this.params.node.data,
      category: id,
      activity_type: type,
    });
    this.params.onCategoryChange(this.params, id, type);
  }

  onDropdownAdd(newCategoryName: string, option: BeInlineCategoryDropdownOption): void {
    if (!option.disabled) {
      this.onDropdownSelected(this.params.onCategoryAdd(newCategoryName, option));
    } else {
      this.params.onCategoryAdd(newCategoryName, option);
    }
  }

  onDropdownEdit(newCategoryName: string, option: BeInlineCategoryDropdownOption): void {
    this.params.onCategoryRename(newCategoryName, option);
  }

  onFilter() {
    const usedCategories = this.params.usedCategories();
    const categories = this.categories.map((c) => {
      const bool = this.isGenerated ? false : c.type !== this.activityType;
      return {
        ...c,
        disabled: c.type === ActivityType.ACTIVITY_INVESTIGATOR || usedCategories[c.type] || bool,
        disabledTooltip: bool ? this.disableChangingCategoriesTooltip : '',
      };
    });

    if (this.filterText) {
      this.filteredCategories = categories.filter((c) => {
        return c.name.toLowerCase().includes(this.filterText.toLowerCase().trim());
      });
    } else {
      this.filteredCategories = categories;
    }
  }

  trackByFn(_: number, c: BeInlineCategoryDropdownOption) {
    return c.id;
  }

  private updateSelectedName() {
    this.selectedCategory =
      this.categories.find(
        (c) =>
          c.type === this.params.node.data?.activity_type &&
          c.id === this.params.node.data?.category
      )?.name || '';
  }
}
