<aux-workflow-panel
  *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
  [className]="'mb-4'"
  [workflowName]="workflowName"
  [processing]="(isCloseMonthsProcessing$ | async)!"
  [hasUserPermissions]="userHasLockPatientDataPermission"
/>

<aux-investigator-transactions-header
  [isContractSelected$]="isContractSelected$"
  [editMode$]="editMode$"
  [gridAPI]="gridAPI"
  (openImportPatientDataModal)="openImportPatientDataModal()"
  [totalCost$]="totalCost$"
  [lastSourceRefreshDate$]="lastSourceRefreshDate$"
  [dataStreamInputs]="datasource.currentServerInput"
/>

<div class="flex">
  <aux-investigator-transactions-filters
    class="flex-1"
    [filterLoading$]="filterLoading$"
    [showSourceColumn$]="showSourceColumn$"
    [gridLoading$]="datasource.loading$"
    [editMode$]="editMode$"
    [gridFiltersFormGroup]="gridFiltersFormGroup"
  />

  <form
    class="flex items-center space-x-4"
    [formGroup]="transactionForm"
    *ngIf="{
      editMode: editMode$ | async,
      addTransactionDisabled: addTransactionDisabled$ | async,
      gridLoading: datasource.loading$ | async
    } as obj"
  >
    <aux-button
      [onClick]="cancelChanges"
      variant="secondary"
      [disabled]="submitInProgress()"
      icon="X"
      label="Cancel"
      *ngIf="obj.editMode"
    />
    <aux-button
      variant="success"
      label="Save"
      icon="Check"
      [onClick]="saveTransactions"
      [disabled]="submitInProgress()"
      *ngIf="obj.editMode"
    />
    <div class="h-7 w-px bg-aux-gray-dark" *ngIf="obj.editMode"></div>
    <aux-button
      [onClick]="addTransaction"
      [disabled]="
        obj.addTransactionDisabled ||
        obj.gridLoading ||
        isPatientsFinalized() ||
        !addTransactionPermission() ||
        obj.editMode === 'edit'
      "
      variant="secondary"
      icon="CirclePlus"
      label="Transaction"
      [auxTooltip]="addTransactionTooltip()"
    />
  </form>
</div>

<aux-pagination-grid
  [gridOptions]="$any(gridOptions$ | async)"
  (gridReady)="onGridReady($event)"
  [dataSource]="datasource"
  [filterForm]="gridFiltersFormGroup"
  [serverSideFilters]="serverSideFilters"
  [loading]="submitInProgress()"
  [filterValues$]="filterValues$"
  [sortModel$]="sortModel$"
  [idTable]="'transactionTable'"
  [hidePinnedRow]="true"
  [getRowId]="getRowId"
  [getContext]="getGridContext"
  (paginationChange)="paginationChange()"
/>

<div class="flex flex-row items-end justify-end" *ngIf="showBottomTotals$ | async">
  <p>Showing 250 of {{ totalRecords$ | async }} records</p>
  <aux-button
    class="pr-8 pl-2"
    variant="hyperlink"
    [onClick]="this.onExportInvestigatorTransactions.bind(this)"
    label="Export full dataset"
    [loading]="isBtnLoading('export') | async"
    [spinnerSize]="5"
  />
</div>
