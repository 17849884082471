import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf, SlicePipe } from '@angular/common';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { InvoiceService } from '../state/invoice.service';

@Component({
  selector: 'aux-invoice-filters',
  templateUrl: './invoice-filters.component.html',
  styleUrls: ['./invoice-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    NgSelectModule,
    TooltipDirective,
    NgIf,
    NgForOf,
    SlicePipe,
  ],
})
export class InvoiceFiltersComponent {
  @Input() form!: FormGroup;

  optionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -10,
      offsetY: 8,
    },
  ];

  constructor(public invoiceService: InvoiceService) {}

  resetAllFilters() {
    Object.values(this.form.controls).forEach((control) => {
      control.reset();
    });
  }
}
