import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TabGroupConfig } from '@components/tab-group/route-tab-group.component';
import { map } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-vendor-payments-page',
  template: `
    <div>
      <div *ngIf="showTabs$ | async" class="mb-8">
        <aux-route-tab-group [tabs]="tabs" />
      </div>

      <router-outlet />
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorPaymentsPageComponent {
  constructor(
    private appService: AppService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  tabs: TabGroupConfig[] = [
    {
      label: 'Invoices',
      route: ROUTING_PATH.VENDOR_PAYMENTS.INVOICES,
      show: this.launchDarklyService.select$((flags) => flags.tab_invoices),
    },
    {
      label: 'Purchase Orders',
      route: ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS,
      show: this.launchDarklyService.select$((flags) => flags.tab_purchase_orders),
    },
    {
      label: 'Vendors',
      route: ROUTING_PATH.VENDOR_PAYMENTS.VENDORS,
      show: this.launchDarklyService.select$((flags) => flags.tab_vendors),
    },
    {
      label: 'Payment Milestones',
      route: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES,
      show: this.launchDarklyService.select$((flags) => flags.tab_payment_milestones),
    },
    {
      label: 'Payment Schedule',
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_payment_schedule),
      route: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE,
    },
  ];

  showTabs$ = this.appService.paramMap$.pipe(map((p) => !p?.has('id')));
}
