import { Injectable } from '@angular/core';
import { forkJoin, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';
import { GqlService, SortOrder } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { MainQuery } from '@shared/store/main/main.query';
import {
  TrialInsightsPatientCostInputType,
  TrialInsightsPatientCostResponseType,
  TrialInsightsResponseGeneric,
  TrialInsightsState,
} from '../../models/trial-insights-store.model';
import { TrialInsightsStore } from '../../store/trial-insights.store';

type state = Partial<TrialInsightsState['patientCost']>;
type requestInput = TrialInsightsPatientCostInputType;
type requestType = 'initial' | 'remaining';
type loadingState = requestType | 'completed';
type responseType = TrialInsightsPatientCostResponseType;
type response = TrialInsightsResponseGeneric<responseType>[];

@Injectable()
export class TrialInsightsClinicalPatientCostStoreService {
  constructor(
    private store: TrialInsightsStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {}

  getPatientCostSummary$() {
    return this.mainQuery
      .select('trialKey')
      .pipe(
        this.updateLoadingState('initial'),
        this.request('initial'),
        this.processResponse(),
        this.updateLoadingState('remaining'),
        this.request('remaining'),
        this.processResponse(),
        this.updateLoadingState('completed')
      );
  }

  requestInput(type: requestType): requestInput[] {
    const request = [
      {
        site_cost_order: SortOrder.DESC,
        patient_group_cost_order: SortOrder.DESC,
      },
    ];

    if (type === 'remaining') {
      request[0].site_cost_order = SortOrder.ASC;
      request[0].patient_group_cost_order = SortOrder.ASC;
    }

    return request;
  }

  loadingState(type: loadingState): state {
    const loadingState: state = {};

    if (type === 'initial') {
      loadingState.isLoading = true;
      loadingState.isLoadingRemaining = true;
      loadingState.data = null;
    } else if (type === 'remaining') {
      loadingState.isLoading = false;
    } else {
      loadingState.isLoadingRemaining = false;
    }

    return loadingState;
  }

  updateLoadingState(type: loadingState) {
    const updates = this.loadingState(type);

    const processFn = (_: unknown, subscriber: Subscriber<unknown>) => {
      this.store.update((state) => {
        return {
          ...state,
          patientCost: {
            ...state.patientCost,
            ...updates,
          },
        };
      });

      subscriber.next(updates);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  request(type: requestType) {
    const processFn = (_: unknown, subscriber: Subscriber<response>) => {
      const inputs = this.requestInput(type);
      const input$ = inputs.map((input) => this.gqlService.getPatientCostSummary$(input));

      forkJoin(input$)
        .pipe(take(1))
        .subscribe((results) => {
          subscriber.next(results);
        });
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  processResponse() {
    const processFn = (sourceValue: response, subscriber: Subscriber<unknown>) => {
      if (!sourceValue.length) {
        return subscriber.next(sourceValue);
      }

      const stateUpdates = sourceValue.reduce((updates, update) => {
        if (!update.data) {
          return updates;
        }

        updates.push(update.data);
        return updates;
      }, [] as responseType[]);

      this.store.update((state) => {
        const currentData = state.patientCost.data || [];

        return {
          ...state,
          patientCost: {
            ...state.patientCost,
            data: [...currentData, ...stateUpdates],
          },
        };
      });

      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
