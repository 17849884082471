import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-title',
  template: `
    <div class="flex items-center justify-between">
      <div class="font-bold text-xl mb-4">
        {{ title() }}
      </div>
      <div></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailTitleComponent {
  invoice = input.required<InvoiceModel>();
  title = computed(
    () => `${this.invoice().organization.name} - Invoice ${this.invoice().invoice_no}`
  );
}
