import { Component, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { InvestigatorTransactionSupportingSectionComponent } from '../investigator-transaction-supporting-section/investigator-transaction-supporting-section.component';
import { ButtonComponent } from '@components/button/button.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  templateUrl: './remove-transaction-modal.component.html',
  standalone: true,
  imports: [InvestigatorTransactionSupportingSectionComponent, ButtonComponent],
})
export class RemoveTransactionModalComponent {
  @ViewChild('supportingSection')
  supportingSection?: InvestigatorTransactionSupportingSectionComponent;

  noteFormControl = new FormControl('', [Validators.required]);

  constructor(public ref: CustomOverlayRef<unknown, { createMode: boolean }>) {}

  deleteTransaction = async () => {
    if (this.noteFormControl.invalid && !this.ref.data?.createMode) {
      return;
    }

    if (this.ref.data?.createMode) {
      this.ref.close({ remove: true });
    }

    const { note, documents } = (await this.supportingSection?.saveSupportingSection()) || {};

    this.ref.close({ note, documents, remove: true });
  };
}
