import { CellClickedEvent, ColDef, RowNode, ValueFormatterParams } from '@ag-grid-community/core';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { AgStatusComponent } from '@shared/components/ag-status/ag-status.component';
import dayjs from 'dayjs';
import { Utils } from '@services/utils';
import { OpsAdminUtils } from '../../utils/ops-admin.utils';
import { StoredDocumentProcessStatus } from '@services/gql.service';

export const getConvertedFilesGridOptions = (
  downloadFile: (downloadLink: string, fileName: string) => Promise<void>
): ColDef[] => {
  return [
    {
      headerName: 'Document Type',
      headerClass: 'ag-header-align-center',
      field: 'document_type',
      minWidth: 125,
    },
    {
      headerName: 'Site',
      headerClass: 'ag-header-align-center',
      field: 'site',
      minWidth: 125,
      valueFormatter: Utils.dashFormatter,
      cellClass: (value) => {
        if (value.node.data.site) {
          return ['ag-cell-align-left'];
        }
        return ['ag-cell-align-right'];
      },
    },
    {
      headerName: 'Original File',
      headerClass: 'ag-header-align-center',
      field: 'original_file',
      minWidth: 125,
      tooltipField: 'original_file',
      cellClass: 'grid-cell underline text-left !text-aux-blue cursor-pointer underline-offset-4',
      cellStyle: {
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
        display: 'block',
      },
      onCellClicked: (event: CellClickedEvent) =>
        downloadFile(event.data.original_file_download_link, event.data.original_file),
    },
    {
      headerName: 'Extraction Status',
      headerClass: 'ag-header-align-center',
      field: 'status',
      minWidth: 125,
      width: 125,
      cellRenderer: AgStatusComponent,
      cellRendererParams: (params: { data: { status: StoredDocumentProcessStatus } }) => ({
        status: OpsAdminUtils.getAgStatusParameters(params.data.status),
      }),
    },
    {
      headerName: 'Converted File',
      headerClass: 'ag-header-align-center',
      field: 'converted_file',
      minWidth: 125,
      tooltipField: 'converted_file',
      cellClass: 'grid-cell underline text-left !text-aux-blue cursor-pointer underline-offset-4',
      cellStyle: {
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
        display: 'block',
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event.data.status === StoredDocumentProcessStatus.STATUS_PROCESSED) {
          downloadFile(event.data.converted_file_download_link, event.data.converted_file).then(
            (r) => r
          );
        }
      },
    },
    {
      headerName: '',
      field: 'actions',
      suppressMenu: true,
      cellRendererSelector: (params: ValueFormatterParams) => ({
        component: AgActionsComponent,
        params,
      }),
      cellRendererParams: {
        hideEditButton: true,
        downloadClickFN: async ({ rowNode }: { rowNode: RowNode }) =>
          downloadFile(rowNode.data.converted_file_download_link, rowNode.data.converted_file),
      },
      cellClass: (params) => {
        if (params.data.status === StoredDocumentProcessStatus.STATUS_PROCESSED) {
          return ['cell-justify-center'];
        }
        return ['[&>*]:!hidden pointer-events-none'];
      },
      maxWidth: 50,
    },
    {
      headerName: 'Date Converted',
      headerClass: 'ag-header-align-center',
      field: 'date_converted',
      minWidth: 125,
      width: 125,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? dayjs(params.value).format('DD-MMM-YYYY') : '';
      },
      getQuickFilterText: (params: ValueFormatterParams) => {
        return params.value ? dayjs(params.value).format('DD-MMM-YYYY') : '';
      },
      cellClass: ['ag-cell-align-right'],
    },
  ] as ColDef[];
};
