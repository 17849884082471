import { computed, inject } from '@angular/core';
import { MessagesConstants } from '@constants/messages.constants';
import { PermissionType, WorkflowStep } from '@services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';

export function getSiteWorkflow() {
  const hasUpdateSitePermission = inject(AuthService).$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPDATE_SITES],
  });

  const isPatientsFinalized = inject(WorkflowQuery).getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  const actionButtonsTooltip = computed(() => {
    if (!hasUpdateSitePermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : '';
  });

  return {
    hasUpdateSitePermission,
    isPatientsFinalized,
    actionButtonsTooltip,
  };
}
