import { Injectable } from '@angular/core';
import { MainQuery } from '@shared/store/main/main.query';
import {
  GqlService,
  listOrganizationWorkPerformedQuery,
  listPaymentMilestonesQuery,
} from '@services/gql.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { switchMap, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Utils } from '@services/utils';

import { PaymentScheduleStore } from './payment-schedule.store';
import dayjs from 'dayjs';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { TimelineService } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';

@Injectable({ providedIn: 'root' })
export class PaymentScheduleService {
  constructor(
    private paymentScheduleStore: PaymentScheduleStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private organizationQuery: OrganizationQuery,
    private timelineQuery: TimelineQuery,
    private timelineService: TimelineService
  ) {}

  getData() {
    return this.organizationQuery.selectActive().pipe(
      switchMap((org) => {
        this.paymentScheduleStore.setLoading(true);
        return combineLatest([
          this.gqlService.listPaymentMilestones$(),
          this.gqlService.listOrganizationWorkPerformed$(org?.id),
          this.organizationQuery.selectActive(),
          this.timelineService.getTimelineItems(),
        ]);
      }),
      tap(([paymentMilestoneResp, organizationWorkPerformedResp, organization]) => {
        const obj: Record<string, unknown> = {};
        const allErrors: string[] = [
          ...paymentMilestoneResp.errors,
          ...organizationWorkPerformedResp.errors,
        ];

        if (allErrors && allErrors.length) {
          console.error(allErrors);
        }

        const pm_data =
          !paymentMilestoneResp.errors.length && paymentMilestoneResp.data?.length
            ? paymentMilestoneResp.data
            : [];

        const orgId = organization?.id;
        const selected_org_pm_data = orgId
          ? pm_data.filter((itm) => itm.organization.id === orgId)
          : pm_data;
        const wp_data =
          !organizationWorkPerformedResp.errors.length && organizationWorkPerformedResp.data?.length
            ? organizationWorkPerformedResp.data
            : [];

        const pm_after_today = selected_org_pm_data.filter(
          (itm) => Utils.dateParse(itm.target_date).getTime() >= new Date().getTime()
        );
        obj.PAYMENT_SCHEDULE_NEXT_PAYMENT_MILESTONE = pm_after_today?.length
          ? pm_after_today.reduce((a, b) => {
              return Date.parse(a.target_date) < Date.parse(b.target_date) ? a : b;
            })
          : { amount: 0, target_date: '' };

        const pm_amount_pre_today = selected_org_pm_data
          .filter((itm) => Date.parse(itm.target_date) <= new Date().getTime())
          .reduce((a, b) => {
            return a + b.amount;
          }, 0);
        const wp_amount_before_today = wp_data
          .filter((itm) => Date.parse(itm.period_date) <= new Date().getTime())
          .reduce((a, b) => {
            return a + b.amount;
          }, 0);
        const prepaid_bal_in_escrow = pm_amount_pre_today - wp_amount_before_today;
        obj.PAYMENT_SCHEDULE_PREPAID_BALANCE_IN_ESCROW = {
          amount: prepaid_bal_in_escrow,
        };

        obj.PAYMENT_SCHEDULE_MILESTONES = selected_org_pm_data
          .sort((a, b) => Date.parse(a.target_date) - Date.parse(b.target_date))
          .map((itm) => {
            return {
              date: itm.target_date,
              name: itm.name,
              organization_name: itm.organization.name,
              amount: itm.amount,
            };
          });

        obj.PAYMENT_SCHEDULE_CUMULATIVE_PAYMENTS_VS_CUMULATIVE_WP = this.calculatePaymentsVsWP(
          selected_org_pm_data,
          wp_data
        );

        obj.PAYMENT_SCHEDULE_CASH_REQUIREMENTS =
          this.calculateCashRequirements(selected_org_pm_data);
        this.paymentScheduleStore.update(obj);
        this.paymentScheduleStore.setLoading(false);
      })
    );
  }

  getQuarterFromDate(x: Date) {
    return Math.floor((x.getUTCMonth() + 3) / 3);
  }

  getMonthDifference(startDate: Date, endDate: Date) {
    return (
      endDate.getUTCMonth() -
      startDate.getUTCMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  calculateCashRequirements(payment_milestone_list: listPaymentMilestonesQuery[]) {
    const cr_data: { pm_total: number; expense_months_back: string }[] = [
      {
        pm_total: 0,
        expense_months_back: '3M',
      },
      {
        pm_total: 0,
        expense_months_back: '6M',
      },
      {
        pm_total: 0,
        expense_months_back: '1Y',
      },
    ];

    // Fix offset time
    const now = new Date();

    payment_milestone_list
      .filter((pm) => Utils.dateParse(pm.target_date) >= now)
      .forEach((pm) => {
        const date = new Date(pm.target_date);
        const mo_between = this.getMonthDifference(date, now);
        if (mo_between > -3 && mo_between <= 0) {
          cr_data[0].pm_total += pm.amount;
        }
        if (mo_between > -6 && mo_between <= 0) {
          cr_data[1].pm_total += pm.amount;
        }
        if (mo_between > -12 && mo_between <= 0) {
          cr_data[2].pm_total += pm.amount;
        }
      });

    return cr_data;
  }

  calculatePaymentsVsWP(
    payment_milestone_list: listPaymentMilestonesQuery[],
    work_performed_data: listOrganizationWorkPerformedQuery[]
  ) {
    const pm_data: { [key: string]: number } = {};
    const wp_data: { [key: string]: number } = {};
    payment_milestone_list.forEach((pm) => {
      const date = new Date(pm.target_date);
      const date_str = `Q${this.getQuarterFromDate(date)} ${date.getUTCFullYear()}` as string;
      if (!Object.prototype.hasOwnProperty.call(pm_data, date_str)) {
        pm_data[date_str] = 0;
      }
      pm_data[date_str] += pm.amount;
    });

    work_performed_data.forEach((wp) => {
      const date = new Date(wp.period_date);
      const date_str = `Q${this.getQuarterFromDate(date)} ${date.getUTCFullYear()}` as string;
      if (!Object.prototype.hasOwnProperty.call(wp_data, date_str)) {
        wp_data[date_str] = 0;
      }
      wp_data[date_str] += wp.amount;
    });

    const wp_vs_pm: {
      [quarter: string]: {
        payment: number;
        wp: number;
        delta: number;
        payment_abs: number;
        wp_abs: number;
      };
    } = {};

    Object.keys(wp_data).forEach((wp_quarter) => {
      const pm_amount = pm_data[wp_quarter] || 0;
      const wp_amount = wp_data[wp_quarter] || 0;
      delete pm_data[wp_quarter];
      wp_vs_pm[wp_quarter] = {
        payment: pm_amount,
        payment_abs: Math.abs(pm_amount),
        wp: wp_amount,
        wp_abs: Math.abs(wp_amount),
        delta: pm_amount - wp_amount,
      };
    });

    Object.keys(pm_data).forEach((pm_quarter) => {
      const pm_amount = pm_data[pm_quarter] || 0;
      const wp_amount = 0;
      wp_vs_pm[pm_quarter] = {
        payment: pm_amount,
        payment_abs: Math.abs(pm_amount),
        wp: wp_amount,
        wp_abs: Math.abs(wp_amount),
        delta: pm_amount - wp_amount,
      };
    });

    return Object.keys(wp_vs_pm)
      .sort((a, b) => {
        const year1 = parseInt(a.split(' ')?.[1], 10) || -1;
        const year2 = parseInt(b.split(' ')?.[1], 10) || -1;
        if (year1 !== year2) {
          return year1 > year2 ? 1 : -1;
        }
        return a > b ? 1 : -1;
      })
      .filter((key) => this.filterQuarters(key))
      .reduce(
        (obj, key) => {
          obj[key] = wp_vs_pm[key];
          return obj;
        },
        {} as Record<string, object>
      );
  }

  filterQuarters(quarter: string) {
    const [eachQuarter, eachYear] = quarter.split(' ');
    const auxStartDate = this.mainQuery.getAuxiliusStartDate();
    const {
      trialTimelineStartQuarter,
      trialTimelineStartYear,
      trialTimelineEndQuarter,
      trialTimelineEndYear,
    } = this.getTrialTimelineMilestonesQuarters();
    if (auxStartDate) {
      const auxStartQuarter = dayjs(auxStartDate).quarter();
      const auxStartYear = auxStartDate.split('-')[0];
      return !(
        +auxStartYear > +eachYear ||
        (+auxStartYear === +eachYear && Number(eachQuarter.charAt(1)) < auxStartQuarter) ||
        (trialTimelineEndQuarter &&
          trialTimelineEndYear &&
          (trialTimelineEndYear < +eachYear ||
            (trialTimelineEndYear === +eachYear &&
              Number(eachQuarter.charAt(1)) > trialTimelineEndQuarter)))
      );
    } else {
      return !(
        (trialTimelineStartQuarter &&
          trialTimelineStartYear &&
          (trialTimelineStartYear > +eachYear ||
            (trialTimelineStartYear === +eachYear &&
              Number(eachQuarter.charAt(1)) < trialTimelineStartQuarter))) ||
        (trialTimelineEndQuarter &&
          trialTimelineEndYear &&
          (trialTimelineEndYear < +eachYear ||
            (trialTimelineEndYear === +eachYear &&
              Number(eachQuarter.charAt(1)) > trialTimelineEndQuarter)))
      );
    }
  }

  getTrialTimelineMilestonesQuarters() {
    const timelineMilestones = this.timelineQuery.getValue().items;
    const trialTimelineStartDate = timelineMilestones[0].contract_start_date;
    let trialTimelineEndDate = timelineMilestones[timelineMilestones.length - 1].contract_end_date;
    timelineMilestones.forEach((timeline) => {
      if (dayjs(timeline.contract_end_date).isAfter(trialTimelineEndDate)) {
        trialTimelineEndDate = timeline.contract_end_date;
      }
    });
    const trialTimelineStartQuarter = dayjs(trialTimelineStartDate).quarter();
    const trialTimelineStartYear = dayjs(trialTimelineStartDate).year();
    const trialTimelineEndQuarter = dayjs(trialTimelineEndDate).quarter();
    const trialTimelineEndYear = dayjs(trialTimelineEndDate).year();
    return {
      trialTimelineStartQuarter,
      trialTimelineStartYear,
      trialTimelineEndQuarter,
      trialTimelineEndYear,
    };
  }
}
