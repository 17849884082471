<div class="mb-4 w-[1200px] be-inline-container">
  <ag-grid-angular
    class="ag-theme-aux tabular-nums be-inline-table h-[300px]"
    [rowData]="filteredRowData"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [getRowId]="getRowId"
    (cellEditingStopped)="onCellEdit($event)"
    (cellValueChanged)="onCellChanged($event)"
  />
</div>
