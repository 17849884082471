import { Injectable } from '@angular/core';
import { User } from '@services/gql.service';
import { Utils } from '@services/utils';
import { AuthQuery } from '@shared/store/auth/auth.query';

@Injectable({
  providedIn: 'root',
})
export class ChangeOrderSharedService {
  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  constructor(private authQuery: AuthQuery) {}

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }
}
