import { TimeagoFormatter } from 'ngx-timeago';
import dayjs from 'dayjs';

export class DaysAgoFormatter implements TimeagoFormatter {
  format(then: number): string {
    const today = dayjs();
    const parsedDate = dayjs(then);

    const isToday = today.isSame(parsedDate, 'day');

    if (isToday) {
      return 'Today';
    }

    const difference = today.diff(parsedDate, 'day');
    const plural = Math.floor(difference) > 1 ? 's' : '';

    return difference > 0 ? `${today.diff(parsedDate, 'day')} day${plural} ago` : '';
  }
}
