<ng-container
  *ngIf="{
    changeOrder: changeOrder$ | async,
    loading: loading$ | async,
    organization: organization$ | async,
    isPendingReview: isPendingReview$ | async,
    isPendingApproval: isPendingApproval$ | async,
    isApproved: isApproved$ | async,
    isDeclined: isDeclined$ | async,
    uploadLoading: uploadLoading$ | async,
    isApproveDisabled:
      (isApproveButtonDisable$ | async) ||
      (isApproveButtonDisableManually$ | async) ||
      hasMissingActivitiesWithActuals,
    isUploadCODisabled: (isUploadCOBudgetButtonDisable$ | async),
    hasUploadCOPermission: (hasUploadCOPermission$ | async)
  } as obj"
>
  <ng-container *ngIf="obj.loading || obj.uploadLoading">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="!obj.loading && !obj.uploadLoading">
    <ng-container *ngIf="obj.changeOrder">
      <div class="flex items-center text-aux-blue-light-200 mb-6" [routerLink]="[changeOrdersLink]">
        <aux-icon name="ArrowRight" class="mr-2 transform rotate-180 cursor-pointer" />
        <div class="cursor-pointer">Back to Change Orders</div>
      </div>

      <div class="flex items-center justify-between mb-4">
        <div class="text-xl font-bold">
          {{ obj.organization?.name }} - Change Order {{ obj.changeOrder.change_order_no }}
        </div>
        <div>
          <button
            *ngIf="obj.isPendingReview"
            class="btn btn--primary"
            (click)="onEditChangeOrder()"
          >
            <aux-icon name="Pencil" [size]="20" />
            <span>Edit</span>
          </button>
        </div>
      </div>

      <div
        class="border px-4"
        [ngClass]="{
          'bg-aux-blue-light-50 text-aux-black': obj.isPendingReview || obj.isPendingApproval,
          'bg-aux-green-dark': obj.isApproved,
          'bg-aux-red-dark pb-4': obj.isDeclined,
          'text-white': obj.isApproved || obj.isDeclined
        }"
      >
        <div class="flex items-center justify-between h-16">
          <div>
            <span>Status:</span>
            <span class="font-bold ml-2">{{ status$ | async }}</span>
            <span *ngIf="approvedBy$ | async as approvedBy" class="font-bold">
              {{ approvedBy }}
            </span>
          </div>
          <div class="space-x-2 flex items-center">
            <button
              *ngIf="obj.isPendingReview"
              class="h-9 btn btn--negative"
              [disabled]="isChangeOrdersWorkflowLocked()"
              [auxTooltip]="deleteBtnTooltip(isChangeOrdersWorkflowLocked())"
              (click)="onDelete()"
            >
              <span *ngIf="isBtnLoading('delete') | async" class="spinner w-5 h-5"></span>

              <aux-icon
                name="Trash"
                [size]="16"
                *ngIf="(isBtnLoading('delete') | async) === false"
              />
              <span class="ml-2">Delete</span>
            </button>

            <ng-container *ngIf="obj.isPendingApproval">
              <button
                class="h-9 btn-nr text-sm btn--hyperlink"
                *ngIf="showApprovalReminderLink$ | async"
                (click)="onSendingPendingApprovalReminder()"
              >
                <span class="ml-2">Resend Reminder Email</span>
              </button>
            </ng-container>

            <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadCO()">
              <span *ngIf="isBtnLoading('download') | async" class="spinner w-5 h-5"></span>
              <aux-icon
                name="Download"
                *ngIf="(isBtnLoading('download') | async) === false"
                [size]="16"
              />
              <span class="ml-2">Download CO</span>
            </button>
            <ng-container *ngIf="obj.isPendingReview">
              <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadLREBudget()">
                <span *ngIf="isBtnLoading('lre-download') | async" class="spinner w-5 h-5"></span>
                <aux-icon
                  name="Download"
                  *ngIf="(isBtnLoading('lre-download') | async) === false"
                  [size]="16"
                />
                <span class="ml-2">Download LRE Budget</span>
              </button>

              <button
                class="h-9 btn btn--secondary bg-white"
                [disabled]="
                  obj.isApproveDisabled ||
                  !$any(userHasApprovePermission$ | async) ||
                  isChangeOrdersWorkflowLocked()
                "
                (click)="onApprove(true)"
                [auxTooltip]="
                  approveBtnTooltip(
                    $any(userHasApprovePermission$ | async),
                    isChangeOrdersWorkflowLocked()
                  )
                "
              >
                <span *ngIf="isBtnLoading('approve') | async" class="spinner w-5 h-5"></span>
                <aux-icon
                  name="Check"
                  *ngIf="(isBtnLoading('approve') | async) === false"
                  [size]="16"
                />
                <span class="ml-2">Force Approve</span>
              </button>

              <div
                [auxTooltip]="
                  hasMissingActivitiesWithActuals
                    ? 'Template uploaded is missing activities with actuals. Re-upload with missing activities to Send for Approval.'
                    : obj.isApproveDisabled
                      ? 'Change Order Budget must be uploaded to Send for Approval'
                      : ''
                "
              >
                <button
                  class="h-9 btn btn--success"
                  [disabled]="obj.isApproveDisabled"
                  (click)="onSendApproval()"
                >
                  <span *ngIf="isBtnLoading('approval') | async" class="spinner w-5 h-5"></span>
                  <aux-icon
                    name="Check"
                    *ngIf="(isBtnLoading('approval') | async) === false"
                    [size]="16"
                  />
                  <span class="ml-2">Send for Approval</span>
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="obj.isPendingApproval">
              <button class="h-9 btn btn--secondary bg-white" (click)="onReturnToAdminReview()">
                <span *ngIf="isBtnLoading('admin-review') | async" class="spinner w-5 h-5"></span>
                <aux-icon
                  *ngIf="(isBtnLoading('admin-review') | async) === false"
                  name="Pencil"
                  [size]="16"
                />
                <span class="ml-2">Return to Admin Review</span>
              </button>

              <button
                class="h-9 btn btn--success"
                [disabled]="
                  obj.isApproveDisabled ||
                  !$any(userHasApprovePermission$ | async) ||
                  isChangeOrdersWorkflowLocked()
                "
                (click)="onApprove()"
                [auxTooltip]="
                  approveBtnTooltip(
                    $any(userHasApprovePermission$ | async),
                    isChangeOrdersWorkflowLocked()
                  )
                "
              >
                <span *ngIf="isBtnLoading('approve') | async" class="spinner w-5 h-5"></span>
                <aux-icon
                  name="Check"
                  *ngIf="(isBtnLoading('approve') | async) === false"
                  [size]="16"
                />
                <span class="ml-2">Approve</span>
              </button>

              <button
                class="h-9 btn btn--negative"
                [disabled]="!$any(userHasApprovePermission$ | async)"
                [auxTooltip]="
                  !$any(userHasApprovePermission$ | async)
                    ? messagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                    : ''
                "
                (click)="onDecline()"
              >
                <span *ngIf="isBtnLoading('decline') | async" class="spinner w-5 h-5"></span>
                <aux-icon
                  name="X"
                  *ngIf="(isBtnLoading('decline') | async) === false"
                  [size]="16"
                />

                <span class="ml-2">Decline</span>
              </button>

              <button
                class="h-9 btn btn--negative"
                [disabled]="isChangeOrdersWorkflowLocked()"
                [auxTooltip]="deleteBtnTooltip(isChangeOrdersWorkflowLocked())"
                (click)="onDelete()"
              >
                <span *ngIf="isBtnLoading('delete') | async" class="spinner w-5 h-5"></span>
                <aux-icon
                  name="Trash"
                  [size]="16"
                  *ngIf="(isBtnLoading('delete') | async) === false"
                />
                <span class="ml-2">Delete</span>
              </button>
            </ng-container>
          </div>
        </div>

        <div *ngIf="obj.isDeclined" class="bg-aux-gray-light mt-4 p-2 text-aux-black">
          <div class="font-bold">Reason for Decline</div>
          <div>{{ obj.changeOrder.decline_reason }}</div>
        </div>
      </div>

      <hr class="mb-3 mt-6" />

      <div class="grid grid-cols-2 gap-5 tabular-nums">
        <div>
          <div class="grid grid-cols-2">
            <div>
              <div class="font-bold mb-1">Vendor</div>
              <div>{{ obj.organization?.name || zeroHyphen }}</div>
            </div>
            <div>
              <div class="font-bold mb-1">Change Order #</div>
              <div>{{ obj.changeOrder.change_order_no || zeroHyphen }}</div>
            </div>
          </div>
          <hr class="my-4" />
          <div class="grid grid-cols-2">
            <div>
              <div class="font-bold mb-1">Effective Date</div>
              <div>{{ obj.changeOrder.effective_date || zeroHyphen }}</div>
            </div>
            <div>
              <div class="font-bold mb-1">Date Received</div>
              <div>{{ obj.changeOrder.date_received || zeroHyphen }}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="font-bold mb-1">Change Order Notes</div>
          <div class="respect_newline">
            {{ obj.changeOrder.notes || zeroHyphen }}
          </div>
        </div>
      </div>
      <hr class="mt-3" />

      <div class="my-6 grid grid-cols-3 gap-4">
        <div class="border flex text-aux-blue-dark items-center h-full flex-auto">
          <div class="bg-aux-gray-light py-3 px-2 pr-8">
            Activities w/<br />
            Increased Cost
          </div>
          <div class="py-3 px-2 ml-auto">
            {{ analyticsCard.co_count_activities_with_increased_cost || zeroHyphen }}
          </div>
        </div>
        <div class="border flex text-aux-blue-dark items-center h-full flex-auto">
          <div class="bg-aux-gray-light py-3 px-2 pr-8">
            Activities w/<br />
            Increased Units
          </div>
          <div class="py-3 px-2 ml-auto">
            {{ analyticsCard.co_count_activities_with_increased_units || zeroHyphen }}
          </div>
        </div>
        <div class="border flex text-aux-blue-dark items-center h-full flex-auto">
          <div class="bg-aux-gray-light py-3 px-2 pr-8">
            Total Cost<br />
            Impact
          </div>
          <div class="py-3 px-2 ml-auto">
            {{ analyticsCard.variance_total_cost_AC || zeroHyphen }}
          </div>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 max-h-screen overflow-x-scroll">
        <div>
          <aux-change-order-verification-card [showEditButton]="obj.isPendingReview === true" />
        </div>

        <div class="col-span-2 border p-4 pb-5 overflow-hidden">
          <div class="h-72">
            <div class="text-sm font-bold">Change By Cost Type</div>
            <canvas class="py-3 px-2 ml-auto" width="2" height="1" id="barChart123"></canvas>
          </div>
        </div>
      </div>

      <ng-container
        *ngIf="{ from: fromBudgetVersion$ | async, to: toBudgetVersion$ | async } as obj2"
      >
        <ng-container *ngIf="obj2.from && obj2.to">
          <div class="flex justify-between mt-6 mb-4 items-center">
            <div class="text-aux-gray-darkest font-bold">Budget Updates from Change Order</div>
            <aux-checkbox
              id="hide_unchanged"
              class="text-sm"
              [disabled]="compare?.shouldDisableHideUnchanged() || false"
              [checked]="compare?.shouldCheckHideUnchanged() || false"
              (customChange)="compare?.hideUnchangedActivities($event)"
            >
              Hide Unchanged
            </aux-checkbox>
            <div class="flex items-center text-sm space-x-2">
              <ng-container *ngIf="!obj.isPendingReview">
                <aux-button
                  variant="secondary"
                  label="Download LRE Budget"
                  icon="Download"
                  [onClick]="onDownloadLREBudget.bind(this)"
                  [loading]="isBtnLoading('lre-download') | async"
                  [spinnerSize]="7"
                />
              </ng-container>
              <aux-button
                *ngIf="obj.isPendingReview"
                variant="secondary"
                label="Replace"
                icon="Replace"
                [disabled]="!obj.hasUploadCOPermission"
                [auxTooltip]="!obj.hasUploadCOPermission ? doNotHavePermissionMessage : ''"
                [onClick]="onReplaceBudget.bind(this)"
                [disableLoading]="true"
              />
              <aux-export-excel-button
                *ngIf="!!compare"
                #excelExport
                [gridAPI]="compare.gridAPI"
                [excelOptions]="compare.excelOptions"
                [ignoreColsId]="[
                  'group1',
                  'group2',
                  'group3',
                  'group4',
                  'group5',
                  'changed',
                  'vendor_name'
                ]"
                [getDynamicExcelParamsCallback]="
                  compare.getDynamicExcelParamsCallback(excelExport.getDashboardIDs())
                "
                pendoTag="change-order-detail-excel-export-button"
              />
            </div>
          </div>
          <div
            *ngIf="
              compare?.doesBudgetDataHaveMissingActivities === true &&
              (authQuery.adminUser$ | async) === true
            "
            class="flex justify-between bg-aux-error/25 my-4 rounded-md border-aux-red-dark border-2"
          >
            <div class="py-4 pl-4 flex items-center text-aux-red-dark">
              <aux-icon name="AlertTriangle" />
              <span class="font-bold pl-1">
                This Change Order is missing activities that are in the current budget. Please
                review below.
              </span>
            </div>
            <aux-checkbox
              id="show_missing_activities"
              class="text-sm py-4 pr-4"
              [checked]="true"
              (customChange)="compare?.showMissingActivities($event)"
            >
              Show Missing Activities
            </aux-checkbox>
          </div>
          <div class="h-max mb-8">
            <aux-compare
              domLayout="autoHeight"
              [fromBudgetVersion]="obj2.from"
              [toBudgetVersion]="obj2.to"
              (budgetData)="onBudgetData($event)"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="obj.isPendingReview && (!obj2.from || !obj2.to)">
          <div class="text-aux-gray-darkest font-bold mt-6 mb-4">
            Budget Updates from Change Order
          </div>
          <div class="grid grid-cols-3 gap-4">
            <div class="border bg-aux-gray-light p-4">
              <div class="mb-4 text-aux-gray-darkest text-sm font-bold">
                1. Download Change Order:
              </div>
              <div>
                <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadCO()">
                  <span *ngIf="isBtnLoading('download') | async" class="spinner w-5 h-5"></span>
                  <aux-icon
                    name="Download"
                    *ngIf="(isBtnLoading('download') | async) === false"
                    [size]="16"
                  />
                  <span class="ml-2">Download CO</span>
                </button>
              </div>
            </div>
            <div class="border bg-aux-gray-light p-4">
              <div class="mb-4 text-aux-gray-darkest text-sm font-bold">2. Download LRE Budget</div>
              <div>
                <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadLREBudget()">
                  <span *ngIf="isBtnLoading('lre-download') | async" class="spinner w-5 h-5"></span>
                  <aux-icon
                    name="Download"
                    *ngIf="(isBtnLoading('lre-download') | async) === false"
                    [size]="16"
                  />
                  <span class="ml-2">Download LRE Budget</span>
                </button>
              </div>
            </div>
            <div class="border bg-aux-gray-light p-4">
              <div class="mb-4 text-aux-gray-darkest text-sm font-bold">
                3. Upload Budget with Change Order:
              </div>
              <div>
                <div>
                  <aux-file-manager
                    #manager
                    class="h-32"
                    [fetchFilesOnInit]="false"
                    [pathFn]="pathFn"
                    [eager]="false"
                    [showSuccessOnUpload]="true"
                    [disableUploadLink]="!obj.hasUploadCOPermission"
                    [linkTooltip]="!obj.hasUploadCOPermission ? doNotHavePermissionMessage : ''"
                  />
                  <div
                    *ngIf="(manager.fileQuery.selectCount() | async) !== 0"
                    class="max-h-60 overflow-auto mt-4"
                  >
                    <aux-file-viewer
                      [fileManager]="manager"
                      [disableFirstFileMargin]="true"
                      [onlyShowUploaded]="false"
                    />
                  </div>

                  <div class="mt-2">
                    <aux-checkbox
                      class="text-sm font-medium"
                      [id]="'bypass-validation'"
                      [(ngModel)]="bypassValidation"
                    >
                      Bypass blank Activity ID check
                    </aux-checkbox>
                  </div>

                  <aux-button
                    variant="custom"
                    classList="w-48 text-sm btn btn--blue mt-2"
                    label="Upload"
                    [disabled]="
                      (manager.fileQuery.selectCount() | async) !== 1 ||
                      obj.isUploadCODisabled ||
                      !obj.hasUploadCOPermission
                    "
                    [auxTooltip]="
                      obj.isUploadCODisabled || (manager.fileQuery.selectCount() | async) !== 1
                        ? 'Cannot upload Change Order Budget Template if Vendor has no budget uploaded'
                        : !obj.hasUploadCOPermission
                          ? doNotHavePermissionMessage
                          : ''
                    "
                    [loading]="(isBtnLoading('upload') | async) === true"
                    [onClick]="onChangeOrderBudgetUpload"
                    icon="Upload"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
