import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-checklist-banner',
  templateUrl: './checklist-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistBannerComponent {
  @Input() disabled = false;

  readonly routerLink = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`;
}
