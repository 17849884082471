import { ChangeDetectionStrategy, Component, input, TemplateRef, viewChild } from '@angular/core';

@Component({
  standalone: true,
  selector: 'aux-tab',
  template: ` <ng-template #tmp><ng-content></ng-content></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuxTabComponent {
  label = input.required<string>();
  tmp = viewChild.required<TemplateRef<unknown>>('tmp');
}
