import { NgModule } from '@angular/core';

import { StageBannerComponent } from '@features/stage-banner/stage-banner.component';
import { StageIndicatorComponent } from '@features/stage-indicator/stage-indicator.component';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';

const sharedComponents = [
  StageBannerComponent,
  StageIndicatorComponent,
  WorkflowPanelComponent,
] as const;

@NgModule({
  imports: [...sharedComponents],
  exports: [...sharedComponents],
})
export class FeaturesModule {}
