<div *ngIf="!mainPage; else mainPageTrialDropdown">
  <div class="bg-white w-64 h-16 relative rounded-md border-2 border-aux-gray-dark">
    <label for="portfolio-trial-select" class="absolute text-sm pl-5 pt-2">Go To Trial</label>
    <ng-select
      id="portfolio-trial-select"
      bindValue="id"
      bindLabel="short_name"
      class="portfolio-trial-select h-full"
      placeholder="Select Trial"
      [formControl]="trialFormControl"
      [clearable]="false"
      [searchable]="searchable"
      [items]="trials"
      (ngModelChange)="onChange(trialFormControl.value)"
    >
      <ng-template let-item="item" let-clear="clear" ng-option-tmp class="flex-nowrap">
        <div class="flex items-center">
          <div
            *ngIf="typecastingForTrialStatus(item.implementation_status) as implementation_status"
          >
            <div
              class="min-w-2 w-2 h-2 rounded-full mr-2 shrink-0"
              [ngStyle]="{ 'background-color': trialStatusMap[implementation_status].color }"
              auxTooltip
              [template]="tooltipTemplate"
              [clearTemplate]="true"
              (mouseenter)="mouseEnter(implementation_status)"
              [auxTooltipPositions]="[tooltipPosition]"
            ></div>
          </div>
          <div
            class="truncate"
            [ngClass]="{
              'font-bold': !item.implementation_status,
              'font-medium': item.implementation_status
            }"
            #homePageOption
            [auxTooltip]="
              homePageOption.offsetWidth < homePageOption.scrollWidth ? item.short_name : ''
            "
            [auxTooltipPositions]="[tooltipPosition]"
          >
            {{ item.short_name }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

<ng-template #mainPageTrialDropdown>
  <div
    class="relative rounded-md border border-aux-gray-dark shrink-0"
    style="width: 195px; height: 70px"
  >
    <label for="trial-select" class="absolute text-xs font-medium pl-5 pt-2">TRIAL</label>
    <ng-select
      id="trial-select"
      class="w-full tabular-nums trial-select"
      [searchable]="searchable"
      [formControl]="trialFormControl"
      placeholder="Select Trial"
      [items]="trials"
      [clearable]="false"
      bindLabel="short_name"
      bindValue="id"
      (ngModelChange)="onChange(trialFormControl.value)"
    >
      <ng-template let-item="item" let-clear="clear" ng-label-tmp class="flex-nowrap">
        <div class="flex flex-col space-y-2" style="line-height: 1.25rem">
          <div
            class="mr-1 font-medium truncate z-10"
            #mainPageTrialLabel
            [auxTooltip]="
              mainPageTrialLabel.offsetWidth < mainPageTrialLabel.scrollWidth ? item.short_name : ''
            "
            [auxTooltipPositions]="[tooltipPosition]"
          >
            {{ item.short_name }}
          </div>
          <div class="flex space-x-1 text-xs flex-nowrap font-normal">
            <span>Auxilius Status:</span>
            <div
              *ngIf="typecastingForTrialStatus(item.implementation_status) as implementation_status"
            >
              <div
                [ngStyle]="{
                  color: trialStatusMap[implementation_status].color,
                  'overflow-x':
                    trialStatusMap[implementation_status].text === 'Implementation'
                      ? 'visible'
                      : '',
                  position:
                    trialStatusMap[implementation_status].text === 'Implementation'
                      ? 'absolute'
                      : ''
                }"
                class="flex justify-start"
              >
                <div
                  class="w-[5px] h-[5px] rounded-full mr-1 shrink-0 mt-[5px] 3xl:max-4xl:mt-[4px]"
                  [ngStyle]="{ 'background-color': trialStatusMap[implementation_status].color }"
                ></div>
                <span>{{ trialStatusMap[implementation_status].text }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template let-item="item" let-clear="clear" ng-option-tmp class="flex-nowrap">
        <div class="flex items-center">
          <div
            *ngIf="typecastingForTrialStatus(item.implementation_status) as implementation_status"
          >
            <div
              class="min-w-2 w-2 h-2 rounded-full mr-2 shrink-0"
              [ngStyle]="{ 'background-color': trialStatusMap[implementation_status].color }"
              auxTooltip
              [template]="tooltipTemplate"
              [clearTemplate]="true"
              (mouseenter)="mouseEnter(implementation_status)"
              [auxTooltipPositions]="[tooltipPosition]"
            ></div>
          </div>
          <div
            class="truncate"
            [ngClass]="{
              'font-bold': !item.implementation_status,
              'font-medium': item.implementation_status
            }"
            #mainPageOption
            [auxTooltip]="
              mainPageOption.offsetWidth < mainPageOption.scrollWidth ? item.short_name : ''
            "
            [auxTooltipPositions]="[tooltipPosition]"
          >
            {{ item.short_name }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</ng-template>

<ng-template #tooltipTemplate>
  <div
    [ngStyle]="{ 'background-color': this.tooltipColor }"
    style="padding: 0.5rem 1rem; border-radius: 4px; color: white"
  >
    <span>{{ this.tooltipValue }}</span>
  </div>
</ng-template>
