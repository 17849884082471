import { Component } from '@angular/core';
import {
  ChecklistComponentData,
  ChecklistComponentDataAddPoReport,
} from '../../models/quarter-close-checklist.model';
import { GatherContractsRoutes } from './gather-contracts-routes';
import { ChecklistRowInfoComponent } from '../checklist-row-info/checklist-row-info.component';
import { Utils } from '@services/utils';
import { MessagesConstants } from '@constants/messages.constants';

@Component({
  templateUrl: './checklist-section-gather-contracts.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
})
export class ChecklistSectionGatherContractsComponent implements ChecklistComponentData {
  readonly messagesConstants = MessagesConstants;

  readonly GatherContractsRoutes = GatherContractsRoutes;

  parent!: ChecklistRowInfoComponent;

  id = 'GatherContracts';

  data: ChecklistComponentDataAddPoReport = {
    disabledLock: false,
    addPoReportUploadClick: () => {},
  };

  available = true;

  zeroHyphen = Utils.zeroHyphen;
}
