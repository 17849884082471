<div>
  <div
    class="px-8 py-4 flex justify-between items-center bg-aux-gray-light border-b"
    *ngVar="site$ | async as site"
  >
    <div class="flex flex-col">
      <div class="flex items-center space-x-1 text-sm">
        <p class="text-aux-blue cursor-pointer" (click)="goToSiteList()">Sites</p>
        <aux-icon name="ChevronRight" [size]="14" />
        <p class="text-aux-blue-dark-1">Site # {{ site?.site_no }}</p>
      </div>

      <p class="font-bold text-2xl mt-1">{{ site?.name }}</p>
      <div class="text-xl text-aux-gray-darkest">Site # {{ site?.site_no }}</div>
    </div>

    <div
      class="flex items-center h-6 text-aux-error"
      (click)="deleteSite()"
      [ngClass]="{
        'cursor-not-allowed opacity-70':
          !siteWorkflow.hasUpdateSitePermission() || siteWorkflow.isPatientsFinalized(),
        'cursor-pointer':
          siteWorkflow.hasUpdateSitePermission() && !siteWorkflow.isPatientsFinalized()
      }"
      [auxTooltip]="siteWorkflow.actionButtonsTooltip()"
    >
      <aux-icon name="Trash" [size]="16" />
      <span class="ml-2">Delete Site</span>
    </div>
  </div>

  <aux-route-tab-group className="px-4 text-base" class="bg-aux-gray-light" [tabs]="tabs" />

  <div class="px-8 py-4">
    <router-outlet></router-outlet>
  </div>
</div>
