import { Directive, effect, ElementRef, input } from '@angular/core';

@Directive({ selector: '[auxSkeletonLoader]', standalone: true })
export class SkeletonLoaderDirective {
  loading = input.required<boolean>();

  constructor(private el: ElementRef) {
    effect(() => {
      this.updateView(this.loading());
    });
  }

  updateView(loading: boolean) {
    if (loading) {
      this.el.nativeElement.classList.add('skeleton-item');
      this.el.nativeElement.classList.add('animate-pulse');
      this.el.nativeElement.classList.add('bg-aux-gray-light-2');
      this.el.nativeElement.classList.add('rounded');
      this.el.nativeElement.classList.add('text-transparent');
    } else {
      this.el.nativeElement.classList.remove('skeleton-item');
      this.el.nativeElement.classList.remove('animate-pulse');
      this.el.nativeElement.classList.remove('bg-aux-gray-light-2');
      this.el.nativeElement.classList.remove('rounded');
      this.el.nativeElement.classList.remove('text-transparent');
    }
  }
}
