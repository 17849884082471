import { CellClickedEvent, ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import { AgStatusComponent } from '@shared/components/ag-status/ag-status.component';
import dayjs from 'dayjs';
import { OpsAdminUtils } from '../../utils/ops-admin.utils';
import { TableConstants } from '@constants/table.constants';
import { AgMultiLinkComponent } from '@shared/components/ag-multi-link/ag-multi-link.component';
import { StoredDocumentProcessStatus } from '@services/gql.service';

export const getEdcConvertedFilesGridOptions = (
  downloadFile: (downloadLink: string, fileName: string) => Promise<void>
): ColDef[] => {
  return [
    {
      headerName: 'EDC Input Files',
      headerClass: 'ag-header-align-center',
      field: 'original_files',
      minWidth: 125,
      cellClass: [
        TableConstants.STYLE_CLASSES.CELL_AUTO_HEIGHT,
        'text-left',
        '!block',
        'max-w-full',
      ],
      wrapText: true,
      autoHeight: true,
      cellRenderer: AgMultiLinkComponent,
      cellRendererParams: {
        downloadFileFn: downloadFile,
      },
    },

    {
      headerName: 'Auxilius Patient Data Output',
      headerClass: 'ag-header-align-center',
      field: 'converted_file',
      minWidth: 125,
      tooltipField: 'converted_file',
      cellClass:
        'grid-cell underline text-left !text-aux-blue cursor-pointer underline-offset-4 !items-start',
      cellStyle: {
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
        display: 'block',
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event.data.status === StoredDocumentProcessStatus.STATUS_PROCESSED) {
          downloadFile(event.data.converted_file_download_link, event.data.converted_file).then(
            (r) => r
          );
        }
      },
    },
    {
      headerName: 'For Month',
      headerClass: 'ag-header-align-center',
      field: 'month_close',
      minWidth: 125,
      width: 125,
      cellClass: '!items-start',
    },
    {
      headerName: 'Conversion Status',
      headerClass: 'ag-header-align-center',
      field: 'status',
      minWidth: 125,
      width: 125,
      cellClass: '!items-start',
      cellRenderer: AgStatusComponent,
      cellRendererParams: (params: { data: { status: StoredDocumentProcessStatus } }) => ({
        status: OpsAdminUtils.getAgStatusParameters(params.data.status),
      }),
    },
    {
      headerName: 'Date Converted',
      headerClass: 'ag-header-align-center',
      field: 'date_converted',
      minWidth: 125,
      width: 125,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? dayjs(params.value).format('DD-MMM-YYYY') : '';
      },
      getQuickFilterText: (params: ValueFormatterParams) => {
        return params.value ? dayjs(params.value).format('DD-MMM-YYYY') : '';
      },
      cellClass: ['ag-cell-align-right, !items-start'],
    },
  ] as ColDef[];
};
