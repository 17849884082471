import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { EventService } from '@services/event.service';
import { MainQuery } from '@shared/store/main/main.query';
import { SnapshotService } from 'src/app/pages/budget-page/tabs/budget-enhanced/compare-dropdown/snapshot.service';
import { BudgetService } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.service';
import { GenericBudgetCompareMenuComponent } from '@components/budget-compare-menu/classes/budget-compare-menu.class';
import { TrialInsightsStore } from '../../store/trial-insights.store';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-finance-bva-chart-menu',
  templateUrl: './bva-chart-menu.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinanceBvaChartMenuComponent
  extends GenericBudgetCompareMenuComponent
  implements OnInit
{
  constructor(
    public cdr: ChangeDetectorRef,
    public mainQuery: MainQuery,
    public eventService: EventService,
    public organizationService: OrganizationService,
    public organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    public snapshotService: SnapshotService,
    public budgetService: BudgetService,
    public trialInsightsStore: TrialInsightsStore
  ) {
    super({
      cdr,
      mainQuery,
      eventService,
      organizationService,
      organizationStore,
      organizationQuery,
      snapshotService,
      budgetService,
      trialInsightsStore,
    });
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
  }
}
