import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Expense_Source } from '@pages/closing-page/tabs/quarter-close-adjustments/quarter-close-adjustments.component';

@Component({
  selector: 'aux-ag-select-editor-option-renderer',
  templateUrl: 'ag-select-editor-option-renderer.component.html',
  styleUrls: ['ag-select-editor-option-renderer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgSelectEditorOptionRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getDataPendoId(): string {
    switch (this.params.value) {
      case Expense_Source.Forecast:
        return 'expense-source-forecast';
      case Expense_Source['Vendor Estimate']:
        return 'expense-source-vendor-estimate';
      case Expense_Source['Evidence Based']:
        return 'expense-source-evidence-based';
      case Expense_Source.Manual:
        return 'expense-source-manual';
      default:
        return '';
    }
  }
}
