import { Directive, effect, HostListener, inject, OnDestroy, viewChild } from '@angular/core';
import { StickyGridService } from './sticky-grid.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import { merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  standalone: true,
  providers: [StickyGridService],
})
export class StickyGridDirective implements OnDestroy {
  grid = viewChild(AgGridAngular);

  stickyElementService = inject(StickyGridService);

  subs: Subscription[] = [];

  subscribeToGridEvents = effect(() => {
    const grid = this.grid();
    if (!grid) {
      return;
    }

    this.subs.forEach((sub) => sub.unsubscribe());

    this.subs.push(
      merge(grid.gridReady, grid.firstDataRendered, grid.viewportChanged, grid.gridSizeChanged)
        .pipe(debounceTime(0))
        .subscribe(() => {
          this.stickyElementService.configure();
        })
    );
  });

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.stickyElementService.reset();
  }
}
