import { Injectable } from '@angular/core';
import { MainQuery } from '@shared/store/main/main.query';
import { switchMap, tap } from 'rxjs/operators';
import {
  CreateDriverPatientDistributionInput,
  DistributionMode,
  GqlService,
  UpdateDriverPatientDistributionInput,
  listDriverPatientDistributionsQuery,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { PatientsStore } from './patients.store';
import { v4 as uuidv4 } from 'uuid';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private patientStore: PatientsStore,
    private overlayService: OverlayService
  ) {}

  get(id?: string) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientStore.setLoading(true);
        return this.gqlService.listDriverPatientDistributions$(id);
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          data.forEach((eachData) => {
            // All forecasts normally have id unless they were not created from csv beforehand. So
            // this is just for making store does its jobs correctly and these id's are not saved at anywhere.
            // if there is no id assigned beforehand, store fills itself with only last value. These assigned id's are not used anywhere.
            if (!eachData.forecast.id) {
              eachData.forecast.id = uuidv4();
            }
            if (eachData.actual == null) {
              eachData.actual = {
                __typename: 'PatientEnrollmentData',
                patients_enrolled: 0,
                patients_discontinued: 0,
                patients_complete: 0,
                net_patients_enrolled: 0,
                cumulative_enrollment_percentage: 0,
                total_patients_enrolled: 0,
                distribution_mode: DistributionMode.DISTRIBUTION_MODE_ACTUAL,
              };
            }
          });
          const bData = (data || []).map((val) => {
            return { ...val, id: val.forecast.id };
          });
          this.patientStore.set(bData);
        } else {
          this.overlayService.error(errors, undefined, true);
        }
        this.patientStore.setLoading(false);
      })
    );
  }

  async createPatient(patient: CreateDriverPatientDistributionInput) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.createDriverPatientDistribution$(patient)
    );
    if (!(success && data)) {
      this.overlayService.error(errors);
    }
    return { success, errors, data };
  }

  async updatePatientDistribution(x: UpdateDriverPatientDistributionInput) {
    this.patientStore.setLoading(true);
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updateDriverPatientDistribution$({
        id: x.id,
        distribution_month: x.distribution_month,
        distribution_mode: x.distribution_mode,
        patients_enrolled: x.patients_enrolled,
        patients_discontinued: x.patients_discontinued,
        patients_complete: x.patients_complete,
        net_patients_enrolled: x.net_patients_enrolled,
        cumulative_enrollment_percentage: x.cumulative_enrollment_percentage,
        total_patients_enrolled: x.total_patients_enrolled,
      })
    );
    if (success && data) {
      const updatedPD = {
        __typename: 'DriverPatientDistribution',
        distribution_mode: data.forecast.distribution_mode,
        forecast: {
          __typename: 'PatientEnrollmentData',
          id: data.forecast.id,
          distribution_month: data.distribution_month,
          patients_enrolled: data.forecast.patients_enrolled,
          patients_discontinued: data.forecast.patients_discontinued,
          patients_complete: data.forecast.patients_complete,
          net_patients_enrolled: data.forecast.net_patients_enrolled,
          cumulative_enrollment_percentage: data.forecast.cumulative_enrollment_percentage,
          total_patients_enrolled: data.forecast.total_patients_enrolled,
        },
      } as unknown as Partial<listDriverPatientDistributionsQuery>;
      this.patientStore.update(
        (entity: listDriverPatientDistributionsQuery) => entity.forecast.id === data.forecast.id,
        updatedPD
      );
      this.patientStore.setLoading(false);
      return true;
    }
    this.overlayService.error(errors);

    this.patientStore.setLoading(false);
    return false;
  }
}
