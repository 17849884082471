import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SitesService } from '@models/sites/sites.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  RouteTabGroupComponent,
  TabGroupConfig,
} from '@components/tab-group/route-tab-group.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { EventType, PermissionType, WorkflowStep } from '@services/gql.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';
import { RouterOutlet } from '@angular/router';
import { EventQuery } from '@models/event/event.query';

@UntilDestroy()
@Component({
  selector: 'aux-forecast-site-drivers',
  templateUrl: './forecast-site-drivers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WorkflowPanelComponent, RouterOutlet, RouteTabGroupComponent],
})
export class ForecastSiteDriversComponent {
  sitesService = inject(SitesService);
  workflowQuery = inject(WorkflowQuery);
  launchDarklyService = inject(LaunchDarklyService);
  authService = inject(AuthService);
  eventQuery = inject(EventQuery);

  tabs: TabGroupConfig[] = [
    { label: 'Site Curves', route: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES },
    {
      label: 'Site Groups',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_site_groups),
      route: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.GROUPS,
    },
  ];
  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES;

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;
  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );
  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);
  userHasLockPatientSiteCurvePermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_SITE_CURVES],
  });

  constructor() {
    this.sitesService.get().pipe(untilDestroyed(this)).subscribe();
  }
}
