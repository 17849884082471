<div class="flex items-center">
  @if (params.showIcon) {
    <aux-icon
      [name]="params.icon"
      [class]="'min-w-[24px] mr-2 ' + params.iconClass"
      [auxTooltip]="params.iconTooltip"
    />
  }
  <span [auxTooltip]="value" class="truncate">{{ value }}</span>
</div>
