<div *ngIf="showTotalCardsSection$ | async" class="flex justify-between items-center my-5">
  <div class="flex space-x-4">
    <aux-card
      topText="Total # of Sites"
      [middleText]="(gridData$ | async)?.length || zeroHyphen"
      className="min-w-[230px]"
    />

    <aux-card
      topText="Total # of Activated Sites"
      [middleText]="this.activatedSitesTotal || zeroHyphen"
      className="min-w-[230px]"
    />

    <aux-card
      topText="Total # of Current Active Sites"
      [middleText]="this.currentlyActiveSitesTotal || zeroHyphen"
      className="min-w-[230px]"
    />

    <aux-card
      topText="Total # of Closed Sites"
      [middleText]="this.closedSitesTotal || zeroHyphen"
      className="min-w-[230px]"
    />
  </div>
</div>

<ng-container *ngIf="sitesQuery.selectLoading() | async">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(sitesQuery.selectLoading() | async) && !$any(loadingTable | async)">
  <div class="my-4 overflow-auto">
    <section class="mb-4 flex items-end justify-between flex-wrap gap-2">
      <form class="flex flex-wrap gap-2" [formGroup]="transactionsForm">
        <aux-input
          class="w-52 mt-4"
          placeholder="Search"
          formControlName="name_filter"
          label=" "
          icon="Search"
        />
        <aux-multi-select-dropdown
          placeholder="Select"
          label="Country:"
          formControlName="country"
          class="w-48"
          bindLabel="name"
          bindValue="name"
          [items]="$any(countryOptions$ | async)"
          [loading]="(dataLoading$ | async)!"
          [searchable]="true"
        />
        <aux-multi-select-dropdown
          placeholder="Select"
          label="Currency:"
          formControlName="currency"
          class="w-36"
          bindLabel="name"
          bindValue="name"
          [items]="$any(currencyOptions$ | async)"
          [loading]="(dataLoading$ | async)!"
          [searchable]="true"
        />
        <div>
          <span class="block mb-1 text-xs">Vendor:</span>
          <ng-select
            class="tabular-nums big-dropdown 2xl:w-[350px] lg:w-[250px] md:w-[200px] sm:w-[150px]"
            placeholder="Select"
            formControlName="managed_by_name"
            bindLabel="name"
            bindValue="name"
            [multiple]="true"
            [clearable]="true"
            [loading]="(dataLoading$ | async)!"
            [items]="$any(organizationQuery.allVendors$ | async)"
          >
            <ng-template let-item="item" ng-option-tmp>
              <div
                class="block truncate text-sm"
                #text
                [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).name : ''"
                [auxTooltipPositions]="optionTooltipPositions"
              >
                {{ item.name }}
              </div>
            </ng-template>
            <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
              <div *ngIf="items.length === 1" class="ng-value">
                <div *ngFor="let item of items | slice: 0 : 1" class="flex">
                  <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
                  <span
                    class="ng-value-label overflow-hidden text-ellipsis max-w-[200px]"
                    #text
                    [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).name : ''"
                    [auxTooltipPositions]="optionTooltipPositions"
                  >
                    {{ $any(item).name }}
                  </span>
                </div>
              </div>
              <div *ngIf="items.length > 1" class="ng-value">
                <span class="ng-value-label">{{ items.length }} Selected</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <span
          class="text-sm font-normal aux-link cursor-pointer mt-7.5 mr-3 whitespace-nowrap"
          (click)="resetFilter()"
          >Reset Filters</span
        >
      </form>
      <div class="flex items-center space-x-2">
        <aux-button
          [disabled]="(userHasSitePermission$ | async) === false"
          [auxTooltip]="noPermissionsTooltipText()"
          variant="secondary"
          label="New Site"
          icon="CirclePlus"
          (click)="onNewSite()"
        />
        <aux-button
          [disabled]="(userHasSitePermission$ | async) === false"
          [auxTooltip]="noPermissionsTooltipText()"
          variant="secondary"
          label="Multiple Sites"
          icon="CirclePlus"
          (click)="onAddMultipleSites()"
        />
      </div>
    </section>
    <ag-grid-angular
      class="ag-theme-alpine sites-table tabular-nums w-full h-full"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="gridData$ | async"
      [domLayout]="'autoHeight'"
      [quickFilterText]="transactionsForm.getRawValue()?.name_filter"
      (gridReady)="onGridReady($event)"
      (viewportChanged)="onWindowScroll()"
      (columnResized)="onGridSizeChanged()"
    />
  </div>
</ng-container>
