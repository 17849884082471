import {
  CellValueChangedEvent,
  FirstDataRenderedEvent,
  GetRowIdFunc,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowSelectedEvent,
} from '@ag-grid-community/core';
import {
  Component,
  EventEmitter,
  HostListener,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PaginationPanelComponent } from '../pagination-panel/pagination-panel.component';
import { Utils } from '@services/utils';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ServerSideDatasource, ServerSideFilterInfo, ServerSideSortOrder } from '@shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StickyElementService } from '@services/sticky-element.service';
import { DatasourceService } from '@shared/services/datasource.service';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { TableSkeletonComponent } from '../table-skeleton/table-skeleton.component';
import { TableConstants } from '@constants/table.constants';
import { AgGridAngular } from '@ag-grid-community/angular';
import { SelectionChangedEvent } from '@ag-grid-community/core/dist/esm/es6/events';

@UntilDestroy()
@Component({
  selector: 'aux-pagination-grid',
  templateUrl: './pagination-grid.component.html',
  standalone: true,
  imports: [
    PaginationPanelComponent,
    TableSkeletonComponent,
    AsyncPipe,
    NgIf,
    AgGridAngular,
    NgClass,
  ],
})
export class PaginationGridComponent<T> implements OnInit, OnDestroy {
  private readonly defaultGridOptions: GridOptions = {
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    rowHeight: 45,
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_CENTER,
      resizable: true,
    },
    rowModelType: 'serverSide',
    maxConcurrentDatasourceRequests: 1,
    pagination: true,
    paginationPageSize: 10,
    suppressPaginationPanel: true,
  };

  private _gridOptions!: GridOptions;

  @Input() set gridOptions(value: GridOptions) {
    this._gridOptions = {
      ...this.defaultGridOptions,
      ...value,
    };
  }

  get gridOptions() {
    return this._gridOptions;
  }

  @Input({ required: true }) dataSource!: ServerSideDatasource<T>;

  @Input({ required: true }) idTable!: string;

  @Input() filterForm?: FormGroup;

  @Input() serverSideFilters?: ServerSideFilterInfo<T>[];

  @Input() filterValues$?: BehaviorSubject<Record<string, unknown>>;

  @Input() sortModel$?: BehaviorSubject<ServerSideSortOrder<T>[]>;

  loading = input<boolean>();

  @Input() exportGridOptions?: GridOptions;

  @Input() getRowId?: GetRowIdFunc;

  @Input() getContext?: () => unknown;

  @Input() hidePinnedRow = false;

  @Input() isAgThemeAlpine = false;

  @Input() enableFillHandle = false;

  @Output() gridReady = new EventEmitter<GridReadyEvent>();

  @Output() paginationChange = new EventEmitter<void>();

  @Output() cellValueChanged = new EventEmitter<CellValueChangedEvent>();

  @Output() rowSelected = new EventEmitter<RowSelectedEvent>();

  @Output() selectionChanged = new EventEmitter<SelectionChangedEvent>();

  @Output() firstDataRendered = new EventEmitter<FirstDataRenderedEvent>();

  @Output() modelUpdated = new EventEmitter<ModelUpdatedEvent>();

  protected gridAPI?: GridApi;

  constructor(
    private stickyElementService: StickyElementService,
    private datasourceService: DatasourceService
  ) {}

  ngOnInit() {
    this.dataSource.refresh$.pipe(untilDestroyed(this)).subscribe(() => this.refreshGridData());

    this.dataSource.aggregation$.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.hidePinnedRow) {
        this.refreshPinnedBottomRow();
      }
    });

    if (this.filterForm) {
      if (!this.serverSideFilters) throw new Error('serverSideFilters input is required');

      if (!this.filterValues$) throw new Error('filterValues$ input is required');

      const formWatcherInfo = this.datasourceService.applyTransformations(
        this.filterForm,
        this.serverSideFilters
      );
      this.filterValues$.next(formWatcherInfo.currentValue);

      formWatcherInfo.valueChangesObservable.pipe(untilDestroyed(this)).subscribe((v) => {
        this.filterValues$?.next(v);
      });
    }
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridAPI = event.api;
    this.onSortChanged();
    this.gridReady.emit(event);
  }

  sizeColumnsToFit(): void {
    this.gridAPI?.sizeColumnsToFit();
  }

  updateGridLayout(): void {
    if (!this.gridAPI) return;

    Utils.updateGridLayout(this.gridAPI, this.idTable, true);
  }

  onSortChanged() {
    const currentSortModel = this.datasourceService.getCurrentSortModel(this.gridAPI);
    this.sortModel$?.next(currentSortModel);
  }

  onCellValueChanged(event: CellValueChangedEvent): void {
    this.cellValueChanged.emit(event);
  }

  onRowSelected(event: RowSelectedEvent): void {
    this.rowSelected.emit(event);
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    this.selectionChanged.emit(event);
  }

  onFirstDataRendered(event: FirstDataRenderedEvent): void {
    this.sizeColumnsToFit();
    this.firstDataRendered.emit(event);
  }

  onModelUpdated(event: ModelUpdatedEvent): void {
    this.modelUpdated.emit(event);
  }

  private refreshGridData() {
    this.gridAPI?.setGridOption('serverSideDatasource', this.dataSource);
  }

  private refreshPinnedBottomRow() {
    const pinnedBottomRowData: Array<unknown> = [];
    if (this.dataSource.currentServerInput) {
      if (this.dataSource.totalRows) {
        pinnedBottomRowData.push(this.dataSource.aggregation);
      }
    }

    this.gridAPI?.setGridOption('pinnedBottomRowData', pinnedBottomRowData);
    this.stickyElementService.configure();
  }

  getGridContext() {
    return this?.getContext ? this.getContext() : null;
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }
}
