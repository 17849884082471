import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TabGroupConfig } from '@components/tab-group/route-tab-group.component';
import { Flags, LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '@services/event.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventType } from '@services/gql.service';
import { FormValuesQuery } from '@models/form-values/form-values.query';
import { map } from 'rxjs';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@UntilDestroy()
@Component({
  selector: 'aux-investigator',
  templateUrl: './investigator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Investigator Summary',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_SUMMARY}`,
      show: this.launchDarklyService.select$((flags) => flags.tab_investigator_summary),
      flag: 'tab_investigator_summary',
    },
    {
      label: 'Investigator Transactions',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS}`,
      show: this.launchDarklyService.select$((flags) => flags.tab_investigator_transactions),
      flag: 'tab_investigator_transactions',
    },
    {
      label: 'Patient Tracker',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER}`,
      show: this.launchDarklyService.select$((flags) => flags.nav_patient),
      flag: 'nav_patient',
    },
    {
      label: 'Patient Budget',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET.INDEX}`,
      show: this.launchDarklyService.select$((flags) => flags.tab_trial_settings_patient_protocol),
      flag: 'tab_trial_settings_patient_protocol',
    },
    {
      label: 'Protocol Entry',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY}`,
      show: this.launchDarklyService.select$((flags) => flags.tab_protocol_entry),
      flag: 'tab_protocol_entry',
    },
    {
      label: 'Patient Groups',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_GROUPS}`,
      show: this.launchDarklyService.select$((flags) => flags.tab_patient_groups),
      flag: 'tab_patient_groups',
    },
    {
      label: 'Sites',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`,
      show: this.launchDarklyService.select$((flags) => flags.tab_investigator_sites),
      flag: 'tab_investigator_sites',
    },
    {
      label: 'Investigator Detail',
      route: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_DETAIL}`,
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_investigator_detail),
      flag: 'tab_forecast_investigator_detail',
    },
  ];

  showTabs$ = this.route.firstChild?.params.pipe(map((params) => !params['id']));

  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private eventService: EventService,
    private formValuesQuery: FormValuesQuery,
    private route: ActivatedRoute,
    private workflowService: WorkflowService
  ) {
    this.launchDarklyService.flags$.subscribe((flags: Flags) => {
      this.investigatorSummaryRedirect(flags);
    });

    // Share data across investigator pages
    this.workflowService.getWorkflowList().pipe(takeUntilDestroyed()).subscribe();

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.formValuesQuery.updateValues({ site_ids: [] }, 'patientBudget');
      });
  }

  private investigatorSummaryRedirect(flag: Flags): void {
    const urlWOutTrial = this.router.url.includes('trial')
      ? this.router.url.split('?')?.[0]
      : this.router.url;
    const nTabs = this.tabs.find((tab) => tab.route === urlWOutTrial);
    if (flag?.[nTabs?.flag as keyof Flags] && this.router.url !== nTabs?.route) {
      this.router.navigate([nTabs?.route]);
    } else if (!flag?.[nTabs?.flag as keyof Flags] && urlWOutTrial === nTabs?.route) {
      this.router.navigate([this.tabs[0].route]);
    }
  }
}
