import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ApprovalType, EntityType, InvoiceStatus, PermissionType } from '@services/gql.service';
import { ButtonComponent } from '@components/button/button.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

import { InvoiceBannerButtonDirective } from './invoice-detail-banner-button.directive';
import { invoiceDisabledTooltip } from '@pages/vendor-payments-page/tabs/invoices/invoices.component';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-status-banner-decline-button',
  template: `
    <aux-button
      [auxTooltip]="tooltip()"
      [disabled]="disabled()"
      [onClick]="onDecline"
      icon="X"
      variant="negative"
      classList="h-9"
      label="Decline"
      [iconSize]="20"
    />
  `,
  imports: [ButtonComponent, TooltipDirective],
  hostDirectives: [
    {
      directive: InvoiceBannerButtonDirective,
      inputs: ['invoice'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailDeclineBtnComponent {
  ctx = inject(InvoiceBannerButtonDirective);

  hasDeclinePermission = this.ctx.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_APPROVE_INVOICE],
  });

  disabled = computed(() => {
    return !this.hasDeclinePermission() || this.ctx.buttonDisabled() || this.ctx.statusCheck();
  });

  tooltip = computed(() => {
    if (!this.hasDeclinePermission()) {
      return this.ctx.dontHavePermission;
    }

    if (this.ctx.buttonDisabled()) {
      return this.ctx.invoiceLockTooltip();
    }

    if (this.ctx.statusCheck()) {
      return invoiceDisabledTooltip;
    }

    return '';
  });

  onDecline = async () => {
    const { overlayService, gqlService, invoiceService, userTaskService } = this.ctx;
    const invoice = this.ctx.invoice();
    const resp = overlayService.openConfirmDialog({
      header: 'Decline Invoice?',
      message: `Are you sure you want to decline Invoice ${invoice.invoice_no}?`,
      okBtnText: 'Decline',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data?.result) {
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      gqlService.approveRule$({
        approved: false,
        comments: '',
        permission: 'PERMISSION_APPROVE_INVOICE',
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: invoice.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      overlayService.error(approveErrors);
      return;
    }

    await invoiceService.update({
      ...invoice,
      decline_reason: event.data.textarea,
      accrual_period: null,
      invoice_status: InvoiceStatus.STATUS_DECLINED,
    });
    await userTaskService.triggerUserTaskList$();
  };
}
