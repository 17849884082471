import { Injectable } from '@angular/core';
import {
  GqlService,
  PageInput,
  PatientProtocolType,
  fetchPatientVisitSchedulesQuery,
} from '@services/gql.service';
import { Maybe } from 'graphql/jsutils/Maybe';
import { combineLatest, Observable } from 'rxjs';
import { PatientTrackerRow, Visit, VisitSchedule } from '../types';
import { map } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { isVisitMonthEqualWithCurrentOpenMonth } from '../utils';
import { PatientVisitDataSteam } from '@shared/services/datasource.service';

@Injectable({ providedIn: 'root' })
export class PatientTrackerScheduleService {
  constructor(
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  getPatientVisitSchedules$(
    patientProtocolVersion: string,
    currentOpenMonth: string,
    site_ids: Maybe<string[]>,
    patientGroupId: Maybe<string> | null,
    pagination: PageInput
  ): Observable<{
    rowData: PatientTrackerRow[];
    metaData: Maybe<{ total: number; currentMonthTotal: number }>;
    visits?: Visit[];
  }> {
    const fetchPatientVisitSchedules: Observable<GraphqlResponse<fetchPatientVisitSchedulesQuery>> =
      this.gqlService.fetchPatientVisitSchedules$({
        site_ids,
        patient_group_ids: patientGroupId ? [patientGroupId] : [null],
        page: pagination,
      });

    return combineLatest([fetchPatientVisitSchedules]).pipe(
      map(([{ data: scheduleData, errors: scheduleErrors }]) => {
        if (scheduleErrors.length) {
          this.overlayService.error(scheduleErrors);
          return {
            rowData: [],
            metaData: null,
          };
        }

        const metaData = scheduleData?.meta_data
          ? {
              total: scheduleData.meta_data.total || 0,
              currentMonthTotal: scheduleData.meta_data.current_month_total || 0,
            }
          : null;

        return {
          rowData: this.parsePatientVisitSchedules(
            patientProtocolVersion,
            currentOpenMonth,
            scheduleData?.items || []
          ),
          metaData,
        };
      })
    );
  }

  private getVisits(visits: VisitSchedule[], currentOpenMonth: string) {
    return visits.reduce((accum, { patient_protocol_id, scheduled_date, cost, completed }) => {
      const isCurrentMonth = scheduled_date
        ? isVisitMonthEqualWithCurrentOpenMonth(scheduled_date, currentOpenMonth)
        : false;

      return {
        ...accum,
        [`${patient_protocol_id}::dates`]: scheduled_date,
        [`${patient_protocol_id}::costs`]: cost.amount,
        [`${patient_protocol_id}::costs::contracted`]: cost.contract_amount,
        [`${patient_protocol_id}::shouldHighlight`]: isCurrentMonth && !completed,
        [`${patient_protocol_id}::completed`]: completed,
      };
    }, {});
  }

  private getPatientCosts(
    visits: VisitSchedule[],
    patientProtocolVersion: string
  ): Omit<
    PatientTrackerRow,
    'external_patient_id' | 'site_no' | 'contractCurrency' | 'currency' | 'site_id'
  > {
    return visits.reduce(
      (accum, { patient_protocol_type, completed, cost, patient_protocol_version_id }) => {
        const isPatientVisitType =
          patient_protocol_type === PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT;

        if (isPatientVisitType && completed) {
          accum.totalVisitCostsToDate += cost.amount || 0;
          accum.totalVisitCostsToDateContracted += cost.contract_amount || 0;
        }

        if (completed && patient_protocol_version_id !== patientProtocolVersion) {
          accum.totalCostsFromOtherVersions += cost.amount || 0;
          accum.totalCostsFromOtherVersionsContracted += cost.contract_amount || 0;
        }

        if (!isPatientVisitType && completed) {
          accum.totalInvoiceablesToDate += cost.amount || 0;
          accum.totalInvoiceablesToDateContracted += cost.contract_amount || 0;
        }

        if (completed) {
          accum.totalLTDCosts += cost.amount || 0;
          accum.totalLTDCostsContracted += cost.contract_amount || 0;
        }

        accum.totalForecastCostThroughEot += cost.amount || 0;
        accum.totalForecastCostThroughEotContracted += cost.contract_amount || 0;

        if (!completed) {
          accum.forecastRemaining += cost.amount || 0;
          accum.forecastRemainingContracted += cost.contract_amount || 0;
        }

        return accum;
      },
      {
        totalVisitCostsToDate: 0,
        totalCostsFromOtherVersions: 0,
        totalInvoiceablesToDate: 0,
        totalLTDCosts: 0,
        totalForecastCostThroughEot: 0,
        forecastRemaining: 0,
        totalVisitCostsToDateContracted: 0,
        totalCostsFromOtherVersionsContracted: 0,
        totalInvoiceablesToDateContracted: 0,
        totalLTDCostsContracted: 0,
        totalForecastCostThroughEotContracted: 0,
        forecastRemainingContracted: 0,
      }
    );
  }

  parsePatientVisitSchedules(
    patientProtocolVersion: string,
    currentOpenMonth: string,
    listSchedules: PatientVisitDataSteam[]
  ): PatientTrackerRow[] {
    return listSchedules.map((patientSchedule) => {
      return {
        // Patient Costs
        ...this.getVisits(
          patientSchedule.visit_schedule.filter(
            ({ patient_protocol_version_id }) =>
              patient_protocol_version_id === patientProtocolVersion
          ),
          currentOpenMonth
        ),
        external_patient_id: patientSchedule.external_patient_id,
        site_no: patientSchedule.site_no,
        site_id: patientSchedule.site_id,
        // Patient Costs column
        ...this.getPatientCosts(patientSchedule.visit_schedule, patientProtocolVersion),
        contractCurrency: patientSchedule.visit_schedule[0].cost.contract_currency || '',
        currency: patientSchedule.visit_schedule[0].cost.amount_currency || '',
      };
    });
  }
}
