<div class="flex items-center justify-center h-full">
  <div [auxTooltip]="params.invoiceLockTooltip() || 'Upload'">
    <button
      class="p-0 w-7 h-7 flex justify-center items-center bg-aux-blue rounded-md ml-1"
      type="button"
      data-pendo-id="invoice-files-upload"
      [disabled]="disabled()"
      (click)="onUploadClick()"
    >
      <aux-icon
        name="CloudUpload"
        [size]="18"
        class="text-white"
        [ngClass]="{ 'opacity-50': disabled() }"
      />
    </button>
  </div>
  <button
    *ngIf="!params.hideDownloadButton"
    class="p-0 w-7 h-7 flex justify-center items-center rounded-md ml-1"
    type="button"
    data-pendo-id="invoice-files-download"
    [auxTooltip]="'Download'"
    [ngClass]="{
      'bg-aux-green': fileExist,
      'bg-aux-gray-dark': !fileExist
    }"
    [disabled]="params.disableDownloadButton || !fileExist"
    (click)="onDownloadClick()"
  >
    <aux-icon
      name="Download"
      class="stroke-[1.4px]"
      [size]="18"
      [ngClass]="{
        'text-white': fileExist,
        'text-aux-gray-darkest': !fileExist,
        'opacity-50': !fileExist
      }"
    />
  </button>

  <button
    class="p-0 w-7 h-7 flex justify-center items-center rounded-md mx-1"
    type="button"
    data-pendo-id="invoice-files-download-lines"
    [auxTooltip]="enableCSV ? 'Download Line Item Details' : ''"
    [ngClass]="{
      'bg-aux-green': enableCSV,
      'bg-aux-gray-dark': !enableCSV
    }"
    [disabled]="!enableCSV"
    (click)="onDownloadLinesClick()"
  >
    <aux-icon
      name="FileTypeCsv"
      [size]="18"
      [ngClass]="{
        'text-white': enableCSV,
        'text-aux-gray-darkest': !enableCSV,
        'opacity-50': !enableCSV
      }"
    />
  </button>
</div>
