import { Injectable } from '@angular/core';
import {
  EntityType,
  EventType,
  GqlService,
  PageInput,
  PatientProtocolType,
  UpdateSitePaymentScheduleInput,
  updateSitePaymentScheduleMutation,
} from '@services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { expand, reduce, switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { EMPTY, firstValueFrom, of } from 'rxjs';
import { flatten, groupBy } from 'lodash-es';

import { FormValuesQuery } from '@models/form-values/form-values.query';
import {
  PaymentSchedulesModel,
  PaymentSchedulesState,
  PaymentSchedulesStore,
} from './payment-schedules.store';
import { batchPromises } from '@shared/utils';
import { Utils } from '@services/utils';

type PaymentScheduleParams = {
  patient_group_id: string;
  site_ids: string[];
  patient_protocol_version_id: string;
};

@Injectable({ providedIn: 'root' })
export class PaymentSchedulesService {
  cache = new Map<string, PaymentSchedulesState[]>();

  constructor(
    private paymentSchedulesStore: PaymentSchedulesStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private formValuesQuery: FormValuesQuery
  ) {}

  get(
    patient_protocol_types: PatientProtocolType[],
    { patient_group_id, site_ids, patient_protocol_version_id }: PaymentScheduleParams,
    isRefresh: string | undefined
  ) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.paymentSchedulesStore.setLoading(true);
        this.paymentSchedulesStore.remove(() => true);

        if (isRefresh) {
          this.cache.clear();
        }

        const values = this.formValuesQuery.getValuesByFormName('patientBudget');
        const new_site_ids = values?.site_ids?.length ? values.site_ids : site_ids;

        const remaining_site_ids = new_site_ids.filter((site_id) => {
          return !this.cache.has(
            this.cacheHash({ patient_group_id, site_id, patient_protocol_version_id })
          );
        });
        if (remaining_site_ids.length === 0 && !isRefresh) {
          this.setPaymentSchedules({
            patient_group_id,
            site_ids: new_site_ids,
            patient_protocol_version_id,
          });
          this.paymentSchedulesStore.setLoading(false);
          return of([]);
        }
        const page_input: PageInput = {
          offset: 0,
          limit: 500,
        };
        return this.gqlService
          .fetchTrialSitePaymentSchedules$({
            patient_protocol_types,
            page: page_input,
            site_ids: remaining_site_ids,
            patient_protocol_version_id,
            patient_group_id: this.filterPatientGroupId(patient_group_id),
          })
          .pipe(
            expand(({ success, data }) => {
              if (success && data) {
                if (Number.isInteger(data.next_offset) && data.next_offset >= 0) {
                  page_input.offset = data.next_offset;
                  return this.gqlService.fetchTrialSitePaymentSchedules$({
                    patient_protocol_types,
                    page: page_input,
                    site_ids: remaining_site_ids,
                    patient_protocol_version_id,
                    patient_group_id: this.filterPatientGroupId(patient_group_id),
                  });
                }
              }
              return EMPTY;
            }),
            reduce(
              (acc, curr) => {
                if (acc.data && curr.success && curr.data) {
                  acc.data = acc.data.concat(curr.data.items);
                  return acc;
                }
                return {
                  success: false,
                  data: null,
                  errors: curr.errors,
                };
              },
              {
                success: true,
                data: [] as Array<PaymentSchedulesState> | null,
                errors: [] as string[],
              }
            ),
            tap(({ data, success }) => {
              if (success && data) {
                const groupedData = groupBy(data, 'site_id');

                for (const site_id of remaining_site_ids) {
                  const site_data = groupedData[site_id] || [];
                  this.cache.set(
                    this.cacheHash({ patient_group_id, site_id, patient_protocol_version_id }),
                    site_data
                  );
                }
                this.setPaymentSchedules({
                  patient_group_id,
                  site_ids: new_site_ids,
                  patient_protocol_version_id,
                });
              }
              this.paymentSchedulesStore.setLoading(false);
            })
          );
      })
    );
  }

  private cacheHash({
    patient_group_id,
    site_id,
    patient_protocol_version_id,
  }: Omit<PaymentScheduleParams, 'site_ids'> & {
    site_id: string;
  }) {
    return `${patient_group_id}-${site_id}-${patient_protocol_version_id}`;
  }

  private setPaymentSchedules({
    patient_group_id,
    site_ids,
    patient_protocol_version_id,
  }: PaymentScheduleParams) {
    const data = flatten(
      site_ids.map((site_id) => {
        return this.cache.get(
          this.cacheHash({ patient_group_id, site_id, patient_protocol_version_id })
        );
      })
    ) as PaymentSchedulesState[];
    this.paymentSchedulesStore.set(
      data.map(
        ({ id, site_id, expense_amount, patient_protocol, note, site_budget_version_name }) => ({
          id,
          amount: expense_amount.amount,
          amount_contract: expense_amount.contract_amount,
          sps_expense_currency: expense_amount.amount_curr,
          sps_contract_expense_currency: expense_amount.contract_curr,
          note,
          site_id,
          patient_protocol_id: patient_protocol.id,
          patient_protocol_type: patient_protocol.patient_protocol_type,
          site_budget_version_name,
        })
      )
    );
  }

  async getSiteSpecificPaymentSchedules(
    site_id: string,
    patient_protocol_types: PatientProtocolType[],
    patient_protocol_version_id?: string
  ) {
    const { success, data, errors } = await firstValueFrom(
      this.gqlService.listSitePaymentSchedules$(
        patient_protocol_types,
        site_id,
        patient_protocol_version_id
      )
    );
    this.paymentSchedulesStore.remove(() => true);

    if (success && data) {
      this.paymentSchedulesStore.add(
        data.map(({ id, expense_amount, patient_protocol, note }) => ({
          id,
          amount: expense_amount.amount,
          site_id,
          note,
          patient_protocol_id: patient_protocol.id,
          patient_protocol_type: patient_protocol.patient_protocol_type,
        }))
      );
    }

    return {
      success,
      data,
      errors,
    };
  }

  add(paymentSchedule: PaymentSchedulesModel) {
    this.paymentSchedulesStore.add(paymentSchedule);
  }

  remove(id: string) {
    this.paymentSchedulesStore.remove(id);
  }

  async update(upsertData: UpdateSitePaymentScheduleInput[], site_id: string) {
    const allErrors: string[][] = [];
    const proms: Promise<GraphqlResponse<updateSitePaymentScheduleMutation>>[] = [];

    for (const mil of upsertData) {
      const { patient_protocol_id, note, amount, id } = mil;
      if (id) {
        proms.push(
          firstValueFrom(
            this.gqlService
              .updateSitePaymentSchedule$({
                id,
                patient_protocol_id,
                note,
                amount,
              })
              .pipe(
                tap(({ success, errors, data }) => {
                  if (success && data) {
                    this.paymentSchedulesStore.update(data.id, {
                      ...data,
                      amount: data.expense_amount.amount,
                    });
                  } else {
                    allErrors.push(errors);
                  }
                })
              )
          )
        );
      } else {
        if (amount && Utils.isNumber(amount)) {
          proms.push(
            firstValueFrom(
              this.gqlService
                .createSitePaymentSchedule$({
                  site_id,
                  note,
                  amount: amount || 0,
                  patient_protocol_id: patient_protocol_id || '',
                  site_budget_version_id: '', // empty string since it's calling only in  site modal that deprecated
                })
                .pipe(
                  tap(({ success, errors, data }) => {
                    if (success && data) {
                      const { id: payment_schedule_id, expense_amount, patient_protocol } = data;
                      this.paymentSchedulesStore.add({
                        id: payment_schedule_id,
                        amount: expense_amount.amount,
                        site_id,
                        note,
                        patient_protocol_id: patient_protocol.id,
                        patient_protocol_type: patient_protocol.patient_protocol_type,
                      });
                    } else {
                      allErrors.push(errors);
                    }
                  })
                )
            )
          );
        }
      }
    }

    await batchPromises(proms, (p) => p);

    if (allErrors.length) {
      this.overlayService.error(...allErrors);
    }
    return !!allErrors.length;
  }

  filterPatientGroupId(patientGroupId: string): string | null {
    if (patientGroupId === 'visits_costs' || patientGroupId === 'invoiceables') {
      return null;
    }

    return patientGroupId;
  }
}
