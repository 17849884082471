import { Component, Input } from '@angular/core';
import { SiteModel } from '@models/sites/sites.store';
import { Utils } from '@services/utils';

interface ExtendedSite extends SiteModel {
  investigator_name?: string;
  primary_investigator_name: string;
  country_name: string;
  address_2: string;
  address_3: string;
}

@Component({
  selector: 'aux-site-information-view',
  templateUrl: './site-information-view.component.html',
})
export class SiteInformationViewComponent {
  @Input() site!: ExtendedSite;

  addressFields: (keyof ExtendedSite)[] = ['country_name', 'address_2', 'address_3'];

  zeroHyphen = Utils.zeroHyphen;
}
