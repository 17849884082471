import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ICellRendererParams } from '@ag-grid-community/core';

export interface AgControlComponentParams extends ICellRendererParams {
  formGroup: UntypedFormGroup;
  changeHandler: (event?: unknown) => void;
  size?: 'compact' | 'auto';
}

export const noop = () => {};

@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AgControlComponent implements ICellRendererAngularComp {
  params!: AgControlComponentParams;

  formGroup$ = new BehaviorSubject<UntypedFormGroup | null>(null);

  formControl!: UntypedFormControl;

  isReady$ = new BehaviorSubject(false);

  protected value!: string;

  fieldName?: string;

  get hasError(): boolean {
    return this.formControl.errors !== null && this.formControl.touched;
  }

  get controlHeightClass() {
    return this.params.size === 'compact' ? `h-9` : '';
  }

  changeHandler: (event?: unknown) => void = () => {};

  agInit(params: AgControlComponentParams) {
    this.value = params.value;
    this.fieldName = params.colDef?.field;
    this.changeHandler = params.changeHandler;

    this.updateView(params);
  }

  protected updateView(params: ICellRendererParams) {
    const entityId = params?.data?.id;

    if (this.fieldName && params.context?.formGroup?.controls?.table) {
      this.formGroup$.next(params.context.formGroup);

      const tableControls = params.context.formGroup.controls.table.controls;
      const rowControl = tableControls.find(
        (control: UntypedFormGroup) => control.value.id === entityId
      );

      if (tableControls && rowControl) {
        this.formControl = rowControl.controls[this.fieldName];
        this.isReady$.next(true);
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateView(params);

    return true;
  }
}
