import { EventQuery } from '@models/event/event.query';
import {
  Component,
  computed,
  DestroyRef,
  effect,
  HostListener,
  inject,
  OnDestroy,
  signal,
  untracked,
} from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  Column,
  EditableCallbackParams,
  FillOperationParams,
  GetQuickFilterTextParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
  ModelUpdatedEvent,
  RowSelectedEvent,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { OverlayService } from '@services/overlay.service';
import { StickyElementService } from '@services/sticky-element.service';
import { filter, startWith, switchMap } from 'rxjs/operators';
import {
  AmountType,
  ApprovalType,
  Currency,
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  InvoiceDataStream,
  InvoiceStatus,
  listVendorAmountTypesQuery,
  PermissionType,
  TrialImplementationStatus,
  WorkflowStep,
} from '@services/gql.service';
import { ArrElement, ExportType, Utils } from '@services/utils';
import { ApiService } from '@services/api.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Subject, Subscription } from 'rxjs';
import { MainQuery } from '@shared/store/main/main.query';
import { EventService } from 'src/app/services/event.service';
import { AuthQuery } from '@shared/store/auth/auth.query';

import dayjs from 'dayjs';
import { AgDatePickerComponent } from '@components/datepicker/ag-date-picker/ag-date-picker.component';
import { cloneDeep, differenceWith, get, isEqual, isString, omit } from 'lodash-es';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AuthService } from '@shared/store/auth/auth.service';
import { TableConstants } from '@constants/table.constants';
import { MessagesConstants } from '@constants/messages.constants';
import {
  AgInvoiceActionsComponent,
  AgInvoiceActionsComponentParamsInput,
} from './ag-invoice-actions/ag-invoice-actions.component';
import { NewInvoiceDialogComponent } from './new-invoice-dialog/new-invoice-dialog.component';
import { UploadDocumentsDialogComponent } from './upload-documents-dialog/upload-documents-dialog.component';
import { InvoiceService, isInvoiceTotalEqual } from './state/invoice.service';
import { InvoicesStatusComponent } from './invoices-status.component';
import { PaymentStatusComponent } from './payment-status.component';
import { AgHeaderActionsComponent } from './ag-invoice-actions/ag-header-actions.component';
import { InvoicesGridFormatterService } from './invoices-grid-formatter.service';
import { InvoiceModel } from './state/invoice.model';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import {
  BulkEditInvoicesComponent,
  BulkEditInvoicesData,
  BulkEditInvoicesResponse,
} from '@pages/vendor-payments-page/tabs/invoices/bulk-edit-invoices.component';
import { OrganizationQuery } from '@models/organization/organization.query';
import { DocumentLibraryFile } from 'src/app/pages/documents/document-library.service';
import {
  AgSetColumnsVisible,
  batchPromises,
  ServerSideColumnFilterType,
  ServerSideFilterInfo,
  ServerSideSortOrder,
} from '@shared/utils';
import { BudgetCurrencyType } from '@pages/budget-page/tabs/budget-enhanced/budget-type';
import { AgCheckboxRendererComponent } from '@components/ag-actions/ag-checkbox-renderer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AgHtmlHeaderComponent } from '@components/ag-html-header/ag-html-header.component';
import { AsyncPipe } from '@angular/common';
import { CheckboxComponent } from '@components/checkbox/checkbox.component';
import { InvoicesSyncButtonsComponent } from './invoices-buttons/invoices-sync-buttons.component';
import { ButtonComponent } from '@components/button/button.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { InvoiceFiltersComponent } from './invoice-filters/invoice-filters.component';
import { ToggleBudgetCurrencyComponent } from '@pages/budget-page/tabs/budget-enhanced/toggle-budget-currency.component';
import { PaginationPanelComponent } from '@shared/components/pagination-panel/pagination-panel.component';
import { Option } from '@components/components.type';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';
import { ComponentsModule } from '@components/components.module';
import { DatasourceService } from '@shared/services/datasource.service';
import { SelectionChangedEvent } from '@ag-grid-community/core/dist/esm/es6/events';
import { PaginationGridComponent } from '@shared/components/pagination-grid/pagination-grid.component';
import { AgIconLinkCellComponent } from '@shared/components/ag-icon-link-cell/ag-icon-link-cell.component';
import { NewValueParams } from '@ag-grid-community/core/dist/esm/es6/entities/colDef';
import { OrganizationService } from '@models/organization/organization.service';

const getQueryParamData = () => {
  const router = inject(Router);
  const route = inject(ActivatedRoute);
  const invoiceService = inject(InvoiceService);

  route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
    if (params['accrualPeriod']) {
      const accrualPeriod = dayjs(params['accrualPeriod']).format('YYYY-MM-DD');
      invoiceService.setAccrualPeriodsAndVendorFilter([accrualPeriod]);
      router.navigate([], { queryParams: {}, replaceUrl: true });
    }
  });
};

export const invoiceDisabledTooltip = 'Cannot edit invoice in closed month.';

interface IInvoicesGridData extends InvoiceModel {
  file?: DocumentLibraryFile;
}

type ErrorRow = {
  causes: ('accrual_period' | 'payment_date' | 'totals')[];
  invoice: IInvoicesGridData;
};

@Component({
  selector: 'aux-invoices',
  templateUrl: './invoices.component.html',
  styles: [
    `
      :host ::ng-deep .ag-cell.aux-link {
        color: var(--aux-blue);
      }
      :host ::ng-deep .ag-cell.aux-link-zero-hyphen {
        color: var(--aux-blue);
      }
    `,
  ],
  standalone: true,
  imports: [
    WorkflowPanelComponent,
    AsyncPipe,
    CheckboxComponent,
    InvoicesSyncButtonsComponent,
    ButtonComponent,
    TooltipDirective,
    InvoiceFiltersComponent,
    ToggleBudgetCurrencyComponent,
    AgGridAngular,
    PaginationPanelComponent,
    ComponentsModule,
    PaginationGridComponent,
  ],
})
export class InvoicesComponent implements OnDestroy {
  apiService = inject(ApiService);
  overlayService = inject(OverlayService);
  mainQuery = inject(MainQuery);
  invoiceService = inject(InvoiceService);
  eventService = inject(EventService);
  authQuery = inject(AuthQuery);
  authService = inject(AuthService);
  organizationQuery = inject(OrganizationQuery);
  gqlService = inject(GqlService);
  invoicesGridFormatterService = inject(InvoicesGridFormatterService);
  workflowQuery = inject(WorkflowQuery);
  stickyElementService = inject(StickyElementService);
  launchDarklyService = inject(LaunchDarklyService);
  eventQuery = inject(EventQuery);
  datasourceService = inject(DatasourceService);
  organizationService = inject(OrganizationService);
  destroyRef = inject(DestroyRef);

  readonly datasource = this.datasourceService.invoiceLibraryDatasource;

  // constants
  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES;
  checkBoxTooltip = 'Please click Edit button in top right corner to edit invoice data.';

  // signals
  selectedBudgetCurrencyType = signal<BudgetCurrencyType>(BudgetCurrencyType.VENDOR);
  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;
  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);
  isInvoiceFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName);
  invoiceLockTooltip = this.workflowQuery.invoiceLockTooltip;
  editMode = signal(false);
  savingChanges = signal(false);
  paginationPageSize = signal(10);
  errorRows = signal<Record<string, ErrorRow>>({});

  // permissions
  userHasLockInvoicesPermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_CHECKLIST_INVOICES],
  });
  userHasEditInvoicePermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });
  userHasDeleteInvoicePermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_DELETE_INVOICE],
  });
  userHasApproveInvoicePermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_APPROVE_INVOICE],
  });

  gridAPI = signal<GridApi | null>(null);
  selectedRows = signal<InvoiceModel[]>([]);
  rowsToDelete = signal<{ invoice: InvoiceModel; reason: string }[]>([]);
  hasChanges = signal(false);
  showErrors = signal(false);
  apiErrors = signal<string[]>([]);

  // feature flags
  showInvoicesPaymentStatus = this.launchDarklyService.$select(
    (flags) => flags.invoices_payment_status
  );
  showInvoicesPaymentDate = this.launchDarklyService.$select(
    (flags) => flags.invoices_payment_date
  );
  showInvoiceDetailLink = this.launchDarklyService.$select((flags) => flags.invoice_detail);
  vendorCurrencyEnabled = this.launchDarklyService.$select((flags) => flags.vendor_currency);
  invoicesDepositsEnabled = this.launchDarklyService.$select((flags) => flags.invoices_deposits);
  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );

  // Computed
  isVendorCurrency = computed(() => {
    return this.selectedBudgetCurrencyType() === BudgetCurrencyType.VENDOR;
  });
  editButtonTooltip = computed(() => {
    const permissionTooltip = this.userHasEditInvoicePermission()
      ? ''
      : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    return this.invoiceLockTooltip() || permissionTooltip;
  });

  refresh$ = new Subject<void>();

  files = new Map<string, DocumentLibraryFile | null>();
  gridOptions = this.getGridOptions();
  savingChanges$ = toObservable(this.savingChanges);
  initialValues: IInvoicesGridData[] = [];

  showRequireAccrualPeriod = false;

  showInvalidCostCategorization = false;

  showRequireCostBreakdown = false;

  serverSideFilters: ServerSideFilterInfo<InvoiceDataStream>[] = [
    {
      column: 'invoice_no',
      type: ServerSideColumnFilterType.Contains,
      inputPropertyName: 'search',
      transformFunction: (v: unknown) => (typeof v === 'string' ? v.trim() : v),
    },
    {
      column: 'vendor_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'vendors',
    },
    {
      column: 'invoice_date',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'invoiceDate',
    },
    {
      column: 'accrual_period',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'accrualPeriod',
    },
  ];

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  sortModel$ = new BehaviorSubject<Array<ServerSideSortOrder<InvoiceDataStream>>>([]);

  isAllRowsOnCurrentPageSelected = false;

  vendorCostCategories: listVendorAmountTypesQuery[] = [];

  vendorHasOneCurrency = true;
  vendorHasOneCurrencySubscription: Subscription | undefined;

  constructor() {
    // vendor currency effect
    effect(() => {
      const gridAPI = this.gridAPI();
      if (!gridAPI) {
        return;
      }

      /*
        if the investigator total column is showing then the breakdown values are visible
        which means we want to keep the trial currency values hidden if the user toggles to trial currency
      */
      if (gridAPI.getColumn('investigator_contract_amount')?.isVisible()) {
        AgSetColumnsVisible({
          gridApi: gridAPI,
          keys: [
            'vendor_currency',
            'fx_rate',
            'total_amount',
            'investigator_amount',
            'passthrough_amount',
            'service_amount',
            'discount_amount',
          ],
          visible: !this.isVendorCurrency(),
        });
      } else {
        AgSetColumnsVisible({
          gridApi: gridAPI,
          keys: ['vendor_currency', 'fx_rate', 'total_amount'],
          visible: !this.isVendorCurrency(),
        });
      }
      setTimeout(() => {
        this.invoiceService.generatePinnedRow();
      }, 0);
    });

    // is_deposit effect
    effect(() => {
      const gridAPI = this.gridAPI();
      const invoicesDepositsEnabled = this.invoicesDepositsEnabled();
      if (!gridAPI) {
        return;
      }
      gridAPI.setColumnVisible('is_deposit', invoicesDepositsEnabled);
    });

    this.invoiceService.initialize().pipe(takeUntilDestroyed()).subscribe();

    this.organizationService
      .listVendorAmountTypes()
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        if (result.success) {
          this.vendorCostCategories = result.data || [];
        }
      });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.invoiceService.setAccrualPeriodsAndVendorFilter();
      });

    this.eventService
      .select$(EventType.INVOICE_UPDATED)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.invoiceService.newInvoiceCreated$.getValue()) {
          this.refresh$.next();
          this.invoiceService.newInvoiceCreated$.next(false);
        }
      });

    merge(
      this.eventService.select$(EventType.REFRESH_BILL_COM),
      this.eventService.select$(EventType.REFRESH_COUPA),
      this.eventService.select$(EventType.REFRESH_DYNAMICS365),
      this.eventService.select$(EventType.REFRESH_DYNAMICS365_FO),
      this.eventService.select$(EventType.REFRESH_NETSUITE),
      this.eventService.select$(EventType.REFRESH_ORACLE_FUSION),
      this.eventService.select$(EventType.REFRESH_QUICKBOOKS_ONLINE),
      this.eventService.select$(EventType.REFRESH_SAGE_INTACCT),
      this.eventService.select$(EventType.BULK_INVOICE_TEMPLATE_UPLOADED),
      this.refresh$
    )
      .pipe(
        startWith(null),
        switchMap(() => this.savingChanges$),
        filter((isSavingChanges) => !isSavingChanges)
      )
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.selectedBudgetCurrencyType.set(BudgetCurrencyType.VENDOR);
        this.datasource.forceRefresh();
      });

    getQueryParamData();
  }

  modelUpdated(event: ModelUpdatedEvent): void {
    if (event.keepRenderedRows) {
      this.fetchDocumentsForPage();
      setTimeout(() => {
        this.invoiceService.generatePinnedRow();
      });
    }
  }

  async fetchDocumentsForPage() {
    const entity_ids: string[] = [];
    const gridAPI = this.gridAPI();
    if (!gridAPI) {
      return;
    }

    const rowNodes: { node: IRowNode; file: unknown }[] = [];
    gridAPI.getRenderedNodes()?.forEach((node) => {
      if (node.data?.id) {
        if (this.files.has(node.data.id)) {
          const file = this.files.get(node.data.id);
          if (file) {
            rowNodes.push({ node, file });
          }
        } else {
          entity_ids.push(node.data.id);
        }
      }
    });

    // update the rows in the next tick
    setTimeout(() => {
      rowNodes.forEach((row) => {
        row.node.setData({ ...row.node.data, file: row.file });
      });
    }, 0);

    const trialKey = this.mainQuery.getValue().trialKey;

    if (!entity_ids.length) {
      return;
    }

    try {
      const [invoice_docs, supporting_docs] = await Promise.all([
        this.apiService.getFilesByFilters(
          `trials/${trialKey}/vendors/`,
          undefined,
          EntityType.INVOICE,
          DocumentType.DOCUMENT_INVOICE,
          entity_ids
        ),
        this.apiService.getFilesByFilters(
          `trials/${trialKey}/vendors/`,
          undefined,
          EntityType.INVOICE,
          DocumentType.DOCUMENT_INVOICE_SUPPORT,
          entity_ids
        ),
      ]);

      const combinedDocuments = [...invoice_docs, ...supporting_docs];
      entity_ids.forEach((id) => {
        const rowNode = gridAPI.getRowNode(id);
        if (rowNode) {
          const file = combinedDocuments.find((f) => f.entity_id?.includes(id));
          rowNode.setData({ ...rowNode.data, file });
          this.files.set(id, file || null);
          gridAPI.redrawRows({ rowNodes: [rowNode] });
        }
      });
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }

  ngOnDestroy(): void {
    this.invoiceService.filtersForm.enable();
    this.stickyElementService.reset();
  }

  onEditModeOn(): void {
    this.invoiceService.filtersForm.disable();
    this.gridAPI()?.startEditingCell({
      rowIndex: 0,
      colKey: 'invoice_no',
    });
    this.gridAPI()?.setGridOption('defaultColDef', {
      ...this.gridOptions().defaultColDef,
      sortable: false,
    });
    this.gridAPI()?.setColumnVisible('checkbox', true);
    this.gridAPI()?.refreshCells({ force: true });
  }

  onEditModeOff(): void {
    this.invoiceService.filtersForm.enable();
    this.gridAPI()?.setGridOption('defaultColDef', {
      ...this.gridOptions().defaultColDef,
      sortable: true,
    });
    this.gridAPI()?.setColumnVisible('checkbox', false);
    this.gridAPI()?.refreshCells({ force: true });
  }

  getGridOptions() {
    return computed(() => {
      const showInvoicesPaymentStatus = this.showInvoicesPaymentStatus();
      const showInvoicesPaymentDate = this.showInvoicesPaymentDate();
      const userHasApproveInvoicePermission = this.userHasApproveInvoicePermission();
      const showInvoiceDetailLink = this.showInvoiceDetailLink();
      const invoicesDepositsEnabled = this.invoicesDepositsEnabled();

      return untracked(() => {
        return <GridOptions>{
          pagination: true,
          paginationPageSize: 10,
          suppressPaginationPanel: true,
          getRowId: ({ data }) => data.id,
          cacheBlockSize: 250,
          tooltipShowDelay: 0,
          isRowSelectable: (rowNode: IRowNode<IInvoicesGridData>) => {
            const status = this.mainQuery.getSelectedTrial()?.implementation_status;

            const data = rowNode.data;
            const originalData = this.initialValues.find((v) => v.id === data?.id);

            if (!status || !originalData) {
              return true;
            }

            if (
              status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED &&
              status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
            ) {
              return true;
            }

            if (originalData.accrual_period) {
              return !dayjs(`${originalData.accrual_period}-01`).isBefore(
                this.mainQuery.getValue().currentOpenMonth
              );
            }

            return true;
          },
          defaultColDef: {
            ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
            resizable: true,
            editable: (params) =>
              this.editMode() &&
              !params.node.rowPinned &&
              this.isRowEditable(params) &&
              !this.savingChanges(),
          },
          ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          enableRangeSelection: true,
          suppressCellFocus: false,
          suppressMenuHide: true,
          fillHandleDirection: 'y',
          onModelUpdated: (event: ModelUpdatedEvent) => {
            if (event.api.getDisplayedRowCount() === 0) {
              event.api.showNoRowsOverlay();
            } else {
              event.api.hideOverlay();
            }
          },
          fillOperation: (params: FillOperationParams) => {
            if (
              params.column.getColId() === 'accrual_period' ||
              params.column.getColId() === 'payment_date' ||
              params.column.getColId() === 'invoice_date' ||
              params.column.getColId() === 'due_date'
            ) {
              return params.values[0];
            }
            return false;
          },
          columnDefs: [
            {
              field: 'checkbox',
              checkboxSelection: true,
              headerCheckboxSelection: true,
              maxWidth: 35,
              width: 35,
              minWidth: 35,
              hide: true,
              suppressFillHandle: true,
              cellClass: 'relative',
              cellRendererSelector: (params) =>
                params.node.selectable
                  ? undefined
                  : {
                      component: AgHtmlHeaderComponent,
                      params: {
                        template: `<div class="absolute left-[10px] top-0"><span class="ag-icon ag-icon-checkbox-unchecked !text-[var(--ag-input-disabled-border-color)]"></span></div>`,
                        tooltip:
                          'Invoices in closed months cannot be selected for Bulk Edit or Delete functionality.',
                      },
                    },
              editable: false,
              sortable: false,
            },
            {
              headerName: 'Files',
              field: 'file',
              cellRendererSelector: (params: ICellRendererParams) => {
                if (params.node.rowPinned) {
                  return { component: Utils.getCellWrapper('ag-cell-value', 'value') };
                }
                return {
                  component: AgInvoiceActionsComponent,
                  params: <AgInvoiceActionsComponentParamsInput>{
                    downloadClickFN: ({ rowNode }: { rowNode: IRowNode }) => {
                      this.invoiceService.downloadInvoiceItems(rowNode);
                    },
                    downloadLinesClickFN: ({ rowNode }: { rowNode: IRowNode }) => {
                      this.invoiceService.downloadInvoiceLines(rowNode);
                    },
                    uploadClickFN: async (uploadParams: {
                      rowNode: IRowNode;
                      instance: AgInvoiceActionsComponent;
                    }) => {
                      const { rowNode, instance } = uploadParams;

                      const filesSaved = await firstValueFrom(
                        this.overlayService.open<unknown[]>({
                          content: UploadDocumentsDialogComponent,
                          data: { invoice: rowNode.data },
                        }).afterClosed$
                      );
                      instance.refreshFileState(filesSaved.data);
                      instance.params.api.refreshCells();
                    },
                    isInvoiceFinalized: this.isInvoiceFinalized,
                    invoiceLockTooltip: this.invoiceLockTooltip,
                    iCloseMonthsProcessing: this.iCloseMonthsProcessing,
                  },
                };
              },
              width: 100,
              editable: false,
              suppressSizeToFit: true,
              cellClass: [
                TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
                TableConstants.STYLE_CLASSES.EDITABLE_CELL,
              ],
              suppressFillHandle: true,
              sortable: false,
            },
            {
              headerName: 'Deposit',
              field: 'is_deposit',
              colId: 'is_deposit',
              width: 100,
              hide: !invoicesDepositsEnabled,
              suppressSizeToFit: true,
              cellClass: this.getEditableClass([TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER]),
              suppressFillHandle: true,
              tooltipValueGetter: this.tooltipValueGetter(''),
              cellRenderer: AgCheckboxRendererComponent,
              cellRendererParams: (params: ICellRendererParams) => ({
                getDisabledState: () =>
                  !this.editMode() || !this.isRowEditable(params as EditableCallbackParams),
                dontSelectRow: true,
                isHidden: params.node.rowPinned === 'bottom',
                tooltip: this.editMode()
                  ? ''
                  : this.isRowEditable(params as EditableCallbackParams)
                    ? this.checkBoxTooltip
                    : '',
              }),
              editable: false,
            },
            {
              headerName: 'Invoice #',
              headerTooltip: 'Invoice #',
              field: 'invoice_no',
              colId: 'invoice_no',
              valueFormatter: Utils.dashFormatter,
              onCellClicked: (event: CellClickedEvent) => {
                if (!this.editMode() && showInvoiceDetailLink) {
                  this.invoiceService.goToInvoiceDetail(event);
                }
              },
              cellClass: (value) => {
                const classes: string[] = ['text-left'];
                if (!value.node.rowPinned) {
                  classes.push(...this.getEditableClass()(value));

                  if (showInvoiceDetailLink) {
                    const editable = value.column.isCellEditable(value.node);
                    if (this.editMode() ? editable : true) {
                      classes.push('cursor-pointer');
                    }
                    if (value.data.invoice_no !== null) {
                      classes.push('aux-link');
                    } else {
                      classes.push('aux-link-zero-hyphen');
                    }
                  }
                }
                return classes;
              },
              cellRenderer: AgIconLinkCellComponent,
              cellRendererParams: (params: ICellRendererParams) => {
                return {
                  showIcon: this.hasInvoiceCategoryOutsideBudget(params),
                  icon: 'AlertTriangle',
                  iconClass: 'text-aux-red-dark',
                  iconTooltip: MessagesConstants.INVOICE.CATEGORY_IS_NOT_IN_BUDGET,
                };
              },
              minWidth: 175,
              width: 175,
            },
            {
              headerName: 'Vendor',
              headerTooltip: 'Vendor',
              field: 'organization',
              colId: 'vendor_id',
              tooltipValueGetter: this.tooltipValueGetter('data.organization.name'),
              minWidth: 175,
              width: 175,
              valueFormatter: Utils.dashFormatter,
              cellClass: this.getEditableClass(['text-left']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              valueGetter: (params: ValueGetterParams) => {
                return params.data?.organization?.name || '';
              },
              valueSetter: this.invoiceService.vendorValueSetter,
              cellEditor: 'agRichSelectCellEditor',
              onCellValueChanged: (params) => {
                if (params.oldValue !== params.newValue) {
                  params.data.organization.currency = this.organizationQuery.getVendor(
                    params.data.organization.id
                  )[0].currency;
                  params.api.refreshCells({ columns: ['organization.currency'] });
                  this.onCellValueChanged(params);
                }
              },
              cellEditorParams: () => {
                return {
                  values: this.invoiceService.vendorOptions,
                  formatValue: (value?: Option | string) => {
                    return isString(value) ? value : value?.label || '';
                  },
                  cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
                };
              },
              filterValueGetter: (params: ValueGetterParams) => params.data?.organization?.name,
            },
            {
              headerName: 'Accrual Period',
              headerTooltip: 'Accrual Period',
              field: 'accrual_period',
              colId: 'accrual_period',
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueFormatter: (params: ValueFormatterParams) => {
                return params.value && params.data.invoice_status !== InvoiceStatus.STATUS_DECLINED
                  ? dayjs(params.value).format('MMMM YYYY')
                  : Utils.zeroHyphen;
              },
              getQuickFilterText(params: GetQuickFilterTextParams) {
                return params.value ? dayjs(params.value).format('MMMM YYYY') : '';
              },
              cellClass: this.getEditableClass(['text-right']),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'accrual_period'),
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              minWidth: 195,
              width: 195,
              suppressSizeToFit: true,
              cellEditor: AgDatePickerComponent,
              cellEditorParams: (params: ICellEditorParams) => ({
                type: 'month',
                ignoreValidations: true,
                value: params.value || this.invoiceService.trialMonthClose,
              }),
            },
            {
              headerName: 'Vendor Currency',
              headerTooltip: 'Vendor Currency',
              field: 'organization.currency',
              colId: 'vendor_currency',
              tooltipValueGetter: this.tooltipValueGetter('data.organization.currency'),
              minWidth: 175,
              width: 175,
              valueFormatter: Utils.dashFormatter,
              cellClass: this.getEditableClass(['text-left']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              valueGetter: (params: ValueGetterParams) => {
                return params.data?.organization?.currency || '';
              },
              hide: true,
              editable: false,
            },
            {
              headerName: 'Exchange Rate',
              headerTooltip: 'Exchange Rate',
              field: 'expense_amounts.invoice_total_trial_currency.exchange_rate',
              colId: 'fx_rate',
              tooltipValueGetter: this.tooltipValueGetter(
                'data.expense_amounts.invoice_total_trial_currency.exchange_rate'
              ),
              minWidth: 175,
              width: 175,
              valueFormatter: Utils.dashFormatter,
              cellClass: this.getEditableClass(['text-right']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              valueGetter: (params: ValueGetterParams) => {
                return (
                  params.data?.expense_amounts.invoice_total_trial_currency.exchange_rate || ''
                );
              },
              hide: true,
              editable: false,
            },
            {
              headerName: 'Total Amount',
              headerTooltip: 'Total Amount',
              field: 'expense_amounts.invoice_total.value',
              colId: 'total_contract_amount',
              valueFormatter: (params) => {
                return Utils.agCurrencyFormatter(
                  params,
                  params.data.expense_amounts.invoice_total.currency ||
                    params.data.organization.currency
                );
              },
              headerComponent: AgHeaderActionsComponent,
              minWidth: 150,
              width: 150,
              cellClass: (params) => {
                const cls = params.data.organization.currency
                  ? `budgetCost${params.data.organization.currency}`
                  : 'budgetCostNoSymbol';

                return this.getEditableClass([cls])(params);
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'totals'),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Total Amount (USD)',
              headerTooltip: 'Total Amount (USD)',
              field: 'expense_amounts.invoice_total_trial_currency.value',
              colId: 'total_amount',
              valueFormatter: Utils.agCurrencyFormatter,
              minWidth: 150,
              width: 150,
              cellClass: this.getEditableClass(['text-right', 'cost']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              hide: true,
              editable: false,
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'totals'),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Services Total',
              headerTooltip: 'Services Total',
              field: 'expense_amounts.services_total.value',
              colId: 'service_contract_amount',
              valueFormatter: (params) => {
                return Utils.agCurrencyFormatter(
                  params,
                  params.data.expense_amounts.services_total.currency ||
                    params.data.organization.currency
                );
              },
              minWidth: 125,
              width: 125,
              cellClass: (params) => {
                const cls = params.data.organization.currency
                  ? `budgetCost${params.data.organization.currency}`
                  : 'budgetCostNoSymbol';

                return this.getEditableClass([cls])(params);
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params: NewValueParams) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) =>
                  this.checkError(params, 'totals') ||
                  (this.editMode() && this.isCategoryOutsideBudget(params, 'services_total')),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Discount Total',
              headerTooltip: 'Discount Total',
              field: 'expense_amounts.discount_total.value',
              colId: 'discount_contract_amount',
              valueFormatter: (params) => {
                return Utils.agCurrencyFormatter(
                  params,
                  params.data.expense_amounts.discount_total.currency ||
                    params.data.organization.currency
                );
              },
              minWidth: 125,
              width: 125,
              cellClass: (params) => {
                const cls = params.data.organization.currency
                  ? `budgetCost${params.data.organization.currency}`
                  : 'budgetCostNoSymbol';

                return this.getEditableClass([cls])(params);
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) =>
                  this.checkError(params, 'totals') ||
                  (this.editMode() && this.isCategoryOutsideBudget(params, 'discount_total')),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Investigator Total',
              headerTooltip: 'Investigator Total',
              field: 'expense_amounts.investigator_total.value',
              colId: 'investigator_contract_amount',
              valueFormatter: (params) => {
                return Utils.agCurrencyFormatter(
                  params,
                  params.data.expense_amounts.investigator_total.currency ||
                    params.data.organization.currency
                );
              },
              minWidth: 150,
              width: 150,
              cellClass: (params) => {
                const cls = params.data.organization.currency
                  ? `budgetCost${params.data.organization.currency}`
                  : 'budgetCostNoSymbol';
                return this.getEditableClass([cls])(params);
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) =>
                  this.checkError(params, 'totals') ||
                  (this.editMode() && this.isCategoryOutsideBudget(params, 'investigator_total')),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Pass-Through Total',
              headerTooltip: 'Pass-Through Total',
              field: 'expense_amounts.pass_thru_total.value',
              colId: 'passthrough_contract_amount',
              valueFormatter: (params) => {
                return Utils.agCurrencyFormatter(
                  params,
                  params.data.expense_amounts.pass_thru_total.currency ||
                    params.data.organization.currency
                );
              },
              minWidth: 160,
              width: 160,
              cellClass: (params) => {
                const cls = params.data.organization.currency
                  ? `budgetCost${params.data.organization.currency}`
                  : 'budgetCostNoSymbol';
                return this.getEditableClass([cls])(params);
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) =>
                  this.checkError(params, 'totals') ||
                  (this.editMode() && this.isCategoryOutsideBudget(params, 'pass_thru_total')),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Services Total (USD)',
              headerTooltip: 'Services Total',
              field: 'expense_amounts.services_total_trial_currency.value',
              colId: 'service_amount',
              valueFormatter: Utils.agCurrencyFormatter,
              hide: true,
              editable: false,
              minWidth: 125,
              width: 125,
              cellClass: this.getEditableClass(['text-right', 'cost']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'totals'),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Discount Total (USD)',
              headerTooltip: 'Discount Total',
              field: 'expense_amounts.discount_total_trial_currency.value',
              colId: 'discount_amount',
              valueFormatter: Utils.agCurrencyFormatter,
              hide: true,
              editable: false,
              minWidth: 125,
              width: 125,
              cellClass: this.getEditableClass(['text-right', 'cost']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'totals'),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Investigator Total (USD)',
              headerTooltip: 'Investigator Total',
              field: 'expense_amounts.investigator_total_trial_currency.value',
              colId: 'investigator_amount',
              valueFormatter: Utils.agCurrencyFormatter,
              hide: true,
              editable: false,
              minWidth: 150,
              width: 150,
              cellClass: this.getEditableClass(['text-right', 'cost']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'totals'),
              },
              cellDataType: 'number',
            },
            {
              headerName: 'Pass-Through Total (USD)',
              headerTooltip: 'Pass-Through Total',
              field: 'expense_amounts.pass_thru_total_trial_currency.value',
              colId: 'passthrough_amount',
              valueFormatter: Utils.agCurrencyFormatter,
              hide: true,
              editable: false,
              minWidth: 160,
              width: 160,
              cellClass: this.getEditableClass(['text-right', 'cost']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              valueParser: (params) => this.invoiceService.setCostValue(params),
              onCellValueChanged: (params) => this.onCellValueChanged(params),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'totals'),
              },
              cellDataType: 'number',
            },
            showInvoicesPaymentDate && {
              headerName: 'Payment Date',
              headerTooltip: 'Payment Date',
              field: 'payment_date',
              colId: 'payment_date',
              valueFormatter: Utils.agDateFormatter,
              minWidth: 170,
              width: 170,
              suppressSizeToFit: true,
              cellClass: this.getEditableClass(['text-right']),
              onCellValueChanged: this.onCellValueChanged.bind(this),
              cellClassRules: {
                'border-aux-error': (params) => this.checkError(params, 'payment_date'),
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              cellEditor: AgDatePickerComponent,
              cellEditorParams: {
                ignoreValidations: true,
              },
            },
            showInvoicesPaymentStatus && {
              headerName: 'Payment Status',
              headerTooltip: 'Payment Status',
              field: 'payment_status',
              colId: 'payment_status',
              cellRenderer: PaymentStatusComponent,
              minWidth: 175,
              maxWidth: 175,
              getQuickFilterText: (params: GetQuickFilterTextParams) =>
                this.invoicesGridFormatterService.getFormattedPaymentStatus(params.value),
              cellClass: this.getEditableClass(['text-left']),
              tooltipValueGetter: (params) => {
                return (
                  this.tooltipValueGetter('')(params) ||
                  this.invoicesGridFormatterService.getFormattedPaymentStatus(
                    params.data.payment_status
                  )
                );
              },
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: () => {
                return {
                  values: this.invoiceService.paymentStatusOptions,
                  cellRenderer: PaymentStatusComponent,
                };
              },
            },
            {
              headerName: 'Invoice Date',
              headerTooltip: 'Invoice Date',
              field: 'invoice_date',
              colId: 'invoice_date',
              valueFormatter: Utils.agDateFormatter,
              minWidth: 170,
              width: 170,
              suppressSizeToFit: true,
              cellClass: this.getEditableClass(['text-right']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              cellEditor: AgDatePickerComponent,
              cellEditorParams: {
                ignoreValidations: true,
              },
            },
            {
              headerName: 'Status',
              headerTooltip: 'Status',
              field: 'invoice_status',
              colId: 'invoice_status',
              minWidth: 200,
              width: 200,
              cellRenderer: InvoicesStatusComponent,
              tooltipValueGetter: (params) => {
                return (
                  this.tooltipValueGetter('')(params) ||
                  this.invoicesGridFormatterService.getFormattedInvoiceStatus(params.value)
                );
              },
              getQuickFilterText: (params: GetQuickFilterTextParams) =>
                this.invoicesGridFormatterService.getFormattedInvoiceStatus(params.value),
              cellClass: this.getEditableClass(['text-left']),
              cellEditor: 'agRichSelectCellEditor',
              suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
                return ['Backspace', 'Delete'].includes(params.event.key);
              },
              editable: (params) =>
                this.editMode() &&
                !params.node.rowPinned &&
                this.isRowEditable(params) &&
                !this.savingChanges() &&
                (userHasApproveInvoicePermission || this.authQuery.isAuxAdmin()),
              suppressFillHandle: !(userHasApproveInvoicePermission || this.authQuery.isAuxAdmin()),
              cellEditorParams: () => {
                return {
                  values: this.invoiceService.invoiceStatusOptions,
                  cellRenderer: InvoicesStatusComponent,
                };
              },
            },
            {
              headerName: 'Due Date',
              headerTooltip: 'Due Date',
              field: 'due_date',
              colId: 'due_date',
              valueFormatter: Utils.agDateFormatter,
              minWidth: 170,
              width: 170,
              suppressSizeToFit: true,
              cellClass: this.getEditableClass(['text-right']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              cellEditor: AgDatePickerComponent,
            },
            {
              headerName: 'PO#',
              headerTooltip: 'PO#',
              field: 'po_reference',
              colId: 'po_reference',
              cellClass: this.getEditableClass([
                'align-right',
                TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
              ]),
              minWidth: 100,
              width: 100,
              suppressFillHandle: true,
              suppressPaste: true,
              valueFormatter: (params: ValueFormatterParams) =>
                this.invoicesGridFormatterService.getFormattedPurchaseOrder(params.value),
              tooltipValueGetter: this.tooltipValueGetter('valueFormatted'),
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: (params: ICellEditorParams) => {
                const options = this.invoiceService.purchaseOrdersQuery
                  .getAll()
                  .filter((order) => order.organization?.id === params.data.organization.id)
                  .map(({ id }) => id);

                return {
                  values: [null, ...options],
                  cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
                };
              },
              getQuickFilterText: (params: GetQuickFilterTextParams) =>
                this.invoicesGridFormatterService.getFormattedPurchaseOrder(params.value),
            },
            {
              headerName: 'Date Imported',
              headerTooltip: 'Date Imported',
              field: 'create_date',
              colId: 'create_date',
              valueFormatter: Utils.agDateFormatter,
              getQuickFilterText: Utils.agDateFormatter,
              sort: 'desc',
              minWidth: 120,
              width: 120,
              cellClass: this.getEditableClass(['text-right']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: (params) => params.valueFormatted,
              editable: false,
              suppressFillHandle: true,
            },
            {
              headerName: 'Created by',
              headerTooltip: 'Created by',
              field: 'created_by',
              colId: 'created_by',
              valueFormatter: (val: ValueFormatterParams) =>
                val.node?.rowPinned
                  ? Utils.zeroHyphen
                  : this.invoicesGridFormatterService.getFormatterCreateAuthor(
                      val,
                      this.invoiceService.userFormatter
                    ),
              getQuickFilterText: (params: GetQuickFilterTextParams) =>
                this.invoiceService.userFormatter(params.value),
              minWidth: 150,
              width: 150,
              cellClass: this.getEditableClass(['text-left']),
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: (params) => params.valueFormatted,
              editable: false,
              suppressFillHandle: true,
            },
          ],
        };
      });
    });
  }

  tooltipValueGetter = (field: string) => (params: ITooltipParams<IInvoicesGridData>) => {
    const tooltip = get(params, field) || '';
    if (params.node) {
      const column = params.column as Column;
      if (
        column?.isCellEditable(params.node) ||
        this.isRowEditable(params as EditableCallbackParams<IInvoicesGridData>)
      ) {
        return tooltip;
      } else {
        if (
          column.getColId() === 'accrual_period' &&
          params.data?.invoice_status === InvoiceStatus.STATUS_DECLINED
        ) {
          const status = this.mainQuery.getSelectedTrial()?.implementation_status;
          if (
            status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED &&
            status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
          ) {
            return invoiceDisabledTooltip;
          }
          return 'Cannot edit accrual period for declined invoices.';
        }
        return invoiceDisabledTooltip;
      }
    }
    return tooltip;
  };

  getEditableClass =
    (classes: string[] = []) =>
    (params: CellClassParams) => {
      const cls = [...classes];
      if (this.editMode()) {
        if (
          params.column.isCellEditable(params.node) ||
          (params.column.getColId() === 'is_deposit' ? this.isRowEditable(params) : false)
        ) {
          cls.push(TableConstants.STYLE_CLASSES.EDITABLE_CELL);
        } else {
          cls.push(TableConstants.STYLE_CLASSES.NOT_EDITABLE_CELL, 'cursor-not-allowed');
        }
      }
      return cls;
    };

  checkChanges() {
    const currentValues: IInvoicesGridData[] = [];

    const gridAPI = this.gridAPI();
    if (!gridAPI) {
      return;
    }

    gridAPI.forEachNode(({ data }) => {
      currentValues.push(data);
    });

    this.hasChanges.set(!isEqual(this.initialValues, currentValues));
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.column.getColId() === 'vendor_id') {
      const data = { ...event.data, po_reference: null };
      event.node.setData(data);
      this.gridAPI()?.refreshCells();
    }
    // if the invoice status is changed to declined or from declined,
    // we need to clear the accrual period if it's before the current open month
    if (
      event.column.getColId() === 'invoice_status' &&
      event.oldValue === InvoiceStatus.STATUS_DECLINED
    ) {
      const trial = this.mainQuery.getSelectedTrial();
      if (
        trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
        trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
      ) {
        const { currentOpenMonth } = this.mainQuery.getValue();
        const data = {
          ...event.data,
          accrual_period: event.data.accrual_period
            ? dayjs(`${event.data.accrual_period}-01`).isBefore(currentOpenMonth)
              ? null
              : event.data.accrual_period
            : null,
        };
        event.node.setData(data);
        this.gridAPI()?.refreshCells();
      }
    }
    this.checkChanges();
  }

  firstDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
    api.setColumnVisible('is_deposit', this.invoicesDepositsEnabled());
  }

  async onNewInvoice() {
    this.overlayService.open({ content: NewInvoiceDialogComponent });
  }

  paginationChange(): void {
    this.fetchDocumentsForPage();
    setTimeout(() => {
      this.invoiceService.generatePinnedRow();
    });
  }

  onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
    this.gridAPI.set(api);
    this.invoiceService.setGridApi(api);

    this.datasource.initialize({
      untilDestroyedPipeOperator: takeUntilDestroyed(this.destroyRef),
      filters: this.serverSideFilters,
      filterValues$: this.filterValues$,
      sortModel$: this.sortModel$,
      parseFunction: (items) => {
        const gridData = items?.map((invoice) => {
          return {
            ...invoice,
            accrual_period: invoice.accrual_period
              ? dayjs(invoice.accrual_period).format('YYYY-MM')
              : null,
            line_items: JSON.parse(invoice.line_items || '[]'),
            ocr_line_items: JSON.parse(invoice.ocr_line_items || '[]'),
            po_reference: invoice.purchase_order_id,
            cards: [],
            file: undefined,
            hasError: false,
            organization: {
              id: invoice.vendor_id,
              name: invoice.vendor_name,
              currency: invoice.vendor_currency,
            },
            expense_amounts: this.getExpenseAmounts(invoice),
          } as unknown as IInvoicesGridData;
        });

        this.initialValues = cloneDeep(gridData);
        return gridData;
      },
    });

    this.vendorHasOneCurrencySubscription?.unsubscribe();
    this.vendorHasOneCurrencySubscription = this.datasource.aggregation$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (
          Object.prototype.hasOwnProperty.call(value, 'vendor_currency') &&
          value.vendor_currency === null
        ) {
          this.vendorHasOneCurrency = false;
        }
      });
  }

  checkError(params: CellClassParams<IInvoicesGridData>, cause: ArrElement<ErrorRow['causes']>) {
    const row = this.errorRows()[params.data?.id || ''];

    if (!this.showErrors() || !row) {
      return false;
    }

    return row.causes.includes(cause);
  }

  removeInvoices = async () => {
    const invoiceNumbers = this.selectedRows()
      .map(({ invoice_no }) => invoice_no)
      .filter((number) => number);
    const amountOfInvoicesWithoutNumber = this.selectedRows().length - invoiceNumbers.length;
    const invoiceNumbersMessage = amountOfInvoicesWithoutNumber
      ? `${invoiceNumbers.join(', ')} and ${amountOfInvoicesWithoutNumber} more`
      : invoiceNumbers.join(', ');
    const message =
      amountOfInvoicesWithoutNumber === this.selectedRows().length
        ? 'Are you sure you want to remove selected Invoices?'
        : `Are you sure you want to remove following Invoices: ${invoiceNumbersMessage}?`;

    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Invoices',
      message,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);

    if (event.data?.result) {
      const rows = this.selectedRows();
      this.rowsToDelete.set([
        ...rows.map((invoice) => ({ invoice, reason: event.data?.textarea || '' })),
      ]);
      this.gridAPI()?.applyServerSideTransaction({ remove: rows });
      this.selectedRows.set([]);
    }

    this.checkChanges();
  };

  checkRequireCostBreakdown(value: boolean) {
    this.showRequireCostBreakdown = value;
    this.datasource.updateOptions({
      overrideFilter: {
        invalid_cost_categorization: this.showInvalidCostCategorization,
        require_cost_breakdown: value,
        require_accrual_period: this.showRequireAccrualPeriod,
      },
    });

    this.datasource.forceRefresh();
  }

  checkRequireAccrualPeriod(value: boolean) {
    this.showRequireAccrualPeriod = value;
    this.datasource.updateOptions({
      overrideFilter: {
        invalid_cost_categorization: this.showInvalidCostCategorization,
        require_accrual_period: value,
        require_cost_breakdown: this.showRequireCostBreakdown,
      },
    });

    this.datasource.forceRefresh();
  }

  checkInvalidCostCategorization(value: boolean) {
    this.showInvalidCostCategorization = value;
    this.datasource.updateOptions({
      overrideFilter: {
        invalid_cost_categorization: value,
        require_accrual_period: this.showRequireAccrualPeriod,
        require_cost_breakdown: this.showRequireCostBreakdown,
      },
    });

    this.datasource.forceRefresh();
  }

  rowSelected(event: RowSelectedEvent) {
    this.selectedRows.set(event.api.getSelectedRows());
  }

  selectionChanged(event: SelectionChangedEvent): void {
    if (event.source === 'uiSelectAll') {
      this.isAllRowsOnCurrentPageSelected = !this.isAllRowsOnCurrentPageSelected;
      // eslint-disable-next-line
      // @ts-ignore
      if (this.isAllRowsOnCurrentPageSelected) {
        this.gridAPI()?.deselectAll();
        this.gridAPI()?.setNodesSelected({
          nodes: this.gridAPI()?.getRenderedNodes() || [],
          newValue: true,
        });
      } else {
        this.gridAPI()?.deselectAll();
      }
    }
  }

  resetEditMode() {
    this.showErrors.set(false);
    this.editMode.set(false);
    this.onEditModeOff();
    this.errorRows.set({});
    this.gridAPI()?.deselectAll();
    this.rowsToDelete.set([]);
    this.hasChanges.set(false);
    this.selectedRows.set([]);
    this.isAllRowsOnCurrentPageSelected = false;
  }

  checkIfInvoiceStatusChanged(invoice: InvoiceModel) {
    return (
      invoice.invoice_status !==
      this.initialValues.find((initialInvoice) => initialInvoice.id === invoice.id)?.invoice_status
    );
  }

  getInvoiceStatusChangeReason = (invoice: InvoiceModel, status: InvoiceStatus) =>
    this.checkIfInvoiceStatusChanged(invoice) && invoice.invoice_status === status
      ? 'In-line Status Change'
      : null;

  getCauses = (currentData: IInvoicesGridData, originalData: IInvoicesGridData) => {
    const causes: ArrElement<ErrorRow['causes']>[] = [];

    const status = this.mainQuery.getSelectedTrial()?.implementation_status;
    const shouldLookAtTheDates =
      status &&
      [
        TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED,
        TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE,
      ].includes(status);

    if (shouldLookAtTheDates) {
      if (currentData.payment_date) {
        if (dayjs(currentData.payment_date).isBefore(this.mainQuery.getValue().currentOpenMonth)) {
          causes.push('payment_date');
        }
      }

      if (
        currentData.accrual_period &&
        originalData.accrual_period !== currentData.accrual_period
      ) {
        if (
          dayjs(`${currentData.accrual_period}-01`).isBefore(
            this.mainQuery.getValue().currentOpenMonth
          )
        ) {
          causes.push('accrual_period');
        }
      }
    }

    if (!isInvoiceTotalEqual(currentData)) {
      causes.push('totals');
    }

    return causes;
  };

  onSave = async () => {
    const gridAPI = this.gridAPI();
    if (!gridAPI) {
      return;
    }
    this.paginationPageSize.set(gridAPI.paginationGetPageSize());

    const apiErrors: string[] = [];
    const currentValues: IInvoicesGridData[] = [];

    gridAPI.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
      });
    });

    const mapError = (result: boolean | { success: boolean; errors: string[] } | Error) => {
      if (result instanceof Error) {
        apiErrors.push(result.message);
      } else if (typeof result === 'object' && result?.success) {
        apiErrors.push(...result.errors);
      }
    };
    const changedRows = differenceWith(currentValues, this.initialValues, (obj1, obj2) => {
      return isEqual(omit(obj1, ['file']), omit(obj2, ['file']));
    });

    if (changedRows.length) {
      const errorRows: {
        causes: ('accrual_period' | 'payment_date' | 'totals')[];
        invoice: IInvoicesGridData;
      }[] = [];
      changedRows.forEach((invoice) => {
        const originalData = this.initialValues.find((v) => v.id === invoice.id);
        const currentData = currentValues.find((v) => v.id === invoice.id);

        if (!originalData || !currentData) {
          return;
        }

        const causes = this.getCauses(currentData, originalData);
        if (causes.length) {
          errorRows.push({ causes, invoice: originalData });
        }
      });

      this.errorRows.set(
        errorRows.reduce(
          (acc, obj) => {
            acc[obj.invoice.id] = obj;
            return acc;
          },
          {} as Record<string, ErrorRow>
        )
      );

      if (errorRows.length) {
        this.overlayService.error(
          errorRows.reduce((acc, { causes, invoice }) => {
            const invoiceNo = `Invoice #${invoice.invoice_no || ''} -`;

            causes.forEach((cause) => {
              if (cause === 'payment_date') {
                acc.push(`${invoiceNo} Payment Date cannot be set in a Closed Month.`);
              }

              if (cause === 'accrual_period') {
                acc.push(`${invoiceNo} Accrual Period cannot be set to a Closed Month.`);
              }

              if (cause === 'totals') {
                acc.push(`${invoiceNo} ${MessagesConstants.INVOICE.TOTAL_VALIDATION}`);
              }
            });

            return acc;
          }, [] as string[]),
          undefined,
          true
        );
        this.showErrors.set(true);
        gridAPI.refreshCells();
        this.savingChanges.set(false);
        return;
      }
    }

    this.savingChanges.set(true);

    if (this.rowsToDelete().length) {
      const deleteResults = await batchPromises(
        this.rowsToDelete(),
        ({ invoice, reason }) => {
          return this.invoiceService.remove(invoice, reason);
        },
        5
      );

      deleteResults.forEach(mapError);
    }

    if (changedRows.length) {
      const invoicesWithApproveRules = changedRows.filter(
        (invoice) =>
          [InvoiceStatus.STATUS_APPROVED, InvoiceStatus.STATUS_DECLINED].includes(
            invoice.invoice_status
          ) && this.checkIfInvoiceStatusChanged(invoice)
      );

      const approveResults = await batchPromises(
        invoicesWithApproveRules,
        ({ invoice_status, id }) => {
          return firstValueFrom(
            this.gqlService.approveRule$({
              approved: invoice_status === InvoiceStatus.STATUS_APPROVED,
              comments: '',
              permission: 'PERMISSION_APPROVE_INVOICE',
              approval_type: ApprovalType.APPROVAL_INVOICE,
              entity_id: id,
              entity_type: EntityType.INVOICE,
              activity_details: '{}',
            })
          );
        },
        5
      );

      approveResults.forEach(mapError);

      await this.invoiceService.batchUpdate(
        changedRows.map((invoice) => {
          return {
            ...invoice,
            po_reference: invoice.po_reference || null,
            accrual_period: invoice.accrual_period
              ? dayjs(invoice.accrual_period).format('YYYY-MM-DD')
              : null,
            invoice_date: invoice.invoice_date || null,
            due_date: invoice.due_date || null,
            payment_date: invoice.payment_date || null,
            decline_reason: this.getInvoiceStatusChangeReason(
              invoice,
              InvoiceStatus.STATUS_DECLINED
            ),
            admin_review_reason: this.getInvoiceStatusChangeReason(
              invoice,
              InvoiceStatus.STATUS_PENDING_REVIEW
            ),
          };
        }),
        false
      );
    }

    if (apiErrors.length) {
      this.overlayService.error(MessagesConstants.SOME_CHANGES_ARE_NOT_SAVED);
      this.apiErrors.set(apiErrors);
    } else {
      this.overlayService.success(MessagesConstants.SUCCESSFULLY_SAVED);
    }

    this.resetEditMode();
    this.savingChanges.set(false);
  };

  onCancel = () => {
    this.resetEditMode();
    this.datasource.forceRefresh();
  };

  enableEditMode = () => {
    this.editMode.set(true);
    this.onEditModeOn();
  };

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  onBulkApplyButtonClick = async () => {
    const gridApi = this.gridAPI();
    if (!gridApi) {
      return;
    }

    const ref = this.overlayService.open<BulkEditInvoicesResponse, BulkEditInvoicesData>({
      content: BulkEditInvoicesComponent,
      data: {
        rowLength: this.selectedRows().length,
      },
    });

    const { data } = await firstValueFrom(ref.afterClosed$);
    if (data?.success) {
      gridApi.applyServerSideTransaction({
        update: this.selectedRows().map((invoice) => {
          const { status, paymentStatus, vendor, accrualPeriod, purchaseOrder } = data.data;
          const getVal = (val: string | null) => (val === '' ? null : val);

          const isNewVendor = vendor == null ? false : vendor !== invoice.organization.id;
          const po_reference =
            purchaseOrder == null
              ? isNewVendor
                ? null
                : invoice.po_reference
              : getVal(purchaseOrder);

          return {
            ...invoice,
            invoice_status: status == null ? invoice.invoice_status : status,
            po_reference,
            accrual_period: accrualPeriod == null ? invoice.accrual_period : getVal(accrualPeriod),
            payment_status: paymentStatus == null ? invoice.payment_status : getVal(paymentStatus),
            organization: isNewVendor
              ? {
                  ...invoice.organization,
                  id: vendor,
                  name: this.invoiceService.vendorOptions.find(({ value }) => value === vendor)
                    ?.label,
                }
              : invoice.organization,
          };
        }),
      });
      this.checkChanges();
    }
  };

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  async onExportInvoices() {
    const gridAPI = this.gridAPI();
    if (!gridAPI) {
      return;
    }
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.INVOICES,
          trial_currency_selected: !this.isVendorCurrency(),
          filename: `${trialName}_auxilius-invoices__${dateStr}`,
          filter_model: this.datasource.currentServerInput?.filter_model || [],
          sort_model: this.sortModel$.getValue(),
          invalid_cost_categorization: this.showInvalidCostCategorization,
          require_cost_breakdown: this.showRequireCostBreakdown,
          require_accrual_period: this.showRequireAccrualPeriod,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
  }

  private isRowEditable(params: EditableCallbackParams<IInvoicesGridData>) {
    if (['create_date', 'created_by'].includes(params.column.getColId())) {
      return false;
    }

    const status = this.mainQuery.getSelectedTrial()?.implementation_status;

    const data = params.data;
    const originalData = this.initialValues.find((v) => v.id === data?.id);
    const openMonth = this.mainQuery.getValue().currentOpenMonth;

    if (!data || !status || !originalData) {
      return true;
    }

    if (
      status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED &&
      status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
    ) {
      return true;
    }

    if (params.column.getColId() === 'payment_status') {
      return true;
    }

    if (params.column.getColId() === 'payment_date') {
      const date = originalData.payment_date;
      return !(date && dayjs(date).isBefore(openMonth));
    }

    if (data.invoice_status === 'STATUS_DECLINED') {
      return params.column.getColId() !== 'accrual_period';
    }

    if (originalData.accrual_period) {
      if (originalData.invoice_status === InvoiceStatus.STATUS_DECLINED) {
        return true;
      }
      if (dayjs(`${originalData.accrual_period}-01`).isBefore(openMonth)) {
        return false;
      }
    }

    return true;
  }

  onCellValueChanged(params: NewValueParams): void {
    const oldData = this.initialValues.find((v) => v.id === params.data.id);

    if (!oldData) {
      return;
    }

    const causes = this.getCauses(params.data, oldData);
    this.errorRows.update((errors) => {
      return {
        ...errors,
        [params.data.id]: { causes, invoice: params.data },
      };
    });

    if (params.node) {
      params.api.refreshCells({
        rowNodes: [params.node],
        force: true,
      });
    }
  }

  private hasInvoiceCategoryOutsideBudget(params: ICellRendererParams): boolean {
    if (!params.data.organization.id || !params.data.expense_amounts.invoice_total.value) {
      return false;
    }

    const vendor = this.vendorCostCategories.find(
      (vendor) => vendor.organization_id === params.data.organization.id
    );

    return (
      (!!params.data.expense_amounts.services_total.value &&
        !vendor?.amount_types.includes(AmountType.AMOUNT_SERVICE)) ||
      (!!params.data.expense_amounts.discount_total.value &&
        !vendor?.amount_types.includes(AmountType.AMOUNT_DISCOUNT)) ||
      (!!params.data.expense_amounts.investigator_total.value &&
        !vendor?.amount_types.includes(AmountType.AMOUNT_INVESTIGATOR)) ||
      (!!params.data.expense_amounts.pass_thru_total.value &&
        !vendor?.amount_types.includes(AmountType.AMOUNT_PASSTHROUGH))
    );
  }

  private isCategoryOutsideBudget(
    params: CellClassParams<IInvoicesGridData>,
    field: string
  ): boolean {
    if (params.data?.organization.id && !!params.data?.expense_amounts.invoice_total.value) {
      const vendor = this.vendorCostCategories.find(
        (vendor) => vendor.organization_id === params.data?.organization.id
      );

      if (field === 'services_total') {
        return (
          !!params.data.expense_amounts.services_total.value &&
          !vendor?.amount_types.includes(AmountType.AMOUNT_SERVICE)
        );
      }

      if (field === 'discount_total') {
        return (
          !!params.data.expense_amounts.discount_total.value &&
          !vendor?.amount_types.includes(AmountType.AMOUNT_DISCOUNT)
        );
      }

      if (field === 'investigator_total') {
        return (
          !!params.data.expense_amounts.investigator_total.value &&
          !vendor?.amount_types.includes(AmountType.AMOUNT_INVESTIGATOR)
        );
      }

      if (field === 'pass_thru_total') {
        return (
          !!params.data.expense_amounts.pass_thru_total.value &&
          !vendor?.amount_types.includes(AmountType.AMOUNT_PASSTHROUGH)
        );
      }
    }

    return false;
  }

  private getExpenseAmounts(invoice: InvoiceDataStream) {
    return {
      invoice_total: this.mapExpenseAmount(
        AmountType.AMOUNT_TOTAL,
        invoice.total_contract_amount,
        invoice,
        true
      ),
      invoice_total_trial_currency: this.mapExpenseAmount(
        AmountType.AMOUNT_TOTAL,
        invoice.total_amount,
        invoice,
        false
      ),
      pass_thru_total: this.mapExpenseAmount(
        AmountType.AMOUNT_PASSTHROUGH,
        invoice.passthrough_contract_amount,
        invoice,
        true
      ),
      pass_thru_total_trial_currency: this.mapExpenseAmount(
        AmountType.AMOUNT_PASSTHROUGH,
        invoice.passthrough_amount,
        invoice,
        false
      ),
      services_total: this.mapExpenseAmount(
        AmountType.AMOUNT_SERVICE,
        invoice.service_contract_amount,
        invoice,
        true
      ),
      services_total_trial_currency: this.mapExpenseAmount(
        AmountType.AMOUNT_SERVICE,
        invoice.service_amount,
        invoice,
        false
      ),
      discount_total: this.mapExpenseAmount(
        AmountType.AMOUNT_DISCOUNT,
        invoice.discount_contract_amount,
        invoice,
        true
      ),
      discount_total_trial_currency: this.mapExpenseAmount(
        AmountType.AMOUNT_DISCOUNT,
        invoice.discount_amount,
        invoice,
        false
      ),
      investigator_total: this.mapExpenseAmount(
        AmountType.AMOUNT_INVESTIGATOR,
        invoice.investigator_contract_amount,
        invoice,
        true
      ),
      investigator_total_trial_currency: this.mapExpenseAmount(
        AmountType.AMOUNT_INVESTIGATOR,
        invoice.investigator_amount,
        invoice,
        false
      ),
    };
  }

  private mapExpenseAmount(
    amountType: AmountType,
    value: number | null | undefined,
    invoice: InvoiceDataStream,
    is_vendor_currency_amount: boolean
  ) {
    if (is_vendor_currency_amount) {
      return {
        value: value || 0,
        type: amountType,
        is_vendor_currency_amount,
        contract_curr: invoice.vendor_currency || Currency.USD,
      };
    } else {
      return {
        value: value || 0,
        type: amountType,
        is_vendor_currency_amount,
        exchange_rate: invoice.fx_rate || 1,
      };
    }
  }
}
