import { Injectable } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import {
  TrialInsightsTableModel,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsFinancePatientsEnrolledTableService implements TrialInsightsTableModel {
  route = ROUTING_PATH.FORECAST_ROUTING.INDEX;
  subRoute = ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST;

  createTable = (
    rowData: TrialInsightsTableRowData[] = [],
    investigatorForecastEnabled?: boolean
  ): TrialInsightsTableOptions => {
    const colors = ['#138EE7'];

    const data = rowData.map((row, index) => {
      row.color = colors[index];
      return row;
    });

    let route = this.route;
    let subRoute = this.subRoute;
    if (!investigatorForecastEnabled) {
      route = ROUTING_PATH.INVESTIGATOR.INDEX;
      subRoute = ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER;
    }

    return {
      buttons: {
        display: false,
        defaultButtonKey: 'patientEnrolled',
        data: [{ key: 'patientEnrolled', value: 'patientEnrolled' }],
      },
      header: {
        display: false,
        data: [
          {
            buttonKey: 'patientEnrolled',
            leftValue: 'patientEnrolled',
            rightValue: 'patientEnrolled',
          },
        ],
      },
      rowData: {
        compact: true,
        data: data,
      },
      link: {
        display: true,
        url: `/${route}/${subRoute}`,
        value: investigatorForecastEnabled ? 'View Investigator Forecast' : 'View Patient Tracker',
      },
    };
  };
}
