<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <div class="px-4 py-10 bg-white">
    <div *ngIf="authQuery.name$ | async as name" class="flex justify-between">
      <div *ngIf="cardUsers$ | async as user" class="grid">
        <div class="aux-black text-3xl font-bold mb-1">Welcome, {{ name }}</div>
        <div *ngIf="user[0]?.sponsor_organization?.name" class="aux-gray-dark-100 text-lg">
          {{ user[0].sponsor_organization.name }}
        </div>
      </div>
      <div *ngIf="trials$ | async as trials" class="flex items-center">
        <div *ngIf="(loadingRefreshPortfolioDashboard$ | async) === true" class="flex">
          <div class="spinner w-9 h-9"></div>
        </div>

        <div *ngIf="(loadingRefreshPortfolioDashboard$ | async) === false">
          <button class="btn--secondary pl-3" (click)="triggerRefreshPortfolioDashboard()">
            <aux-icon name="CloudDownload" class="mr-2" />
            Refresh
          </button>
        </div>
        <aux-export-excel-button
          [gridAPI]="gridAPI"
          [excelOptions]="excelOptions"
          [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
          [className]="'ml-2 mr-2 h-auto'"
          pendoTag="portfolio-dashboard-excel-export-button"
        />
        <aux-trial-dropdown
          [trials]="trials"
          [trialFormControl]="trialFc"
          [searchable]="true"
          [mainPage]="false"
        />
      </div>
    </div>
  </div>

  <div class="bg-aux-gray-light">
    <div class="xl:border-b"></div>

    <section class="grid grid-cols-3 gap-4 my-4 mx-7">
      <div
        [ngClass]="{
          'col-span-2': (showTaskSection$ | async) === true,
          'col-span-3': (showTaskSection$ | async) === false
        }"
      >
        <div class="bg-white border-aux-blue-dark border-l-8 flex justify-items-stretch mb-4">
          <div
            *ngIf="cardValues | async as val"
            class="border-aux-gray-dark xl:border-t xl:border-b xl:border-r p-2 w-full"
          >
            <div class="grid grid-cols-7 gap-y-4 bg-white pt-4 pb-4 w-full">
              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Total Current (LRE)</div>
                  <div class="text-xl text-aux-black">{{ val.clre | money }}</div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Total Baseline</div>
                  <div class="text-xl text-aux-black">{{ val.base | money }}</div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid items-center w-full">
                  <div class="text-sm text-aux-black">Variance ($)</div>
                  <div *ngIf="val.var_cost > 0" class="text-xl text-aux-black flex items-center">
                    {{ val.var_cost | money }}
                    <aux-icon name="CircleArrowUpFilled" class="text-aux-error ml-1" />
                  </div>
                  <div *ngIf="val.var_cost < 0" class="text-xl text-aux-black flex items-center">
                    {{ val.var_cost * -1 | money }}
                    <aux-icon name="CircleArrowDownFilled" class="ml-1 text-aux-green" />
                  </div>
                  <div
                    *ngIf="val.var_cost === 0"
                    class="items-center text-xl text-aux-black text-center"
                  >
                    —
                  </div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid items-center w-full">
                  <div class="text-sm text-aux-black">Variance (%)</div>
                  <div *ngIf="val.var_cost > 0" class="text-xl text-aux-black flex items-center">
                    {{ (val.var_cost / val.base) * 100 | number: '1.2-2' }}%
                    <aux-icon name="CircleArrowUpFilled" class="ml-1 text-aux-error" />
                  </div>
                  <div *ngIf="val.var_cost < 0" class="text-xl text-aux-black flex items-center">
                    {{ (val.var_cost / val.base) * -100 | number: '1.2-2' }}%
                    <aux-icon name="CircleArrowDownFilled" class="ml-1 text-aux-green" />
                  </div>
                  <div
                    *ngIf="val.var_cost === 0"
                    class="items-center text-xl text-aux-black text-center"
                  >
                    —
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Work Performed</div>
                  <div class="text-xl text-aux-black">{{ val.wpltd | money }}</div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Invoiced Total</div>
                  <div class="text-xl text-aux-black">{{ val.invoiced | money }}</div>
                </div>
              </div>

              <div
                *ngIf="(section_portfolio_analytics_avg_month_close$ | async) === true"
                class="flex justify-center"
              >
                <div class="border-r"></div>
              </div>

              <div
                *ngIf="(section_portfolio_analytics_avg_month_close$ | async) === true"
                class="flex"
              >
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Avg. Month Close</div>
                  <div class="text-xl text-aux-black">12 Days</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white border-l-8 border-aux-blue-dark flex justify-items-stretch">
          <div
            *ngIf="lineChart as lineChart"
            class="w-full border-aux-gray-dark xl:border-t xl:border-b xl:border-r"
          >
            <div class="grid mt-2">
              <canvas
                style="max-height: 600px; min-height: 400px"
                baseChart
                class="chart"
                [type]="lineChart.type"
                [data]="lineChart.data"
                [options]="lineChart.options"
              >
              </canvas>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="showTaskSection$ | async"
        class="col-span-1 relative border-l-8 border-aux-blue-dark"
      >
        <aux-portfolio-tasks class="absolute inset-0" />
      </div>
    </section>

    <div class="bg-aux-gray-light border-aux-gray-dark mt-4 mx-7 mb-16">
      <ag-grid-angular
        class="ag-theme-aux portfolio-grid tabular-nums w-full"
        [gridOptions]="$any(gridOptions$ | async)"
        [rowData]="$any(gridData$ | async)"
        [domLayout]="'autoHeight'"
        [enableBrowserTooltips]="true"
        (gridReady)="onGridReady($event)"
        (window:resize)="autoSize()"
      />
    </div>
  </div>
</ng-container>
