import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { RequireSome, Utils } from '@services/utils';
import {
  CellValueChangedEvent,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowDragEndEvent,
  RowNode,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, firstValueFrom, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, take } from 'rxjs/operators';
import { EventService } from '@services/event.service';
import { EventType, Organization, PatientGroupType } from '@services/gql.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { AgCheckboxRendererComponent } from '@components/ag-actions/ag-checkbox-renderer.component';
import { OrganizationQuery } from '@models/organization/organization.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { PatientGroupsService } from './state/patient-groups.service';
import { PatientGroupsQuery } from './state/patient-groups.query';
import { PatientGroupsModel } from './state/patient-groups.model';
import { PatientCurveService } from '../../../patient-curve/patient-curve.service';
import { PatientCurveQuery } from '../../../patient-curve/patient-curve.query';
import { TableConstants } from '@constants/table.constants';
import { MessagesConstants } from '@constants/messages.constants';
import { PatientGroupsStore } from './state/patient-groups.store';
import { MainQuery } from '@shared/store/main/main.query';
import { AgSetColumnsVisible } from '@shared/utils';

@UntilDestroy()
@Component({
  selector: 'aux-patient-groups',
  templateUrl: './patient-groups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientGroupsComponent {
  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      editable: () => {
        return this.editModeGrid$.getValue();
      },
      suppressMenu: true,
      suppressMovable: true,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressCopyRowsToClipboard: true,
    rowSelection: 'multiple',
    suppressMenuHide: true,
    rowClassRules: {
      'has-error': (params) => params.data.showError,
    },
    getRowClass: Utils.oddEvenRowClass,
    rowDragManaged: true,
    suppressCellFocus: false,
    enterNavigatesVertically: true,
    enterNavigatesVerticallyAfterEdit: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    getRowId: ({ data }) => data?.id || data?.randomID,
    columnDefs: [
      {
        headerName: 'id',
        field: 'id',
        hide: true,
      },
      {
        ...TableConstants.DEFAULT_GRID_OPTIONS.ACTIONS_COL_DEF,
        cellRendererParams: {
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (!rowNode.data.id) {
              this.gridAPI.applyTransaction({ remove: [rowNode.data] });
              if (this.addedRowCounter === 1) {
                this.reorderedRows.clear();
                this.removedRows = [];
                this.newRowAdded = false;
              } else {
                this.removedRows.pop();
              }
              this.addedRowCounter -= 1;
              this.checkChanges();
            } else {
              this.dialogConfirmRemovePatientGroup(rowNode);
            }
          },
        },
        rowDrag: true,
        hide: true,
      },
      {
        headerName: 'Patient Group',
        field: 'name',
        tooltipValueGetter: Utils.getDuplicateNameCellTooltip,
        cellClass: 'text-left',
        valueSetter: (params: ValueSetterParams) => {
          params.data.name = params.newValue.toString();

          return true;
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        tooltipValueGetter: Utils.getDuplicateNameCellTooltip,
        cellClass: 'text-left',
      },
      {
        headerName: 'Curve Only',
        field: 'curveOnly',
        minWidth: 70,
        maxWidth: 70,
        cellRenderer: AgCheckboxRendererComponent,
        cellRendererParams: {
          getDisabledState: () => !this.editModeGrid$.getValue(),
        },
        editable: false,
        suppressFillHandle: true,
      },
    ],
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'text-aux-error',
        font: { color: '#D73C37' },
      },
      {
        id: 'text-aux-green',
        font: { color: '#437F7F' },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
    ],
  } as GridOptions);

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Patient Groups',
    fileName: 'auxilius-patient-group.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    processCellCallback(params: ProcessCellForExportParams): string {
      if (params.column.getColId() === 'curveOnly') {
        return params.value ? 'Yes' : 'No';
      }
      return params.value;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'type':
          return 75;
        default:
          return 225;
      }
    },
  } as ExcelExportParams;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridData$ = this.patientGroupsQuery.selectAll().pipe(
    map((value) => {
      return value.map(({ id, name, description, type, rank_order, curveOnly }) => ({
        id,
        name,
        description,
        type,
        rank_order,
        curveOnly,
      }));
    })
  );

  editedRows = new Set<string>();

  removedRows: PatientGroupsModel[] = [];

  newRowAdded = false;

  addedRowCounter = 0;

  reorderQueue: { ids: string; new_index: number }[] = [];

  reorderedRows = new Set<string>();

  initialValues: PatientGroupsModel[] = [];

  hasChanges = false;

  valuesBeforeRemove: PatientGroupsModel[] = [];

  editModeGrid$ = new BehaviorSubject(false);

  loading$ = this.patientGroupsQuery.selectLoading();

  constructor(
    public overlayService: OverlayService,
    private mainQuery: MainQuery,
    public patientGroupsQuery: PatientGroupsQuery,
    public patientGroupsService: PatientGroupsService,
    public patientCurveService: PatientCurveService,
    public patientCurveQuery: PatientCurveQuery,
    private eventService: EventService,
    private changeDetector: ChangeDetectorRef,
    private patientGroupsStore: PatientGroupsStore,
    public organizationQuery: OrganizationQuery,
    private trialsQuery: TrialsQuery,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.patientGroupsService.get().pipe(untilDestroyed(this)).subscribe();
    this.patientCurveService.get().pipe(untilDestroyed(this)).subscribe();

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.initialValues = gridData;

      if (this.valuesBeforeRemove.length) {
        setTimeout(() => {
          this.gridAPI?.setGridOption('rowData', this.valuesBeforeRemove);
          this.valuesBeforeRemove = [];
        }, 0);
      }

      if (!this.hasChanges) {
        this.gridAPI?.setGridOption('rowData', gridData);
      }

      this.checkChanges();
    });

    this.gridAPI$
      .pipe(
        take(1),
        switchMap(() =>
          this.launchDarklyService.select$((flags) => flags.patient_groups_curve_only)
        )
      )
      .pipe(untilDestroyed(this))
      .subscribe((bool) => {
        AgSetColumnsVisible({
          gridApi: this.gridAPI,
          keys: ['curveOnly'],
          visible: bool,
        });
      });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initialValues = [];
        this.cancelEditMode();
      });
  }

  async canDeactivate(): Promise<boolean> {
    if (!this.editModeGrid$.getValue()) {
      return true;
    }

    this.checkChanges();

    if (this.hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);

      return !!event.data;
    }

    return true;
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridAPI$.next(api);
    api.sizeColumnsToFit();
    Utils.updateGridLayout(this.gridAPI, 'patientGroupsGrid');

    this.initialValues = this.getCurrentValues();
  }

  onAddPatientGroups() {
    const ss = this.gridAPI.applyTransaction({
      add: [{ randomID: Utils.uuid() }],
    });
    if (ss?.add.length && ss.add[0].rowIndex) {
      this.gridAPI.startEditingCell({
        rowIndex: ss.add[0].rowIndex,
        colKey: 'name',
      });
    }
    this.addedRowCounter += 1;
    this.newRowAdded = true;
    this.checkChanges();
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.id) {
      this.editedRows.add(event.data.id);
      this.checkChanges();
    }

    this.showErrors();
    this.gridAPI.redrawRows();
  }

  onSaveAll = async () => {
    if (this.showErrors()) {
      this.overlayService.error(MessagesConstants.ERROR_OCCURRED);
      this.gridAPI.redrawRows();
      return;
    }

    this.patientGroupsStore.setLoading(true);

    if (await this.savePatientGroups()) {
      this.overlayService.success(MessagesConstants.SUCCESSFULLY_SAVED);
    } else {
      this.overlayService.error(MessagesConstants.ERROR_OCCURRED);
    }

    this.patientGroupsStore.setLoading(false);
    this.cancelEditMode();
  };

  onRowDragEnd(event: RowDragEndEvent) {
    if (event.node.data?.id && event.node.rowIndex !== null) {
      this.reorderQueue.push({
        ids: event.node.data.id,
        new_index: event.node.rowIndex + 1,
      });
      this.reorderedRows.add(event.node.data.id);
      this.checkChanges();
    }
  }

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  editGrid(): void {
    this.editModeGrid$.next(true);

    if (this.gridAPI) {
      AgSetColumnsVisible({
        gridApi: this.gridAPI,
        keys: [TableConstants.FIELDS.ACTIONS],
        visible: true,
      });
    }

    this.sizeColumnsToFit();
    this.gridAPI.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'name',
    });
  }

  cancelEditMode(): void {
    this.gridAPI?.stopEditing();
    this.editModeGrid$.next(false);
    if (this.gridAPI) {
      AgSetColumnsVisible({
        gridApi: this.gridAPI,
        keys: [TableConstants.FIELDS.ACTIONS],
        visible: false,
      });
    }
    this.sizeColumnsToFit();
    this.gridData$ = this.patientGroupsQuery.selectAll().pipe(
      map((value) => {
        return value.map(({ id, name, description, type, rank_order, curveOnly }) => ({
          id,
          name,
          description,
          type,
          rank_order,
          curveOnly,
        }));
      })
    );
    this.resetChangeIndicators();
    this.gridAPI.redrawRows();
  }

  private resetChangeIndicators(): void {
    this.reorderedRows.clear();
    this.editedRows.clear();
    this.removedRows = [];
    this.reorderQueue = [];
    this.newRowAdded = false;
    this.addedRowCounter = 0;
    this.hasChanges = false;
    this.valuesBeforeRemove = [];
  }

  private getCurrentValues(): PatientGroupsModel[] {
    const currentValues: PatientGroupsModel[] = [];
    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
        description: data.description === '' ? null : data.description,
      });
    });

    return currentValues;
  }

  private checkChanges(): void {
    const currentValues = this.getCurrentValues();
    this.hasChanges = !isEqual(this.initialValues, currentValues);
    if (this.hasChanges) {
      this.changeDetector.markForCheck();
    }
  }

  private async dialogConfirmRemovePatientGroup(rowNode: RowNode) {
    const patient = this.patientCurveQuery.getAll();
    const curves = patient.filter((el) => el.patient_group_id === rowNode.data.id);

    const blendedCurves = patient.filter((el) =>
      el.constituent_patient_groups.find((c) => c.id === rowNode.data.id)
    );

    const organizations_with_forecasts = [...curves, ...blendedCurves].reduce(
      (acc: Organization[], el) => {
        const organizations = el.organizations_with_forecasts;
        organizations.forEach((org) => {
          if (!acc.find((e) => e.id === org.id)) {
            acc.push(org);
          }
        });
        return acc;
      },
      []
    );

    if (!curves.length && !blendedCurves.length) {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Patient Group',
        message: `Are you sure you want to remove ${rowNode.data.name}?<br>`,
        okBtnText: 'Remove',
      });

      firstValueFrom(resp.afterClosed$).then(({ data }) => {
        if (data?.result) {
          this.removedRows.push(rowNode.data);
          this.gridAPI.applyTransaction({ remove: [rowNode.data] });
          this.valuesBeforeRemove = this.getCurrentValues();
          this.checkChanges();
        }
      });
      return;
    }

    let list = `<br>Patient Curves:<br><ul class="list-disc pl-4">${curves
      .map(
        ({ name }) =>
          `<li><a class="aux-link" href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}">${name}</a></li>`
      )
      .join('')}${blendedCurves
      .map(
        ({ name }) =>
          `<li><a class="aux-link"  href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}">${name}</a></li>`
      )
      .join('')}</ul>`;

    if (organizations_with_forecasts.length) {
      list += `<br>Forecast Methodology:<br><ul class="list-disc pl-4">${organizations_with_forecasts
        .map(
          ({ name }) =>
            `<li><a class="aux-link" href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}">${name}</a></li>`
        )
        .join('')}</ul>`;
    }

    this.overlayService.openConfirmDialog({
      header: 'Remove Patient Group',
      message: `${rowNode.data.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${rowNode.data.name} can be successfully deleted.<br>${list}`,
      okBtnText: 'Remove',
      okBtnDisabled: true,
    });
  }

  private showErrors(): boolean {
    let isThereAnyInvalidRow = false;
    const uniqueNames = this.gridUniqueNames();

    this.gridAPI.forEachNode((node) => {
      const { name } = node.data as PatientGroupsModel;
      if (name && name.trim() && uniqueNames.includes(name)) {
        // eslint-disable-next-line no-param-reassign
        node.data.showError = false;
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
      }
    });

    return isThereAnyInvalidRow;
  }

  private gridUniqueNames(): string[] {
    const nodeNames = this.gridAPI.getRenderedNodes().map((node) => node.data?.name || '');
    return nodeNames.filter((el, i, arr) => arr.indexOf(el) === arr.lastIndexOf(el));
  }

  private parseChanges(): {
    updateData: PatientGroupsModel[];
    reorderData: PatientGroupsModel[];
    insertData: PatientGroupsModel[];
  } {
    const updateData: PatientGroupsModel[] = [];
    const reorderData: PatientGroupsModel[] = [];
    const insertData: PatientGroupsModel[] = [];

    if (
      this.reorderQueue.length < this.gridAPI.getDisplayedRowCount() &&
      !this.removedRows.length
    ) {
      this.reorderQueue.forEach(({ new_index, ids }) => {
        const pp = this.patientGroupsQuery.getEntity(ids);
        if (pp) {
          const { id, name, description, type, curveOnly } = pp;
          let dataType = type;
          if (type !== PatientGroupType.PATIENT_GROUP_BLENDED) {
            dataType = curveOnly
              ? PatientGroupType.PATIENT_GROUP_FORECAST_ONLY
              : PatientGroupType.PATIENT_GROUP_STANDARD;
          }
          updateData.push({
            id,
            name,
            description,
            type: dataType,
            rank_order: new_index,
          });
        }
      });
      this.gridAPI.forEachNode((node) => {
        const rowIndex = (node.rowIndex || 0) + 1;

        const { id, name, description, type, curveOnly } = node.data as RequireSome<
          PatientGroupsModel,
          'id' | 'name' | 'description' | 'type' | 'rank_order'
        >;

        let dataType = type;
        if (type !== PatientGroupType.PATIENT_GROUP_BLENDED) {
          dataType = curveOnly
            ? PatientGroupType.PATIENT_GROUP_FORECAST_ONLY
            : PatientGroupType.PATIENT_GROUP_STANDARD;
        }

        if (!id && !this.editedRows.has(id)) {
          insertData.push({
            id,
            name,
            description,
            type: dataType,
            rank_order: rowIndex,
          });
        }

        if (id && this.editedRows.has(id)) {
          updateData.push({
            id,
            name,
            description,
            type: dataType,
            rank_order: rowIndex,
          });
        }

        if (id) {
          reorderData.push({
            id,
            name,
            description,
            type: dataType,
            rank_order: rowIndex,
          });
        }
      });
    } else {
      this.gridAPI.forEachNode((node) => {
        const rowIndex = (node.rowIndex || 0) + 1;
        const { id, name, description, type, curveOnly } = node.data as RequireSome<
          PatientGroupsModel,
          'id' | 'name' | 'description' | 'type' | 'rank_order'
        >;

        let dataType = type;
        if (type !== PatientGroupType.PATIENT_GROUP_BLENDED) {
          dataType = curveOnly
            ? PatientGroupType.PATIENT_GROUP_FORECAST_ONLY
            : PatientGroupType.PATIENT_GROUP_STANDARD;
        }

        if (this.reorderQueue.length || !id || this.editedRows.has(id) || this.removedRows.length) {
          updateData.push({
            id,
            name,
            description,
            type: dataType,
            rank_order: rowIndex,
          });
        }

        if (!id && !this.editedRows.has(id)) {
          insertData.push({
            id,
            name,
            description,
            type: dataType,
            rank_order: rowIndex,
          });
        }

        if (id) {
          reorderData.push({
            id,
            name,
            description,
            type: dataType,
            rank_order: rowIndex,
          });
        }
      });
    }

    return { updateData, reorderData, insertData };
  }

  private async savePatientGroups(): Promise<boolean> {
    let errorList: string[] = [];
    const { updateData, reorderData, insertData } = this.parseChanges();

    if (this.removedRows.length) {
      for (const { id, name, description, type, rank_order } of this.removedRows) {
        errorList = errorList.concat(
          // eslint-disable-next-line no-await-in-loop
          (await this.patientGroupsService.remove({ id, name, description, type, rank_order }))
            .errors
        );
      }
    }

    if (updateData.length) {
      for (const { id, name, description, type, rank_order } of reorderData) {
        errorList = errorList.concat(
          // eslint-disable-next-line no-await-in-loop
          (await this.patientGroupsService.update({ id, name, description, type, rank_order }))
            .errors
        );
      }
    }

    if (insertData.length) {
      for (const pg of insertData) {
        errorList = errorList.concat(
          // eslint-disable-next-line prettier/prettier
          // eslint-disable-next-line no-await-in-loop
          (await this.patientGroupsService.add(pg)).errors
        );
      }
    }

    return !errorList.length;
  }

  getDynamicExcelParams(): ExcelExportParams {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }

    return {
      ...this.excelOptions,
      columnKeys: ['name', 'description', 'curveOnly'],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: 2,
              styleId: 'first_row',
            },
          ],
        },
      ],
    } as ExcelExportParams;
  }
}
