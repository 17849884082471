import { computed, Directive, inject, input } from '@angular/core';

import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventQuery } from '@models/event/event.query';
import { AuthService } from '@shared/store/auth/auth.service';
import { OverlayService } from '@services/overlay.service';
import { EventType, GqlService, WorkflowStep } from '@services/gql.service';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { InvoiceQuery } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.query';
import { UserTasksService } from '@models/user-tasks';
import { MessagesConstants } from '@constants/messages.constants';
import {
  InvoiceFormComponent,
  StatusCheckLogic,
} from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.component';
import { MainQuery } from '@shared/store/main/main.query';

@Directive({
  standalone: true,
})
export class InvoiceBannerButtonDirective {
  invoice = input.required<InvoiceModel>();
  invoiceFormComponent = input<InvoiceFormComponent>();
  workflowQuery = inject(WorkflowQuery);
  eventQuery = inject(EventQuery);
  authService = inject(AuthService);
  overlayService = inject(OverlayService);
  gqlService = inject(GqlService);
  invoiceService = inject(InvoiceService);
  invoiceQuery = inject(InvoiceQuery);
  userTaskService = inject(UserTasksService);
  mainQuery = inject(MainQuery);

  dontHavePermission = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  invoiceLockTooltip = this.workflowQuery.invoiceLockTooltip;
  isInvoiceFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );
  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  buttonDisabled = computed(() => {
    return this.isInvoiceFinalized() || this.iCloseMonthsProcessing();
  });

  statusCheck = computed(() => {
    const trial = this.mainQuery.selectedTrial();
    const { currentOpenMonth } = this.mainQuery.mainState();
    const invoice = this.invoice();

    return StatusCheckLogic({ invoice, trial, currentOpenMonth });
  });
}
