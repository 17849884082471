<form class="flex justify-between items-center my-4" [formGroup]="patientBudgetForm">
  <div class="flex items-center">
    <div class="mr-2 text-xs">Site:</div>
    <aux-multi-select-dropdown
      placeholder="Select"
      formControlName="site_ids"
      class="w-64 mr-3 mt-2"
      bindLabel="textToSearch"
      bindValue="value"
      [items]="$any(siteOptions$ | async)"
      [searchable]="true"
      [customOption]="true"
    />
  </div>
  <aux-currency-checkboxes [selectedCurrencies$]="$any(selectedCurrencies$)" />
</form>
<ag-grid-angular
  domLayout="autoHeight"
  class="ag-theme-aux"
  [gridOptions]="$any(gridOptions$ | async)"
  [rowData]="$any(gridData$ | async)"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="onDataRendered()"
  (gridSizeChanged)="gridSizeChanged()"
  (viewportChanged)="onWindowScroll()"
/>
