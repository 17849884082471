import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService } from '@services/api.service';
import { DatasourceService } from '@shared/services/datasource.service';
import { UserAuditLog } from '@services/gql.service';
import { Utils } from '@services/utils';
import {
  ServerSideColumnFilterType,
  ServerSideFilterInfo,
  ServerSideSortOrder,
  convertLocalDateToIsoTimestamp,
} from '@shared/utils/server-side-datasource';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'aux-data-streaming-demo-2',
  templateUrl: './data-streaming-demo-2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataStreamingDemo2Component {
  readonly datasource = this.datasourceService.userAuditLogDatasource;

  serverSideFilters: ServerSideFilterInfo<UserAuditLog>[] = [
    {
      column: 'create_date',
      type: ServerSideColumnFilterType.IsGreaterThanOrEqualTo,
      inputPropertyName: 'afterDate',
    },
  ];

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  sortModel$ = new BehaviorSubject<Array<ServerSideSortOrder<UserAuditLog>>>([]);

  exporting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private datasourceService: DatasourceService,
    private apiService: ApiService
  ) {
    this.datasource.initialize({
      untilDestroyedPipeOperator: untilDestroyed(this),
      filters: this.serverSideFilters,
      filterValues$: this.filterValues$,
      sortModel$: this.sortModel$,
      disableAutoRefresh: true,
      refreshGridDebounceMilliseconds: 0,
    });

    this.datasource.refresh$.pipe(untilDestroyed(this)).subscribe(() => {
      this.generateCsvFile().catch((err) => console.error('Failed to generate the csv file', err));
    });
  }

  onExportClick = () => {
    this.filterValues$.next({
      afterDate: convertLocalDateToIsoTimestamp('2023-09-01'),
    });

    this.sortModel$.next([
      {
        column: 'create_date',
        descending: true,
      },
      {
        column: 'id',
      },
    ]);

    this.datasource.forceRefresh();
  };

  async generateCsvFile(): Promise<void> {
    this.exporting$.next(true);
    try {
      console.log('Downloading all data for input', this.datasource.currentServerInput);
      const data = await this.datasource.downloadAll();
      const csv = Utils.objectToCsv(data.items);
      const blob = new Blob([csv]);
      this.apiService.downloadBlob(blob, 'audit-logs.csv');
    } finally {
      this.exporting$.next(false);
    }
  }
}
