import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  FormGroupDirective,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import dayjs from 'dayjs';
import { NgSelectModule } from '@ng-select/ng-select';

import { MessagesConstants } from '@constants/messages.constants';
import { DirectivesModule } from '@directives/directives.module';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { PurchaseOrdersQuery } from '@pages/vendor-payments-page/tabs/purchase-orders/state/purchase-orders.query';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { AuthService } from '@shared/store/auth/auth.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import {
  EventType,
  PermissionType,
  TrialImplementationStatus,
  WorkflowStep,
} from '@services/gql.service';
import { EventQuery } from '@models/event/event.query';
import { MainQuery } from '@shared/store/main/main.query';

import { InvoiceNotesComponent } from './invoice-notes.component';
import { Maybe, Utils } from '@services/utils';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { TrialModel } from '@models/trials/trials.store';
import { invoiceDisabledTooltip } from '@pages/vendor-payments-page/tabs/invoices/invoices.component';
import { isEqual } from 'lodash-es';

const initialFormValues = {
  selectedPOReference: '',
  due_date: '',
  notes: '',
  accrual_period: '',
  vendor_id: '',
  invoice_no: '',
  invoice_date: '',
  invoice_total: '',
  investigator_total: '',
  services_total: '',
  discount_total: '',
  pass_thru_total: '',
  invoice_total_trial_currency: '',
  pass_thru_total_trial_currency: '',
  services_total_trial_currency: '',
  discount_total_trial_currency: '',
  investigator_total_trial_currency: '',
};

const invoiceModelToForm = (invoice: InvoiceModel) => {
  return <typeof initialFormValues>{
    due_date: invoice.due_date,
    selectedPOReference: invoice.po_reference,
    notes: invoice.notes?.[0]?.message || '',
    accrual_period: invoice.accrual_period ? dayjs(invoice.accrual_period).format('YYYY-MM') : null,
    vendor_id: invoice.organization.id,
    invoice_no: invoice.invoice_no,
    invoice_date: invoice.invoice_date,
    invoice_total: Utils.agCurrencyFormatter(
      invoice.expense_amounts.invoice_total,
      invoice.expense_amounts.invoice_total.contract_curr || invoice.organization.currency
    ).toString(),
    investigator_total: Utils.agCurrencyFormatter(
      invoice.expense_amounts.investigator_total,
      invoice.expense_amounts.investigator_total.contract_curr || invoice.organization.currency
    ).toString(),
    services_total: Utils.agCurrencyFormatter(
      invoice.expense_amounts.services_total,
      invoice.expense_amounts.services_total.contract_curr || invoice.organization.currency
    ).toString(),
    discount_total: Utils.agCurrencyFormatter(
      invoice.expense_amounts.discount_total,
      invoice.expense_amounts.discount_total.contract_curr || invoice.organization.currency
    ).toString(),
    pass_thru_total: Utils.agCurrencyFormatter(
      invoice.expense_amounts.pass_thru_total,
      invoice.expense_amounts.pass_thru_total.contract_curr || invoice.organization.currency
    ).toString(),
    invoice_total_trial_currency: Utils.currencyFormatter(
      invoice.expense_amounts.invoice_total_trial_currency.value
    ).toString(),
    pass_thru_total_trial_currency: Utils.currencyFormatter(
      invoice.expense_amounts.pass_thru_total_trial_currency.value
    ).toString(),
    services_total_trial_currency: Utils.currencyFormatter(
      invoice.expense_amounts.services_total_trial_currency.value
    ).toString(),
    discount_total_trial_currency: Utils.currencyFormatter(
      invoice.expense_amounts.discount_total_trial_currency.value
    ).toString(),
    investigator_total_trial_currency: Utils.currencyFormatter(
      invoice.expense_amounts.investigator_total_trial_currency.value
    ).toString(),
  };
};

export const InvoiceFormToInvoiceModel = (
  formValues: typeof initialFormValues,
  invoice: InvoiceModel
) => {
  return <InvoiceModel>{
    ...invoice,
    due_date: formValues.due_date,
    po_reference: formValues.selectedPOReference,
    notes: formValues.notes ? [{ message: formValues.notes }] : [],
    accrual_period: formValues.accrual_period,
    organization: { id: formValues.vendor_id },
    invoice_no: formValues.invoice_no,
    invoice_date: formValues.invoice_date,
    expense_amounts: {
      invoice_total: {
        ...invoice.expense_amounts.invoice_total,
        value: Utils.reverseAccountingFormat(formValues.invoice_total),
      },
      investigator_total: {
        ...invoice.expense_amounts.investigator_total,
        value: Utils.reverseAccountingFormat(formValues.investigator_total),
      },
      services_total: {
        ...invoice.expense_amounts.services_total,
        value: Utils.reverseAccountingFormat(formValues.services_total),
      },
      discount_total: {
        ...invoice.expense_amounts.discount_total,
        value: Utils.reverseAccountingFormat(formValues.discount_total),
      },
      pass_thru_total: {
        ...invoice.expense_amounts.pass_thru_total,
        value: Utils.reverseAccountingFormat(formValues.pass_thru_total),
      },
      invoice_total_trial_currency: {
        ...invoice.expense_amounts.invoice_total_trial_currency,
        value: Utils.reverseAccountingFormat(formValues.invoice_total_trial_currency),
      },
      pass_thru_total_trial_currency: {
        ...invoice.expense_amounts.pass_thru_total_trial_currency,
        value: Utils.reverseAccountingFormat(formValues.pass_thru_total_trial_currency),
      },
      services_total_trial_currency: {
        ...invoice.expense_amounts.services_total_trial_currency,
        value: Utils.reverseAccountingFormat(formValues.services_total_trial_currency),
      },
      discount_total_trial_currency: {
        ...invoice.expense_amounts.discount_total_trial_currency,
        value: Utils.reverseAccountingFormat(formValues.discount_total_trial_currency),
      },
      investigator_total_trial_currency: {
        ...invoice.expense_amounts.investigator_total_trial_currency,
        value: Utils.reverseAccountingFormat(formValues.investigator_total_trial_currency),
      },
    },
  };
};

export const StatusCheckLogic = ({
  invoice,
  trial,
  currentOpenMonth,
}: {
  currentOpenMonth: string;
  invoice: InvoiceModel;
  trial: Maybe<TrialModel>;
}) => {
  if (!trial?.implementation_status) {
    return false;
  }

  if (
    trial.implementation_status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED &&
    trial.implementation_status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
  ) {
    return false;
  }

  if (invoice.invoice_status === 'STATUS_DECLINED') {
    return false;
  }

  if (!invoice.accrual_period) {
    return false;
  }

  let period = invoice.accrual_period || '';
  if (period.split('-').length === 1) {
    period = `${period}-01`;
  }
  return dayjs(period).isBefore(currentOpenMonth);
};

@Component({
  standalone: true,
  selector: 'aux-invoice-form',
  template: `
    <form #invoiceFormRef="ngForm" [formGroup]="invoiceForm">
      <div class="grid grid-cols-5 gap-5 p-5 rounded-t-md border mb-8">
        <div>
          <div class="text-sm mb-1">Vendor</div>
          <ng-select
            placeholder="Select"
            id="vendor"
            formControlName="vendor_id"
            auxFormError
            required
            [searchable]="false"
            [multiple]="false"
            [clearable]="true"
            [auxTooltip]="formTooltip()"
            (change)="onVendorChange()"
          >
            @for (vendor of orgQuery.allVendors(); track vendor.id) {
              <ng-option [value]="vendor.id">{{ vendor.name }}</ng-option>
            }
          </ng-select>
        </div>

        <div>
          <div class="text-sm mb-1">Invoice Number</div>
          <aux-input
            formControlName="invoice_no"
            class="mb-4"
            validators="required"
            [auxTooltip]="formTooltip()"
          />
        </div>

        <div>
          <div class="text-sm mb-1">PO Number</div>
          <ng-select
            placeholder="Select"
            id="po_reference"
            [searchable]="false"
            [multiple]="false"
            formControlName="selectedPOReference"
            [clearable]="true"
            [ngClass]="{
              'is-invalid': invoiceForm.controls.selectedPOReference.status === 'INVALID'
            }"
            [auxTooltip]="formTooltip()"
          >
            @for (po of filteredPONumbers(); track po.id) {
              <ng-option [value]="po.id">
                {{ po.po_number }}
              </ng-option>
            }
          </ng-select>
        </div>

        <div></div>
        <div></div>

        <div>
          <div class="text-sm mb-1">Invoice Total</div>
          <aux-input formControlName="invoice_total" [auxTooltip]="formTooltip()" />
        </div>

        <div>
          <div class="text-sm mb-1">Services Total</div>
          <aux-input formControlName="services_total" [auxTooltip]="formTooltip()" />
        </div>

        <div>
          <div class="text-sm mb-1">Discount Total</div>
          <aux-input formControlName="discount_total" [auxTooltip]="formTooltip()" />
        </div>

        <div>
          <div class="text-sm mb-1">Pass-Through Total</div>
          <aux-input formControlName="pass_thru_total" [auxTooltip]="formTooltip()" />
        </div>

        <div>
          <div class="text-sm mb-1">Investigator Total</div>
          <aux-input formControlName="investigator_total" [auxTooltip]="formTooltip()" />
        </div>

        @if (!isDecline()) {
          <div>
            <div class="text-sm mb-1">Accrual Period</div>
            <aux-input
              formControlName="accrual_period"
              [disabled]="accrualDisabled()"
              [type]="'month'"
              [max]="accrualMaxDate()"
              [min]="accrualMinDate()"
              [auxTooltip]="formTooltip()"
              [validators]="accrualValidators"
              [customErrorMessages]="customErrorMessages"
            />
          </div>
        }

        <div>
          <div class="text-sm mb-1">Invoice Date</div>
          <aux-input
            formControlName="invoice_date"
            validators="required"
            [type]="'date'"
            [auxTooltip]="formTooltip()"
          />
        </div>

        <div>
          <div class="text-sm mb-1">Due Date</div>
          <aux-input formControlName="due_date" [type]="'date'" [auxTooltip]="formTooltip()" />
        </div>
      </div>

      <aux-invoice-detail-invoice-notes
        [notes]="invoiceForm.controls.notes.value"
        (notesChange)="invoiceForm.controls.notes.setValue($event)"
        (saveNotes)="saveNotes()"
        (cancelNotes)="cancelNotes()"
      />
    </form>
  `,
  imports: [
    InputComponent,
    NgSelectModule,
    ReactiveFormsModule,
    TooltipDirective,
    NgClass,
    FormsModule,
    InvoiceNotesComponent,
    DirectivesModule,
  ],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceFormComponent {
  formBuilder = inject(NonNullableFormBuilder);
  workflowQuery = inject(WorkflowQuery);
  orgQuery = inject(OrganizationQuery);
  purchaseOrdersQuery = inject(PurchaseOrdersQuery);
  authQuery = inject(AuthQuery);
  authService = inject(AuthService);
  eventQuery = inject(EventQuery);
  mainQuery = inject(MainQuery);
  invoiceService = inject(InvoiceService);

  protected readonly messagesConstants = MessagesConstants;

  invoiceFormRef = viewChild.required<FormGroupDirective>('invoiceFormRef');

  invoice = input.required<InvoiceModel>();
  invoiceLockTooltip = this.workflowQuery.invoiceLockTooltip;
  formValues = signal(initialFormValues);
  initializing = signal(true);

  hasEditPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });
  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);
  isInvoiceFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  filteredPONumbers = computed(() => {
    const invoice = this.invoice();
    const { vendor_id } = this.formValues();
    const purchaseOrders = this.purchaseOrdersQuery.$selectAll();

    if (vendor_id) {
      return purchaseOrders.filter((po) => po.organization_id === vendor_id);
    }

    return purchaseOrders.filter((po) => po.id === invoice.organization.id);
  });
  isDecline = computed(() => this.invoice().invoice_status === 'STATUS_DECLINED');
  accrualDisabled = computed(() => {
    // todo confirm with the qa to remove this logic.
    // const { currentOpenMonth } = this.mainQuery.mainState();
    // const { accrual_period } = this.invoice();
    //
    // if (currentOpenMonth && accrual_period) {
    //   return !dayjs(`${accrual_period}-01`).isSameOrAfter(currentOpenMonth, 'month');
    // }
    return false;
  });
  accrualMinDate = computed(() => {
    const { currentOpenMonth } = this.mainQuery.mainState();
    return dayjs(currentOpenMonth).format('YYYY-MM');
  });
  accrualMaxDate = computed(() => {
    const { trialEndDate } = this.mainQuery.mainState();
    return dayjs(trialEndDate).format('YYYY-MM');
  });
  statusCheck = computed(() => {
    const trial = this.mainQuery.selectedTrial();
    const { currentOpenMonth } = this.mainQuery.mainState();
    const invoice = this.invoice();

    return StatusCheckLogic({ invoice, trial, currentOpenMonth });
  });
  isFormDisabled = computed(() => {
    const isAdmin = this.authQuery.adminUser();
    return (
      this.statusCheck() ||
      (!isAdmin && !this.hasEditPermission()) ||
      this.isInvoiceFinalized() ||
      !!this.iCloseMonthsProcessing()
    );
  });
  formTooltip = computed(() => {
    if (this.statusCheck()) {
      return invoiceDisabledTooltip;
    }
    if (this.isInvoiceFinalized()) {
      return this.invoiceLockTooltip();
    }
    return '';
  });

  invoiceForm = this.formBuilder.group(initialFormValues);
  autoAdjustedAccrual = false;
  userAdjustedAccrual = false;
  submit = false;

  patchForm = effect(
    () => {
      this.initializing.set(true);
      const invoice = this.invoice();
      this.invoiceForm.setValue(invoiceModelToForm(invoice), { emitEvent: false });

      this.initialFormValues = this.invoiceForm.getRawValue();
      this.formValues.set(this.invoiceForm.getRawValue());
      this.invoiceForm.markAsPristine();
      this.initializing.set(false);
    },
    {
      allowSignalWrites: true,
    }
  );
  disableEffect = effect(() => {
    if (this.isFormDisabled()) {
      this.invoiceForm.disable();
    } else {
      this.invoiceForm.enable();
    }
  });

  accrualValidators: ValidatorFn[] = [
    (control) => {
      if (!this.submit) {
        return null;
      }
      if (this.isFormDisabled() || this.accrualDisabled()) {
        return null;
      }

      const value = control.value;
      if (!value) {
        return { required: true };
      }

      const { currentOpenMonth } = this.mainQuery.mainState();
      const trial = this.mainQuery.selectedTrial();
      const accrualDate = dayjs(`${value}-01`);
      if (
        accrualDate.isBefore(currentOpenMonth, 'month') &&
        (trial?.implementation_status ===
          TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
          trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE)
      ) {
        return { before: true };
      }

      return null;
    },
  ];
  customErrorMessages = {
    before: '',
  };

  // on initialize or when invoice changes, we are updating the form values.
  // While we are updating the form values we shouldn't get any event in the valueChanges subscription.
  // in order to fix that we are using initializing signal.
  private initializing$ = toObservable(this.initializing);
  // needed for checking if the form values are changed or not.
  private initialFormValues = initialFormValues;

  constructor() {
    this.initializing$
      .pipe(
        takeUntilDestroyed(),
        filter((value) => !value),
        switchMap(() => this.invoiceForm.valueChanges),
        debounceTime(100)
      )
      .subscribe(() => {
        // todo remove later
        console.log('TEST', this.invoiceForm.getRawValue());
        const next = this.invoiceForm.getRawValue();
        this.customLogicToUpdateAccrualPeriod(this.formValues(), next);

        this.formValues.set(next);
      });
  }

  onVendorChange() {
    if (this.invoiceForm.controls.selectedPOReference.value) {
      this.invoiceForm.controls.selectedPOReference.setErrors({ message: 'error' });
    }
  }

  async saveNotes() {
    const invoice = this.invoice();
    const { notes } = this.formValues();

    if (invoice.notes?.length) {
      await this.invoiceService.updateInvoiceNote(invoice, notes);
    } else {
      await this.invoiceService.createInvoiceNote(invoice, notes);
    }
    this.invoiceForm.controls.notes.markAsPristine();
  }

  cancelNotes() {
    const invoice = this.invoice();
    this.invoiceForm.controls.notes.setValue(invoice.notes?.[0]?.message || '');
    this.invoiceForm.controls.notes.markAsPristine();
  }

  customLogicToUpdateAccrualPeriod(prev: typeof initialFormValues, next: typeof initialFormValues) {
    // invoice date was changed and user has not manually adjusted accrual
    if (next.invoice_date && prev.invoice_date !== next.invoice_date && !this.userAdjustedAccrual) {
      // timeout ensure that patchValue happens after current call
      setTimeout(
        () =>
          this.invoiceForm.patchValue(
            {
              accrual_period: this.getAccrualPeriodFromDate(next.invoice_date),
            },
            { emitEvent: false, onlySelf: true }
          ),
        0
      );
      this.autoAdjustedAccrual = true;
      // accrual was changed
    } else if (next.accrual_period !== prev.accrual_period) {
      if (this.autoAdjustedAccrual) {
        this.autoAdjustedAccrual = false;
      } else {
        this.userAdjustedAccrual = true;
      }
    }
  }

  getAccrualPeriodFromDate(invoiceDate: string): string {
    const monthClose = this.mainQuery.mainState().currentOpenMonth;
    const isInvoiceDateValid = invoiceDate && dayjs(invoiceDate).isAfter(monthClose, 'month');

    return dayjs(isInvoiceDateValid ? invoiceDate : this.accrualMinDate()).format('YYYY-MM');
  }

  canDeactivate() {
    return isEqual(this.invoiceForm.getRawValue(), this.initialFormValues);
  }
}
